import gql from 'graphql-tag'

const getTrelloBoards = gql`
  query getTrelloBoards($token: String!) {
    getTrelloBoards(token: $token) {
      id
      name
      desc
    }
  }
`

export default getTrelloBoards
