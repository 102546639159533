import gql from 'graphql-tag'

export const acceptRequestInvite = gql`
  mutation acceptRequestInvite($user_id: Float!, $team_id: Float!, $workspace_id: Float!) {
    acceptRequestInvite(user_id: $user_id, team_id: $team_id, workspace_id: $workspace_id) {
      data
      msg
      status
    }
  }
`
