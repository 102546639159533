import gql from 'graphql-tag'

export const convertToJob = gql`
  mutation ($job_id: Float!, $lexorank: String, $workspace_id: Float!) {
    convertToJob(job_id: $job_id, lexorank: $lexorank, workspace_id: $workspace_id) {
      status
      msg
      data
    }
  }
`
