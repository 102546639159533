import gql from 'graphql-tag'

export const convertToJobType = gql`
  mutation ($job_id: Float!, $workspace_id: Float!) {
    convertToJobType(job_id: $job_id, workspace_id: $workspace_id) {
      status
      msg
      data
    }
  }
`
