import gql from 'graphql-tag'

const updatePassword = gql`
  mutation updatePassword(
    $old_password: String!
    $new_password: String!
    $confirm_password: String!
  ) {
    updatePassword(
      old_password: $old_password
      new_password: $new_password
      confirm_password: $confirm_password
    ) {
      status
      msg
      data
    }
  }
`

export default updatePassword
