var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-center"},[(_vm.selectedIds)?_c('limit-elements',{staticClass:"v-avatar-group flex-grow-1",class:_vm.rootThemeClasses,attrs:{"elements":_vm.attachments,"limit":3},scopedSlots:_vm._u([{key:"default",fn:function(item){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","max-height":"26","max-width":"26"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                name: 'attachment-detail',
                params: { id: item.data.id },
              })}}},'v-btn',attrs,false),on),[_c('v-img',{staticClass:"rounded elevation-1",attrs:{"src":_vm.thumbnailURL(item.data),"width":"26","height":"26","aspect-ratio":"800/800"}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.data.file_name))])])]}},{key:"others",fn:function(item){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-1",attrs:{"icon":"","max-height":"26","max-width":"26"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                name: 'attachment-detail',
                params: { id: item.data.id },
              })}}},'v-btn',attrs,false),on),[_c('v-img',{staticClass:"rounded elevation-1",attrs:{"src":_vm.thumbnailURL(item.data),"width":"26","height":"26","aspect-ratio":"800/800"}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.data.file_name))])])]}},{key:"others-activator",fn:function(item){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-avatar',_vm._g(_vm._b({staticClass:"primary--text text-caption",attrs:{"size":"20","color":"white"}},'v-avatar',attrs,false),on),[_c('span',[_vm._v("+"+_vm._s(item.data.limit))])])]}}],null,true)},[_c('span',[_vm._v("Lihat File Lain")])])]}}],null,false,2238729163)}):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","outlined":"","small":"","icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.attachMenu = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAttachment)+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.attributeName))])]),(_vm.attachMenu)?_c('v-dialog',{attrs:{"max-width":"50vw","scrollable":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.attachMenu),callback:function ($$v) {_vm.attachMenu=$$v},expression:"attachMenu"}},[(_vm.attachments)?_c('attachment-grid',{attrs:{"selected-attachments":_vm.attachments,"allow-selection":true,"job-id":_vm.jobId,"name":_vm.name,"job-type-id":_vm.jobTypeId,"attribute-id":_vm.attributeId,"width":'50vw',"height":'322px'},on:{"select":function($event){return _vm.handleSelection($event)}}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }