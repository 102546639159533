<template>
  <v-form
    ref="commandForm"
    class="w-full"
    lazy-validation
    @submit.prevent="submit"
  >
    <h3 class="mb-4 mt-4">
      {{ updateMode ? 'Ubah Command' : 'Buat Command Baru' }}
    </h3>
    <v-alert
      border="left"
      color="warning"
      dense
      text
      type="info"
      class="mb-4 mt-2 text-subtitle-2 py-2"
    >
      <span class="text-caption"
        >Untuk saat ini command hanya dapat digunakan untuk channel Telegram.</span
      >
    </v-alert>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="formData.name"
          :rules="[required]"
          label="Nama Command"
          placeholder="Input nama command"
          outlined
          dense
          hide-details="auto"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="formData.command"
          :rules="[required, validateCommand]"
          label="Command"
          placeholder="Input perintah command"
          outlined
          dense
          hide-details="auto"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-textarea
          v-model="formData.description"
          label="Deskripsi Command"
          placeholder="Input deskripsi command"
          outlined
          dense
          hide-details="auto"
          :rules="[required]"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-textarea
          v-model="formData.respond"
          label="Respon"
          placeholder="Respon ketika command dipakai oleh pelanggan"
          outlined
          dense
          hide-details="auto"
          :rules="[required]"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-select
          v-model="formData.typeId"
          :items="commandTypeList"
          label="Tipe Command"
          outlined
          item-text="name"
          item-value="id"
          dense
          hide-details="auto"
          :rules="[required]"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-select
          v-model="formData.workspace_token_id"
          :items="tokenDetail"
          label="Koneksi Telegram"
          outlined
          item-text="connection_name"
          item-value="id"
          dense
          hide-details="auto"
          :rules="[required]"
        />
      </v-col>
    </v-row>
    <div class="d-flex flex-column flex-md-row mt-4">
      <v-btn
        class="mb-2 mb-md-0 mr-md-2 ml-md-auto"
        outlined
        @click="reset()"
      >
        Reset Form
      </v-btn>
      <v-btn
        color="primary"
        :loading="loadingSubmit"
        @click="submit()"
      >
        Simpan Command
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import Vue from 'vue'
import { ref, onMounted } from 'vue'
import { required } from '@core/utils/validation'
import useChatCommand from '@/composables/useChatCommand'
import useIntegration from '@/composables/useIntegration'
import { apolloClient } from '@/vue-apollo'
import { createChatBotCommand, updateChatBotCommand } from '@/graphql/mutations'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'

export default {
  props: {
    initialValue: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const commandForm = ref()
    const loadingSubmit = ref(false)
    const updateMode = ref(false)
    const formData = ref({
      id: null,
      name: '',
      command: '',
      description: '',
      typeId: 2,
      respond: '',
      workspace_token_id: null,
      platform_id: 1,
    })

    const reset = () => {
      updateMode.value = false
      loadingSubmit.value = false
      formData.value = {
        id: null,
        name: '',
        command: '',
        description: '',
        typeId: 2,
        respond: '',
        workspace_token_id: null,
        platform_id: 1,
      }
    }

    const submit = () => {
      if (commandForm.value.validate()) {
        loadingSubmit.value = true

        if (!updateMode.value) {
          apolloClient
            .mutate({
              mutation: createChatBotCommand,
              variables: {
                ...formData.value,
                workspace_id: store.getters.getCurrentWorkspaceId,
              },
            })
            .then(result => {
              Vue.notify({
                title: 'Sukses',
                text: 'Berhasil membuat command!',
              })
              reset()
              emit('success', result)
              loadingSubmit.value = false
            })
            .catch(err => {
              loadingSubmit.value = false
              errorHandling(err)
            })
        } else {
          apolloClient
            .mutate({
              mutation: updateChatBotCommand,
              variables: {
                ...formData.value,
                workspace_id: store.getters.getCurrentWorkspaceId,
              },
            })
            .then(result => {
              Vue.notify({
                title: 'Sukses',
                text: 'Berhasil mengubah command!',
              })
              reset()
              emit('success', result)
              loadingSubmit.value = false
            })
            .catch(err => {
              loadingSubmit.value = false
              errorHandling(err)
            })
        }
      }
    }

    const validateCommand = text => {
      if (!text.startsWith('/')) return 'Perintah harus diawali dengan simbol garis miring ("/")'

      return true
    }

    const { commandTypeList, getCommandTypeList } = useChatCommand()
    const { tokenDetail, getTokenDetail } = useIntegration()

    onMounted(() => {
      getCommandTypeList()
      getTokenDetail(1)

      if (props.initialValue) {
        const data = props.initialValue

        formData.value = {
          id: data.id,
          name: data.name,
          command: data.command,
          description: data.description,
          typeId: data.type.id,
          respond: data.respond,
          workspace_token_id: data.token.id,
          platform_id: data.platform.id,
        }

        updateMode.value = true
      }
    })

    return {
      commandForm,
      loadingSubmit,
      updateMode,
      formData,
      commandTypeList,
      tokenDetail,

      reset,
      submit,
      required,
      validateCommand,
    }
  },
}
</script>

<style></style>
