import gql from 'graphql-tag'

const getDriveFolderList = gql`
  query getDriveFolderList(
    $refresh_token: String!
    $search: String
    $page_token: String
    $page_size: Float!
  ) {
    getDriveFolderList(
      refresh_token: $refresh_token
      search: $search
      page_token: $page_token
      page_size: $page_size
    ) {
      incompleteSearch
      kind
      nextPageToken
      files {
        kind
        id
        parents
        name
        folderColorRgb
        iconLink
      }
    }
  }
`

export default getDriveFolderList
