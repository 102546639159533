import gql from 'graphql-tag'

const attachmentDetail = gql`
  query attachmentDetail($attachment_id: String!, $workspace_id: Float!) {
    attachmentDetail(attachment_id: $attachment_id, workspace_id: $workspace_id) {
      attachment_detail {
        id
        file_name
        file_type
        job {
          id
          name
          job_type {
            id
          }
        }
      }
      attachments {
        id
        file_name
        file_type
        file_size
        is_cover
        created_at
        modify_at
        user {
          id
          name
        }
      }
    }
  }
`

export default attachmentDetail
