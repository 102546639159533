import gql from 'graphql-tag'

export const archiveDocument = gql`
  mutation archiveDocument($documentUuid: String!, $workspaceId: Float!) {
    archiveDocument(document_uuid: $documentUuid, workspace_id: $workspaceId) {
      status
      msg
      data
    }
  }
`
