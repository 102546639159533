import gql from 'graphql-tag'

const getTelegramLink = gql`
  query getTelegramLink($file_id: String!, $workspace_id: Float!, $connection_name: String!) {
    getTelegramLink(
      file_id: $file_id
      workspace_id: $workspace_id
      connection_name: $connection_name
    )
  }
`

export default getTelegramLink
