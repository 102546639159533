import gql from 'graphql-tag'

export const addJobType = gql`
  mutation addJobType(
    $job_status: [paramJobStatusInitJobType!]!
    $job_priority: [paramJobPriorityInitJobType!]!
    $job_custom_attribute: [paramJobCustomAttributeInitJobType!]
    $user_permissions: [UserFolderPermission!]!
    $name: String!
    $folder_id: Float!
    $color: String!
    $is_public: Boolean!
    $workspace_id: Float!
  ) {
    addJobType(
      job_status: $job_status
      job_priority: $job_priority
      job_custom_attribute: $job_custom_attribute
      user_permissions: $user_permissions
      name: $name
      folder_id: $folder_id
      color: $color
      is_public: $is_public
      workspace_id: $workspace_id
    ) {
      status
      data
      msg
    }
  }
`
