import Vue, { ref, computed } from 'vue'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import { apolloClient } from '@/vue-apollo'
import { createFieldMapper } from 'vuex-use-fields'
import router from '@/router'
import {
  documentHierarchy as documentHierarchyQuery,
  documentDetail as documentDetailQuery,
  documentList as documentListQuery,
  documentTags as documentTagsQuery,
  documentSnapshots as documentSnapshotsQuery,
  documentParentHierarchy as documentParentHierarchyQuery,
} from '@/graphql/queries'
import {
  addDocument as addDocumentMutation,
  moveDocument as moveDocumentMutation,
  deleteDocument as deleteDocumentMutation,
  archiveDocument as archiveDocumentMutation,
  unarchiveDocument as unarchiveDocumentMutation,
  duplicateDocument as duplicateDocumentMutation,
  updateSnapshot as updateSnapshotMutation,
  updateDocumentAttribute,
  addDocumentTag,
  editDocumentTag,
  deleteDocumentTag,
  updateTagToDocument,
  changeDocumentOwnership as changeDocumentOwnershipMutation,
  updateDocumentPermission as updateDocumentPermissionMutation,
  updateDocumentLexorankAndParent as updateDocumentLexorankAndParentMutation,
} from '@/graphql/mutations'

const useFieldDocument = createFieldMapper({
  getter: 'document/getField',
  setter: 'document/setField',
})

const useDocument = () => {
  const state = {
    ...useFieldDocument(['search', 'sort', 'filter', 'filterActive', 'treeSearch', 'sortActive']),
  }
  const workspaceId = store.getters.getCurrentWorkspaceId

  const documentDetail = ref()
  const documentHierarchy = ref()
  const documentList = ref([])
  const documentTags = ref()
  const documentSnapshots = ref()

  const loadingDocument = ref(false)
  const loadingDocumentTags = ref(false)
  const loadingDocumentTree = ref(false)
  const loadingSnapshots = ref(false)

  const sort = computed(() =>
    state.sortActive.value
      ? state.sort.value.map(el => ({
          field: el.field,
          order: el.type,
        }))
      : null,
  )
  const filter = computed(() => (state.filterActive.value ? state.filter.value : null))
  const filterCount = computed(() => (state.filterActive.value ? state.filter.value.length : null))
  const sortCount = computed(() => (state.sortActive.value ? state.sort.value.length : null))
  const search = computed(() => state.search.value)
  const treeSearch = computed(() => (state.treeSearch.value ? state.treeSearch.value : null))
  const showArchived = ref('IS NOT SET')
  const snapshotCount = ref(0)
  const snapshotPagination = ref({
    offset: 0,
    limit: 20,
  })

  const fetchDetail = id => {
    loadingDocument.value = true
    apolloClient
      .query({
        query: documentDetailQuery,
        variables: {
          document_uuid: id,
          workspaceId,
        },
        fetchPolicy: 'no-cache',
      })
      .then(({ data }) => {
        documentDetail.value = data.documentDetail
      })
      .catch(err => {
        errorHandling(err)
      })
      .finally(() => {
        loadingDocument.value = false
      })
  }
  const fetchDocuments = async ({ rootOnly = true, filterSearchChildren = false } = {}) => {
    loadingDocument.value = true

    try {
      const { data } = await apolloClient.query({
        query: documentListQuery,
        variables: {
          workspace_id: workspaceId,
          filter_search_children: filterSearchChildren,
          get_root_only: rootOnly,
          sort: sort.value,
          filter: filter.value,
          search: search.value,
        },
        fetchPolicy: 'no-cache',
      })

      documentList.value = data.documents

      return data.documents
    } catch (err) {
      errorHandling(err)
    } finally {
      loadingDocument.value = false
    }
  }
  const fetchDocumentParentHierarchy = ({ rootOnly = true, filterSearchChildren = false } = {}) => {
    loadingDocument.value = true
    apolloClient
      .query({
        query: documentParentHierarchyQuery,
        variables: {
          workspace_id: workspaceId,
          filter_search_children: filterSearchChildren,
          get_root_only: rootOnly,
          sort: sort.value,
          filter: filter.value,
          search: search.value,
        },
        fetchPolicy: 'no-cache',
      })
      .then(({ data }) => {
        documentList.value = data.documents
      })
      .catch(err => {
        errorHandling(err)
      })
      .finally(() => {
        loadingDocument.value = false
      })
  }
  const fetchSnapshots = ({ id, fetchMore = false }) => {
    loadingSnapshots.value = true
    apolloClient
      .query({
        query: documentSnapshotsQuery,
        variables: {
          documentUuid: id,
          workspaceId,
          pagination: snapshotPagination.value,
        },
        fetchPolicy: 'no-cache',
      })
      .then(({ data }) => {
        snapshotCount.value = data.snapshots.count

        if (!fetchMore) {
          documentSnapshots.value = data.snapshots.snapshots

          return
        }

        documentSnapshots.value.push(...data.snapshots.snapshots)
      })
      .catch(err => {
        errorHandling(err)
      })
      .finally(() => {
        loadingSnapshots.value = false
      })
  }
  const fetchDocumentTags = cb => {
    loadingDocumentTags.value = true
    apolloClient
      .query({
        query: documentTagsQuery,
        variables: {
          workspaceId,
        },
        fetchPolicy: 'no-cache',
      })
      .then(({ data }) => {
        documentTags.value = data.documentTags
        if (cb) cb(data.documentTags)
      })
      .catch(err => {
        errorHandling(err)
      })
      .finally(() => {
        loadingDocumentTags.value = false
      })
  }
  const fetchDocumentHierarchy = ({ id }) => {
    loadingDocumentTree.value = true
    apolloClient
      .query({
        query: documentHierarchyQuery,
        variables: {
          search: treeSearch.value,
          workspaceId,
          documentUuid: id,
          showArchived: showArchived.value,
        },
        fetchPolicy: 'no-cache',
      })
      .then(({ data }) => {
        // eslint-disable-next-line prefer-destructuring
        documentHierarchy.value = data.documentHierarchy[0]
      })
      .catch(err => {
        errorHandling(err)
      })
      .finally(() => {
        loadingDocumentTree.value = false
      })
  }

  const addDocument = ({ id }, cb) => {
    loadingDocument.value = true
    apolloClient
      .mutate({
        mutation: addDocumentMutation,
        variables: {
          workspace_id: workspaceId,
          is_locked: false,
          parent_uuid: id || null,
          is_public: false,
          user_permissions: [],
        },
      })
      .then(({ data }) => {
        router.push({
          name: 'document-detail',
          params: { id: JSON.parse(data.addDocument.data).uuid },
        })
        if (cb) cb(data.addDocument)
      })
      .catch(err => {
        errorHandling(err)
      })
      .finally(() => {
        loadingDocument.value = false
      })
  }
  const updateDocument = ({ id, name, isLocked = false }, cb) => {
    apolloClient
      .mutate({
        mutation: updateDocumentAttribute,
        variables: {
          documentUuid: id,
          name,
          isLocked,
          workspaceId,
        },
      })
      .then(({ data }) => {
        if (cb) cb(data.updateDocumentAttribute)
      })
      .catch(err => {
        errorHandling(err)
      })
  }
  const moveDocument = ({ id, folderId }, cb) => {
    apolloClient
      .mutate({
        mutation: moveDocumentMutation,
        variables: {
          documentUuids: id,
          folderId,
          workspaceId,
        },
      })
      .then(({ data }) => {
        if (cb) cb(data.moveDocument)
      })
      .catch(err => {
        errorHandling(err)
      })
  }
  const duplicateDocument = ({ id }, cb) => {
    apolloClient
      .mutate({
        mutation: duplicateDocumentMutation,
        variables: {
          documentUuid: id,
          workspaceId,
        },
      })
      .then(({ data }) => {
        if (cb) cb(data.duplicateDocument)
      })
      .catch(err => {
        errorHandling(err)
      })
  }
  const archiveDocument = ({ id }, cb) => {
    apolloClient
      .mutate({
        mutation: archiveDocumentMutation,
        variables: {
          documentUuid: id,
          workspaceId,
        },
      })
      .then(({ data }) => {
        if (cb) cb(data.archiveDocument)
      })
      .catch(err => {
        errorHandling(err)
      })
  }
  const unarchiveDocument = ({ id }, cb) => {
    apolloClient
      .mutate({
        mutation: unarchiveDocumentMutation,
        variables: {
          documentUuid: id,
          workspaceId,
        },
      })
      .then(({ data }) => {
        if (cb) cb(data.unarchiveDocument)
      })
      .catch(err => {
        errorHandling(err)
      })
  }
  const deleteDocument = ({ id }, cb) => {
    apolloClient
      .mutate({
        mutation: deleteDocumentMutation,
        variables: {
          documentUuid: id,
          workspaceId,
        },
      })
      .then(({ data }) => {
        if (cb) cb(data.deleteDocument)
      })
      .catch(err => {
        errorHandling(err)
      })
  }
  const updateDocumentTag = ({ id, tagIds }, cb) => {
    apolloClient
      .mutate({
        mutation: updateTagToDocument,
        variables: {
          documentId: id,
          tagIds,
        },
      })
      .then(({ data }) => {
        if (cb) cb(data.updateDocumentTag)
      })
      .catch(err => {
        errorHandling(err)
      })
  }

  const addTag = ({ name }, cb) => {
    apolloClient
      .mutate({
        mutation: addDocumentTag,
        variables: {
          name,
          workspaceId,
        },
      })
      .then(({ data }) => {
        cb(data.addDocumentTag)
      })
      .catch(err => {
        errorHandling(err)
      })
  }

  /* TODO: Implement query delete document tag */
  const deleteTag = ({ id }, cb) => {
    apolloClient
      .mutate({
        mutation: deleteDocumentTag,
        variables: {
          tagId: id,
          workspaceId,
        },
      })
      .then(({ data }) => {
        if (cb) cb(data.deleteDocumentTag)
      })
      .catch(err => {
        errorHandling(err)
      })
  }
  const updateTag = ({ name, color, tagId }, cb) => {
    apolloClient
      .mutate({
        mutation: editDocumentTag,
        variables: {
          name,
          color,
          workspaceId,
          tagId,
        },
      })
      .then(({ data }) => {
        if (cb) cb(data.editDocumentTag)
      })
      .catch(err => {
        errorHandling(err)
      })
  }
  const updateSnapshot = ({ id, name }, cb) => {
    apolloClient
      .mutate({
        mutation: updateSnapshotMutation,
        variables: {
          name,
          workspaceId,
          snapshotId: id,
        },
      })
      .then(({ data }) => {
        if (cb) cb(data.updateSnapshot)
      })
      .catch(err => {
        errorHandling(err)
      })
  }
  const changeDocumentOwnership = async ({ documentUuid, userId }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: changeDocumentOwnershipMutation,
        variables: {
          document_uuid: documentUuid,
          user_id: userId,
          workspace_id: workspaceId,
        },
      })

      return data.changeDocumentOwnership
    } catch (err) {
      errorHandling(err)
      throw err
    }
  }
  const updateDocumentPermission = async ({ documentUuid, isPublic, userPermissions }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: updateDocumentPermissionMutation,
        variables: {
          workspace_id: workspaceId,
          document_uuid: documentUuid,
          is_public: isPublic,
          user_permissions: userPermissions,
        },
      })

      return data.updateDocumentPermission
    } catch (err) {
      errorHandling(err)
      throw err
    }
  }

  const updateDocumentLexorankAndParent = async (lexorank, document_uuid) => {
    loadingDocument.value = true

    try {
      const { data } = await apolloClient.mutate({
        mutation: updateDocumentLexorankAndParentMutation,
        variables: {
          lexorank,
          document_uuid,
          workspace_id: workspaceId,
        },
      })

      Vue.notify({
        title: 'Sukses',
        text: `Berhasil mengubah urutan dokumen!`,
      })

      return data.updateLexorank
    } catch (err) {
      errorHandling(err, 'Update Lexorank')
    } finally {
      loadingDocument.value = false
    }
  }

  return {
    fetchDocumentParentHierarchy,
    fetchDocumentHierarchy,
    fetchDocumentTags,
    fetchDocuments,
    fetchDetail,
    fetchSnapshots,
    addTag,
    deleteTag,
    documentTags,
    addDocument,
    updateDocument,
    deleteDocument,
    updateDocumentTag,
    updateTag,
    loadingDocumentTags,
    loadingDocumentTree,
    loadingSnapshots,
    archiveDocument,
    unarchiveDocument,
    duplicateDocument,
    moveDocument,
    updateSnapshot,
    showArchived,
    snapshotPagination,
    snapshotCount,
    changeDocumentOwnership,
    updateDocumentPermission,
    updateDocumentLexorankAndParent,

    search,
    treeSearch,
    documentHierarchy,
    documentDetail,
    documentSnapshots,
    documentList,
    loadingDocument,
    filterCount,
    sortCount,
  }
}

export default useDocument
