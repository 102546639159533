import gql from 'graphql-tag'

const referenceLogsByUser = gql`
  query ReferenceLogsByUser(
    $pagination: pagination!
    $categoryId: [Int!]
    $createdBy: [Int!]!
    $workspaceId: Float!
    $searchJobName: String
  ) {
    referenceLogsByUser(
      pagination: $pagination
      category_id: $categoryId
      created_by: $createdBy
      workspace_id: $workspaceId
      search_job_name: $searchJobName
    ) {
      count
      referenceLogs {
        id
        category_id
        created_at
        custom_attribute {
          id
          name
          data_type {
            id
            name
          }
        }
        from_value
        to_value
        from
        to
        group_id
        job {
          id
          name
          job_type {
            id
            name
            folder {
              id
              name
            }
          }
          parent {
            name
            id
          }
        }
        created_by {
          id
          name
        }
      }
    }
  }
`

export default referenceLogsByUser
