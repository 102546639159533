const { default: gql } = require('graphql-tag')

export const updateCustomAttributeFormula = gql`
  mutation UpdateCustomAttributeFormula(
    $jobTypeId: Float!
    $workspaceId: Float!
    $update: CustomAttributeUpdate!
  ) {
    updateCustomAttributeFormula(
      job_type_id: $jobTypeId
      workspace_id: $workspaceId
      update: $update
    ) {
      status
      msg
      data
    }
  }
`
