import gql from 'graphql-tag'

export const deleteJobStatus = gql`
  mutation ($workspace_id: Float!, $delete_id: [Float!]!, $job_type_id: Float!) {
    deleteJobStatus(workspace_id: $workspace_id, delete_id: $delete_id, job_type_id: $job_type_id) {
      status
      msg
      data
    }
  }
`
