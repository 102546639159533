import gql from 'graphql-tag'

export const unarchiveNotification = gql`
  mutation UnarchiveNotification($objectType: String!, $objectId: String!, $workspaceId: Float!) {
    unarchiveNotification(
      object_type: $objectType
      object_id: $objectId
      workspace_id: $workspaceId
    ) {
      status
      msg
      data
    }
  }
`
