import '@/plugins/syncfusion'
import '@/styles/styles.scss'
import 'vue-img-viewr/styles/index.css'
import Vue, { provide } from 'vue'
import VueAnimXYZ from '@animxyz/vue'
import '@animxyz/core'
import Notifications from 'vue-notification'
import { abilitiesPlugin } from '@casl/vue'
import ability from './plugins/acl/ability'
import { apolloProvider, apolloClient } from '@/vue-apollo'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import { DefaultApolloClient } from '@vue/apollo-composable'

Vue.use(VueAnimXYZ)
Vue.use(Notifications)
Vue.config.productionTip = false
Vue.use(abilitiesPlugin, ability)
Vue.use(VueViewer)

new Vue({
  router,
  store,
  vuetify,
  apolloProvider,
  setup() {
    provide(DefaultApolloClient, apolloClient)
  },
  render: h => h(App),
}).$mount('#app')
