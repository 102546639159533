import { getField, setField } from 'vuex-use-fields'

export default {
  namespaced: true,
  state: {
    customers: [],
    customersCount: 0,
    customerFilter: {
      raw_filter: [],
      custom_filter: [],
      customer_type: [],
    },
    search_field: [
      {
        field: 'name',
        label: 'Nama',
        value: true,
      },
      {
        field: 'email',
        label: 'Email',
        value: true,
      },
      {
        field: 'phone_number',
        label: 'Nomor HP',
        value: true,
      },
      {
        field: 'description',
        label: 'Deskripsi',
        value: true,
      },
      {
        field: 'address',
        label: 'Alamat',
        value: true,
      },
      {
        field: 'custom_attribute_values',
        label: 'Custom Field',
        value: false,
      },
    ],
    headerSort: [],
    sort: {
      label: 'Aktivitas Terakhir Terdekat',
      sort: 'last_activity_at',
      order: 'DESC',
    },
  },
  getters: {
    getField,
    filterCount(state) {
      let filterCount = 0

      const filter = state.customerFilter

      if (filter.raw_filter.length) {
        filterCount += filter.custom_filter.length + filter.customer_type.length
      }

      return filterCount
    },
  },
  mutations: {
    setField,
  },
  actions: {},
}
