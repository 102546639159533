import gql from 'graphql-tag'

const permissionsList = gql`
  query permissionsList {
    permissionsList {
      id
      name
    }
  }
`
export default permissionsList
