import gql from 'graphql-tag'

const insertBatchProduct = gql`
  mutation ($data: [paramProductBatch!]!, $workspace_id: Float!) {
    insertBatchProduct(data: $data, workspace_id: $workspace_id) {
      status
      msg
      data
    }
  }
`

export default insertBatchProduct
