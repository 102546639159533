import gql from 'graphql-tag'

const register = gql`
  mutation ($phone: String, $email: String!, $first_name: String!, $last_name: String!) {
    register(phone: $phone, email: $email, first_name: $first_name, last_name: $last_name) {
      status
      msg
      data
    }
  }
`

export default register
