import gql from 'graphql-tag'

const deleteLookup = gql`
  mutation DeleteLookup($workspace_id: Float!, $job_type_id: Float!, $id: [Float!]!) {
    deleteLookup(workspace_id: $workspace_id, job_type_id: $job_type_id, id: $id) {
      status
      msg
      data
    }
  }
`

export default deleteLookup
