<template>
  <v-row
    no-gutters
    class="align-center"
    id="serachInput"
  >
    <!-- Chip Group -->
    <template v-if="objectList.length">
      <v-col
        v-for="(item, index) in mapObjectIds"
        :key="item.id"
        cols="auto"
        class="me-2"
      >
        <v-chip
          v-if="index !== maxItems - 1"
          small
          close
          @click:close="
            objectType === 'document' ? $emit('remove', item.uuid) : $emit('remove', item.id)
          "
        >
          <span
            style="max-width: 60px; display: inline-block"
            class="text-truncate"
          >
            {{ item.name }}
          </span>
        </v-chip>
        <v-menu
          v-else
          style="z-index: 9999999"
        >
          <template #activator="{ on, attrs }">
            <v-chip
              small
              v-bind="attrs"
              v-on="on"
            >
              ...
            </v-chip>
          </template>
          <v-card :max-width="width">
            <v-card-text>
              <v-chip
                v-for="object in mapObjectList"
                :key="objectType === 'document' ? object.uuid : object.id"
                small
                close
                @click:close="
                  objectType === 'document' ? $emit('remove', item.uuid) : $emit('remove', item.id)
                "
              >
                {{ object.name.length > 9 ? `${object.name.substring(0, 9)}...` : object.name }}
              </v-chip>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-col>
    </template>

    <!-- Search Input -->
    <v-col
      class="flex-grow-1"
      style="min-width: 100px"
    >
      <v-text-field
        v-model="searchKeyword"
        :placeholder="objectType === 'document' ? 'Cari Dokumen' : 'Cari User / Team'"
        :loading="!objectList.length"
        hide-details
        dense
        :outlined="outlined"
        :autofocus="autoFocus"
      >
        <template #append>
          <v-icon
            v-if="selectedObjectIds.length"
            size="20"
            class="my-auto"
            @click="$emit('clear')"
          >
            {{ icons.mdiCloseCircle }}
          </v-icon>
        </template>
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { computed } from 'vue'
import { mdiCloseCircle } from '@mdi/js'

export default {
  props: {
    selectedObjectIds: {
      type: Array,
      default: () => [],
    },
    objectList: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 200,
    },
    maxItems: {
      type: Number,
      default: 10,
    },
    objectType: {
      type: String,
      required: true,
      validator: value => ['document', 'user'].includes(value),
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const mapObjectIds = computed(() => {
      switch (props.objectType) {
        case 'document':
          return props.selectedObjectIds
        default:
          return props.selectedObjectIds
            .slice(0, props.maxItems)
            .map(id => props.objectList.find(user => user.id === id))
      }
    })

    const mapObjectList = computed(() => {
      switch (props.objectType) {
        case 'document':
          return props.objectList.filter(document =>
            props.selectedObjectIds.slice(props.maxItems - 1).includes(document.uuid),
          )
        default:
          return props.objectList.filter(user =>
            props.selectedObjectIds.slice(props.maxItems - 1).includes(user.id),
          )
      }
    })

    const searchKeyword = computed({
      get() {
        return props.search
      },
      set(val) {
        return emit('input-search', val)
      },
    })

    return {
      searchKeyword,
      mapObjectIds,
      mapObjectList,

      icons: {
        mdiCloseCircle,
      },
    }
  },
}
</script>
