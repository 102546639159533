import gql from 'graphql-tag'

const deleteRelation = gql`
  mutation DeleteRelation($jobId: Float!, $parentId: Float!) {
    deleteRelation(job_id: $jobId, parent_id: $parentId) {
      status
      msg
      data
    }
  }
`

export default deleteRelation
