import gql from 'graphql-tag'

const documentHierarchy = gql`
  fragment details on docHierarchy {
    uuid
    name
    archived_at
    matchContent
    matchContentHighlight
    created_by {
      id
      name
      photo
    }
    document_permissions {
      id
      user {
        id
        name
      }
      document {
        uuid
      }
      permission_type
    }
  }

  query documentHierarchy(
    $showArchived: String!
    $search: String
    $documentUuid: String!
    $workspaceId: Float!
  ) {
    documentHierarchy(
      show_archived: $showArchived
      search: $search
      document_uuid: $documentUuid
      workspace_id: $workspaceId
    ) {
      ...details
      children {
        ...details
        children {
          ...details
          children {
            ...details
            children {
              ...details
              children {
                ...details
                children {
                  ...details
                  children {
                    ...details
                    children {
                      ...details
                      children {
                        ...details
                        children {
                          ...details
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default documentHierarchy
