<template>
  <v-menu
    v-model="isOpenFilter"
    max-width="350px"
    :close-on-content-click="false"
    offset-y
    outlined
  >
    <template #activator="{ props }">
      <v-btn
        height="72"
        outlined
        text
        class="btn-block"
        v-bind="props"
        block
        color="primary"
        @click="showFilter"
      >
        Filter
        <v-badge
          :value="filterCount > 0"
          color="primary"
          :content="filterCount"
          overlap
          inline
          class="align-self-center"
        />
      </v-btn>
    </template>

    <v-card
      class="mx-auto"
      max-width="400"
      location="bottom"
    >
      <div class="d-flex justify-space-between mb-4">
        <span class="text-h6 font-medium primary--text" />
        <v-icon
          class="mr-2 mt-2"
          @click="closeFilter"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-card-text>
        <h2 class="text-h6 mb-2">Filter Role</h2>

        <v-chip-group
          v-model="state.filter.value.roleSelect"
          column
          multiple
          color="#3D5AFE"
        >
          <v-chip
            outlined
            @click="fetchWorkspaceMember()"
          >
            Admin
          </v-chip>
          <v-chip
            outlined
            @click="fetchWorkspaceMember()"
          >
            Member
          </v-chip>
          <v-chip
            outlined
            @click="fetchWorkspaceMember()"
          >
            Guest
          </v-chip>
        </v-chip-group>
      </v-card-text>
      <v-card-text>
        <h2 class="text-h6 mb-2">Filter Status</h2>

        <v-chip-group
          v-model="state.filter.value.statusSelect"
          column
          multiple
          color="primary"
        >
          <v-chip
            outlined
            @click="fetchWorkspaceMember()"
          >
            Belum Verifikasi
          </v-chip>
          <v-chip
            outlined
            @click="fetchWorkspaceMember()"
          >
            Terverifikasi
          </v-chip>
          <v-chip
            outlined
            @click="fetchWorkspaceMember()"
          >
            Disuspen
          </v-chip>
          <v-chip
            outlined
            @click="fetchWorkspaceMember()"
          >
            Diban
          </v-chip>
          <v-chip
            outlined
            @click="fetchWorkspaceMember()"
          >
            Pending (belum user)
          </v-chip>
        </v-chip-group>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { computed, ref } from 'vue'
import { apolloClient } from '@/vue-apollo'
import { workspaceMembers } from '@/graphql/queries'
import errorHandling from '@/utils/errorHandling'
import { createFieldMapper } from 'vuex-use-fields'
import { mdiClose } from '@mdi/js'

const useFieldWorkspaceMember = createFieldMapper({
  getter: 'workspaceMember/getField',
  setter: 'workspaceMember/setField',
})

export default {
  props: {
    workspace: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  // data: () => ({
  //   roleSelect: [],
  //   statusSelect: [],
  // }),
  setup(props) {
    const isOpenFilter = ref(false)

    const state = {
      ...useFieldWorkspaceMember([
        'loadingMember',
        'memberList',
        'sortCount',
        'filter',
        'memberCount',
      ]),
    }
    const filterCount = computed(() => {
      let count = 0
      if (state.filter.value.roleSelect.length !== 0) count += state.filter.value.roleSelect.length
      if (state.filter.value.statusSelect.length !== 0)
        count += state.filter.value.statusSelect.length

      return count
    })
    const fetchWorkspaceMember = () => {
      state.loadingMember.value = true
      apolloClient
        .query({
          query: workspaceMembers,
          fetchPolicy: 'no-cache',
          variables: {
            search: state.filter.value.search,
            workspace_id: props.workspace.id,
            pagination: {
              limit: 20,
              offset: 0,
            },
            filter: {
              role_id: state.filter.value.roleSelect.map(v => v + 1),
              user_status: state.filter.value.statusSelect.map(v => v + 1),
              sort: state.filter.value.sortSelect.value,
              order: state.filter.value.sortSelect.sortType[state.filter.value.isASC].type,
            },
          },
        })
        .then(({ data }) => {
          state.memberList.value = data.workspaceMembers.members
          state.memberCount.value = data.workspaceMembers.users_count
          let newData = []
          state.memberList.value = state.memberList.value.map(val => {
            newData = val
            // newData.user.photoPreview = URL.createObjectURL(val.user.photo)
            // console.log(val.user.photo, newData.user.photoPreview)

            return newData
          })

          state.loadingMember.value = false
        })
        .catch(err => {
          state.loadingMember.value = false
          errorHandling(err)
          console.log(err)
        })
    }

    const showFilter = () => {
      isOpenFilter.value = true
      // console.log(isOpenFilter)
    }

    const closeFilter = () => {
      isOpenFilter.value = false
      // console.log(isOpenFilter)
    }

    // const addRoleFilter = val => {
    //   const index = state.filter.value.roleSelect.indexOf(val)
    //   if (index !== -1) {
    //     // If the val is already in the array, remove it
    //     state.filter.value.roleSelect.splice(index, 1)
    //   } else {
    //     // If the val is not in the array, add it
    //     state.filter.value.roleSelect.push(val)
    //   }
    //   fetchWorkspaceMember()
    // }

    // const addStatusFilter = val => {
    //   const index = state.filter.value.statusSelect.indexOf(val)
    //   if (index !== -1) {
    //     // If the val is already in the array, remove it
    //     state.filter.value.statusSelect.splice(index, 1)
    //   } else {
    //     // If the val is not in the array, add it
    //     state.filter.value.statusSelect.push(val)
    //   }
    //   fetchWorkspaceMember()
    // }

    return {
      fetchWorkspaceMember,
      state,
      icons: {
        mdiClose,
      },
      showFilter,
      closeFilter,
      isOpenFilter,
      filterCount,
    }
  },
}
</script>
<style lang="scss">
.btn-block {
  width: 100%; /* Make the button take up 100% of the parent's width */
  box-sizing: border-box; /* Include padding and border in the total width */
}
</style>
