import gql from 'graphql-tag'

const jobTypes = gql`
  query jobTypes($workspace_id: Float!, $folder_id: Float!, $filter: ParamJobType!) {
    jobTypes(workspace_id: $workspace_id, folder_id: $folder_id, filter: $filter) {
      id
      name
      is_public
      my_role
      color
      owner {
        id
        name
        photo
      }
      archived_at
    }
  }
`

export default jobTypes
