<template>
  <v-dialog
    v-model="isOpen"
    max-width="720"
  >
    <v-card>
      <div class="d-flex justify-space-between text-h5 mb-4 pa-4">
        <div>Upgrade Plan</div>
        <v-icon @click="close()">
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-card-text>
        <div
          v-if="item"
          class="d-flex justify-center align-center flex-column text-h6"
        >
          <div>
            Plan anda saat ini adalah <strong>{{ userData.subscription.name }}</strong>
          </div>
          <div class="mt-1">Berlaku hingga {{ formatDate(userData.subscription_ends_at) }}</div>
          <div class="mt-3">
            Anda akan meningkatkan plan tersebut menjadi <strong>{{ item.name }}</strong>
          </div>
          <div class="mt-1">
            Anda akan membayar sebesar
            <strong>{{
              formatCurrency(upgradePrice(userData, item, userData.subscription_ends_at))
            }}</strong>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="mt-2">
        <v-spacer />
        <v-btn
          color="primary"
          large
          @click="
            addSubsTransaction(
              userData,
              item,
              upgradePrice(userData, item, userData.subscription_ends_at),
            )
          "
        >
          Tingkatkan Plan
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { computed, ref } from 'vue'
import { formatCurrency, formatDate } from '@core/utils/filter'
import { mdiClose } from '@mdi/js'
import { createSubsTransaction } from '@/graphql/mutations'
import store from '@/store'
import { apolloClient } from '@/vue-apollo'
import errorHandling from '@/utils/errorHandling'

export default {
  setup() {
    const { snap } = window
    const isOpen = ref(false)
    const item = ref(null)
    const show = param => {
      isOpen.value = true
      item.value = param
    }
    const close = () => {
      isOpen.value = false
    }

    const userData = computed(() => store.state.auth.userData)
    const addSubsTransaction = (user, newPlan, price) => {
      console.log(price)
      apolloClient
        .mutate({
          mutation: createSubsTransaction,
          fetchPolicy: 'no-cache',
          variables: {
            param: {
              order_id: `ORIENS-${store.getters.getUserData.id}-${Date.now()}-${newPlan.id}-${
                user.payment_interval === '1' ? '1' : '12'
              }-UP`,
              item: {
                id: newPlan.id,
                name: `Upgrade ${user.subscription.name} ke ${newPlan.name}`,
                price,
                quantity: 1,
              },
            },
          },
        })
        .then(async ({ data }) => {
          console.log(data.createSubsTransaction.data)
          await snap.pay(data.createSubsTransaction.data)
        })
        .catch(err => {
          errorHandling(err)
        })
    }

    const upgradePrice = (user, newPlan, endDate) => {
      const endDateTime = new Date(endDate).getTime()
      const dateNow = new Date()
      const nowDateTime = new Date(dateNow.toISOString().split('T')[0]).getTime()
      const dayDiff = (endDateTime - nowDateTime) / (1000 * 3600 * 24)
      let price = 0
      console.log(newPlan.price_month)
      console.log(user.subscription.price_month)
      if (user.payment_interval === 'monthly') {
        price = Math.ceil(((newPlan.price_month - user.subscription.price_month) / 30) * dayDiff)
      } else if (user.payment_interval === 'yearly') {
        price = Math.ceil(((newPlan.price_year - user.subscription.price_year) / 365) * dayDiff)
      }

      return price
    }

    return {
      isOpen,
      show,
      close,
      item,
      formatCurrency,
      addSubsTransaction,
      icons: {
        mdiClose,
      },
      userData,
      formatDate,
      upgradePrice,
    }
  },
}
</script>
<style scoped>
.plan-duration-card-active {
  border: 2px solid var(--v-primary-base) !important;
  color: var(--v-primary-base) !important;
}
</style>
