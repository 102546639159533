import gql from 'graphql-tag'

const deletePost = gql`
  mutation ($post_id: Float!) {
    deletePost(post_id: $post_id) {
      status
      msg
      data
    }
  }
`

export default deletePost
