import gql from 'graphql-tag'

const tokopediaGetProducts = gql`
  query tokopediaGetProducts(
    $page: Float!
    $per_page: Float!
    $connection_name: String!
    $workspace_id: Float!
  ) {
    tokopediaGetProducts(
      page: $page
      per_page: $per_page
      connection_name: $connection_name
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`

export default tokopediaGetProducts
