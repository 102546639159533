import gql from 'graphql-tag'

const unarchiveFolder = gql`
  mutation unarchiveFolder($workspace_id: Float!, $folder_id: Float!) {
    unarchiveFolder(workspace_id: $workspace_id, folder_id: $folder_id) {
      status
      msg
      data
    }
  }
`

export default unarchiveFolder
