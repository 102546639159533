import gql from 'graphql-tag'

export const updateTeam = gql`
  mutation updateTeam(
    $team_id: Float!
    $name: String!
    $channel_permission: Boolean!
    $workspace_id: Float!
    $description: String
  ) {
    updateTeamAttribute(
      team_id: $team_id
      name: $name
      channel_permission: $channel_permission
      workspace_id: $workspace_id
      description: $description
    ) {
      data
      msg
      status
    }
  }
`
