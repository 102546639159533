import gql from 'graphql-tag'

export const addJob = gql`
  mutation addJob(
    $assign_user_id: [Int!]
    $expected_close_date: DateTime
    $start_date: DateTime
    $description: String
    $job_priority_id: Float
    $customer_id: Float
    $name: String!
    $job_status_id: Float!
    $job_type_id: Float!
    $workspace_id: Float!
    $job_product: [jobProduct!]
    $custom_attribute: [customAttributeValues!]!
    $parent_id: Float
  ) {
    addJob(
      assign_user_id: $assign_user_id
      expected_close_date: $expected_close_date
      start_date: $start_date
      description: $description
      job_priority_id: $job_priority_id
      customer_id: $customer_id
      name: $name
      job_product: $job_product
      job_status_id: $job_status_id
      job_type_id: $job_type_id
      workspace_id: $workspace_id
      custom_attribute: $custom_attribute
      parent_id: $parent_id
    ) {
      status
      msg
      data
    }
  }
`
