<template>
  <dashboard
    ref="dash"
    :uppy="uppyInstance"
    :props="uppyOptions"
    :plugins="['ImageEditor']"
  />
</template>

<script>
import Vue from 'vue'
import { computed } from 'vue'
import { Dashboard } from '@uppy/vue'

import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import DropTarget from '@uppy/drop-target'
import ImageEditor from '@uppy/image-editor'

export default {
  components: {
    Dashboard,
  },
  props: {
    meta: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const backendBaseUrl = new URL(process.env.VUE_APP_GRAPHQL_HTTP).origin

    const uppyInstance = computed(() =>
      new Uppy({
        metaFields: [
          {
            id: 'name',
            name: 'Name',
            placeholder: 'Nama File',
          },
        ],
        meta: props.meta,
        theme: 'dark',
      })
        .use(ImageEditor, { quality: 0.8 })
        .use(DropTarget, { target: document.body })
        .use(XHRUpload, {
          endpoint: `${backendBaseUrl}/attachment`,
          fieldName: 'files',
          formData: true,
          bundle: false,
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          getResponseData(responseText) {
            const result = JSON.parse(responseText)
            const { data, msg } = result
            Vue.notify({
              title: 'Sukses!',
              text: msg,
            })

            uppyInstance.value.close()
            emit('uploaded', data)
          },
        })
        .on('file-added', file => {
          uppyInstance.value.setFileMeta(file.id, {
            size: file.size,
          })
        }),
    )

    const uppyOptions = {
      metaFields: [
        {
          id: 'name',
          name: 'Name',
          placeholder: 'file name',
          render({ value, onChange, fieldCSSClasses }, h) {
            const point = value.lastIndexOf('.')
            const name = value.slice(0, point)
            const ext = value.slice(point + 1)

            return h('input', {
              class: fieldCSSClasses.text,
              type: 'text',
              value: name,
              onChange: event => onChange(`${event.target.value}.${ext}`),
              'data-uppy-super-focusable': true,
            })
          },
        },
      ],
    }

    return {
      uppyInstance,
      uppyOptions,
    }
  },
}
</script>
<style lang="scss">
@import '~@uppy/core/dist/style.css';
@import '~@uppy/dashboard/dist/style.css';
@import '~@uppy/image-editor/dist/style.css';
@import '~@/styles/uppy-overrides.scss';
</style>
