export const sortOptions = [
  { label: 'Nama', value: 'name' },
  { label: 'Waktu dibuat', value: 'created_at' },
  { label: 'Aktivitas terakhir', value: 'last_activity_at' },
]

const dateOrder = [
  {
    label: 'Terbaru',
    value: 'DESC',
  },
  {
    label: 'Terlama',
    value: 'ASC',
  },
]

export const orderOptions = {
  name: [
    {
      label: 'A - Z',
      value: 'ASC',
    },
    {
      label: 'Z - A',
      value: 'DESC',
    },
  ],
  created_at: dateOrder,
  last_activity_at: dateOrder,
}
