import gql from 'graphql-tag'

const getCalendarColor = gql`
  query getCalendarColor($refresh_token: String!) {
    getCalendarColor(refresh_token: $refresh_token) {
      calendar {
        id
        background
        foreground
      }
      event {
        id
        background
        foreground
      }
      kind
      updated
    }
  }
`

export default getCalendarColor
