import gql from 'graphql-tag'

const updateTask = gql`
  mutation updateStatusRole($user_id: Float!, $role_id: Float!, $workspace_id: Float!) {
    updateStatusRole(user_id: $user_id, role_id: $role_id, workspace_id: $workspace_id) {
      status
      msg
      data
    }
  }
`
export default updateTask
