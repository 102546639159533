import gql from 'graphql-tag'

export const notificationReferences = gql`
  query notificationReferences($workspace_id: Float!, $document_uuid: String, $job_id: Float) {
    notificationReferences(
      workspace_id: $workspace_id
      document_uuid: $document_uuid
      job_id: $job_id
    ) {
      count
      users {
        id
      }
    }
  }
`
