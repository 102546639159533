import gql from 'graphql-tag'

const getRelationTypeFromJobType = gql`
  query getRelationTypeFromJobType($jobTypeId: Float!, $workspaceId: Float!) {
    jobTypeDetail(job_type_id: $jobTypeId, workspace_id: $workspaceId) {
      job_type_relation_type {
        id
        name
        color
        is_active
        multiple_record
        lookup {
          id
          name
          attribute_key
          function_name
        }
        job_type {
          id
          name
        }
        relate_to_job_type {
          id
          name
        }
        opposite_relation_type {
          id
          name
          color
          is_active
          multiple_record
          job_type {
            id
            name
          }
          relate_to_job_type {
            id
            name
          }
        }
      }
    }
  }
`
export default getRelationTypeFromJobType
