import gql from 'graphql-tag'

export const teams = gql`
  query teams(
    $pagination: pagination
    $search: String
    $workspace_id: Float!
    $sort: [sortTeam!]
    $filter: [filterTeam!]
    $userId: [Float!]
  ) {
    teams(
      pagination: $pagination
      search: $search
      workspace_id: $workspace_id
      sort: $sort
      filter: $filter
      user_id: $userId
    ) {
      count
      teams {
        id
        name
        description
        parent {
          id
          name
        }
        users {
          permission_type
          user {
            id
            phone
            email
            name
            first_name
            last_name
            photo
          }
        }
        created_by {
          id
          phone
          email
          name
          first_name
          last_name
          photo
        }
        sub_team_count
        channel_permission
        is_public
        last_activity_at
        created_at
        updated_at
        deleted_at
        archived_at
        userCount
        status
      }
    }
  }
`
