import gql from 'graphql-tag'

const deleteUserCard = gql`
  mutation deleteUserCard($card_id: Float!) {
    deleteUserCard(card_id: $card_id) {
      status
      msg
      data
    }
  }
`

export default deleteUserCard
