<template>
  <v-menu
    v-model="editMode"
    max-width="300"
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        text
        x-small
        v-on="on"
        v-bind="attrs"
        :block="block"
        :plain="block"
        v-ripple="!block"
        elevation="0"
        class="font-weight-regular"
        :class="block ? 'justify-start px-0 date-input-button' : ''"
        style="padding-left: 5px; padding-right: 5px"
        @click.prevent.stop="editMode = true"
      >
        <slot>
          <span>{{ value ? dateFormat(value, 3) : '-' }}</span>
        </slot>
      </v-btn>
    </template>

    <DateTimeSelect
      v-model="localValue"
      @reset="localValue = value"
    />
  </v-menu>
</template>

<script setup>
import { ref, watch } from 'vue'
import DateTimeSelect from './components/DateTimeSelect.vue'
import dateFormat from '@/utils/dateFormat'

const emit = defineEmits(['input'])
const props = defineProps({
  value: {
    type: String,
    default: '',
  },
  block: {
    type: Boolean,
    default: false,
  },
})

const localValue = ref(props.value)
const editMode = ref(false)

watch(editMode, val => {
  if (!val) emit('input', localValue.value)
})
</script>

<style lang="scss">
.date-input-button {
  .v-btn__content {
    opacity: 1 !important;
  }
}
</style>
