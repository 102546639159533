/**
 * @module viewer/viewercommand
 */
import { Command } from 'ckeditor5/src/core'
import ImageUtils from '@ckeditor/ckeditor5-image/src/imageutils'

export default class ViewerCommand extends Command {
  static get requires() {
    return [ImageUtils]
  }

  refresh() {
    const { editor } = this
    const imageUtils = editor.plugins.get('ImageUtils')
    const element = imageUtils.getClosestSelectedImageElement(editor.model.document.selection)

    this.isEnabled = !!element
  }

  execute() {
    const { editor } = this
    const { model } = editor
    const imageUtils = editor.plugins.get('ImageUtils')
    const imageElement = imageUtils.getClosestSelectedImageElement(model.document.selection)

    // eslint-disable-next-line no-underscore-dangle
    return imageElement._attrs.get('src')
  }
}
