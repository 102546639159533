import gql from 'graphql-tag'

export const updateJobStatus = gql`
  mutation updateJobStatus(
    $workspace_id: Float!
    $job_status: [paramUpdateJobStatus!]!
    $job_type_id: Float!
  ) {
    updateJobStatus(
      workspace_id: $workspace_id
      job_status: $job_status
      job_type_id: $job_type_id
    ) {
      status
      data
      msg
    }
  }
`
