import gql from 'graphql-tag'

const archiveTask = gql`
  mutation archiveTask($is_archive: Boolean!, $id: Float!, $workspace_id: Float!) {
    archiveTask(is_archive: $is_archive, id: $id, workspace_id: $workspace_id) {
      status
      msg
      data
    }
  }
`
export default archiveTask
