import gql from 'graphql-tag'

export const updateDocumentLexorankAndParent = gql`
  mutation updateDocumentLexorankAndParent(
    $lexorank: String!
    $document_uuid: String!
    $workspace_id: Float!
    $parent_document_uuid: String
  ) {
    updateDocumentLexorankAndParent(
      lexorank: $lexorank
      document_uuid: $document_uuid
      workspace_id: $workspace_id
      parent_document_uuid: $parent_document_uuid
    ) {
      status
      msg
      data
    }
  }
`
