<template>
  <v-dialog
    v-model="isOpen"
    scrollable
    max-width="1280"
  >
    <v-card
      v-if="isOpen"
      height="80vh"
    >
      <v-navigation-drawer
        v-model="isLeftSidebarOpen"
        width="260"
        touchless
        mobile-breakpoint="sm"
        :temporary="$vuetify.breakpoint.smAndDown"
        absolute
        class="right-sidebar"
      >
        <div class="d-flex flex-column h-full py-4 px-2">
          <div class="d-flex flex-column mx-2 mb-1">
            <span class="text-caption primary--text mb-2 font-medium">Profil Saya</span>
            <span
              v-if="profileData.first_name"
              class="text-subtitle-2"
              >{{ `${profileData.first_name} ${profileData.last_name}` }}</span
            >
            <span
              v-if="profileData.email"
              class="text-caption text--disabled"
              >{{ profileData.email }}</span
            >
          </div>
          <v-divider class="my-2" />
          <v-list
            rounded
            dense
          >
            <v-list-item-group
              v-model="selectedTab"
              color="primary"
              mandatory
            >
              <v-list-item
                v-for="(tab, index) in tabItems"
                :key="index"
                @click="
                  isLeftSidebarOpen && $vuetify.breakpoint.smAndDown
                    ? (isLeftSidebarOpen = !isLeftSidebarOpen)
                    : null
                "
              >
                <v-list-item-icon>
                  <v-icon>{{ tab.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ tab.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-btn
            outlined
            small
            text
            color="error"
            class="mt-auto mx-2"
            @click="$store.dispatch('logout')"
          >
            Logout
          </v-btn>
        </div>
      </v-navigation-drawer>

      <div
        :style="{
          'margin-left': isLeftSidebarOpen && $vuetify.breakpoint.mdAndUp ? '260px' : null,
        }"
        class="main-area h-full"
      >
        <div
          class="w-full h-full d-flex flex-column"
          style="position: relative"
        >
          <div class="d-flex pt-6 mb-4">
            <v-icon
              class="ml-4"
              @click="isLeftSidebarOpen = !isLeftSidebarOpen"
            >
              {{ isLeftSidebarOpen ? icons.mdiChevronDoubleLeft : icons.mdiChevronDoubleRight }}
            </v-icon>
            <h3
              class="mx-auto"
              :class="{ 'text-subtitle-2 font-weight-bold': $vuetify.breakpoint.width < 500 }"
            >
              {{ tabItems[selectedTab].title }}
            </h3>
            <v-icon
              class="mr-6"
              @click="isOpen = !isOpen"
            >
              {{ icons.mdiClose }}
            </v-icon>
          </div>

          <v-tabs-items
            v-model="selectedTab"
            touchless
            class="flex-fill overflow-auto"
            style="height: 100%"
          >
            <v-tab-item>
              <GeneralInformation />
            </v-tab-item>
            <!-- <v-tab-item>
              <v-card-text class="d-flex flex-column h-full"> Performa </v-card-text>
            </v-tab-item> -->
            <v-tab-item>
              <UsagePlan ref="usagePlanTab" />
            </v-tab-item>
            <v-tab-item>
              <payment-method @changeSubscribe="changeSubscribe()" />
            </v-tab-item>
            <v-tab-item>
              <my-transaction />
            </v-tab-item>
            <v-tab-item
              class="flex-fill"
              style="max-height: 100%"
            >
              <NotificationSettings />
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { getVuetify } from '@/@core/utils'
import store from '@/store'
import {
  mdiAccount,
  mdiBell,
  mdiCashMultiple,
  mdiChartBox,
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiClose,
  mdiCreditCard,
} from '@mdi/js'
import { useStorage } from '@vueuse/core'
import Vue, { onMounted, ref, watchEffect } from 'vue'
import GeneralInformation from './component/GeneralInformation.vue'
import MyTransaction from './component/MyTransaction.vue'
import NotificationSettings from './component/NotificationSettings.vue'
import PaymentMethod from './component/PaymentMethod.vue'
import UsagePlan from './component/UsagePlan.vue'

export default {
  components: {
    GeneralInformation,
    UsagePlan,
    MyTransaction,
    PaymentMethod,
    NotificationSettings,
  },
  setup(props, { emit }) {
    const isOpen = ref(false)
    const usagePlanTab = ref(null)
    const isLeftSidebarOpen = ref(false)
    const selectedTab = useStorage('workspaceTabItem', 0)
    const tabItems = [
      {
        title: 'Informasi Akun',
        icon: mdiAccount,
      },
      // {
      //   title: 'Performa Akun',
      //   icon: mdiChartTimelineVariantShimmer,
      // },
      {
        title: 'Penggunaan dan Tagihan',
        icon: mdiChartBox,
      },
      {
        title: 'Metode Pembayaran',
        icon: mdiCreditCard,
      },
      {
        title: 'Riwayat Transaksi',
        icon: mdiCashMultiple,
      },
      {
        title: 'Notifikasi',
        icon: mdiBell,
      },
    ]
    const profileData = ref({
      first_name: '',
      last_name: '',
      email: '',
      photo: null,
      photoPreview: '',
    })

    const $vuetify = getVuetify()

    watchEffect(() => {
      if ($vuetify.breakpoint.mdAndUp) isLeftSidebarOpen.value = true
      else isLeftSidebarOpen.value = false
    })

    const getUserData = async () => {
      /* eslint-disable camelcase */
      const { first_name, last_name, email, phone, photo } = await store.getters.getUserData

      profileData.value = {
        first_name,
        last_name,
        email,
        phone,
        photoPreview: photo,
      }
    }

    const show = () => {
      selectedTab.value = 0

      isOpen.value = true
      isLeftSidebarOpen.value = true
    }

    const close = () => {
      isOpen.value = false
    }

    onMounted(() => {
      if (store.state.auth.isAuthenticated) {
        getUserData()
      }

      Vue.$profile = (open, destination) => {
        if (destination) selectedTab.value = destination
        isOpen.value = open
      }
    })

    const changeSubscribe = () => {
      if (usagePlanTab.value) usagePlanTab.value.getUserData()
      emit('changeSubscribe')
    }

    return {
      isOpen,
      isLeftSidebarOpen,
      selectedTab,
      tabItems,
      profileData,

      show,
      close,

      icons: {
        mdiChevronDoubleLeft,
        mdiChevronDoubleRight,
        mdiClose,
      },
      changeSubscribe,
      usagePlanTab,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.main-area {
  @include style-scroll-bar();
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
