import gql from 'graphql-tag'

const addHierarchy = gql`
  mutation addHierarchy(
    $name: String!
    $description: String
    $userId: [Int!]!
    $parent_id: Float
    $workspace_id: Float!
  ) {
    addHierarchy(
      name: $name
      description: $description
      userId: $userId
      parent_id: $parent_id
      workspace_id: $workspace_id
    ) {
      msg
      status
      data
    }
  }
`
export default addHierarchy
