import { getField, setField } from 'vuex-use-fields'

export default {
  namespaced: true,
  state: {
    selectedSort: {
      label: 'Relevance',
      field: 'relevance',
      type: 'DESC',
    },
    selectedObject: {
      id: 1,
      name: 'All',
    },
    selectedWorkspaces: [],
    filterStatus: [],
    sortOptions: [
      {
        label: 'Relevance',
        field: 'relevance',
        type: 'DESC',
      },
      // {
      //   label: 'Relevance',
      //   type: 'DESC',
      // },
      {
        label: 'Recent',
        field: 'updated_at',
        type: 'DESC',
      },
      // {
      //   label: 'Recent',
      //   type: 'DESC',
      // },
    ],
    objects: [
      {
        id: 1,
        name: 'All',
      },
      {
        id: 2,
        name: 'Task',
      },
      {
        id: 3,
        name: 'Job',
      },
      {
        id: 4,
        name: 'Customer',
      },
      {
        id: 5,
        name: 'Product',
      },
      {
        id: 6,
        name: 'Comment',
      },
      {
        id: 7,
        name: 'Comment Files',
      },
    ],
    pagination: {
      limit: 10,
      offset: 0,
    },
  },
  getters: {
    getField,
  },
  mutations: {
    setField,
  },
  actions: {},
}
