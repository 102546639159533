import { ref } from 'vue'
import Vue from 'vue'
import { apolloClient } from '@/vue-apollo'
import {
  accurateAuthorizationLink,
  connectionChecker,
  platformList as thirdPartyPlatformQuery,
  shopeeAuthorizationLink,
  workspaceTokenList,
} from '@/graphql/queries'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'
import {
  addWorkspaceToken,
  deleteWorkspaceToken,
  reconnectThirdParty,
  updateWorkspaceToken,
} from '@/graphql/mutations'

const useIntegration = () => {
  const workspaceId = store.getters.getCurrentWorkspaceId
  const platformList = ref([])
  const loadingPlatform = ref(false)
  const loadingForm = ref(false)
  const integrationList = ref([])

  const fetchPlatformList = () => {
    loadingPlatform.value = true
    apolloClient
      .query({
        query: thirdPartyPlatformQuery,
      })
      .then(result => {
        platformList.value = result.data.platformList
        loadingPlatform.value = false
      })
      .catch(err => {
        errorHandling(err)
        loadingPlatform.value = false
      })
  }

  const fetchIntegrationList = () => {
    loadingPlatform.value = true

    console.log('getting integration list')
    setTimeout(() => {
      loadingPlatform.value = false
    }, 3000)
  }

  // token detail
  const loadingDetail = ref(false)
  const tokenDetail = ref([])

  const getTokenDetail = async (platformId, isActiveOnly = true) => {
    loadingDetail.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: workspaceTokenList,
          variables: {
            platformId,
            workspace_id: workspaceId,
            is_active_only: isActiveOnly,
          },
          fetchPolicy: 'no-cache',
        })
        .then(result => {
          loadingDetail.value = false
          tokenDetail.value = result.data.workspaceTokenList
          resolve(result.data.workspaceTokenList)
        })
        .catch(err => {
          loadingDetail.value = false
          errorHandling(err)
          reject(err)
        })
    })
  }

  const loadingConnectionStatus = ref(false)
  const connectionStatus = ref({})
  const fetchConnectionChekcer = (platformId, connectionName) => {
    loadingConnectionStatus.value = true
    apolloClient
      .query({
        query: connectionChecker,
        fetchPolicy: 'no-cache',
        variables: {
          platformId,
          connection_name: connectionName,
          workspace_id: workspaceId,
        },
      })
      .then(({ data }) => {
        connectionStatus.value = data.connectionChecker
        loadingConnectionStatus.value = false
      })
      .catch(err => {
        loadingConnectionStatus.value = false
        errorHandling(err)
      })
  }

  const addWorkspaceTokenFunc = (variables, success = () => {}, error = () => {}) => {
    loadingForm.value = true
    const updatedVariable = { ...variables, workspace_id: workspaceId }
    apolloClient
      .mutate({
        mutation: addWorkspaceToken,
        variables: updatedVariable,
      })
      .then(() => {
        Vue.notify({
          title: 'Sukses',
          text: 'Berhasil menambahkan koneksi',
        })
        loadingForm.value = false
        success()
      })
      .catch(err => {
        loadingForm.value = false
        errorHandling(err, 'Tambah token')
        error()
      })
  }
  const updateWorkspaceTokenFunc = (variables, success = () => {}) => {
    loadingForm.value = true
    const updatedVariable = { ...variables, workspace_id: workspaceId }
    apolloClient
      .mutate({
        mutation: updateWorkspaceToken,
        variables: updatedVariable,
      })
      .then(({ data }) => {
        const connectionName = data.updateWorkspaceToken.data
        Vue.notify({
          title: 'Sukses',
          text: 'Berhasil mengubah koneksi',
        })
        loadingForm.value = false
        success(connectionName)
      })
      .catch(err => {
        loadingForm.value = false
        errorHandling(err, 'Update token')
      })
  }

  // reconnect Third Party
  const loadingReconnect = ref(false)
  const reconnectThirdPartyFunc = (variables, success = () => {}) => {
    const updatedVariable = { ...variables, workspace_id: workspaceId }
    loadingReconnect.value = true
    apolloClient
      .mutate({
        mutation: reconnectThirdParty,
        variables: updatedVariable,
        fetchPolicy: 'no-cache',
      })
      .then(() => {
        loadingReconnect.value = false
        success()
      })
      .catch(err => {
        loadingReconnect.value = false
        errorHandling(err)
      })
  }

  // Shopee Integration
  const loadingShopeeAuthLink = ref(false)
  const shopeeAuthLink = () => {
    loadingShopeeAuthLink.value = true
    apolloClient
      .query({
        query: shopeeAuthorizationLink,
        variables: {
          workspace_id: workspaceId,
        },
      })
      .then(result => {
        console.log(result)
        window
          .open(
            result.data.shopeeAuthorizationLink,
            '_blank',
            'location=yes,height=600,width=900,scrollbars=yes,status=yes',
          )
          .focus()
      })
      .catch(err => {
        errorHandling(err)
      })
  }

  // Accurate Integration
  const loadingAccurateAuthLink = ref(false)
  const accurateAuthLink = () => {
    loadingAccurateAuthLink.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: accurateAuthorizationLink,
          variables: {
            workspace_id: workspaceId,
          },
        })
        .then(({ data }) => {
          loadingAccurateAuthLink.value = false
          resolve(data.accurateAuthorizationLink)
        })
        .catch(err => {
          errorHandling(err)
          loadingAccurateAuthLink.value = false
          reject(err)
        })
    })
  }

  // Accurate Integration
  const loadingDeleteToken = ref(false)
  const removeWorkspaceToken = (id, tokenWorkspaceId) => {
    loadingDeleteToken.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: deleteWorkspaceToken,
          variables: {
            id,
            workspace_id: tokenWorkspaceId,
          },
        })
        .then(result => {
          loadingDeleteToken.value = false
          resolve(result)
          Vue.notify({
            title: 'Sukses',
            text: 'Berhasil menghapus token',
          })
        })
        .catch(err => {
          errorHandling(err)
          loadingDeleteToken.value = false
          reject(err)
        })
    })
  }

  return {
    platformList,
    loadingPlatform,
    integrationList,
    connectionStatus,
    loadingForm,

    loadingDetail,
    tokenDetail,
    getTokenDetail,
    loadingConnectionStatus,

    fetchPlatformList,
    fetchIntegrationList,
    fetchConnectionChekcer,
    addWorkspaceTokenFunc,
    updateWorkspaceTokenFunc,

    loadingReconnect,
    reconnectThirdPartyFunc,

    // shopee integration
    loadingShopeeAuthLink,
    shopeeAuthLink,

    // accurate integration
    loadingAccurateAuthLink,
    accurateAuthLink,

    // delete token
    loadingDeleteToken,
    removeWorkspaceToken,
  }
}

export default useIntegration
