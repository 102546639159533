import gql from 'graphql-tag'

const documentSnapshots = gql`
  query snapshots($documentUuid: String!, $pagination: pagination!, $workspaceId: Float!) {
    snapshots(document_uuid: $documentUuid, pagination: $pagination, workspace_id: $workspaceId) {
      count
      snapshots {
        id
        name
        content
        snapshot_at
        name_snapshot
        snapshot_by {
          id
          name
          photo
        }
        tags {
          id
          name
          color
        }
      }
    }
  }
`

export default documentSnapshots
