import gql from 'graphql-tag'

const attachments = gql`
  query attachments($job_id: Float!, $workspace_id: Float!) {
    attachments(job_id: $job_id, workspace_id: $workspace_id) {
      id
      file_name
      file_type
      file_size
      is_cover
      created_at
      modify_at
      user {
        id
        name
        photo
      }
      job {
        job_type {
          id
        }
      }
    }
  }
`

export default attachments
