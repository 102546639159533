import gql from 'graphql-tag'

const workspaceMembers = gql`
  query workspaceMembers(
    $pagination: pagination!
    $filter: filterUser
    $workspace_id: Float!
    $search: String
  ) {
    workspaceMembers(
      pagination: $pagination
      filter: $filter
      workspace_id: $workspace_id
      search: $search
    ) {
      users_count
      members {
        user {
          id
          phone
          name
          email
          first_name
          last_name
          photo
          forgot_password_code_expired_at
          user_status {
            id
            name
          }
          last_seen_at
          created_at
          updated_at
        }
        workspace {
          id
        }
        role {
          id
          name
        }
        email
        is_pending
      }
    }
  }
`
export default workspaceMembers
