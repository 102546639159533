import gql from 'graphql-tag'

const queryDeleteAttachments = gql`
  query queryDeleteAttachments(
    $attachIds: [String!]!
    $workspace_id: Float!
    $job_type_id: Float!
  ) {
    queryDeleteAttachments(
      attachIds: $attachIds
      workspace_id: $workspace_id
      job_type_id: $job_type_id
    ) {
      id
      count_custom_attribute
      count_comment
      file_name
    }
  }
`

export default queryDeleteAttachments
