import gql from 'graphql-tag'

const visibilities = gql`
  query {
    visibilities {
      id
      name
      description
    }
  }
`

export default visibilities
