import gql from 'graphql-tag'

export const addJobPriority = gql`
  mutation addJobPriority(
    $workspace_id: Float!
    $job_priority: [paramAddJobPriority!]!
    $job_type_id: Float!
  ) {
    addJobPriority(
      workspace_id: $workspace_id
      job_priority: $job_priority
      job_type_id: $job_type_id
    ) {
      status
      msg
      data
    }
  }
`
