import gql from 'graphql-tag'

export const updateTeamParent = gql`
  mutation updateTeamParent($workspace_id: Float!, $team_id: Float!, $parent_team_id: Float) {
    updateTeamParent(
      team_id: $team_id
      workspace_id: $workspace_id
      parent_team_id: $parent_team_id
    ) {
      data
      msg
      status
    }
  }
`
