import gql from 'graphql-tag'

export const convertToSubJob = gql`
  mutation ($dest_job_id: Float!, $job_id: Float!, $workspace_id: Float!) {
    convertToSubJob(dest_job_id: $dest_job_id, job_id: $job_id, workspace_id: $workspace_id) {
      status
      msg
      data
    }
  }
`
