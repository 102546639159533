import gql from 'graphql-tag'

const removeFromWorkspace = gql`
  mutation removeFromWorkspace($user_id: Float!, $workspace_id: Float!) {
    removeFromWorkspace(user_id: $user_id, workspace_id: $workspace_id) {
      msg
      status
      data
    }
  }
`
export default removeFromWorkspace
