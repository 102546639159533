import useJob from '@/composables/useJob'
import { getField, setField } from 'vuex-use-fields'

export default {
  namespaced: true,
  state: {
    jobs: null,
    jobCount: 0,
    activeTabKanban: 0,
    jobPriority: [],
    advancedFilter: null,
    jobFilter: {
      search: '',
      types: [],
      job_type_id: null,
      assigned_comment: false,
      search_field: [
        {
          field: 'name',
          label: 'Nama',
          value: true,
        },
        {
          field: 'description',
          label: 'Deskripsi',
          value: true,
        },
        {
          field: 'created_by',
          label: 'Dibuat Oleh',
          value: true,
        },
        {
          field: 'customer',
          label: 'Pelanggan',
          value: false,
        },
        {
          field: 'assigned_to',
          label: 'Ditugaskan ke',
          value: false,
        },
        {
          field: 'custom_attribute_values',
          label: 'Custom Field',
          value: false,
        },
      ],
      options: [
        {
          field: 'collapse_sub_job',
          label: 'Collapsed Sub Job',
          value: false,
        },
        {
          field: 'include_sub_job',
          label: 'Searchable Sub Job',
          value: false,
        },
        {
          field: 'exclude_closed_status',
          label: 'Sembunyikan Status Tertutup',
          value: false,
        },
      ],
    },
    jobGroup: {
      group: undefined,
      groupOrder: 'ASC',
    },
    jobSort: [],
    jobColumns: [],
    jobStatus: [],
    customAttributes: [],
    savedViews: [],
    loadingJob: false,
  },
  getters: {
    getField,
    getJobFilterValue: state => {
      const { search, search_field, assigned_comment } = state.jobFilter

      return {
        sort: state.jobSort.map(el => ({
          field: el.field,
          order: el.type,
        })),
        search,
        assigned_comment,
        search_field: search_field.filter(el => el.value).map(el => el.field),
      }
    },
  },
  mutations: {
    setField,
  },
  actions: {
    getCustomAttributes({ state }) {
      return state.customAttributes
    },
    resetFilter({ state }) {
      state.advancedFilter = null
    },
    resetGroup({ state }) {
      state.jobGroup.group = {
        label: 'Status',
        field: 'status',
      }
    },
    resetSort({ state }) {
      state.jobSort = []
    },
    appendCondition({ state }, index = null) {
      if (index === null) {
        state.advancedFilter.filter_condition.push({
          key: 'name',
          operator: 'IS',
          value: [''],
        })
      } else {
        state.advancedFilter.filter_group[index].filter_condition.push({
          key: 'name',
          operator: 'IS',
          value: [''],
        })
      }
    },
    appendGroup({ state }) {
      state.advancedFilter.filter_group.push({
        group_operator: 'AND',
        filter_condition: [
          {
            key: 'name',
            operator: 'IS',
            value: [''],
          },
        ],
      })
    },
    resetColumns({ state }) {
      state.jobColumns = []
    },
    async fetchJobs({ state, getters }) {
      const { newFetchJobs } = useJob()
      const { getJobFilterValue } = getters

      const include_sub_job = state.jobFilter.options[1].value
      const exclude_closed_status = state.jobFilter.options[2].value
      const advanced_filter = state.advancedFilter
      const job_type_id = state.jobFilter.job_type_id

      state.loadingJob = true

      try {
        const { jobs, count } = await newFetchJobs({
          job_type_id,
          include_sub_job,
          advanced_filter,
          exclude_closed_status,
          filter: getJobFilterValue,
        })

        state.jobs = jobs
        state.jobCount = count
      } finally {
        state.loadingJob = false
      }
    },
  },
}
