import gql from 'graphql-tag'

const updateThread = gql`
  mutation ($thread_id: Float!, $html_comment: String!, $is_pinned: Boolean!) {
    updateThread(thread_id: $thread_id, html_comment: $html_comment, is_pinned: $is_pinned) {
      status
      msg
      data
    }
  }
`

export default updateThread
