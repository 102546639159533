/**
 * @module uppy-upload/uppyupload
 */

import { Plugin } from '@ckeditor/ckeditor5-core'
import UploadUI from './uploadui'
import UploadEditing from './uploadediting'

export default class UppyUpload extends Plugin {
  static get pluginName() {
    return 'UppyUpload'
  }

  static get requires() {
    return [UploadEditing, UploadUI]
  }
}
