import gql from 'graphql-tag'

const addRelation = gql`
  mutation AddRelation($relationTypeId: Float!, $jobId: Float!, $relateToJobId: Float!) {
    addRelation(
      relation_type_id: $relationTypeId
      job_id: $jobId
      relate_to_job_id: $relateToJobId
    ) {
      status
      msg
      data
    }
  }
`

export default addRelation
