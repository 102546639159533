import { mdiSortAlphabeticalAscending } from '@mdi/js'
import { getField, setField } from 'vuex-use-fields'

export default {
  namespaced: true,
  state: {
    loadingFolder: false,
    folderList: [],

    filter: {
      search: '',
      my_folder: {
        id: 1,
        text: 'All',
        value: null,
      },
      show_archived: {
        id: 2,
        text: 'Hide',
        value: 'IS NOT SET',
      },
      sort: {
        id: 1,
        text: 'Nama (A-Z)',
        value: 'name',
        sortType: 'ASC',
        icon: mdiSortAlphabeticalAscending,
      },
    },
  },
  getters: {
    getField,
    getFolderList: state => state.folderList,
    getLoadingFolder: state => state.loadingFolder,
  },
  mutations: {
    setField,
  },
  actions: {
    resetFolderFilter(state) {
      state.state.filter = {
        search: '',
        my_folder: {
          id: 1,
          text: 'All',
          value: null,
        },
        show_archived: {
          id: 2,
          text: 'Hide',
          value: 'IS NOT SET',
        },
        sort: {
          id: 1,
          text: 'Nama (A-Z)',
          value: 'name',
          sortType: 'ASC',
          icon: mdiSortAlphabeticalAscending,
        },
      }
    },
  },
}
