<template>
  <div>
    <ckeditor-field
      placeholder="Masukkan deskripsi job"
      :content.sync="editorData"
      :plugins="jobId ? completePlugin : mentionPlugin"
      :configs="configs"
      :read-only="readOnly"
      :ready="onEditorReady"
    />

    <v-dialog
      v-if="attachMenu && jobId"
      v-model="attachMenu"
      max-width="50vw"
      scrollable
      hide-overlay
      transition="dialog-bottom-transition"
      @click:outside="closeDialog"
    >
      <attachment-grid
        :allow-selection="true"
        :job-id="jobId"
        :multiple="false"
        :job-type-id="jobTypeId"
        :width="'50vw'"
        :height="'322px'"
        @select="insertFile($event)"
      />
    </v-dialog>

    <v-menu
      v-model="mentionMenu"
      :position-x="menuX"
      :position-y="menuY"
      absolute
      :close-on-content-click="false"
    >
      <v-card width="400">
        <user-team-selector
          v-if="mentionMenu"
          @select="handleSelectMention"
        />
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { MentionCustomization } from '@/components/ckeditor/extensions/mentionHelper'
import { UppyUpload } from '@/components/ckeditor/extensions/ckeditor5-uppy-upload'
import { Viewer } from '@/components/ckeditor/extensions/ckeditor5-viewer'
import { Mention } from '@ckeditor/ckeditor5-mention'
import CkeditorField from '@/components/ckeditor/CkeditorField.vue'
import AttachmentGrid from '@/views/job/components/AttachmentGrid.vue'
import UserTeamSelector from '@/components/inputs/user-team-selector/UserTeamSelector.vue'
import useAttachment from '@/composables/useAttachment'
import store from '@/store'
import router from '@/router'

export default {
  components: {
    CkeditorField,
    AttachmentGrid,
    UserTeamSelector,
  },
  props: {
    content: {
      type: String,
      default: '',
    },
    jobId: {
      type: Number,
      default: null,
    },
    jobTypeId: {
      type: Number,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const editorInstance = ref()
    const attachMenu = ref(false)
    const mentionMenu = ref(false)
    const selectedUser = ref([])
    const menuX = ref(0)
    const menuY = ref(0)

    const { baseAttachmentUrl } = useAttachment()

    const onEditorReady = editor => {
      if (!editor) return

      editorInstance.value = editor

      if (props.jobId) {
        editor.commands.get('uppyUpload').on('execute', evt => {
          attachMenu.value = evt.return
        })

        editor.commands.get('viewer').on('execute', evt => {
          router.push({
            name: 'attachment-detail',
            params: {
              id: evt.return.split('/')[evt.return.split('/').length - 2],
            },
          })
        })

        editor.keystrokes.set('Shift+2', data => {
          const { x, y, width } = data.domTarget.getBoundingClientRect()

          menuX.value = x + width - 400 - 1
          menuY.value = y + 1
          mentionMenu.value = true

          setTimeout(() => {
            document.querySelector('.v-menu__content input').focus()
          }, 500)
        })
      }
    }

    const getContent = () => {
      return editorInstance.value.getData()
    }

    const configs = {}
    const mentionPlugin = [Mention, MentionCustomization]
    const uploadPlugin = [UppyUpload, Viewer]

    const completePlugin = [...uploadPlugin, ...mentionPlugin]

    const closeDialog = () => {
      editorInstance.value.commands.get('uppyUpload').execute(false)
      attachMenu.value = false
    }

    const insertFile = evt => {
      const file = evt[0]
      const instance = editorInstance.value
      const url = `${baseAttachmentUrl}/${file.job.job_type.id}/${file.id}/${file.file_name}`

      if (file.file_type === 'image') {
        instance.execute('insertImage', { source: url })
      } else {
        instance.model.change(writer => {
          const linkedText = writer.createText(file.file_name, { linkHref: url })
          instance.model.insertContent(linkedText, instance.model.document.selection)
        })
      }

      closeDialog()
    }

    watch(mentionMenu, () => {
      if (!mentionMenu.value) {
        selectedUser.value = []
      }
    })

    const handleSelectMention = mentions => {
      const editor = editorInstance.value
      const { focus } = editor.model.document.selection
      const workspaceSlug = store.getters.getCurrentWorkspace.identifier_id
      const mentionLength = selectedUser.value.reduce((acc, item) => acc + item.name.length + 2, 0)
      const shiftedLength = mentionLength ? 0 - mentionLength : -1

      if (!mentions.length && selectedUser.value.length === 1) {
        editor.model.change(writer => {
          const range = editor.model.createRange(focus.getShiftedBy(shiftedLength), focus)
          writer.remove(range)
        })
      }

      mentions.forEach((item, index) => {
        editor.execute('mention', {
          marker: '@',
          mention: {
            id: `@${item.name}`,
            name: item.name,
            link: `/${workspaceSlug}/users/detail/${item.id}`,
            userId: item.id,
          },
          range:
            index === 0 ? editor.model.createRange(focus.getShiftedBy(shiftedLength), focus) : null,
        })
      })

      selectedUser.value = mentions.length ? mentions : selectedUser.value
    }

    return {
      menuX,
      menuY,
      configs,
      insertFile,
      attachMenu,
      closeDialog,
      mentionMenu,
      uploadPlugin,
      getContent,
      onEditorReady,
      mentionPlugin,
      completePlugin,
      handleSelectMention,
    }
  },
  computed: {
    editorData: {
      get() {
        return this.content
      },
      set(value) {
        this.$emit('update:content', value)
      },
    },
  },
}
</script>
