import { position } from 'caret-pos'

const placeCaretAtEnd = el => {
  el.focus()
  if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
    const range = document.createRange()
    range.selectNodeContents(el)
    range.collapse(false)
    const sel = window.getSelection()
    sel.removeAllRanges()
    sel.addRange(range)
  } else if (typeof document.body.createTextRange !== 'undefined') {
    const textRange = document.body.createTextRange()
    textRange.moveToElementText(el)
    textRange.collapse(false)
    textRange.select()
  }
}

const getCaretPosition = el => position(el)

/* eslint-disable no-param-reassign */
const setCaretPosition = (el, pos) => {
  function createRange(node, chars, range) {
    if (!range) {
      range = document.createRange()
      range.selectNode(node)
      range.setStart(node, 0)
    }

    if (chars.count === 0) {
      range.setEnd(node, chars.count)
    } else if (node && chars.count > 0) {
      if (node.nodeType === Node.TEXT_NODE) {
        if (node.textContent.length < chars.count) {
          chars.count -= node.textContent.length
        } else {
          range.setEnd(node, chars.count)
          chars.count = 0
        }
      } else {
        for (let lp = 0; lp < node.childNodes.length; lp += 1) {
          range = createRange(node.childNodes[lp], chars, range)

          if (chars.count === 0) {
            break
          }
        }
      }
    }

    return range
  }

  if (pos >= 0) {
    const selection = window.getSelection()

    const range = createRange(el.parentNode, { count: pos })

    if (range) {
      range.collapse(false)
      selection.removeAllRanges()
      selection.addRange(range)
    }
  }
}

/* eslint-disable no-cond-assign */
const pasteHtmlAtCaret = html => {
  let sel
  let range
  if (window.getSelection) {
    // IE9 and non-IE
    sel = window.getSelection()
    if (sel.getRangeAt && sel.rangeCount) {
      range = sel.getRangeAt(0)
      range.deleteContents()

      // Range.createContextualFragment() would be useful here but is
      // only relatively recently standardized and is not supported in
      // some browsers (IE9, for one)
      const el = document.createElement('div')
      el.innerHTML = html
      const frag = document.createDocumentFragment()
      let node
      let lastNode
      while ((node = el.firstChild)) {
        lastNode = frag.appendChild(node)
      }
      range.insertNode(frag)

      // Preserve the selection
      if (lastNode) {
        range = range.cloneRange()
        range.setStartAfter(lastNode)
        range.collapse(true)
        sel.removeAllRanges()
        sel.addRange(range)
      }
    }
  } else if ((sel = document.selection) && sel.type !== 'Control') {
    // IE < 9
    const originalRange = sel.createRange()
    originalRange.collapse(true)
    sel.createRange().pasteHTML(html)
  }
}

/* eslint-disable no-cond-assign */
const pasteHtmlAtCaretWorkflow = html => {
  let sel
  let range
  if (window.getSelection) {
    // IE9 and non-IE
    sel = window.getSelection()
    if (sel.getRangeAt && sel.rangeCount) {
      range = sel.getRangeAt(0)
      range.deleteContents()

      // Range.createContextualFragment() would be useful here but is
      // only relatively recently standardized and is not supported in
      // some browsers (IE9, for one)
      const el = document.createElement('div')
      el.innerHTML = html
      const frag = document.createDocumentFragment()
      let node
      let lastNode
      while ((node = el.firstChild)) {
        lastNode = frag.appendChild(node)
      }
      range.insertNode(frag)

      // Preserve the selection
      if (lastNode) {
        range = range.cloneRange()
        range.setStartAfter(lastNode)
        range.collapse(true)
        sel.removeAllRanges()
        sel.addRange(range)
      }
    }
  } else if ((sel = document.selection) && sel.type !== 'Control') {
    // IE < 9
    const originalRange = sel.createRange()
    originalRange.collapse(true)
    sel.createRange().pasteHTML(html)
  }
}

export {
  placeCaretAtEnd,
  getCaretPosition,
  setCaretPosition,
  pasteHtmlAtCaret,
  pasteHtmlAtCaretWorkflow,
}
