import gql from 'graphql-tag'

const archiveThread = gql`
  mutation ($thread_id: Float!) {
    archiveThread(thread_id: $thread_id) {
      status
      msg
      data
    }
  }
`

export default archiveThread
