import gql from 'graphql-tag'

const threads = gql`
  query (
    $pagination: pagination!
    $channel_id: Float!
    $is_pinned: Boolean
    $is_archived: Boolean
    $search: String
  ) {
    threads(
      pagination: $pagination
      channel_id: $channel_id
      is_pinned: $is_pinned
      is_archived: $is_archived
      search: $search
    ) {
      id
      html_comment
      is_pinned
      channel {
        id
        name
        comment_permission
        channel_notification
        is_pinned
        archived_at
        last_activity_at
        deleted_at
      }
      user {
        id
        phone
        email
        name
        first_name
        last_name
        photo
      }
      posts {
        id
        html_comment
        user {
          id
          name
          phone
          photo
          email
        }
        created_at
        updated_at
        deleted_at
      }
      archived_at
      last_activity_at
      created_at
      updated_at
      deleted_at
      remaining_post
    }
  }
`

export default threads
