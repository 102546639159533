import gql from 'graphql-tag'

export const editDocumentTag = gql`
  mutation editDocumentTag($name: String!, $color: String!, $workspaceId: Float!, $tagId: Float!) {
    editDocumentTag(name: $name, color: $color, workspace_id: $workspaceId, tag_id: $tagId) {
      status
      msg
      data
    }
  }
`
