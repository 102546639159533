import gql from 'graphql-tag'

const getTrelloBoardMember = gql`
  query getTrelloBoardMembers($token: String!, $board_id: String!) {
    getTrelloBoardMembers(token: $token, board_id: $board_id) {
      id
      fullName
      username
    }
  }
`

export default getTrelloBoardMember
