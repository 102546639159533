import gql from 'graphql-tag'

const updateTelegramRoomStatus = gql`
  mutation ($status_id: Float!, $room_id: Float!) {
    updateTelegramRoomStatus(status_id: $status_id, room_id: $room_id) {
      status
      msg
    }
  }
`

export default updateTelegramRoomStatus
