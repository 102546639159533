import gql from 'graphql-tag'

const getTrelloCards = gql`
  query getTrelloCards($token: String!, $list_id: String!) {
    getTrelloCards(token: $token, list_id: $list_id) {
      id
      name
    }
  }
`

export default getTrelloCards
