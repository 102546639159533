<template>
  <v-dialog
    v-model="isOpen"
    scrollable
    max-width="900"
  >
    <v-card
      height="80vh"
      :loading="loadingCommand"
    >
      <v-card-text class="pt-4">
        <v-tabs-items
          v-model="currentTab"
          touchless
        >
          <v-tab-item>
            <div class="d-flex align-center mb-4">
              <h3>Bot Command</h3>
              <v-btn
                class="ml-auto"
                color="primary"
                outlined
                small
                @click="
                  editCommandData = null
                  currentTab = 1
                "
              >
                Buat Baru
              </v-btn>
            </div>

            <ChatCommandCard
              v-for="command in commandList"
              :key="command.id"
              :command="command"
              @delete="deleteCommand(command.id)"
              @update="updateCommand(command)"
            />
          </v-tab-item>
          <v-tab-item>
            <div
              v-if="currentTab === 1"
              class="d-flex flex-column align-start"
            >
              <v-btn
                color="primary"
                outlined
                text
                small
                class="mb-2"
                @click="currentTab = 0"
              >
                Kembali
              </v-btn>
              <ChatCommandForm
                v-if="currentTab === 1"
                ref="chatCommandForm"
                :initial-value="editCommandData"
                @success="
                  currentTab = 0
                  getCommandList(true)
                  editCommandData = null
                "
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, onMounted } from 'vue'
import useChatCommand from '@/composables/useChatCommand'
import ChatCommandCard from './ChatCommandCard.vue'
import ChatCommandForm from './ChatCommandForm.vue'

export default {
  components: {
    ChatCommandCard,
    ChatCommandForm,
  },
  setup() {
    const isOpen = ref(false)
    const currentTab = ref(0)
    const editCommandData = ref(null)

    const show = () => {
      isOpen.value = true
    }

    const close = () => {
      isOpen.value = false
    }

    const {
      loadingCommand,
      commandList,
      getCommandList,
      deleteCommand: deleteCommandAction,
    } = useChatCommand()

    const deleteCommand = id => {
      deleteCommandAction(id).then(() => getCommandList(true))
    }

    onMounted(() => {
      getCommandList(true)
    })

    const updateCommand = command => {
      editCommandData.value = command
      currentTab.value = 1
    }

    return {
      isOpen,
      currentTab,
      loadingCommand,
      commandList,
      editCommandData,

      show,
      close,
      getCommandList,
      deleteCommand,
      updateCommand,
    }
  },
}
</script>

<style></style>
