import gql from 'graphql-tag'

export const proceedJobStatus = gql`
  mutation proceedJobStatus(
    $status_id: Float!
    $id: Float!
    $workspace_id: Float!
    $lexorank: String!
  ) {
    proceedJobStatus(
      status_id: $status_id
      workspace_id: $workspace_id
      lexorank: $lexorank
      id: $id
    ) {
      status
      msg
      data
    }
  }
`
