import gql from 'graphql-tag'

const getTelegramChatList = gql`
  query getTelegramChatList($workspace_id: Float!, $token_id: Float!) {
    getTelegramChatList(workspace_id: $workspace_id, token_id: $token_id) {
      id
      chat_room_id
      telegram_user_id
      telegram_username
      first_name
      last_name
      group_name
    }
  }
`

export default getTelegramChatList
