import gql from 'graphql-tag'

const addProduct = gql`
  mutation addProduct(
    $name: String!
    $description: String
    $status: Float!
    $price: Float!
    $stock: Float!
    $workspace_id: Float!
  ) {
    addProduct(
      name: $name
      description: $description
      status: $status
      price: $price
      stock: $stock
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`

export default addProduct
