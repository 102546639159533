import gql from 'graphql-tag'

export const selectSavedView = gql`
  mutation selectSavedView($saved_view_id: Float!, $workspace_id: Float!) {
    selectSavedView(saved_view_id: $saved_view_id, workspace_id: $workspace_id) {
      status
      msg
    }
  }
`
