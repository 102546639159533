import gql from 'graphql-tag'

export const updateJobTypePermission = gql`
  mutation updateJobTypePermission(
    $workspace_id: Float!
    $job_type_id: Float!
    $is_public: Boolean!
    $user_permissions: [UserFolderPermission!]!
  ) {
    updateJobTypePermission(
      workspace_id: $workspace_id
      job_type_id: $job_type_id
      is_public: $is_public
      user_permissions: $user_permissions
    ) {
      status
      msg
      data
    }
  }
`
