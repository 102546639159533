import gql from 'graphql-tag'

const test = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      refreshToken
      status
      token
    }
  }
`

export default test
