<template>
  <v-dialog
    v-model="isOpen"
    max-width="600"
    scrollable
  >
    <v-card :loading="loadingDetail">
      <v-card-text
        v-if="tokenDetail.length"
        class="pt-4"
      >
        <div class="d-flex align-center justify-space-between mb-2">
          <h3>Autoreply</h3>
          <v-icon @click="close">
            {{ icons.mdiClose }}
          </v-icon>
        </div>
        <p>
          Kirimkan respon otomatis kepada pelanggan ketika pelanggan mengirimkan pesan di luar jam
          kerja. <span class="primary--text cursor-pointer">Atur jam kerja.</span>
        </p>
        <v-alert
          border="left"
          color="warning"
          dense
          text
          type="info"
          class="mb-4 mt-2 text-subtitle-2 py-2"
        >
          <span class="text-caption"
            >Untuk saat ini autoreply hanya dapat digunakan untuk channel Telegram.</span
          >
        </v-alert>
        <v-chip-group
          v-model="selectedToken"
          mandatory
          active-class="primary--text"
          column
          class="mb-2"
        >
          <v-chip
            v-for="token in tokenDetail"
            :key="token.id"
          >
            {{ token.connection_name }}
          </v-chip>
        </v-chip-group>
        <v-form
          ref="autoReplyForm"
          lazy-validation
          @submit.prevent="submit"
        >
          <v-textarea
            v-model="formData.respond"
            label="Respon"
            placeholder="Input respon"
            outlined
            dense
            hide-details="auto"
            :loading="loadingCommand"
            :rules="[required]"
          />
          <div class="d-flex">
            <v-btn
              class="mt-4 ml-auto"
              color="primary"
              :loading="loadingSubmit"
              :disabled="loadingCommand"
              @click="submit"
            >
              Simpan
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
      <v-card-text
        v-else
        class="pt-4"
      >
        <p>Belum ada bot Telegram yang terhubung, silahkan integrasikan terlebih dahulu</p>
        <v-btn
          class="mt-2"
          color="primary"
          outlined
          small
          @click="$router.push({ name: 'integrations' })"
        >
          Integrasi
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import { ref, computed, watch } from 'vue'
import { mdiClose } from '@mdi/js'
import { required } from '@core/utils/validation'
import useChatCommand from '@/composables/useChatCommand'
import useIntegration from '@/composables/useIntegration'
import store from '@/store'
import { apolloClient } from '@/vue-apollo'
import { createChatBotCommand, updateChatBotCommand } from '@/graphql/mutations'
import errorHandling from '@/utils/errorHandling'

export default {
  setup() {
    const { loadingDetail, tokenDetail, getTokenDetail } = useIntegration()
    const { loadingCommand, commandList, getCommandList } = useChatCommand()

    const autoReplyForm = ref()
    const isOpen = ref(false)
    const selectedToken = ref(0)
    const formData = ref({
      respond: '',
    })
    const loadingSubmit = ref(false)

    const token = computed(() => {
      if (!tokenDetail.value.length) {
        return null
      }

      return tokenDetail.value[selectedToken.value]
    })

    const filteredCommandList = computed(() => {
      if (!token.value) {
        return []
      }

      return commandList.value.filter(
        el => el.type.id === 3 && el.token.connection_name === token.value.connection_name,
      )
    })

    const initForm = async () => {
      await getCommandList()

      if (filteredCommandList.value.length) {
        formData.value.respond = filteredCommandList.value[0].respond
      }
    }

    watch(selectedToken, () => initForm())

    const show = async () => {
      isOpen.value = true

      await getTokenDetail(1)

      initForm()
    }

    const close = () => {
      isOpen.value = false
    }

    const submit = async () => {
      if (autoReplyForm.value.validate()) {
        loadingSubmit.value = true
        const variables = {
          name: 'autoreply',
          description: 'autoreply description generated from front end',
          typeId: 3,
          respond: formData.value.respond,
          platform_id: 1,
          workspace_id: store.getters.getCurrentWorkspaceId,
        }

        if (filteredCommandList.value.length) {
          apolloClient
            .mutate({
              mutation: updateChatBotCommand,
              variables: {
                ...variables,
                id: filteredCommandList.value[0].id,
              },
            })
            .then(() => {
              loadingSubmit.value = false
              Vue.notify({
                title: 'Sukses',
                text: 'Berhasil menyimpan pesan autoreply!',
              })
              initForm()
            })
            .catch(err => {
              loadingSubmit.value = false
              errorHandling(err)
            })
        } else {
          apolloClient
            .mutate({
              mutation: createChatBotCommand,
              variables: {
                ...variables,
                workspace_token_id: token.value.id,
              },
            })
            .then(() => {
              loadingSubmit.value = false
              Vue.notify({
                title: 'Sukses',
                text: 'Berhasil menyimpan pesan autoreply!',
              })
              initForm()
            })
            .catch(err => {
              loadingSubmit.value = false
              errorHandling(err)
            })
        }
      }
    }

    return {
      autoReplyForm,
      isOpen,
      formData,
      loadingSubmit,
      tokenDetail,
      commandList,
      selectedToken,
      filteredCommandList,
      loadingCommand,
      loadingDetail,

      show,
      close,
      submit,

      icons: {
        mdiClose,
      },

      required,
    }
  },
}
</script>

<style></style>
