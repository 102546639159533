import gql from 'graphql-tag'

const userUsage = gql`
  query {
    userUsage {
      total_member
      total_workspace
    }
  }
`

export default userUsage
