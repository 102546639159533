import gql from 'graphql-tag'

export const archiveAllReadNotification = gql`
  mutation ArchiveAllReadNotification($workspaceId: Float!) {
    archiveAllReadNotification(workspace_id: $workspaceId) {
      status
      msg
      data
    }
  }
`
