import gql from 'graphql-tag'

const updatePermission = gql`
  mutation updatePermission(
    $role_id: Float!
    $create: String!
    $read: String!
    $update: String!
    $del: String!
    $workspace_id: Float!
  ) {
    updatePermission(
      role_id: $role_id
      create: $create
      read: $read
      update: $update
      del: $del
      workspace_id: $workspace_id
    ) {
      status
      msg
    }
  }
`

export default updatePermission
