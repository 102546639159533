import gql from 'graphql-tag'

const posts = gql`
  query ($pagination: pagination!, $timestamp: DateTime!, $thread_id: Float!) {
    posts(pagination: $pagination, timestamp: $timestamp, thread_id: $thread_id) {
      id
      html_comment
      user {
        id
        name
        phone
        photo
        email
      }
      thread {
        id
        html_comment
      }
      created_at
      updated_at
      deleted_at
    }
  }
`

export default posts
