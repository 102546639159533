import gql from 'graphql-tag'

const sendVerificationCode = gql`
  mutation sendVerificationCode($email: String, $phone: String) {
    sendVerificationCode(email: $email, phone: $phone) {
      msg
      status
      data
    }
  }
`

export default sendVerificationCode
