<template>
  <div>
    <component
      :is="resolveLayoutVariant"
      :class="`skin-variant--${appSkinVariant}`"
    >
      <transition
        v-if="$route"
        :name="appRouteTransition"
        mode="out-in"
        appear
      >
        <router-view />
      </transition>
    </component>

    <notifications
      position="bottom right"
      :duration="5000"
    />
    <v-overlay
      :value="globalLoading"
      :opacity="0.1"
    >
      <div class="d-flex align-center h-full pa-16 ms-16 pe-0">
        <Lottie
          :options="{
            animationData: catloading,
          }"
          style="max-width: 800px; margin: 0"
        />
      </div>
    </v-overlay>
    <global-dialog />
    <global-detail />
  </div>
</template>

<script setup>
import store from '@/store'
import { computed, onMounted } from 'vue'
import { useRouter } from '@core/utils'
import { useLayout } from '@core/layouts/composable/useLayout'
import useAppConfig from '@core/@app-config/useAppConfig'
import useDynamicVh from '@core/utils/useDynamicVh'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import LayoutAdmin from '@/layouts/variants/admin/LayoutAdmin.vue'
import catloading from '@/assets/lottie/cat_loading.json'

import Lottie from 'vue-lottie'
import GlobalDialog from '@/components/misc/GlobalDialog.vue'
import GlobalDetail from '@/components/misc/GlobalDetail.vue'

const { route } = useRouter()
const { appContentLayoutNav, appSkinVariant, appRouteTransition, isDark } = useAppConfig()
const globalLoading = computed(() => store.state.auth.globalLoading)

const { handleBreakpointLayoutSwitch } = useLayout()
handleBreakpointLayoutSwitch()

const resolveLayoutVariant = computed(() => {
  if (!route.value.name) return LayoutBlank
  if (route.value && route.value.meta && route.value.meta.layout === 'blank') return LayoutBlank
  if (route.value && route.value.meta && route.value.meta.layout === 'admin') return LayoutAdmin
  if (appContentLayoutNav.value === 'vertical') return LayoutContentVerticalNav

  return LayoutContentHorizontalNav
})

useDynamicVh()

onMounted(() => {
  document.body.classList.add(isDark.value ? 'dark-layout' : 'light-layout')
})
</script>
