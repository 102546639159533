import gql from 'graphql-tag'

export const leaveTeam = gql`
  mutation leaveTeam($team_id: Float!, $workspace_id: Float!) {
    leaveTeam(team_id: $team_id, workspace_id: $workspace_id) {
      data
      status
      msg
    }
  }
`
