import gql from 'graphql-tag'

const toggleStatusWorkflow = gql`
  mutation toggleStatusWorkflow($workspace_id: Float!, $trigger_id: Float!, $value: Boolean!) {
    toggleStatusWorkflow(workspace_id: $workspace_id, trigger_id: $trigger_id, value: $value) {
      status
      msg
    }
  }
`

export default toggleStatusWorkflow
