import errorHandling from '@/utils/errorHandling'
import { getJobTypeDetail, getJobTypes } from '@/services/jobTypeService'
import { computed, ref } from 'vue'

const useJobType = () => {
  const error = ref(null)
  const jobTypeList = ref([])
  const jobTypeDetail = ref(null)

  const jobTypeStatuses = computed(() => jobTypeDetail.value?.statuses)
  const jobTypePriorities = computed(() => jobTypeDetail.value?.priorities)

  const fetchJobTypes = async (folderId, filterJobType) => {
    try {
      jobTypeList.value = await getJobTypes(folderId, filterJobType)
    } catch (err) {
      errorHandling(err)
      error.value = err
    }
  }

  const fetchJobTypeDetail = async ({ jobTypeId, excludeClosedStatus = false } = {}) => {
    try {
      jobTypeDetail.value = await getJobTypeDetail({ jobTypeId, excludeClosedStatus })
    } catch (err) {
      errorHandling(err)
      error.value = err
    }
  }

  return {
    jobTypeList,
    fetchJobTypes,

    jobTypeDetail,
    jobTypeStatuses,
    jobTypePriorities,
    fetchJobTypeDetail,

    error,
  }
}

export default useJobType
