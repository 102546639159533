<template>
  <v-card width="300">
    <v-card-title class="pa-3 date-time-select-fields">
      <v-row
        no-gutters
        style="row-gap: 8px"
      >
        <v-col :cols="timeMode || rangeMode ? 6 : 12">
          <v-text-field
            :value="dateValue"
            :rules="[validDateText]"
            dense
            height="30"
            :class="[rangeMode && !timeMode ? 'pe-2' : '', timeMode ? 'date-with-time-field' : '']"
            filled
            outlined
            hide-details
            @blur="dateValue = $event.target.value"
          />
        </v-col>

        <v-col
          v-if="timeMode"
          :cols="6"
        >
          <v-text-field
            :value="timeValue"
            :rules="[validTimeText]"
            dense
            height="30"
            filled
            class="time-field"
            outlined
            hide-details
            @blur="timeValue = $event.target.value"
          />
        </v-col>

        <v-col
          v-if="rangeMode"
          cols="6"
        >
          <v-text-field
            :value="dateValue"
            :rules="[validDateText]"
            :class="timeMode ? 'date-with-time-field' : ''"
            dense
            height="30"
            filled
            outlined
            hide-details
            @blur="dateValue = $event.target.value"
          />
        </v-col>

        <v-col
          v-if="timeMode && rangeMode"
          :cols="6"
        >
          <v-text-field
            :value="timeValue"
            :rules="[validTimeText]"
            dense
            height="30"
            filled
            class="time-field"
            outlined
            hide-details
            @blur="timeValue = $event.target.value"
          />
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text class="pa-0">
      <v-date-picker
        v-model="datePickerValue"
        width="300"
        no-title
      />

      <div>
        <v-divider />

        <v-list
          dense
          nav
        >
          <v-list-item
            v-if="range"
            @click="rangeMode = !rangeMode"
          >
            <v-list-item-title>Range</v-list-item-title>
            <v-icon
              :color="rangeMode ? 'primary' : null"
              size="18"
            >
              {{ rangeMode ? mdiCheckboxMarked : mdiCheckboxBlankOutline }}
            </v-icon>
          </v-list-item>

          <v-list-item
            :disabled="value === null"
            @click="handleIncludeTime"
          >
            <v-list-item-title>Include Time</v-list-item-title>
            <v-icon
              :color="timeMode ? 'primary' : null"
              size="18"
            >
              {{ timeMode ? mdiCheckboxMarked : mdiCheckboxBlankOutline }}
            </v-icon>
          </v-list-item>

          <v-divider class="my-1" />

          <v-list-item @click="handleResetDateTime">
            <v-list-item-title>Reset</v-list-item-title>
          </v-list-item>

          <v-list-item @click="handleClearDateTime">
            <v-list-item-title>Clear</v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { mdiCheckboxBlankOutline, mdiCheckboxMarked } from '@mdi/js'
import { computed, ref } from 'vue'
import { format, isValid } from 'date-fns'

const emit = defineEmits(['input'])
const props = defineProps({
  value: {
    type: String,
    default: null,
  },
  range: {
    type: Boolean,
    default: false,
  },
})

const datePickerValue = computed({
  get() {
    if (props.value === null) return null

    return format(new Date(props.value), 'yyyy-MM-dd')
  },
  set(value) {
    const date = new Date(`${value}T${timeValue.value}:${timeMode.value ? '00' : '01'}`)

    emit('input', date.toISOString())
  },
})
const dateValue = computed({
  get() {
    if (props.value === null) return '-'

    return format(new Date(props.value), 'dd-MM-yyyy')
  },
  set(value) {
    if (validDateText(value) !== true) return

    const [d, m, y] = value.split('-')
    const date = new Date(`${y}-${m}-${d}T${timeValue.value}:${timeMode.value ? '00' : '01'}`)

    emit('input', date.toISOString())
  },
})
const timeValue = computed({
  get() {
    if (!props.value) return '00:00'

    return format(new Date(props.value), 'HH:mm')
  },
  set(value) {
    if (validTimeText(value) !== true) return

    const localDate = format(new Date(props.value), 'yyyy-MM-dd')
    const date = new Date(`${localDate}T${value}`)

    emit('input', date.toISOString())
  },
})

const rangeMode = ref(false)
const timeMode = computed({
  get() {
    if (!props.value) return false

    return new Date(props.value).getSeconds() === 0
  },
  set(value) {
    const date = new Date(props.value)
    date.setHours(0)
    date.setMinutes(0)

    if (value) {
      date.setSeconds(0)
      emit('input', date.toISOString())

      return
    }

    date.setSeconds(1)
    emit('input', date.toISOString())
  },
})

const validDateText = e => {
  if (props.value === null) return true
  if (e.length !== 10) return 'Invalid date format'

  const [d, m, y] = e.split('-')

  if (!isValid(new Date(`${y}-${m}-${d}`))) return 'Invalid date format'

  return true
}
const validTimeText = e => {
  if (props.value === null) return true
  if (e.length !== 5) return 'Invalid time format'

  const [hours, minutes] = e.split(':')
  const hoursNum = Number(hours)
  const minutesNum = Number(minutes)

  if (
    isNaN(hoursNum) ||
    isNaN(minutesNum) ||
    hoursNum < 0 ||
    hoursNum > 23 ||
    minutesNum < 0 ||
    minutesNum > 59
  ) {
    return 'Invalid time format'
  }

  return true
}

/**
 * This is very specific case for this app:
 * It sets the seconds to either 00 or 01 to decides
 * whether the time is included on the date or not.
 *
 * 00 means the time is included. (it will popup a time picker)
 * 01 means the time is not included. (time picker will be hidden)
 *
 * refer to timeMode computed for implementation detail
 */
const handleIncludeTime = () => {
  timeMode.value = !timeMode.value
}
const handleResetDateTime = () => {
  emit('reset')
}
const handleClearDateTime = () => {
  emit('input', null)
}
</script>

<style lang="scss">
.date-time-select-fields {
  .v-input__slot {
    min-height: 20px !important;
  }
}
.date-with-time-field {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.time-field {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;

  fieldset {
    border-left-width: 0px;
  }
}
</style>
