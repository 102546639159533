import gql from 'graphql-tag'

export const documentTags = gql`
  query documentTags($workspaceId: Float!, $sort: tagsSort) {
    documentTags(workspace_id: $workspaceId, sort: $sort) {
      id
      name
      color
      doc_count
    }
  }
`
export default documentTags
