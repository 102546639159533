import gql from 'graphql-tag'

export const editJob = gql`
  mutation editJob(
    $id: Float!
    $workspace_id: Float!
    $name: String
    $start_date: DateTime
    $expected_close_date: DateTime
    $description: String
    $job_priority_id: Float
    $job_status_id: Float
  ) {
    editJob(
      id: $id
      workspace_id: $workspace_id
      name: $name
      start_date: $start_date
      expected_close_date: $expected_close_date
      description: $description
      job_priority_id: $job_priority_id
      job_status_id: $job_status_id
    ) {
      status
      msg
      data
    }
  }
`
