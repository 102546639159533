<template>
  <v-dialog
    v-if="isOpen"
    v-model="isOpen"
    :retain-focus="false"
    max-width="90vw"
    @click:outside="handleClickOutside"
  >
    <v-card
      class="global-detail"
      :class="isTransparent ? 'bg-transparent' : 'bg-app'"
      max-height="85vh"
    >
      <component
        :is="component"
        :class="`ma-${marginSize}`"
        v-bind="componentProps"
      />
    </v-card>
  </v-dialog>
</template>

<script setup>
import Vue from 'vue'
import { ref } from 'vue'
import router from '@/router'

const component = ref()
const componentProps = ref()
const marginSize = ref(0)
const isOpen = ref(false)
const isTransparent = ref(false)
const rootRoute = ref(null)

const open = ({ componentId, props }) => {
  if (!isOpen.value) rootRoute.value = router.currentRoute

  switch (componentId) {
    case 'job-detail':
      component.value = () => import('@/views/job/JobDetail.vue')
      marginSize.value = 5
      isTransparent.value = false
      break

    case 'document-detail':
      component.value = () => import('@/views/document/DocumentDetail.vue')
      marginSize.value = 0
      isTransparent.value = false
      break

    case 'attachment-detail':
      component.value = () => import('@/views/attachment/AttachmentDetail.vue')
      marginSize.value = 0
      isTransparent.value = true
      break

    default:
      break
  }

  componentProps.value = props
  isOpen.value = true
}

const handleClickOutside = () => {
  if (rootRoute.value.name) {
    window.history.pushState({}, null, rootRoute.value.fullPath)

    return
  }

  router.push({ name: 'workspace-root' })
}
const toggle = () => {
  isOpen.value = !isOpen.value
}

Vue.$detail = open
Vue.$toggleDetail = toggle
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.global-detail {
  @include style-scroll-bar();
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    background: none;
  }
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-app {
  background-color: map-deep-get($material-light, 'background');

  .theme--dark & {
    background-color: map-deep-get($material-dark, 'background');
  }
}
</style>
