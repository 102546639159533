import gql from 'graphql-tag'

const deleteWorkflow = gql`
  mutation deleteWorkflow($workspace_id: Float!, $trigger_id: Float!) {
    deleteWorkflow(workspace_id: $workspace_id, trigger_id: $trigger_id) {
      status
      msg
    }
  }
`

export default deleteWorkflow
