import gql from 'graphql-tag'

export const changeDocumentOwnership = gql`
  mutation ChangeDocumentOwnership(
    $user_id: Float!
    $document_uuid: String!
    $workspace_id: Float!
  ) {
    changeDocumentOwnership(
      user_id: $user_id
      document_uuid: $document_uuid
      workspace_id: $workspace_id
    ) {
      data
      msg
      status
    }
  }
`
