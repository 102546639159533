import gql from 'graphql-tag'

const addCustomerTypes = gql`
  mutation ($name: String!, $color: String!, $workspace_id: Float!) {
    addCustomerTypes(name: $name, color: $color, workspace_id: $workspace_id) {
      status
      msg
      data
    }
  }
`

export default addCustomerTypes
