import gql from 'graphql-tag'

const customerTypes = gql`
  query customerTypes($workspace_id: Float!) {
    customerTypes(workspace_id: $workspace_id) {
      id
      name
      color
    }
  }
`

export default customerTypes
