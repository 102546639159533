import gql from 'graphql-tag'

const quitWorkspace = gql`
  mutation quitWorkspace($workspace_id: Float!) {
    quitWorkspace(workspace_id: $workspace_id) {
      status
      msg
      data
    }
  }
`
export default quitWorkspace
