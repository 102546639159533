import gql from 'graphql-tag'

const resetPassword = gql`
  mutation resetPassword(
    $forgot_password_code: String!
    $password: String!
    $confirm_password: String!
  ) {
    resetPassword(
      forgot_password_code: $forgot_password_code
      new_password: $password
      confirm_password: $confirm_password
    ) {
      msg
      status
      data
    }
  }
`

export default resetPassword
