import gql from 'graphql-tag'

const updateRelationType = gql`
  mutation updateRelationType($workspace_id: Float!, $relation_types: [paramUpdateRelationType!]!) {
    updateRelationType(workspace_id: $workspace_id, relation_types: $relation_types) {
      status
      msg
      data
    }
  }
`

export default updateRelationType
