import gql from 'graphql-tag'

export const unassignComment = gql`
  mutation unassignComment($comment_id: Float!, $workspace_id: Float!) {
    unassignComment(comment_id: $comment_id, workspace_id: $workspace_id) {
      status
      msg
      data
    }
  }
`
