import gql from 'graphql-tag'

const login = gql`
  mutation ($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      status
      token
      refreshToken
    }
  }
`

export default login
