import gql from 'graphql-tag'

const deleteChatBotCommand = gql`
  mutation deleteChatBotCommand($id: Float!, $platform_id: Float!, $workspace_id: Float!) {
    deleteChatBotCommand(id: $id, platform_id: $platform_id, workspace_id: $workspace_id) {
      status
      msg
      data
    }
  }
`

export default deleteChatBotCommand
