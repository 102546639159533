const stringOperator = [
  { name: 'Contains', value: 'CONTAINS' },
  { name: 'Does not contain', value: 'DOES NOT CONTAIN' },
  { name: 'Is', value: 'IS' },
  { name: 'Is not', value: 'IS NOT' },
  { name: 'Is set', value: 'IS SET' },
  { name: 'Is not set', value: 'IS NOT SET' },
  { name: 'Begins with', value: 'BEGINS WITH' },
  { name: 'Not begin with', value: 'NOT BEGIN WITH' },
  { name: 'Ends with', value: 'ENDS WITH' },
  { name: 'Not end with', value: 'NOT END WITH' },
]
const multipleOperator = [
  { name: 'Contains', value: 'IS' },
  { name: 'Does not contain', value: 'IS NOT' },
  { name: 'Is set', value: 'IS SET' },
  { name: 'Is not set', value: 'IS NOT SET' },
  { name: 'Has any of', value: 'HAS ANY OF' },
  { name: 'Has all of', value: 'HAS ALL OF' },
  { name: 'Is exactly', value: 'IS EXACTLY' },
  { name: 'Has none of', value: 'HAS NONE OF' },
]
const dateOperator = [
  { name: 'Is set', value: 'IS SET' },
  { name: 'Is not set', value: 'IS NOT SET' },
  { name: 'Between', value: 'BETWEEN' },
  { name: 'Not between', value: 'NOT BETWEEN' },
  { name: 'Is Before', value: 'IS BEFORE' },
  { name: 'Is After', value: 'IS AFTER' },
]

export const filterOptions = [
  {
    name: 'Nama',
    field_name: 'name',
    operator: stringOperator,
  },
  {
    name: 'Diarsipkan',
    field_name: 'archived_at',
    operator: [
      { name: 'Ya', value: 'IS SET' },
      { name: 'Tidak', value: 'IS NOT SET' },
      { name: 'Tampilkan Semua', value: 'ALL' },
    ],
  },
  {
    name: 'Aktivitas Terakhir',
    field_name: 'last_activity_at',
    operator: dateOperator,
  },
  {
    name: 'Dibuat Pada',
    field_name: 'created_at',
    operator: dateOperator,
  },
  {
    name: 'Dibuat Oleh',
    field_name: 'created_by',
    operator: multipleOperator,
  },
  {
    name: 'Visibilitas',
    field_name: 'is_public',
    operator: [
      {
        name: 'Publik',
        value: 'IS',
      },
      {
        name: 'Privat',
        value: 'IS NOT',
      },
    ],
  },
]
