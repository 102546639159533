import gql from 'graphql-tag'

const pinThread = gql`
  mutation ($thread_id: Float!) {
    pinThread(thread_id: $thread_id) {
      status
      msg
      data
    }
  }
`

export default pinThread
