import gql from 'graphql-tag'

export const unreadNotification = gql`
  mutation UnreadNotification($objectType: String!, $objectId: String!, $workspaceId: Float!) {
    unreadNotification(object_type: $objectType, object_id: $objectId, workspace_id: $workspaceId) {
      status
      msg
    }
  }
`
