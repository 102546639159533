import { notificationAccessor } from '@/constants/notification'
import errorHandling from '@/utils/errorHandling'
import { formatISO } from 'date-fns'
import Vue, { computed } from 'vue'
import { createHelpers } from 'vuex-map-fields'

const { getNotifications, updateNotifications } = createHelpers({
  getterType: 'getNotifications',
  mutationType: 'updateNotifications',
})

// TODO: make a function to solve client-side conflict / duplicate
// TODO: gruopOfNotification if a notification is moved around
// const checkExistance = state => {}

export default {
  state: {
    notificationLoading: false,
    paginationLoading: false,

    unreadNotification: 0,

    unreadNormalNotification: 0,
    unreadSnoozedNotification: 0,
    unreadArchivedNotification: 0,

    normalNotificationList: [],
    snoozedNotificationList: [],
    archivedNotificationList: [],
  },
  getters: {
    getNotifications,
  },
  mutations: {
    updateNotifications,

    addNotificationByObject(state, { objectId, notificationType, value }) {
      const path = notificationAccessor[notificationType].path
      const index = state[path].findIndex(notification => notification.objectId === objectId)

      state[path][index] = {
        ...state[path][index],
        notificationByObject: value,
      }
    },

    pushNotificationByObject(state, { objectId, notificationType, value }) {
      const path = notificationAccessor[notificationType].path
      const index = state[path].findIndex(notification => notification.objectId === objectId)

      state[path][index].notificationByObject.push(...value)
    },

    readNotification(state, { objectId, notificationType }) {
      const notification = computed(() =>
        state[notificationAccessor[notificationType].path].find(
          notification => notification.objectId === objectId,
        ),
      )

      if (!notification.value) return errorHandling('Tidak dapat membaca notifikasi!')

      notification.value.notification.read_at = formatISO(Date.now())
      // ! might be changed later on
      // ? due to: notifiationBadge turned into subscription model
      state[notificationAccessor[notificationType].badge] -= 1
      state.unreadNotification -= 1
    },

    unreadNotification(state, { objectId, notificationType }) {
      const notification = computed(() =>
        state[notificationAccessor[notificationType].path].find(
          notification => notification.objectId === objectId,
        ),
      )

      if (!notification.value) return errorHandling('Notifikasi tidak bisa diset belum terbaca!')

      notification.value.notification.read_at = null
      // ! might be changed later on
      // ? due to: notifiationBadge turned into subscription model
      state[notificationAccessor[notificationType].badge] += 1
      state.unreadNotification += 1
    },

    archiveNotification(state, { objectId, notificationType }) {
      const path = state[notificationAccessor[notificationType].path]
      const target = path.findIndex(notification => notification.objectId === objectId)

      if (target == -1) return errorHandling('Notifikasi tidak dapat diarsipkan!')

      const notification = path.splice(target, 1)[0]

      state.archivedNotificationList.unshift(notification)
      state[notificationAccessor[notificationType].badge] -= 1
    },

    unarchiveNotification(state, { objectId, notificationType }) {
      const path = state[notificationAccessor[notificationType].path]
      const target = path.findIndex(notification => notification.objectId === objectId)

      if (target == -1) return errorHandling('Arsip notifikasi tidak dapat dibuka!')

      const notification = path.splice(target, 1)[0]

      state.normalNotificationList.unshift(notification)
      state[notificationAccessor[notificationType].badge] -= 1
    },

    snoozeNotification(state, { objectId }) {
      const path = state.normalNotificationList
      const target = path.findIndex(notification => notification.objectId === objectId)

      if (target == -1) return errorHandling('Notifikasi tidak dapat disnooze!')

      const notification = path.splice(target, 1)[0]

      state.snoozedNotificationList.unshift(notification)
      state.unreadNormalNotificaiton -= 1
    },

    unsnoozeNotification(state, { objectId }) {
      const path = state.snoozedNotificationList
      const target = path.findIndex(notification => notification.objectId === objectId)

      if (target == -1) return errorHandling('Snooze notifikasi tidak dapat dihilangkan!')

      const notification = path.splice(target, 1)[0]

      state.normalNotificationList.unshift(notification)
      state.unreadSnoozedNotificaiton -= 1
    },

    // TODO: add functionality
    readAllNotification(state, { notificationType }) {
      const notification = computed(() =>
        state[notificationAccessor[notificationType].path].find(
          notification => notification.read_at === null,
        ),
      )

      console.log(notification.value)
    },

    receiveSubscriptionNotification(state, incomingNotification) {
      let group, path, type

      if (incomingNotification.document) type = 'document'
      else if (incomingNotification.job) type = 'job'
      else if (incomingNotification.team) type = 'team'

      if (incomingNotification.snoozed_at) group = 1
      else if (incomingNotification.archived_at) group = 2
      else group = 0

      path = state[notificationAccessor[group].path]

      const target = path.findIndex(notification => {
        if (incomingNotification.document)
          return notification.objectId == incomingNotification.document.uuid
        else if (incomingNotification.job)
          return notification.objectId == incomingNotification.job.id
        else if (incomingNotification.team)
          return notification.objectId == incomingNotification.team.id
        else return false
      })

      if (path[target]) {
        if (path[target].notification.read_at != null) state[notificationAccessor[group].badge] += 1

        Vue.set(path[target], 'notification', incomingNotification)

        if (path[target].notificationByObject)
          path[target].notificationByObject.unshift(incomingNotification)

        if (target > 0) {
          const notification = path.splice(target, 1)[0]
          path.unshift(notification)
        }
      } else {
        path.unshift({
          objectId: incomingNotification[type].id || incomingNotification[type].id,
          objectType: type,
          min_read_at: null,
          max_snoozed_at: null,
          notification: incomingNotification,
        })

        state.unreadNotification += 1
        state[notificationAccessor[group].badge] += 1
      }
    },
  },
  actions: {
    updateNotificationLoading(state, payload) {
      state.commit('updateNotifications', { path: 'notificationLoading', value: payload })
    },

    updateUnreadNotificationBadge(state, payload) {
      state.commit('updateNotifications', { path: 'unreadNotification', value: payload })
    },

    updateUnreadNotificationThreeGroups(
      state,
      {
        countUnreadNormalNotification,
        countUnreadSnoozedNotification,
        countUnreadArchivedNotification,
      },
    ) {
      state.commit('updateNotifications', {
        path: 'unreadNormalNotification',
        value: countUnreadNormalNotification,
      })
      state.commit('updateNotifications', {
        path: 'unreadSnoozedNotification',
        value: countUnreadSnoozedNotification,
      })
      state.commit('updateNotifications', {
        path: 'unreadArchivedNotification',
        value: countUnreadArchivedNotification,
      })
    },

    updateNotificationList(state, { type, data }) {
      state.commit('updateNotifications', {
        value: data,
        path: notificationAccessor[type].path,
      })
    },

    updatePushNotificationList(state, { type, data }) {
      const newData = [...notificationAccessor[type].state(), ...data]

      state.commit('updateNotifications', {
        value: newData,
        path: notificationAccessor[type].path,
      })
    },

    updateNotificationByObject(state, payload) {
      state.commit('addNotificationByObject', payload)
    },

    updatePushNotificationByObject(state, payload) {
      state.commit('pushNotificationByObject', payload)
    },

    updateReadNotification(state, payload) {
      state.commit('readNotification', payload)
    },

    updateReadAllNotification(state, payload) {
      state.commit('readAllNotification', payload)
    },

    updateUnreadNotification(state, payload) {
      state.commit('unreadNotification', payload)
    },

    updateArchiveNotification(state, payload) {
      state.commit('archiveNotification', payload)
    },

    updateUnarchiveNotification(state, payload) {
      state.commit('unarchiveNotification', payload)
    },

    updateSnoozeNotification(state, payload) {
      state.commit('snoozeNotification', payload)
    },

    updateUnsnoozeNotification(state, payload) {
      state.commit('unsnoozeNotification', payload)
    },

    updateSubscriptionNotification(state, payload) {
      state.commit('receiveSubscriptionNotification', payload)
    },
  },
}
