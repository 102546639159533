import gql from 'graphql-tag'

const userCards = gql`
  query userCards {
    userCards {
      id
      token
      masked_number
      card_type
      expired_at
    }
  }
`

export default userCards
