const { default: gql } = require('graphql-tag')

const hierarchies = gql`
  query hierarchies($workspace_id: Float!) {
    hierarchies(workspace_id: $workspace_id) {
      id
      name
      description
      users {
        id
        phone
        email
        name
        first_name
        last_name
        photo
      }
      parent_id
      created_at
      updated_at
      deleted_at
    }
  }
`
export default hierarchies
