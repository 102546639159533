import { createHelpers } from 'vuex-map-fields'

const { getWorkspaces, updateWorkspaces } = createHelpers({
  getterType: 'getWorkspaces',
  mutationType: 'updateWorkspaces',
})

export default {
  state: {
    workspaceList: [],
    selectedWorkspace: JSON.parse(localStorage.getItem('selectedWorkspace') ?? '{}'),
    modalActivator: false,
  },
  getters: {
    getWorkspaces,

    /**
     * @method
     * @param {import('./definition/auth').IState} state
     * @returns {number|null}
     */
    getCurrentWorkspaceId(state) {
      if (!state.selectedWorkspace.workspace) return null

      return state.selectedWorkspace.workspace.id
    },
    getCurrentWorkspace(state) {
      if (!state.selectedWorkspace.workspace) return null

      return state.selectedWorkspace.workspace
    },
  },
  mutations: {
    updateWorkspaces,
  },
  actions: {
    updateWorkspaceData(state, payload) {
      state.commit('updateWorkspaces', { path: 'workspaceList', value: payload })
    },
    setSelectedWorkspace(state, payload) {
      state.commit('updateWorkspaces', { path: 'selectedWorkspace', value: payload })
      localStorage.setItem('selectedWorkspace', JSON.stringify(payload))
    },
    setModalActivator(state, payload) {
      state.commit('updateWorkspaces', { path: 'modalActivator', value: payload })
    },
  },
}
