<template>
  <v-hover v-slot="{ hover }">
    <v-card
      outlined
      :to="to"
      :class="hover ? 'detail-info-card-hover' : ''"
      class="pa-2 horizontal-list-card detail-info-card"
    >
      <template>
        <div class="d-flex align-center">
          <span class="text-caption text--disabled">
            {{ title }}
          </span>
          <v-icon
            v-if="to !== null"
            color="primary"
            size="16"
            class="ml-auto"
          >
            {{ icons.mdiOpenInNew }}
          </v-icon>
        </div>
        <span class="text-caption text-truncate">
          <slot />
        </span>
      </template>
    </v-card>
  </v-hover>
</template>

<script>
import { mdiOpenInNew } from '@mdi/js'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    to: {
      type: [String, Object],
      default: null,
    },
  },
  setup() {
    return {
      icons: {
        mdiOpenInNew,
      },
    }
  },
}
</script>

<style lang="scss">
.detail-info-card-hover {
  border-color: map-deep-get($material-light, 'text-fields', 'outlined') !important;
  border-width: 1px !important;

  .theme--dark & {
    border-color: map-deep-get($material-dark, 'text-fields', 'outlined') !important;
  }
}
</style>
