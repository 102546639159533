import gql from 'graphql-tag'

/* TODO: make a fragment for job query, to prevent redundancy */
const jobs = gql`
  query jobs(
    $pagination: pagination
    $filter: paramQueryJobs
    $job_type_id: Float!
    $advanced_filter: advancedFilter
    $include_sub_job: Boolean
    $exclude_closed_status: Boolean
    $workspace_id: Float!
  ) {
    jobs(
      pagination: $pagination
      include_sub_job: $include_sub_job
      job_type_id: $job_type_id
      advanced_filter: $advanced_filter
      exclude_closed_status: $exclude_closed_status
      filter: $filter
      workspace_id: $workspace_id
    ) {
      count
      jobs {
        id
        name
        description
        strip_description
        attachment {
          file_name
          id
          file_size
          file_type
          created_at
        }
        job_type {
          id
          name
        }
        status {
          id
          name
          color
          urutan
        }
        priority {
          id
          name
          color
          urutan
        }
        start_date
        expected_close_date
        created_by {
          id
          first_name
          last_name
          name
        }
        created_at
        updated_at
        deleted_at
        assignedTo {
          id
          user {
            id
            phone
            email
            name
            first_name
            last_name
            photo
          }
        }
        lexorank
        custom_attribute_values
        lookup_values
        relation_type {
          id
          name
          origin
          multiple_record
          color
        }
        relation {
          job {
            id
            name
            description
          }
          relation_type {
            id
            name
            origin
            multiple_record
            color
          }
        }
        lookup {
          id
          name
          function_name
          value
        }
        parent {
          id
        }
        sub_job {
          id
          name
          description
          strip_description
          job_type {
            id
            name
          }
          parent {
            id
          }
          status {
            id
            name
            color
            urutan
          }
          priority {
            id
            name
            color
            urutan
          }
          start_date
          expected_close_date
          created_by {
            id
            first_name
            last_name
            name
          }
          created_at
          updated_at
          deleted_at
          assignedTo {
            id
            user {
              id
              phone
              email
              name
              first_name
              last_name
              photo
            }
          }
          lexorank
          custom_attribute_values
        }
      }
    }
  }
`

export default jobs
