<template>
  <div class="pa-3">
    <div class="d-flex flex-wrap flex-column flex-md-row justify-space-between">
      <h3>Integrasi Tokopedia</h3>
      <v-btn
        color="#04AB0F"
        small
        class="mt-4 mt-md-0"
        @click="$refs.tokopediaForm.show()"
      >
        <span class="text-white">Tambah Integrasi Tokopedia</span>
      </v-btn>
    </div>
    <v-card
      class="mt-6"
      outlined
    >
      <v-alert
        v-if="!tokenDetail.length && !loadingDetail"
        border="left"
        color="warning"
        dense
        text
        class="mb-0 text-subtitle-2 py-2 rounded-b-0"
      >
        <span class="text-caption"
          >Tokopedia belum terhubung ke Oriens CRM. Ikuti instruksi di bawah untuk
          menghubungkan.</span
        >
      </v-alert>
      <div
        v-else-if="loadingDetail"
        class="d-flex justify-center"
      >
        <v-progress-circular
          indeterminate
          color="success"
          class="my-4"
        />
      </div>
      <div v-else>
        <TokopediaConnectionCard
          v-for="token in tokenDetail"
          :key="token.id"
          :connection-data="token"
          @onDeleteSuccess="getTokenDetail(2, false)"
        />
      </div>
      <v-divider />
      <div class="pa-3 d-flex flex-column">
        <h5>Apa itu Tokopedia?</h5>
        <span class="text-subtitle-2"
          >Tokopedia adalah perusahaan internet yang memungkinkan setiap individu dan pemilik bisnis
          di Indonesia untuk mengembangkan dan mengelola bisnis online mereka secara mudah dan
          gratis, sekaligus memungkinkan pengalaman berbelanja online yang lebih aman dan
          nyaman.</span
        >
      </div>
      <v-divider />
      <div class="pa-3 d-flex flex-column">
        <h5>Cara Integrasi Tokopedia</h5>
        <span class="text-subtitle-2">1. Klik tombol Tambah Integrasi Tokopedia.</span>
        <span class="text-subtitle-2">2. Masukkan link URL toko anda.</span>
        <span class="text-subtitle-2">3. Klik Simpan.</span>
        <span class="text-subtitle-2"
          >4. Koneksi akan berstatus gagal atau muncul error, tunggu hingga admin Oriens menambahkan
          toko anda ke sistem Oriens.</span
        >
        <span class="text-subtitle-2"
          >5. Setelah toko ditambahkan oleh admin Oriens, harap cek email anda untuk menyetujui
          permintaan integrasi dari tokopedia</span
        >
        <span class="text-subtitle-2">6. Selamat! Toko anda sudah terintegrasi dengan Oriens.</span>
      </div>
    </v-card>

    <tokopedia-form
      ref="tokopediaForm"
      @success="getTokenDetail(2, false)"
    />
  </div>
</template>

<script>
import { onMounted } from 'vue'
import useIntegration from '@/composables/useIntegration'
import TokopediaForm from './TokopediaForm.vue'
import TokopediaConnectionCard from '@/views/integration/tokopedia/component/TokopediaConnectionCard.vue'

export default {
  components: {
    TokopediaForm,
    TokopediaConnectionCard,
  },
  setup() {
    const { tokenDetail, getTokenDetail, loadingDetail } = useIntegration()
    onMounted(() => {
      getTokenDetail(2, false)
    })

    return {
      tokenDetail,
      getTokenDetail,
      loadingDetail,
    }
  },
}
</script>

<style></style>
