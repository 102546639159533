<template>
  <v-dialog
    v-model="isOpen"
    max-width="1080"
    scrollable
    persistent
  >
    <v-form
      ref="folderForm"
      @submit.prevent="onSubmit"
    >
      <v-card>
        <div class="d-flex justify-space-between pa-4">
          <span class="text-h6 font-medium primary--text">Buat Folder Baru</span>
          <v-icon @click="close()">
            {{ icons.mdiClose }}
          </v-icon>
        </div>
        <template>
          <v-card-text class="pt-2">
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="formData.name"
                  :rules="[required]"
                  label="Nama folder"
                  placeholder="Masukkan nama folder"
                  outlined
                  dense
                  hide-details="auto"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <color-picker
                  v-model="formData.color"
                  label="Warna"
                  class="mb-2"
                  outlined
                  :rules="[required]"
                />
              </v-col>
            </v-row>
            <folder-permission-form
              ref="folderPermissionForm"
              v-model="formData"
            />
          </v-card-text>
        </template>
        <v-card-actions>
          <div class="mt-4 ml-md-auto">
            <v-btn
              color="primary"
              @click="submitForm"
            >
              Tambahkan Folder
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { ref } from 'vue'
import ColorPicker from '@/components/inputs/ColorPicker.vue'
import FolderPermissionForm from './FolderPermissionForm.vue'
import useFolder from '@/composables/useFolder'
import { avatarText } from '@core/utils/filter'
import { required } from '@core/utils/validation'
import { mdiClose, mdiAccountGroup, mdiShieldLock } from '@mdi/js'

export default {
  components: {
    ColorPicker,
    FolderPermissionForm,
  },
  setup(props, { emit }) {
    const isOpen = ref(false)
    const folderForm = ref(null)
    const formData = ref({
      is_public: true,
      user_permissions: [],
    })

    const { createFolder } = useFolder()
    const folderPermissionForm = ref(null)

    const reset = () => {
      formData.value = {
        isPublic: true,
        userPermissions: [],
      }
      folderForm.value.reset()
      if (folderPermissionForm.value) folderPermissionForm.value.reset()
    }

    const show = () => {
      isOpen.value = true
    }

    const close = () => {
      reset()
      isOpen.value = false
    }

    const submitForm = () => {
      if (folderForm.value.validate()) {
        createFolder(formData.value).then(() => {
          close()
          emit('onChangeFolder')
        })
      }
    }

    return {
      show,
      close,
      isOpen,
      formData,

      folderForm,
      submitForm,
      folderPermissionForm,

      required,
      avatarText,

      icons: {
        mdiClose,
        mdiAccountGroup,
        mdiShieldLock,
      },
    }
  },
}
</script>
