<template>
  <v-select
    v-model="inputData"
    :items="visibilities"
    outlined
    hide-details="auto"
    dense
    :label="label"
    item-text="name"
    item-value="id"
    class="mt-4"
    :loading="loadingVisibility"
  >
    <template #item="{ item }">
      <div class="d-flex">
        <v-icon class="mr-4">
          {{ resolveIconVisibility(item.id) }}
        </v-icon>
        <div class="d-flex flex-column my-1">
          <span class="text-subtitle-2">{{ item.name }}</span>
          <span class="text-caption text--disabled">{{ item.description }}</span>
        </div>
      </div>
    </template>
  </v-select>
</template>

<script>
import { onMounted, computed } from 'vue'
import useVisibility from '@/composables/useVisibility'

export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: 'Visibilitas',
    },
  },
  setup(props, { emit }) {
    const inputData = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })

    const { visibilityOptions, loadingVisibility, resolveIconVisibility, fetchVisibilities } =
      useVisibility()

    onMounted(() => {
      fetchVisibilities()
    })

    // filter out visibility id 5
    const visibilities = computed(() => visibilityOptions.value.filter(el => el.id !== 5))

    return {
      inputData,
      visibilities,
      loadingVisibility,
      resolveIconVisibility,
      fetchVisibilities,
    }
  },
}
</script>

<style></style>
