import { getField, setField } from 'vuex-use-fields'

export default {
  namespaced: true,
  state: {
    pagination: {
      limit: 10,
      offset: 0,
    },
  },
  getters: {
    getField,
  },
  mutations: {
    setField,
  },
  actions: {},
}
