const { default: gql } = require('graphql-tag')

const restartTelegramBot = gql`
  mutation restartTelegramBot {
    restartTelegramBot {
      status
      msg
      data
    }
  }
`
export default restartTelegramBot
