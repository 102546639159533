import gql from 'graphql-tag'

export const teamHierarchy = gql`
  query teamHierarchy(
    $workspace_id: Float!
    $team_id: Float
    $job_type_id: Float
    $folder_id: Float
    $document_uuid: String
  ) {
    teamHierarchy(
      workspace_id: $workspace_id
      team_id: $team_id
      job_type_id: $job_type_id
      folder_id: $folder_id
      document_uuid: $document_uuid
    ) {
      teams {
        ...recursiveTeams
      }
      usersNotInTeam {
        ...userDetail
      }
      userList {
        ...userDetail
      }
    }
  }

  fragment userDetail on User {
    id
    name
    phone
    email
    photo
    is_guest
    is_object_owner
    object_permission
    folder_permissions {
      permission_type
      job_type {
        id
      }
      folder {
        id
      }
    }
    document_permissions {
      permission_type
      document {
        uuid
      }
    }
  }
  fragment teamDetail on Teams {
    id
    name
    status
    is_public
    description
    parent {
      id
    }
    created_by {
      id
    }
    users {
      permission_type
      user {
        ...userDetail
      }
    }
  }
  fragment recursiveTeams on Teams {
    ...teamDetail
    children {
      ...teamDetail
      children {
        ...teamDetail
        children {
          ...teamDetail
          children {
            ...teamDetail
            children {
              ...teamDetail
              children {
                ...teamDetail
                children {
                  ...teamDetail
                  children {
                    ...teamDetail
                    children {
                      ...teamDetail
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
