import gql from 'graphql-tag'

const popoverUsers = gql`
  query popoverUsers($user_id: Float!, $workspace_id: Float!) {
    popoverUsers(user_id: $user_id, workspace_id: $workspace_id) {
      user {
        id
        phone
        email
        name
        first_name
        last_name
        photo
        user_status {
          id
          name
        }
        last_seen_at
        created_at
      }
      hierarchy {
        id
        name
        description
        created_at
      }
      teams {
        id
        name
        is_public
        channel_permission
        created_at
        status
      }
    }
  }
`

export default popoverUsers
