<template>
  <div
    v-show="isShown()"
    class="v-treeview-node v-treeview-node--click"
    :class="{ 'v-treeview-node--leaf': hasChildren }"
  >
    <v-lazy
      :options="{ threshold: 0.5 }"
      min-height="40"
      transition="fade-transition"
    >
      <div
        class="v-treeview-node__root"
        :class="{
          'v-treeview-node--active': selected || highlighted,
          'primary--text': selected,
          'warning--text': highlighted && !selected,
        }"
      >
        <div
          v-for="index in appendLevel"
          :key="index"
          class="v-treeview-node__level"
        />

        <div @click.stop>
          <v-btn
            v-if="hasChildren"
            icon
            x-small
            min-width="24"
            max-width="24"
            :disabled="value.disabled && !value.is_public"
            @click="open = !open"
          >
            <v-icon>
              {{ open ? icons.mdiMenuDown : icons.mdiMenuRight }}
            </v-icon>
          </v-btn>
        </div>

        <slot
          name="prepend"
          v-bind="{ item: value, open }"
        />

        <div class="v-treeview-node__label">
          <slot
            name="label"
            v-bind="{ item: value, open }"
          >
            {{ value.name }}
          </slot>
        </div>

        <slot
          name="append"
          v-bind="{ item: value }"
        />
      </div>
    </v-lazy>

    <div
      v-if="open"
      class="v-treeview-node__children"
    >
      <div
        :value="value.users"
        @input="updateValue"
      >
        <treeview-node
          v-for="child in value.users"
          :key="child.id"
          :value="child"
          :level="level + 1"
          :search="search"
          :has-match="isMatched(value)"
          @input="updateChildValue"
        >
          <template #prepend="{ item, open: openItem }">
            <slot
              name="prepend"
              v-bind="{ item, open: openItem }"
            />
          </template>
          <template #label="{ item, open: openItem }">
            <slot
              name="label"
              v-bind="{ item, open: openItem }"
            />
          </template>
          <template #append="{ item }">
            <slot
              name="append"
              v-bind="{ item }"
            />
          </template>
        </treeview-node>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { mdiMenuDown, mdiMenuRight } from '@mdi/js'

export default {
  name: 'TreeviewNode',
  props: {
    level: {
      type: Number,
      default: 0,
    },
    value: {
      type: Object,
      default: () => ({
        id: 0,
        key: '',
        name: '',
        users: [],
      }),
    },
    search: {
      type: String,
      default: null,
    },
    hasMatch: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const localValue = ref({ ...props.value })
    const open = ref(false)

    const hasChildren = computed(() => props.value.users != null && props.value.users.length > 0)
    const appendLevel = computed(() => props.level + (hasChildren.value ? 0 : 1))
    const highlighted = computed(() => false)
    const selected = computed(() => false)

    const hasSelectedChild = item => item.users?.some(hasSelectedChild) || false
    const hasMatchedChild = item =>
      item.users?.some(
        child =>
          child.name.toLowerCase().includes(props.search.toLowerCase()) ||
          child.email.toLowerCase().includes(props.search.toLowerCase()) ||
          hasMatchedChild(child),
      ) || false

    const isMatched = item =>
      item.name.toLowerCase().includes(props.search.toLowerCase()) ||
      item.email.toLowerCase().includes(props.search.toLowerCase())

    const isShown = () => {
      if (props.search === '') return true

      const matched = isMatched(props.value)
      const hasChildMatch = hasMatchedChild(props.value)

      if (hasChildren.value) return matched || (!matched && hasChildMatch)

      return (matched && !props.hasMatch) || (!matched && props.hasMatch)
    }

    const updateChildValue = value => {
      const index = localValue.value.users.findIndex(c => c.id === value.id)

      localValue.value.users[index] = value
      emit('input', localValue.value)
    }
    const updateValue = value => {
      console.log('update value')
      localValue.value.users = [...value]
      emit('input', localValue.value)
    }

    watch(
      () => props.value,
      value => {
        localValue.value = { ...value }
        open.value = hasSelectedChild(props.value)
      },
    )
    watch(
      () => props.search,
      value => {
        if (value === '') open.value = false
        else open.value = true
      },
    )

    return {
      open,
      isShown,
      selected,
      isMatched,
      highlighted,
      hasChildren,
      appendLevel,
      updateValue,
      updateChildValue,

      icons: {
        mdiMenuDown,
        mdiMenuRight,
      },
    }
  },
}
</script>
