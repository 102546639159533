export const COMMENT_TYPES = {
  JOB: 'job',
  DOCUMENT: 'document',
  ATTACHMENT: 'attachment',
}

export const stateMapping = {
  [COMMENT_TYPES.JOB]: {
    list: 'jobCommentList',
    count: 'jobCommentCount',
    loading: 'jobCommentLoading',
    pagination: 'jobCommentPagination',
  },
  [COMMENT_TYPES.DOCUMENT]: {
    list: 'documentCommentList',
    count: 'documentCommentCount',
    loading: 'documentCommentLoading',
    pagination: 'documentCommentPagination',
  },
  [COMMENT_TYPES.ATTACHMENT]: {
    list: 'attachmentCommentList',
    count: 'attachmentCommentCount',
    loading: 'attachmentCommentLoading',
    pagination: 'attachmentCommentPagination',
  },
}
