import gql from 'graphql-tag'

const getUserByForgotPasswordCode = gql`
  query getUserByForgotPasswordCode($forgot_password_code: String!) {
    getUserByForgotPasswordCode(forgot_password_code: $forgot_password_code) {
      id
      phone
      email
      first_name
      last_name
      photo
    }
  }
`

export default getUserByForgotPasswordCode
