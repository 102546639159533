import gql from 'graphql-tag'

export const archiveNotification = gql`
  mutation ArchiveNotification($objectId: String!, $objectType: String!, $workspaceId: Float!) {
    archiveNotification(
      object_id: $objectId
      object_type: $objectType
      workspace_id: $workspaceId
    ) {
      status
      msg
      data
    }
  }
`
