import gql from 'graphql-tag'

export const addDocument = gql`
  mutation addDocument(
    $workspace_id: Float!
    $parent_uuid: String
    $is_locked: Boolean!
    $is_public: Boolean!
    $user_permissions: [UserDocumentPermission!]!
  ) {
    addDocument(
      workspace_id: $workspace_id
      parent_uuid: $parent_uuid
      is_locked: $is_locked
      is_public: $is_public
      user_permissions: $user_permissions
    ) {
      status
      msg
      data
    }
  }
`
