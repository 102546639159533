import gql from 'graphql-tag'

const verifyNumberOrEmail = gql`
  mutation verifyNumberOrEmail(
    $phoneVerificationCode: String
    $emailVerificationCode: String
    $phone: String
    $email: String
  ) {
    verifyNumberOrEmail(
      phone_verification_code: $phoneVerificationCode
      email_verification_code: $emailVerificationCode
      phone: $phone
      email: $email
    ) {
      data
      msg
      status
    }
  }
`
export default verifyNumberOrEmail
