import gql from 'graphql-tag'

export const updateNotificationSettings = gql`
  mutation UpdateNotificationSettings($notificationSettings: JSON!, $workspaceId: Float!) {
    updateNotificationSettings(
      notification_settings: $notificationSettings
      workspace_id: $workspaceId
    ) {
      status
      msg
      data
    }
  }
`
