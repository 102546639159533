import gql from 'graphql-tag'

const taskDetail = gql`
  query taskDetail($task_id: Float!, $workspace_id: Float!) {
    taskDetail(task_id: $task_id, workspace_id: $workspace_id) {
      id
      subject
      description
      start_date
      end_date
      completed_at
      created_by {
        id
        name
      }
      tag {
        id
        name
        color
      }
      created_at
      updated_at
      deleted_at
      assignedTo {
        id
        user {
          id
          name
          photo
        }
      }
      private_reference {
        id
        user {
          id
          name
          photo
        }
      }
      customer {
        id
        name
        email
        phone_number
      }
      job {
        id
        name
      }
    }
  }
`

export default taskDetail
