import gql from 'graphql-tag'

export const updateCustomAttributeValue = gql`
  mutation updateCustomAttributeValue(
    $objectId: Float!
    $typeId: Float!
    $values: [customAttributeValues!]!
    $workspace_id: Float!
  ) {
    updateCustomAttributeValue(
      objectId: $objectId
      typeId: $typeId
      values: $values
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`
