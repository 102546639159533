import gql from 'graphql-tag'

export const setCoverAttachment = gql`
  mutation setCoverAttachment($attachId: String!) {
    setCoverAttachment(attachId: $attachId) {
      status
      msg
      data
    }
  }
`
