import errorHandling from '@/utils/errorHandling'
import { getTelegramBotList, getTelegramChatList } from '@/graphql/queries'
import { apolloClient } from '@/vue-apollo'
import { ref } from 'vue'
import store from '@/store'
// const MTProto = require('@mtproto/core/envs/browser')

const useTelegram = () => {
  const loadingTelegramBotList = ref(false)
  const loadingTelegramChatList = ref(false)

  const telegramBotList = ref([])
  const telegramChatList = ref([])

  const fetchTelegramBotList = () => {
    loadingTelegramBotList.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: getTelegramBotList,
          fetchPolicy: 'no-cache',
          variables: {
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        })
        .then(result => {
          loadingTelegramBotList.value = false
          telegramBotList.value = result.data.getTelegramBotList
          resolve(result.data.getTelegramBotList)
        })
        .catch(err => {
          loadingTelegramBotList.value = false
          reject(err)
          errorHandling(err)
        })
    })
  }

  const fetchTelegramChatList = tokenId => {
    loadingTelegramChatList.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: getTelegramChatList,
          fetchPolicy: 'no-cache',
          variables: {
            workspace_id: store.getters.getCurrentWorkspaceId,
            token_id: tokenId,
          },
        })
        .then(result => {
          loadingTelegramChatList.value = false
          telegramChatList.value = result.data.getTelegramChatList
          resolve(result.data.getTelegramChatList)
        })
        .catch(err => {
          loadingTelegramChatList.value = false
          reject(err)
          errorHandling(err)
        })
    })
  }

  return {
    loadingTelegramBotList,
    telegramBotList,
    fetchTelegramBotList,

    loadingTelegramChatList,
    telegramChatList,
    fetchTelegramChatList,
  }
}

export default useTelegram
