import gql from 'graphql-tag'

const addUserCard = gql`
  mutation addUserCard($param: paramAddCard!) {
    addUserCard(param: $param) {
      status
      msg
      data
    }
  }
`

export default addUserCard
