import gql from 'graphql-tag'

const getJobKanban = gql`
  query jobGroups($pagination: pagination!, $filter: paramGroupJobs, $workspace_id: Float!) {
    jobGroups(pagination: $pagination, filter: $filter, workspace_id: $workspace_id) {
      count
      jobs {
        id
        name
        description
        status {
          id
          name
          color
          urutan
          job_type {
            id
            name
          }
        }
        priority {
          id
          name
          color
        }
        expected_close_date
        customer {
          id
          name
        }
        created_by {
          id
          first_name
          last_name
        }
        created_at
        updated_at
        deleted_at
        assignedTo {
          id
          user {
            id
            phone
            email
            name
            first_name
            last_name
            photo
          }
        }
        custom_attribute_values
      }
    }
  }
`

export default getJobKanban
