import { format, formatDistanceToNow, isToday, isYesterday, parseISO } from 'date-fns'
import { id } from 'date-fns/locale'

export default function dateFormat(date, type) {
  if (!date) return ''

  const formatedDate = (e, useSuffix = false) =>
    format(parseISO(date), e, { locale: id, addSuffix: useSuffix })

  if (type === 2) {
    return formatedDate('EEEE, dd MMMM y')
  }

  /**
   * This is very specific case for this app:
   * Sets seconds to either 00 or 01 to decides
   * whether the time is included on the date or not.
   *
   * 00 means the time is included. (time will be shown)
   * 01 means the time is not included. (time will be hidden)
   */
  if (type === 3) {
    if (new Date(date).getSeconds() !== 0) return formatedDate('dd MMMM y')

    return formatedDate('dd MMMM y · HH.mm')
  }

  if (type === 4) {
    return formatedDate('dd MMMM y')
  }

  if (type === 5) {
    return formatedDate('dd/MM/y')
  }

  if (type === 6) {
    return formatedDate('dd/MM/yy')
  }

  if (type === 7) {
    return formatedDate('HH.mm')
  }

  if (type === 8) {
    return formatDistanceToNow(parseISO(date), { locale: id, addSuffix: true })
  }

  if (type === 9) {
    return formatedDate('dd/MM/y · HH.mm')
  }

  if (type === 10) {
    // distanceToNow.match(/\d+/)[0] (untuk mendapatkan angka)

    const distanceToNow = formatDistanceToNow(parseISO(date), { locale: id })

    // ketika dibawah 1 menit
    if (distanceToNow.includes('menit') && distanceToNow.match(/\d+/)[0] < 1) {
      return `Baru saja`
    }

    if (
      distanceToNow.includes('bulan') ||
      (distanceToNow.includes('hari') && distanceToNow.match(/\d+/)[0] > 7)
    ) {
      return formatedDate('dd MMMM y')
    }

    return distanceToNow
  }

  if (type === 11) {
    const distanceToNow = formatDistanceToNow(parseISO(date), { locale: id, addSuffix: true })

    if (distanceToNow.includes('menit') && distanceToNow.match(/\d+/)[0] <= 1) {
      return 'Baru saja'
    }

    if (isToday(parseISO(date))) {
      return `Hari ini, ${formatedDate('hh.mm', true)}`
    }

    if (isYesterday(parseISO(date))) {
      return `Kemarin, ${formatedDate('hh.mm', true)}`
    }

    return formatedDate('dd MMMM yyyy')
  }

  // ?- GUIDE: this type is to simply check wheter the
  // ?-        passed Date is recent than five minutes or not
  if (type === 12) {
    const distanceToNow = formatDistanceToNow(parseISO(date), { locale: id, addSuffix: true })

    // check whether the current date is recent in less than or equal to 5 minutes
    if (distanceToNow.includes('menit') && distanceToNow.match(/\d+/)[0] <= 5) return true

    // if not return false, when the date is not recent
    return false
  }

  return formatedDate('EEEE, dd MMMM y · HH.mm')
}
