import gql from 'graphql-tag'

const addTask = gql`
  mutation addTask(
    $assign_user_id: [Int!]
    $assign_team_id: [Int!]
    $subject: String!
    $description: String!
    $start_date: DateTime
    $end_date: DateTime
    $job_id: Float
    $customer_id: Float
    $visibility: Float!
    $visibility_5_users: [Int!]
    $tag_id: [Int!]
    $workspace_id: Float!
    $custom_attribute: [customAttributeValues!]!
  ) {
    addTask(
      assign_user_id: $assign_user_id
      assign_team_id: $assign_team_id
      subject: $subject
      description: $description
      start_date: $start_date
      end_date: $end_date
      job_id: $job_id
      customer_id: $customer_id
      visibility: $visibility
      visibility_5_users: $visibility_5_users
      tag_id: $tag_id
      workspace_id: $workspace_id
      custom_attribute: $custom_attribute
    ) {
      status
      msg
      data
    }
  }
`
export default addTask
