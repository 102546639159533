import gql from 'graphql-tag'

const globalSearch = gql`
  query globalSearch(
    $search: String!
    $object: String!
    $workspace_ids: [Float!]!
    $pagination: pagination!
    $sort: String!
    $sort_type: String!
    $archive_status: [Float!]!
    $created_by: Float!
    $assigned_to: Float!
  ) {
    globalSearch(
      search: $search
      object: $object
      workspace_ids: $workspace_ids
      pagination: $pagination
      sort: $sort
      sort_type: $sort_type
      archive_status: $archive_status
      created_by: $created_by
      assigned_to: $assigned_to
    ) {
      id
      name
      description
      created_at
      updated_at
      workspace {
        id
        name
        identifier_id
        members {
          role {
            id
            name
          }
          user {
            email
            first_name
            id
            last_name
            photo
          }
        }
      }
      type
    }
  }
`

export default globalSearch
