import gql from 'graphql-tag'

export const readNotification = gql`
  mutation ReadNotification($objectType: String!, $objectId: String!, $workspaceId: Float!) {
    readNotification(object_type: $objectType, object_id: $objectId, workspace_id: $workspaceId) {
      status
      msg
      data
    }
  }
`
