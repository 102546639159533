import gql from 'graphql-tag'

export const updateSavedView = gql`
  mutation updateSavedView(
    $saved_view_id: Float!
    $workspace_id: Float!
    $name: String
    $fields: [JSON!]
    $default_mode: String
    $is_default: Boolean
    $is_public: Boolean
    $is_view_locked: Boolean
    $is_autosave: Boolean
    $sort_state: [JSON!]
    $filter_state: JSON
    $group_state: JSON
  ) {
    updateSavedView(
      saved_view_id: $saved_view_id
      workspace_id: $workspace_id
      name: $name
      fields: $fields
      default_mode: $default_mode
      is_default: $is_default
      is_public: $is_public
      is_view_locked: $is_view_locked
      is_autosave: $is_autosave
      sort_state: $sort_state
      filter_state: $filter_state
      group_state: $group_state
    ) {
      status
      msg
      data
    }
  }
`
