import gql from 'graphql-tag'

export const deleteDocumentTag = gql`
  mutation DeleteDocumentTag($workspaceId: Float!, $tagId: Float!) {
    deleteDocumentTag(workspace_id: $workspaceId, tag_id: $tagId) {
      status
      msg
      data
    }
  }
`
