import gql from 'graphql-tag'

export const addJobTypePermission = gql`
  mutation addJobTypePermission(
    $user_permissions: [UserFolderPermission!]!
    $job_type_id: Float!
    $workspace_id: Float!
  ) {
    addJobTypePermission(
      user_permissions: $user_permissions
      job_type_id: $job_type_id
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`
