<template>
  <v-list-item
    rounded
    color="primary"
    :input-value="outlined"
    class="mb-2"
    :class="outlined ? 'workspace-card-active' : 'workspace-card-inactive'"
    @click="$emit('change')"
  >
    <v-list-item-avatar
      size="36"
      class="mr-2"
    >
      <v-img
        :src="
          data.workspace.photo
            ? data.workspace.photo
            : 'https://dummyimage.com/200x200/EDF2FE/6185F6.png?text=Oriens'
        "
      />
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title
        class="text-subtitle-2 me-2 text-truncate"
        style="max-width: 180px"
      >
        {{ data.workspace.name }}
      </v-list-item-title>

      <v-list-item-subtitle class="text-caption text--disabled">
        {{ data.role.name }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action class="d-flex flex-row align-items-center">
      <v-chip
        v-if="isMyWorkspace"
        x-small
        color="primary"
        outlined
        label
        class="pa-2 align-self-center mr-1"
      >
        Milik Saya
      </v-chip>

      <v-menu offset-y>
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            x-small
            v-bind="attrs"
            class="align-self-center"
            v-on="on"
          >
            <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item
            v-if="WorkspacePermissionGuard(data, 1)"
            @click="$emit('detail')"
          >
            <v-list-item-icon class="mr-1">
              <v-icon size="20">
                {{ icons.mdiCog }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Pengaturan</v-list-item-title>
          </v-list-item>

          <v-divider
            v-if="!isMyWorkspace"
            class="my-2"
          />

          <v-list-item
            v-if="!isMyWorkspace"
            @click="$emit('detail')"
          >
            <v-list-item-icon class="mr-1">
              <v-icon size="20">
                {{ icons.mdiExitToApp }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Keluar dari Workspace</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { computed } from 'vue'
import { mdiDotsVertical, mdiCog, mdiExitToApp } from '@mdi/js'
import store from '@/store'
import useWorkspace from '@/composables/useWorkspace'

export default {
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const userData = computed(() => store.state.auth.userData)
    const isMyWorkspace = computed(() => props.data.workspace.owner.id === userData.value.id)
    const { WorkspacePermissionGuard } = useWorkspace()

    return {
      isMyWorkspace,

      icons: {
        mdiDotsVertical,
        mdiCog,
        mdiExitToApp,
      },
      WorkspacePermissionGuard,
    }
  },
}
</script>

<style lang="scss">
.workspace-card-active {
  border: 1px solid map-deep-get($theme-colors, 'primary');
}

.workspace-card-inactive {
  border: 1px solid map-deep-get($material-light, 'dividers');

  .theme--dark & {
    border: 1px solid map-deep-get($material-dark, 'dividers');
  }
}
</style>
