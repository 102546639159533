import gql from 'graphql-tag'

const usersTeams = gql`
  query usersTeams(
    $search: String
    $pagination: pagination
    $job_type_id: Float
    $workspace_id: Float!
  ) {
    usersTeams(
      search: $search
      pagination: $pagination
      job_type_id: $job_type_id
      workspace_id: $workspace_id
    ) {
      users {
        id
        phone
        email
        name
        first_name
        last_name
        photo
      }
      teams {
        id
        name
        description
        users {
          user {
            id
            phone
            email
            name
            first_name
            last_name
            photo
          }
        }
      }
    }
  }
`

export default usersTeams
