<template>
  <div class="d-flex align-center">
    <limit-elements
      v-if="selectedIds"
      :elements="attachments"
      :limit="3"
      class="v-avatar-group flex-grow-1"
      :class="rootThemeClasses"
    >
      <!-- Items -->
      <template #default="item">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              icon
              max-height="26"
              max-width="26"
              v-on="on"
              @click.stop="
                $router.push({
                  name: 'attachment-detail',
                  params: { id: item.data.id },
                })
              "
            >
              <v-img
                :src="thumbnailURL(item.data)"
                width="26"
                height="26"
                class="rounded elevation-1"
                aspect-ratio="800/800"
              />
            </v-btn>
          </template>

          <span>{{ item.data.file_name }}</span>
        </v-tooltip>
      </template>

      <!-- Remaining Items -->
      <template #others="item">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              icon
              class="my-1"
              max-height="26"
              max-width="26"
              v-on="on"
              @click.stop="
                $router.push({
                  name: 'attachment-detail',
                  params: { id: item.data.id },
                })
              "
            >
              <v-img
                :src="thumbnailURL(item.data)"
                width="26"
                height="26"
                class="rounded elevation-1"
                aspect-ratio="800/800"
              />
            </v-btn>
          </template>
          <span>{{ item.data.file_name }}</span>
        </v-tooltip>
      </template>

      <!-- Expand Button -->
      <template #others-activator="item">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-avatar
              v-bind="attrs"
              size="20"
              color="white"
              class="primary--text text-caption"
              v-on="on"
            >
              <span>+{{ item.data.limit }}</span>
            </v-avatar>
          </template>

          <span>Lihat File Lain</span>
        </v-tooltip>
      </template>
    </limit-elements>

    <!-- Select Button -->
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-btn
          text
          outlined
          small
          v-bind="attrs"
          v-on="on"
          icon
          color="primary"
          @click.stop="attachMenu = true"
        >
          <v-icon size="18">
            {{ icons.mdiAttachment }}
          </v-icon>
        </v-btn>
      </template>

      <span>{{ attributeName }}</span>
    </v-tooltip>

    <v-dialog
      v-if="attachMenu"
      v-model="attachMenu"
      max-width="50vw"
      scrollable
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <attachment-grid
        v-if="attachments"
        :selected-attachments="attachments"
        :allow-selection="true"
        :job-id="jobId"
        :name="name"
        :job-type-id="jobTypeId"
        :attribute-id="attributeId"
        :width="'50vw'"
        :height="'322px'"
        @select="handleSelection($event)"
      />
    </v-dialog>
  </div>
</template>
<script>
import { onMounted, ref } from 'vue'
import LimitElements from '@/components/misc/LimitElements.vue'
import AttachmentGrid from '@/views/job/components/AttachmentGrid.vue'
import { mdiAttachment } from '@mdi/js'
import useVuetify from '@core/utils/vuetify'
import errorHandling from '@/utils/errorHandling'
import useCustomAttributes from '@/composables/useCustomAttributes'
import useAttachment from '@/composables/useAttachment'

export default {
  components: {
    LimitElements,
    AttachmentGrid,
  },
  props: {
    attachmentList: {
      type: Array,
      default: () => [],
    },
    selectedIds: {
      type: String,
      default: () => '',
    },
    attributeId: {
      type: Number,
      required: true,
    },
    jobId: {
      type: Number,
      required: true,
    },
    jobTypeId: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      default: null,
    },
    attributeName: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const attachMenu = ref(false)
    const { rootThemeClasses } = useVuetify()
    const attachments = ref()

    const { baseAttachmentUrl } = useAttachment()
    const { updateCustomAttributeValue } = useCustomAttributes({ objectTypeId: 2 })

    const toDataURL = async url => {
      const blob = await fetch(url).then(res => res.blob())

      return URL.createObjectURL(blob)
    }

    const thumbnailURL = att => {
      if (att.file_type !== 'image') {
        return `/filetypes/${att.file_name.split('.').pop()}.png`
      }

      // eslint-disable-next-line camelcase
      const { id, file_name } = att
      const fullname = file_name.split('.')
      const extension = fullname.pop()
      const name = fullname.join('.')

      return `${baseAttachmentUrl}/${props.jobTypeId}/${id}/${name}_thumbnail.${extension}`
    }

    onMounted(async () => {
      const ids = props.selectedIds ? props.selectedIds.slice(1, -1).split(',') : []
      attachments.value = props.attachmentList.filter(el => ids.includes(el.id))
    })

    const handleSelection = evt => {
      const selectedRowId = `[${evt.map(el => el.id).join(',')}]`

      updateCustomAttributeValue({
        objectId: props.jobId,
        typeId: 2,
        values: {
          id: +props.attributeId,
          value: selectedRowId,
        },
      })
        .then(() => {
          attachMenu.value = false
        })
        .catch(e => {
          errorHandling(e)
        })
    }

    return {
      attachMenu,
      rootThemeClasses,
      attachments,
      handleSelection,

      thumbnailURL,
      toDataURL,

      icons: {
        mdiAttachment,
      },
    }
  },
}
</script>
