<template>
  <div>
    <v-card
      outlined
      class="pa-2 horizontal-list-card detail-info-card mb-3"
    >
      <v-card-text class="py-0 px-0">
        <span class="text-caption text--disabled">Formula </span>

        <!-- Input Content Editable -->
        <div v-if="!errorData.isError">
          <span class="text-caption text--disabled">=</span>
          <span
            class="text-caption"
            style="white-space: pre-wrap"
          >
            {{ formulaValue }}
          </span>
        </div>
        <div v-else>
          <span
            style="color: red"
            class="text-caption"
            >{{ errorData.msg }}</span
          >
        </div>
        <div
          :class="`v-input v-input--hide-details v-input--is-label-active v-input--is-dirty v-input--dense theme--${
            isDark ? 'dark' : 'light'
          } v-text-field v-text-field--single-line v-text-field--is-booted v-text-field--placeholder`"
        >
          <div class="v-input__control">
            <div class="v-input__slot border-sm">
              <div
                class="v-text-field__slot"
                @click="setEndCaret"
              >
                <div
                  ref="elementInput"
                  contenteditable="true"
                  :style="`
                  white-space: pre-wrap;
                  overflow-wrap: anywhere !important;
                  width: 100%;
                  padding: 4px 0 2px;
                  ${isDark ? 'color: rgba(231, 227, 252, 0.87)' : 'rgba(94, 86, 105, 0.87)'};
                  outline: none;
                  caret-color: #6285f6;
                `"
                  @keydown="processInput"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <!-- Input Content Editable -->

        <!-- List Formula -->
        <formula-list
          :selected-index="1"
          :search-string="searchString"
          :list-items="searchList"
          :seek-index="seekIndex"
          class="mt-3"
          @update-search="updateSelectedString"
          @handle-enter-list="handleEnterList"
          @update-seek-index="
            e => {
              seekIndex = e
            }
          "
          @update-length-list="
            e => {
              lengthList = e.length
              fullValueList = e
            }
          "
          @click-list="handleEnterList"
        />
        <!-- List Formula -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { getCaretPosition, setCaretPosition } from '@/utils/caretHelper'
import FormulaList from './FormulaList.vue'
import useAppConfig from '@core/@app-config/useAppConfig'
import { hyperFormula } from '@/utils/hyperFormula'
import { registerFunctions } from '@/utils/hyperFormulaFunction'
import UndoRedoJs from '@/utils/undoRedo'

export default {
  components: {
    FormulaList,
  },

  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    customAttributes: {
      type: Array,
      required: true,
    },
    customAttribute: {
      type: Object,
      default: ref({}),
    },
    staticAttribute: {
      type: Object,
      default: ref({}),
    },
    priorityOptions: {
      type: Array,
      required: false,
    },
    statusOptions: {
      type: Array,
      required: false,
    },
    jobDetail: {
      type: Object,
      required: false,
    },
  },

  setup(props, { emit }) {
    // Error
    const errorData = ref({
      msg: '',
      isError: false,
    })

    let undoRedo

    // Variable
    const fullValueList = ref([])
    const elementInput = ref(null)
    const stringFormula = ref('')
    const lastCaretPos = ref(0)
    const selectedString = ref('')
    const properties = ref([
      {
        name: 'Nama',
        type: 'staticAttribute',
        propsName: 'name',
        typeData: 1,
        icon: 'mdiText',
        value: props.jobDetail ? props.jobDetail.name : 'name',
      },
      {
        name: 'Deskripsi',
        type: 'staticAttribute',
        typeData: 1,
        propsName: 'description',
        icon: 'mdiText',
        value: props.jobDetail ? props.jobDetail.description : 'Deskripsi',
      },
      {
        name: 'Prioritas',
        type: 'staticAttribute',
        typeData: 1,
        propsName: 'job_priority_id',
        icon: 'mdiArrowDownDropCircleOutline',
        value: props.jobDetail ? props.jobDetail.priority.name : 'prioritas',
      },
      {
        name: 'Status',
        type: 'staticAttribute',
        typeData: 1,
        propsName: 'job_status_id',
        icon: 'mdiArrowDownDropCircleOutline',
        value: props.jobDetail ? props.jobDetail.status.name : 'status',
      },
      {
        name: 'Ditugaskan Ke',
        type: 'staticAttribute',
        typeData: 5,
        propsName: 'assignedTo',
        icon: 'mdiFormatListCheckbox',
        value: props.jobDetail
          ? props.jobDetail.assignedTo.map(e => e.user.name).join(', ')
          : 'Data1, Data2',
      },
      {
        name: 'Sub Job',
        type: 'staticAttributeFixed',
        typeData: 5,
        propsName: 'sub_job',
        icon: 'mdiFormatListCheckbox',
        value: props.jobDetail
          ? props.jobDetail.sub_job.map(e => e.name).join(', ')
          : 'Data1, Data2',
      },
      {
        name: 'Watcher',
        type: 'staticAttributeFixed',
        typeData: 5,
        propsName: 'notification_reference',
        icon: 'mdiFormatListCheckbox',
        value: props.jobDetail
          ? props.jobDetail.notification_reference.map(e => e.user.name).join(', ')
          : 'Data1, Data2',
      },
      {
        name: 'Parent Job',
        type: 'staticAttributeFixed',
        typeData: 1,
        propsName: 'parent',
        icon: 'mdiText',
        value: props.jobDetail
          ? props.jobDetail.parent
            ? props.jobDetail.parent.name
            : '-'
          : 'Parent Job',
      },
      {
        name: 'Waktu Mulai',
        type: 'staticAttribute',
        typeData: 3,
        propsName: 'start_date',
        icon: 'mdiCalendarRange',
        value: props.jobDetail ? props.jobDetail.start_date : 'NOW()',
      },
      {
        name: 'Ekspektasi Waktu Tutup',
        type: 'staticAttribute',
        typeData: 3,
        propsName: 'expected_close_date',
        icon: 'mdiCalendarRange',
        value: props.jobDetail ? props.jobDetail.expected_close_date : 'NOW()',
      },
      {
        name: 'Dibuat Tanggal',
        type: 'staticAttributeFixed',
        typeData: 3,
        propsName: 'created_at',
        icon: 'mdiCalendarRange',
        value: props.jobDetail ? props.jobDetail.created_at : 'NOW()',
      },
      {
        name: 'Terakhir Terupdate',
        type: 'staticAttributeFixed',
        typeData: 3,
        propsName: 'updated_at',
        icon: 'mdiCalendarRange',
        value: props.jobDetail ? props.jobDetail.updated_at : 'NOW()',
      },
      {
        name: 'Dibuat Oleh',
        type: 'staticAttributeFixed',
        typeData: 1,
        propsName: 'created_by',
        icon: 'mdiAccount',
        value: props.jobDetail ? props.jobDetail.created_by.name : 'name',
      },
      {
        name: 'File Attachment',
        type: 'staticAttributeFixed',
        typeData: 5,
        propsName: 'attachment',
        icon: 'mdiCalendarRange',
        value: props.jobDetail
          ? props.jobDetail.attachment.map(e => e.file_name).join(', ')
          : 'name',
      },
    ])
    const seekIndex = ref(1)
    const lengthList = ref(0)

    const saveProperties = ref([])
    const saveStaticProps = ref([])

    const functions = registerFunctions

    const builtIns = [
      {
        id: 5,
        name: '&',
        icon: 'mdiPound',
        type: 'builtIns',
        description: 'Digunakan untuk penggabungan String',
        example: {
          title: '&',
          syntax: [
            {
              code: '"Hello" & "World"',
            },
            {
              code: 'CONCATENATE("Hello", "World")',
            },
          ],
        },
      },
      {
        id: 1,
        name: '+',
        icon: 'mdiPound',
        type: 'builtIns',
        description: 'Digunakan untuk melakukan pertambahan',
        example: {
          title: '+',
          syntax: [
            {
              code: '10 + 10',
            },
            {
              code: '5 + 5',
            },
          ],
        },
      },
      {
        id: 2,
        name: '-',
        icon: 'mdiPound',
        type: 'builtIns',
        description: 'Digunakan untuk melakukan pengurangan',
        example: {
          title: '-',
          syntax: [
            {
              code: '10 - 5',
            },
            {
              code: '5 - 2',
            },
          ],
        },
      },
      {
        id: 3,
        name: '*',
        icon: 'mdiPound',
        type: 'builtIns',
        description: 'Digunakan untuk melakukan perkalian',
        example: {
          title: '*',
          syntax: [
            {
              code: '10 * 2',
            },
            {
              code: '5 * 2',
            },
          ],
        },
      },
      {
        id: 4,
        name: '/',
        icon: 'mdiPound',
        type: 'builtIns',
        description: 'Digunakan untuk melakukan pembagian',
        example: {
          title: '/',
          syntax: [
            {
              code: '10 / 5',
            },
            {
              code: '8 / 2',
            },
          ],
        },
      },
      {
        id: 8,
        name: '>',
        icon: 'mdiPound',
        type: 'builtIns',
        description: 'Digunakan untuk melakukan perbandingan lebih besar dari',
        example: {
          title: '>',
          syntax: [
            {
              code: '10 > 5',
            },
            {
              code: '2 > 4',
            },
            {
              code: '2 > 2',
            },
          ],
        },
      },
      {
        id: 6,
        name: '>=',
        icon: 'mdiPound',
        type: 'builtIns',
        description: 'Digunakan untuk melakukan perbandingan lebih besar sama dengan',
        example: {
          title: '>=',
          syntax: [
            {
              code: '10 >= 5',
            },
            {
              code: '2 >= 4',
            },
            {
              code: '2 >= 2',
            },
          ],
        },
      },
      {
        id: 9,
        name: '<',
        icon: 'mdiPound',
        type: 'builtIns',
        description: 'Digunakan untuk melakukan perbandingan lebih kecil dari',
        example: {
          title: '<',
          syntax: [
            {
              code: '10 < 5',
            },
            {
              code: '2 < 4',
            },
            {
              code: '2 < 2',
            },
          ],
        },
      },
      {
        id: 7,
        name: '<=',
        icon: 'mdiPound',
        type: 'builtIns',
        description: 'Digunakan untuk melakukan perbandingan lebih kecil sama dengan',
        example: {
          title: '<=',
          syntax: [
            {
              code: '10 <= 5',
            },
            {
              code: '2 <= 4',
            },
            {
              code: '2 <= 2',
            },
          ],
        },
      },
      {
        id: 10,
        name: '=',
        icon: 'mdiPound',
        type: 'builtIns',
        description: 'Digunakan untuk melakukan perbandingan sama dengan',
        example: {
          title: '<=',
          syntax: [
            {
              code: '10 = 5',
            },
            {
              code: '2 = 4',
            },
            {
              code: '2 = 2',
            },
          ],
        },
      },
    ]

    // Custom Attribute Builder
    // Computed
    if (props.edit) {
      props.customAttributes.forEach(e => {
        if (props.customAttribute.id !== e.custom_attribute.id) {
          let icon = ''
          let value = ''
          if (e.custom_attribute.data_type.id === 1) {
            icon = 'mdiText'
            value = e.value
          }
          if (e.custom_attribute.data_type.id === 2) {
            icon = 'mdiNumeric'
            value = e.value
          }
          if (e.custom_attribute.data_type.id === 3) {
            icon = 'mdiCalendarRange'
            value = e.value
          }
          if (e.custom_attribute.data_type.id === 4) {
            icon = 'mdiArrowDownDropCircleOutline'
            value = `${props.jobDetail.custom_attribute_values[e.custom_attribute.id]}`
          }
          if (e.custom_attribute.data_type.id === 5) {
            icon = 'mdiFormatListCheckbox'
            value = `${props.jobDetail.custom_attribute_values[e.custom_attribute.id]}`
          }
          if (e.custom_attribute.data_type.id === 6) {
            icon = 'mdiCheckboxMarked'
            value = e.value ?? 'false'
          }
          if (e.custom_attribute.data_type.id === 7) {
            icon = 'mdiAttachment'
            if (typeof e.value !== 'string' && e.value !== null && e.value !== undefined) {
              return `"${e.value.map(f => f.file_name).join(', ')}"`
            } else {
              const valueId = e.value ? e.value.slice(1, -1).split(',') : []

              value = `${valueId.map(
                e =>
                  props.staticAttribute.attachment.filter(f => {
                    return e === f.id
                  })[0].file_name,
              )}`
            }
          }
          if (e.custom_attribute.data_type.id === 8) {
            icon = 'mdiSigma'
            value = e.value
          }

          properties.value.push({
            name: e.custom_attribute.name,
            type: 'customAttribute',
            id: e.custom_attribute.id,
            typeData: e.custom_attribute.data_type.id,
            icon: icon,
            value: value,
          })
        }
      })
    } else {
      props.customAttributes.forEach(e => {
        let icon = ''
        if (e.data_type.id === 1) {
          icon = 'mdiText'
        }
        if (e.data_type.id === 2) {
          icon = 'mdiNumeric'
        }
        if (e.data_type.id === 3) {
          icon = 'mdiCalendarRange'
        }
        if (e.data_type.id === 4) {
          icon = 'mdiArrowDownDropCircleOutline'
        }
        if (e.data_type.id === 5) {
          icon = 'mdiFormatListCheckbox'
        }
        if (e.data_type.id === 6) {
          icon = 'mdiCheckboxMarked'
        }
        if (e.data_type.id === 7) {
          icon = 'mdiAttachment'
        }
        if (e.data_type.id === 8) {
          icon = 'mdiSigma'
        }

        properties.value.push({
          name: e.name,
          type: 'customAttribute',
          id: e.id,
          typeData: e.data_type.id,
          icon: icon,
        })
      })
    }

    const testingGetPost = () => {
      return getCaretPosition(elementInput.value)
    }

    const searchList = {
      properties: properties.value,
      functions: functions,
      builtIns: builtIns,
    }

    const { isDark } = useAppConfig()

    // @methods mencari string pada stringFormula
    const searchString = ref('')

    // StringFormulaToBackendHelper
    const stringFormulaToBackend = computed(() => {
      const str = stringFormula.value

      const helper = {
        index: -1,
      }
      const buildCustomAttribute = str.replace(/(▸(?:[^▸◂])*◂)/gi, () => {
        helper.index += 1

        return `${saveProperties.value[helper.index].id}`
      })

      const buildStaticAttribtue = buildCustomAttribute.replace(/(▹(?:[^▹◃])*◃)/gi, match => {
        const staticProperty = properties.value.filter(e => e.name === match.slice(1, -1))[0]

        return `▹${staticProperty.propsName}◃`
      })

      return buildStaticAttribtue
    })

    // @methods BuildFormulaValue dengan tujuan menghitung formula
    // return value Formula
    const BuildFormulaValue = (str, argPropertiesInside = '', argSaveParent = []) => {
      try {
        // Digunakan Untuk menyimpan property jika yang diambil adalah property bertype formula
        // @scope variable
        const propertiesInside = {
          value: '',
        }

        // @scope saveParent
        let saveParent = []

        // Check Argumentasi dan memasukan ke variable properties inside
        if (argPropertiesInside === '') {
          propertiesInside.value = saveProperties.value
        } else {
          propertiesInside.value = argPropertiesInside
        }

        let afterBuildStr = ''

        // Menyimpan FormulaBuilderString
        // @scope variable
        const formulaBuilder = `=${str}`

        // Check Apakah di edit atau tidak
        if (props.edit) {
          // @scope Variable Index
          const helper = {
            index: -1,
          }

          // Mengubah semuah $ menjadi value didalam custom Attribute
          afterBuildStr = formulaBuilder.replace(/(▸(?:[^▸◂])*◂|▹(?:[^▹◃])*◃)/gi, match => {
            if (match.startsWith('▸')) {
              // Increment helper index
              helper.index += 1

              // Mengambil property
              const property = props.customAttributes.find(e => {
                if (typeof propertiesInside.value[helper.index] === 'object') {
                  return (
                    e.custom_attribute.id ===
                    Number(propertiesInside.value[helper.index].id.slice(1, -1))
                  )
                }

                return (
                  e.custom_attribute.id ===
                  Number(propertiesInside.value[helper.index].slice(1, -1))
                )
              })

              if (argSaveParent.length !== 0) {
                saveParent = argSaveParent
              } else {
                saveParent = [props.customAttribute.id]
              }

              if (saveParent.includes(property.custom_attribute.id)) {
                throw new Error('Repetitive Formula In Same Formula')
              }

              // check apakah custom attribute bertipe Formula
              if (
                property.custom_attribute.data_type.id === 8 ||
                property.custom_attribute.data_type.id === 9
              ) {
                // Check apakah didalam formula terdapat property
                if (property.custom_attribute.formula.includes('▸')) {
                  // Simpan Property yang ada didalam string formula menjadi sebuah array
                  const propertiesInsideNewFormula =
                    property.custom_attribute.formula.match(/▸(?:[^▸◂])*◂/gi)

                  saveParent.push(property.custom_attribute.id)

                  // check apakah custom attribute bertype Formula Text
                  if (property.custom_attribute.data_type.id === 8) {
                    return `"${BuildFormulaValue(
                      property.custom_attribute.formula.slice(1),
                      propertiesInsideNewFormula,
                      saveParent,
                    )}"`
                  }

                  // Jika disini sudah pasti bertype Formula Number
                  return BuildFormulaValue(
                    property.custom_attribute.formula.slice(1),
                    propertiesInsideNewFormula,
                  )
                }

                // Jika Didalam Formula tidak ada property / $
                return property.custom_attribute.formula.slice(1)
              }
              // Type Data Date

              if (property.custom_attribute.data_type.id === 1) {
                return `"${property.value}"`
              }

              if (property.custom_attribute.data_type.id === 3) {
                return `"${property.value.split('T')[0]}"`
              }

              if (property.custom_attribute.data_type.id === 4) {
                return `"${
                  property.custom_attribute.options.filter(e => {
                    return e.id === property.value
                  })[0].name
                }"`
              }

              if (property.custom_attribute.data_type.id === 5) {
                return `"${property.value
                  .map(e => {
                    return property.custom_attribute.options.filter(f => {
                      return f.id === e
                    })[0].name
                  })
                  .toString()}"`
              }

              if (property.custom_attribute.data_type.id === 6) {
                return `"${property.value ?? 'false'}"`
              }

              if (property.custom_attribute.data_type.id === 7) {
                if (typeof property.value !== 'string') {
                  return `"${property.value.map(e => e.file_name).join(', ')}"`
                }

                const valueId = property.value ? property.value.slice(1, -1).split(',') : []

                return `"${valueId.map(
                  e =>
                    props.staticAttribute.attachment.filter(f => {
                      return e === f.id
                    })[0].file_name,
                )}"`
              }

              // Mengembalikan nilai asli
              return property.value
            }

            if (match.slice(1, -1) === 'Deleted') {
              return `"Null"`
            }

            const staticProperty = properties.value.filter(e => e.name === match.slice(1, -1))[0]

            let staticAttribute = []
            if (staticProperty.type === 'staticAttributeFixed') {
              staticAttribute = props.jobDetail[staticProperty.propsName]
            } else {
              staticAttribute = props.staticAttribute[staticProperty.propsName]
            }

            if (match.slice(1, -1) === 'Prioritas') {
              return `"${
                props.priorityOptions.filter(e => e.id === props.jobDetail.priority.id)[0].name
              }"`
            }

            if (match.slice(1, -1) === 'Status') {
              return `"${
                props.statusOptions.filter(e => e.id === props.jobDetail.status.id)[0].name
              }"`
            }

            if (match.slice(1, -1) === 'Parent Job') {
              return `"${props.jobDetail.parent.name}"`
            }

            if (match.slice(1, -1) === 'Dibuat Oleh') {
              return `"${props.jobDetail.created_by.name}"`
            }

            if (staticProperty.typeData === 1) {
              return `"${staticAttribute}"`
            }

            if (staticProperty.typeData === 3) {
              if (typeof staticAttribute === 'string') {
                return `"${staticAttribute.split('T')[0]}"`
              }

              const monthBuilder = (staticAttribute.getMonth() + 1).toString().padStart(2, '0')
              const dateBuilder = staticAttribute.getDate().toString().padStart(2, '0')

              return `"${staticAttribute.getFullYear()}-${monthBuilder}-${dateBuilder}"`
            }

            if (match.slice(1, -1) === 'Ditugaskan Ke') {
              return `"${props.jobDetail.assignedTo.map(e => e.user.name).join(', ')}"`
            }

            if (match.slice(1, -1) === 'Sub Job') {
              return `"${props.jobDetail.sub_job.map(e => e.name).join(', ')}"`
            }

            if (match.slice(1, -1) === 'Watcher') {
              return `"${props.jobDetail.notification_reference.map(e => e.user.name).join(', ')}"`
            }

            if (match.slice(1, -1) === 'File Attachment') {
              return `"${props.jobDetail.attachment.map(e => e.file_name).join(', ')}"`
            }
          })
        } else {
          const helper = {
            index: -1,
          }

          afterBuildStr = formulaBuilder.replace(/(▸(?:[^▸◂])*◂|▹(?:[^▹◃])*◃)/gi, match => {
            if (match.startsWith('▸')) {
              helper.index += 1

              const property = properties.value.find(e => {
                if (typeof propertiesInside.value[helper.index] === 'object') {
                  return e.id === Number(propertiesInside.value[helper.index].id.slice(1, -1))
                }

                return (
                  e.custom_attribute.id ===
                  Number(propertiesInside.value[helper.index].slice(1, -1))
                )
              })

              if (property.typeData === 1) {
                return '"Text"'
              } else if (property.typeData === 2) {
                return '1'
              } else if (property.typeData === 3) {
                return 'NOW()'
              } else if (property.typeData === 4) {
                return '"Data1"'
              } else if (property.typeData === 5) {
                return '"Data1, Data2"'
              } else if (property.typeData === 6) {
                return '"true"'
              } else if (property.typeData === 7) {
                return '"Data1,Data2"'
              } else if (property.typeData === 8) {
                return '""'
              }

              return `"${property.name}"`
            }

            const staticProperty = properties.value.filter(e => e.name === match.slice(1, -1))[0]

            if (staticProperty.typeData === 5) {
              return `"Data1,Data2"`
            } else if (staticProperty.typeData === 3) {
              return `NOW()`
            }

            return `"${match.slice(1, -1)}"`
          })
        }

        // Mengembalikan Nilai Formula
        const value = hyperFormula.setCellContents({ col: 0, row: 0, sheet: 0 }, afterBuildStr)[0]
          .newValue
        const dataType = hyperFormula.getCellValueType({ col: 0, row: 0, sheet: 0 })

        if (value.type != 'ERROR') {
          switch (dataType) {
            case 'STRING':
              if (value.match(/\d{4}-\d{2}-\d{2}/g)) {
                emit('update-datatype', 3)
                break
              }

              emit('update-datatype', 1)
              break
            case 'NUMBER':
              emit('update-datatype', 2)
              break
            case 'BOOLEAN':
              emit('update-datatype', 6)
              break
            default:
              break
          }
        }

        if (value.message) {
          if (value.message.length > 300 && afterBuildStr.length !== 1) {
            return value.message.replace(/Expecting: [^]*ErrorLiteral]\nbut /, '')
          }

          if (afterBuildStr.length !== 1) {
            value.message = value.message.replace(/token of type --> RParen/, '--> )')

            return value.message
          } else {
            return ''
          }
        }

        return value
      } catch (error) {
        errorData.value = {
          msg: error,
          isError: true,
        }

        return error
      }
    }

    // @Computed Ini ditujukan untuk merender ulang preview
    const formulaValue = computed(() => {
      // Memanggil Fungsi BuildFormulaValue
      return BuildFormulaValue(stringFormula.value)
    })

    const doIfCaretInsideChips = () => {
      try {
        const selection = window.getSelection()

        if (!selection.anchorNode.parentElement.classList.contains('v-chip__content')) {
          throw 'Cant Calculate'
        }

        const parents = selection.anchorNode.parentElement.parentElement.parentElement
        const chips = selection.anchorNode.parentElement.parentElement
        const posChips = {
          index: -1,
        }

        parents.childNodes.forEach((e, i) => {
          if (e === chips) {
            posChips.index = i
          }
        })

        if (chips.textContent.length / 2 > selection.anchorOffset) {
          selection.setBaseAndExtent(parents, posChips.index, parents, posChips.index)
        } else {
          if (
            parents.childNodes[posChips.index + 1] &&
            !parents.childNodes[posChips.index + 1].classList
          ) {
            selection.setBaseAndExtent(
              parents.childNodes[posChips.index + 1],
              0,
              parents.childNodes[posChips.index + 1],
              0,
            )
          } else {
            selection.setBaseAndExtent(parents, posChips.index + 1, parents, posChips.index + 1)
          }
        }
      } catch (e) {
        return false
      }
    }

    // Methods
    // @setEndCaret Ke Posisi Akhir
    const setEndCaret = () => {
      doIfCaretInsideChips()
      getWord()
    }

    // @methods formatedStringInput mengubah stringFormated menjadi enak Diliat
    const strInputFormatted = () => {
      const str = stringFormula.value
      const helper = {
        index: -1,
      }
      const left = `[^▸◂]*(?="▸[^▸◂]*◂")`
      const rigth = `(?<="▸[^▸◂]*◂")[^▸◂]*`

      const regex = new RegExp(`${left}|${rigth}`, 'gi')

      const buildPlainText = str.replace(regex, e => {
        return `<span><span>${e}</span></span>`
      })

      // Replace string dengan awalan $ menjadi sebuah chip vuetify
      const customAttributeBuilder = buildPlainText.replace(/(▸(?:[^▸◂])*◂)/gi, (index, match) => {
        helper.index += 1

        return `<span contenteditable="false" class="mt-0 v-chip theme--${
          isDark ? 'dark' : 'light'
        } v-size--small secondary"><span index="${helper.index}" id="${
          saveProperties.value[helper.index].id
        }" class="v-chip__content">${match}</span></span>`
      })

      const staticAttributeBuilder = customAttributeBuilder.replace(/▹(?:[^▹◃])*◃/gi, match => {
        return `<span contenteditable="false" class="mt-0 v-chip theme--${
          isDark ? 'dark' : 'light'
        } v-size--small secondary"><span class="v-chip__content">${match}</span></span>`
      })

      return staticAttributeBuilder
    }

    const checkCaretOutsideDivContenteditable = () => {
      const t = window.getSelection()

      if (t.anchorNode.classList) {
        if (t.anchorNode.classList.contains('v-input__slot')) {
          return true
        }
      }

      return false
    }

    const setBackInsideDivContenteditable = () => {
      const t = window.getSelection()

      if (t.anchorOffset === 0) {
        t.setBaseAndExtent(
          t.anchorNode.children[0].children[0],
          0,
          t.anchorNode.children[0].children[0],
          0,
        )
      } else {
        t.setBaseAndExtent(
          t.anchorNode.children[0].children[0],
          t.anchorNode.children[0].children[0].childNodes.length,
          t.anchorNode.children[0].children[0],
          t.anchorNode.children[0].children[0].childNodes.length,
        )
      }
    }

    // @handle enter list w
    const handleEnterList = selectedStr => {
      if (selectedStr.name === undefined) {
        return
      }

      let lengthString = selectedStr.name.length - searchString.value.length

      if (selectedStr.type === 'customAttribute') {
        stringFormula.value = `${stringFormula.value.substring(
          0,
          lastCaretPos.value - searchString.value.length,
        )}▸${selectedStr.name}◂${stringFormula.value.substring(lastCaretPos.value)}`
        lengthString += 2
      } else if (
        selectedStr.type === 'staticAttribute' ||
        selectedStr.type === 'staticAttributeFixed'
      ) {
        stringFormula.value = `${stringFormula.value.substring(
          0,
          lastCaretPos.value - searchString.value.length,
        )}▹${selectedStr.name}◃${stringFormula.value.substring(lastCaretPos.value)}`
        lengthString += 2
      } else if (selectedStr.type === 'functions') {
        stringFormula.value = `${stringFormula.value.substring(
          0,
          lastCaretPos.value - searchString.value.length,
        )}${selectedStr.name}${stringFormula.value.substring(lastCaretPos.value)}`
        lengthString -= +1
      } else {
        stringFormula.value = `${stringFormula.value.substring(0, lastCaretPos.value)}${
          selectedStr.name
        }${stringFormula.value.substring(lastCaretPos.value)}`
        lengthString = selectedStr.name.length
      }

      // Process Save Properties
      saveProperties.value = saveProperties.value.map(e => {
        if (e.loc >= lastCaretPos.value) {
          e.loc += lengthString
        }

        return e
      })

      if (selectedStr.type === 'customAttribute') {
        saveProperties.value.push({
          id: `▸${selectedStr.id}◂`,
          loc: lastCaretPos.value - searchString.value.length,
        })
      }

      saveProperties.value = saveProperties.value.sort((a, b) => a.loc - b.loc)

      elementInput.value.innerHTML = strInputFormatted()
      setCaretPosition(elementInput.value, lastCaretPos.value + lengthString)

      if (checkCaretOutsideDivContenteditable()) {
        setBackInsideDivContenteditable()
      }

      doIfCaretInsideChips()

      getWord()
      lastCaretPos.value = getCaretPosition(elementInput.value).pos
      undoRedo.record(
        {
          text: stringFormula.value,
          saveProps: [...saveProperties.value],
          posCaret: testingGetPost().pos,
        },
        true,
      )

      emit('update-formula', `=${stringFormulaToBackend.value}`)
    }

    const copyFunction = () => {
      const s = window.getSelection()
      const r = s.getRangeAt(0)
      const contentClone = r.cloneContents()

      const builderJson = []
      let isText = false

      contentClone.childNodes.forEach(e => {
        if (e.wholeText && !isText) {
          builderJson.push({
            type: 'text',
            text: e.wholeText,
          })

          isText = true
        }

        if (e.classList) {
          if (e.children.length !== 0) {
            if (e.children[0].attributes.id) {
              builderJson.push({
                type: 'customAttribute',
                text: e.children[0].textContent,
                id: e.children[0].attributes.id.value,
              })
            } else {
              builderJson.push({
                type: 'staticAttribute',
                text: e.children[0].textContent,
              })
            }
            isText = false
          }
        }
      })

      navigator.clipboard.writeText(JSON.stringify(builderJson))
    }

    const pasteFunction = () => {
      navigator.clipboard.readText().then(e => {
        // stringFormula.value += e
        let json = ''
        let stringBuilder = ''
        try {
          json = JSON.parse(e)
        } catch (error) {
          errorData.value = {
            msg: 'Aktifitas yang dilakukan tidak bisa dilakukan',
            isError: true,
          }

          return error
        }

        json.forEach(e => {
          if (e.type === 'customAttribute') {
            stringBuilder += e.text
            saveProperties.value.push(e.id)
          }

          if (e.type === 'staticAttribute') {
            stringBuilder += e.text
          }

          if (e.type === 'text') {
            stringBuilder += e.text
          }
        })

        stringFormula.value = `${stringFormula.value.substring(
          0,
          getCaretPosition(elementInput.value).pos,
        )}${stringBuilder}${stringFormula.value.substring(
          getCaretPosition(elementInput.value).pos,
        )}`
        elementInput.value.innerHTML = strInputFormatted()

        setCaretPosition(elementInput.value, lastCaretPos.value + stringBuilder.length)

        getWord()

        emit('update-formula', `=${stringFormulaToBackend.value}`)
        undoRedo.record(
          {
            text: stringFormula.value,
            saveProps: [...saveProperties.value],
            posCaret: testingGetPost().pos,
          },
          true,
        )
      })
    }

    const selectionDelete = (type = 'delete') => {
      const s = window.getSelection()
      const r = s.getRangeAt(0)
      const cloneRange = r.cloneRange()
      const contentInsideRange = cloneRange.cloneContents().children

      // Get Start Position
      r.collapse(true)
      const startPos = testingGetPost().pos

      s.removeAllRanges()
      s.addRange(cloneRange)

      // Get End Position
      r.collapse(false)
      const endPos = testingGetPost().pos

      const saveIndexFromContentRange = []
      contentInsideRange.forEach(e => {
        if (e.children.length !== 0) {
          if (e.children[0].attributes.id) {
            saveIndexFromContentRange.push(e.children[0].attributes.index.value)
          }
        }
      })

      saveProperties.value = saveProperties.value.filter((e, i) => {
        return !saveIndexFromContentRange.includes(String(i))
      })

      stringFormula.value = `${stringFormula.value.substring(
        0,
        startPos,
      )}${stringFormula.value.substring(endPos)}`
      elementInput.value.innerHTML = strInputFormatted()

      saveProperties.value = saveProperties.value.map(e => {
        if (e.loc > startPos) {
          e.loc -= endPos - startPos
        }

        return e
      })

      if (type === 'delete') {
        setCaretPosition(elementInput.value, startPos)
      } else {
        setCaretPosition(elementInput.value, startPos)
      }
      getWord()

      undoRedo.record(
        {
          text: stringFormula.value,
          saveProps: [...saveProperties.value],
          posCaret: testingGetPost().pos,
        },
        true,
      )
    }

    const getWord = (direction = 'default') => {
      let sel,
        word = ''

      const tokenSkip = ['+', '-', '/', '*', '(', ')', ',', ' ', '&']

      let savePosition = {
        current: 0,
        end: 0,
        start: 0,
      }

      if (window.getSelection && (sel = window.getSelection()).modify) {
        var selectedRange = sel.getRangeAt(0)
        sel.collapseToStart()

        savePosition.current = sel.extentOffset

        sel.modify('move', 'backward', 'word')
        sel.modify('extend', 'forward', 'word')

        savePosition.end = sel.extentOffset
        savePosition.start = sel.anchorOffset

        const splitIndex = savePosition.end - savePosition.start
        const endString = savePosition.end - savePosition.current

        if (direction === 'default') {
          word = sel.toString().substring(0, splitIndex - endString)
        }

        if (direction === 'toRigth') {
          word = sel.toString().substring(0, savePosition.current - savePosition.start)
        }

        // Restore selection
        sel.removeAllRanges()
        sel.addRange(selectedRange)
      }

      if (!tokenSkip.includes(stringFormula.value[getCaretPosition(elementInput.value).pos - 1])) {
        searchString.value = word
      } else {
        searchString.value = ''
      }
    }

    const cutFunction = () => {
      copyFunction()
      selectionDelete('cut')
    }

    // @Methods yang tujuan untuk memproses semua yang diinputkan user
    const processInput = e => {
      if (elementInput.value === '') {
        elementInput.value = e.target
      }

      errorData.value = {
        msg: '',
        isError: false,
      }

      const el = e.target

      lastCaretPos.value = getCaretPosition(el).pos

      if (e.code === 'Escape') {
        seekIndex.value = 0
      }

      // Handle Select List with arrow key up and down
      if (e.code.startsWith('ArrowUp') || e.code.startsWith('ArrowDown')) {
        if (e.code === 'ArrowUp') {
          if (seekIndex.value - 1 > 0) {
            seekIndex.value -= 1
          } else {
            seekIndex.value = lengthList.value
          }
        } else if (e.code === 'ArrowDown') {
          if (seekIndex.value < lengthList.value) {
            seekIndex.value += 1
          } else {
            seekIndex.value = 1
          }
        }
        e.preventDefault()

        return
      }

      // menyimpan last position lastCaret
      if (e.code.startsWith('ArrowLeft')) {
        if (e.shiftKey) {
          return
        }

        const s = window.getSelection()

        if (s.focusNode.previousElementSibling) {
          if (s.focusNode.previousElementSibling.classList) {
            if (
              (s.focusNode.previousElementSibling.classList.contains('v-chip') ||
                s.focusNode.previousElementSibling.classList.contains('v-chip__content')) &&
              s.focusOffset === 1
            ) {
              searchString.value = ''

              return
            }

            if (
              (s.focusNode.previousElementSibling.classList.contains('v-chip') ||
                s.focusNode.previousElementSibling.classList.contains('v-chip__content')) &&
              s.focusOffset === 0
            ) {
              searchString.value = ''

              return
            }
          }
        }

        if (s.focusNode === elementInput.value) {
          if (s.focusNode.childNodes[s.focusOffset - 1]) {
            s.setBaseAndExtent(s.focusNode, s.focusOffset, s.focusNode, s.focusOffset)

            return
          }
        }

        const pos = getCaretPosition(elementInput.value).pos
        elementInput.value.innerHTML = ''
        elementInput.value.innerHTML = strInputFormatted()

        if (pos !== 1) {
          setCaretPosition(elementInput.value, pos - 1)
        }

        e.preventDefault()
        doIfCaretInsideChips()
        getWord()

        return
      }

      if (e.code.startsWith('ArrowRight')) {
        const s = window.getSelection()

        if (e.shiftKey) {
          return
        }

        if (s.focusNode.nextElementSibling) {
          if (s.focusNode.nextElementSibling.classList) {
            if (
              (s.focusNode.nextElementSibling.classList.contains('v-chip') ||
                s.focusNode.nextElementSibling.classList.contains('v-chip__content')) &&
              s.focusOffset === s.focusNode.wholeText.length - 1
            ) {
              searchString.value = ''

              return
            }

            if (
              (s.focusNode.nextElementSibling.classList.contains('v-chip') ||
                s.focusNode.nextElementSibling.classList.contains('v-chip__content')) &&
              s.focusOffset === s.focusNode.wholeText.length
            ) {
              searchString.value = ''

              return
            }
          }
        }

        if (s.focusNode === elementInput.value) {
          if (s.focusNode.childNodes[s.focusOffset]) {
            s.setBaseAndExtent(s.focusNode, s.focusOffset, s.focusNode, s.focusOffset)

            return
          }
        }

        const isInChips = s.focusNode.parentElement.classList.contains('v-chip__content')

        const pos = getCaretPosition(elementInput.value).pos

        if (isInChips) {
          setCaretPosition(elementInput.value, pos + s.focusNode.wholeText.length)

          return
        }

        if (pos !== stringFormula.value.length) {
          elementInput.value.innerHTML = ''
          elementInput.value.innerHTML = strInputFormatted()
          setCaretPosition(elementInput.value, pos + 1)
          e.preventDefault()
        }

        doIfCaretInsideChips()

        getWord('toRigth')

        return
      }

      // Prevent Enter
      if (e.code.startsWith('Enter')) {
        if (e.shiftKey) {
          stringFormula.value = `${stringFormula.value.substring(
            0,
            testingGetPost().pos,
          )}\n${stringFormula.value.substring(testingGetPost().pos)}`

          return
        }

        e.preventDefault()
        handleEnterList(fullValueList.value[seekIndex.value - 1])

        return
      }

      if (e.code === 'Tab') {
        const pos = testingGetPost().pos
        stringFormula.value = `${stringFormula.value.substring(
          0,
          pos,
        )}\t${stringFormula.value.substring(pos)}`

        elementInput.value.innerHTML = strInputFormatted()

        setCaretPosition(elementInput.value, pos + 1)

        e.preventDefault()

        return
      }

      // Section Utama
      // Menambahkan Char
      if (e.key.length === 1 || e.key === 'Space') {
        const s = window.getSelection()

        if (e.ctrlKey && e.code === 'KeyA') {
          return
        }

        if (e.ctrlKey && e.code === 'KeyC') {
          copyFunction()
          e.preventDefault()

          return
        }

        if (e.ctrlKey && e.code === 'KeyV') {
          if (s.toString().length !== 0) {
            selectionDelete()
          }

          pasteFunction()
          e.preventDefault()

          return
        }

        if (e.ctrlKey && e.code === 'KeyX') {
          cutFunction()
          e.preventDefault()
          emit('update-formula', `=${stringFormulaToBackend.value}`)

          return
        }

        if (e.ctrlKey && e.code === 'KeyZ') {
          const tempUndo = undoRedo.undo(false)

          stringFormula.value = tempUndo.text
          saveProperties.value = tempUndo.saveProps
          el.innerHTML = strInputFormatted()
          setCaretPosition(el, tempUndo.posCaret)
          doIfCaretInsideChips()

          getWord()
          emit('update-formula', `=${stringFormulaToBackend.value}`)

          e.preventDefault()

          return
        }

        if (e.ctrlKey && e.code === 'KeyY') {
          const tempUndo = undoRedo.redo(false)

          stringFormula.value = tempUndo.text
          saveProperties.value = tempUndo.saveProps
          el.innerHTML = strInputFormatted()
          setCaretPosition(el, tempUndo.posCaret)
          emit('update-formula', `=${stringFormulaToBackend.value}`)
          doIfCaretInsideChips()
          getWord()

          e.preventDefault()

          return
        }

        if (s.toString().length !== 0) {
          selectionDelete()
        }

        // Menambahkan Char
        stringFormula.value = `${stringFormula.value.substring(0, getCaretPosition(el).pos)}${
          e.key
        }${stringFormula.value.substring(getCaretPosition(el).pos)}`
        el.innerHTML = strInputFormatted()

        // Set Caret to next position
        setCaretPosition(el, lastCaretPos.value + 1)

        getWord()
        seekIndex.value = 1

        saveProperties.value = saveProperties.value.map(e => {
          if (e.loc > lastCaretPos.value - searchString.value.length) {
            e.loc += 1
          }

          return e
        })

        undoRedo.record({
          text: stringFormula.value,
          saveProps: [...saveProperties.value],
          posCaret: testingGetPost().pos,
        })

        e.preventDefault()
      } else if (e.key === 'Backspace') {
        // Hapus Char
        const s = window.getSelection()

        if (s.toString().length !== 0) {
          selectionDelete()
          emit('update-formula', `=${stringFormulaToBackend.value}`)
          e.preventDefault()

          return
        }

        const isInChips =
          s.focusNode.parentElement.classList.contains('v-chip__content') &&
          s.focusOffset === s.focusNode.wholeText.length

        let isBehindChips = false
        let pel = ''

        if (s.focusNode.previousSibling) {
          if (s.focusNode.previousSibling.classList) {
            isBehindChips =
              (s.focusNode.previousSibling.classList.contains('v-chip') ||
                s.focusNode.previousSibling.classList.contains('v-chip__content')) &&
              s.focusOffset === 0
          }
        }

        if (s.focusNode === elementInput.value && testingGetPost().pos !== 0) {
          if (s.focusNode.childNodes[s.focusOffset - 1].classList) {
            pel = s.focusNode.childNodes[s.focusOffset - 1].children[0]
          }
        }

        // Check if the current element is the v-chip
        if (isInChips || isBehindChips || pel) {
          e.preventDefault()

          if (isInChips) {
            pel = s.focusNode.parentElement
          } else if (isBehindChips) {
            pel = s.focusNode.previousElementSibling.children[0]
          }
          stringFormula.value =
            stringFormula.value.substring(0, lastCaretPos.value - pel.textContent.length) +
            stringFormula.value.substring(lastCaretPos.value)

          if (pel.attributes.index) {
            saveProperties.value.splice(pel.attributes.index.value, 1)

            saveProperties.value = saveProperties.value.map(e => {
              if (e.loc > lastCaretPos.value - searchString.value.length) {
                e.loc -= pel.textContent.length
              }

              return e
            })
          }
          pel.parentElement.remove()
          undoRedo.record(
            {
              text: stringFormula.value,
              saveProps: [...saveProperties.value],
              posCaret: testingGetPost().pos,
            },
            true,
          )
          getWord()
        } else {
          stringFormula.value = `${stringFormula.value.substring(
            0,
            getCaretPosition(el).pos - 1,
          )}${stringFormula.value.substring(getCaretPosition(el).pos)}`
          el.innerHTML = strInputFormatted()

          // Set Caret to prev position
          if (testingGetPost().pos - 1 === 0) {
            setCaretPosition(el, 0)
            getWord()
          } else {
            setCaretPosition(el, lastCaretPos.value - 1)
            getWord()

            saveProperties.value = saveProperties.value.map(e => {
              if (e.loc > lastCaretPos.value - searchString.value.length) {
                e.loc -= 1
              }

              return e
            })
          }

          doIfCaretInsideChips()

          e.preventDefault()
          undoRedo.record({
            text: stringFormula.value,
            saveProps: [...saveProperties.value],
            posCaret: testingGetPost().pos,
          })
        }
      }

      emit('update-formula', `=${stringFormulaToBackend.value}`)
    }

    // @methods update Selected String
    const updateSelectedString = e => {
      selectedString.value = e
    }

    // @Methods Load Builder From props.customAttribute
    const loadBuilder = () => {
      const builderFormulaString = props.customAttribute.formula.slice(1)

      let length = 0
      let afterBuilder = builderFormulaString.replace(/▸(?:[^▸◂])*◂/gi, (match, ...args) => {
        const temp = props.customAttributes.find(
          e => e.custom_attribute.id === Number(match.slice(1, -1)),
        )

        if (temp) {
          saveProperties.value.push({
            id: `▸${temp.custom_attribute.id}◂`,
            loc: args[0] + length,
          })
          length += `▸${temp.custom_attribute.name}◂`.length - match.length

          return `▸${temp.custom_attribute.name}◂`
        }

        return `▹Deleted◃`
      })

      afterBuilder = afterBuilder.replace(/▹(?:[^▹◃])*◃/gi, match => {
        saveStaticProps.value.push(match)

        if (match.startsWith('"')) {
          const staticProperty = properties.value.filter(e => e.propsName === match.slice(2, -2))[0]

          return `"▹${staticProperty.name}◃"`
        }
        const staticProperty = properties.value.filter(e => e.propsName === match.slice(1, -1))[0]

        if (staticProperty) {
          return `▹${staticProperty.name}◃`
        }

        return `▹Deleted◃`
      })

      stringFormula.value = afterBuilder
      elementInput.value.innerHTML = strInputFormatted()
      undoRedo = new UndoRedoJs(5, {
        text: stringFormula.value,
        saveProps: [...saveProperties.value],
        posCaret: stringFormula.value.length,
      })
    }

    onMounted(async () => {
      if (props.edit) {
        loadBuilder()
      } else {
        undoRedo = new UndoRedoJs(5)
      }
    })

    return {
      testingGetPost,
      errorData,
      elementInput,
      formulaValue,
      searchString,
      fullValueList,
      props,
      seekIndex,
      lengthList,
      stringFormula,
      isDark,
      searchList,
      saveProperties,
      stringFormulaToBackend,
      handleEnterList,
      updateSelectedString,
      strInputFormatted,
      processInput,
      setEndCaret,
    }
  },
}
</script>
