import gql from 'graphql-tag'

export const addJobStatus = gql`
  mutation ($workspace_id: Float!, $job_status: [paramAddJobStatus!]!, $job_type_id: Float!) {
    addJobStatus(workspace_id: $workspace_id, job_status: $job_status, job_type_id: $job_type_id) {
      status
      msg
      data
    }
  }
`
