/**
 * ?- GUIDE, notificationSettingsContext_Explained
 *
 * To understand what the array [ NUMBER, NUMBER ] means
 * it simply translate to the state of the notificaiton
 * -> 1 = true
 * -> 0 = false
 *
 * [index-0, index-1]
 * and their order is determined by
 * the first index of the array that its an "IN-APP" notification
 * while the second index is a email notificaion
 *
 * if [index-0, index-1, index-2]
 * is present, then it simply means that the
 * third index its used for before_time
 */

import {
  mdiCancel,
  mdiCardBulletedOutline,
  mdiPriorityHigh,
  mdiStarFourPointsOutline,
} from '@mdi/js'

export const notificationContext = [
  {
    label: 'Default',
    description: 'Settings notifikasi secara default',
    icon: mdiCardBulletedOutline,
    color: '',
  },
  {
    label: 'Penting',
    description: 'Hanya notifikasi yang penting saja',
    icon: mdiPriorityHigh,
    color: 'info',
  },
  {
    label: 'Custom',
    description: 'Konfigurasi notifikasi-mu sendiri',
    icon: mdiStarFourPointsOutline,
    color: 'warning',
  },
  {
    label: 'Off',
    description: 'Semua notifikasi ini dalam kondisi off',
    icon: mdiCancel,
    color: 'error',
  },
]

export const settingsContext = [
  {
    title: 'job',
    children: [
      {
        accessor: 'assignee_changed',
        description: 'Perubahan Assignee',
      },
      {
        accessor: 'status_changed',
        description: 'Perubahan Status',
      },
      {
        accessor: 'priority_changed',
        description: 'Perubahan Prioritas',
      },
      {
        accessor: 'job_assigned_to_me',
        description: 'Job Assign ke Saya',
      },
      {
        accessor: 'job_unassigned_to_me',
        description: 'Job Unassign dari Saya',
      },
      {
        accessor: 'description_updated',
        description: 'Perubahan Deskripsi',
      },
      {
        accessor: 'description_mentioned_me',
        description: 'Perubahan Deskripsi yang mention Saya',
      },
      {
        accessor: 'tags_updated',
        description: 'Perubahan Tag',
      },
      {
        accessor: 'delete_restore',
        description: 'Saat Job dihapus atau direstore',
      },
      {
        accessor: 'archive_unarchive',
        description: 'Saat Job diarchive atau diunarchive',
      },
      {
        accessor: 'add_me_as_watcher',
        description: 'Saya ditambahkan sebagai Watcher',
      },
      {
        accessor: 'remove_me_as_watcher',
        description: 'Saya diremove sebagai Watcher',
      },
      {
        accessor: 'custom_attribute_value_changed',
        description: 'Perubahan Custom Attribute',
      },
      {
        accessor: 'name_changed',
        description: 'Perubahan nama Job',
      },
      {
        accessor: 'parent_job_changed',
        description: 'Perubahan Parent Job',
      },
      {
        accessor: 'sub_job_changed',
        description: 'Perubahan Sub Job',
      },
      {
        accessor: 'due_date_changed',
        description: 'Perubahan Due Date',
      },
      {
        accessor: 'start_date_changed',
        description: 'Perubahan Start Date',
      },
      {
        accessor: 'attachment_updated',
        description: 'Perubahan Attachment',
      },
      {
        accessor: 'comment',
        description: 'Saat sebuah komentar dibuat',
      },
      {
        accessor: 'comment_mentioned_me',
        description: 'Saat komentar tersebut mention Saya',
      },
      {
        accessor: 'comment_assigned_me',
        description: 'Saat komentar di assign ke Saya',
      },
      {
        accessor: 'comment_unassigned_me',
        description: 'Saat komentar tersebut di unassign dari Saya',
      },
      {
        accessor: 'comment_assign_unassign',
        description: 'Saat komentar di assign / unassign kepada seseorang',
      },
      {
        accessor: 'comment_resolve_unresolve',
        description: 'Saat komentar di resolve / unresolve',
      },
    ],
  },
  {
    title: 'Document',
    children: [
      {
        accessor: 'delete_restore',
        description: 'Saat Job dihapus atau direstore',
      },
      {
        accessor: 'archive_unarchive',
        description: 'Saat Job diarchive atau diunarchive',
      },
      {
        accessor: 'add_me_as_watcher',
        description: 'Saya ditambahkan sebagai Watcher',
      },
      {
        accessor: 'remove_me_as_watcher',
        description: 'Saya diremove sebagai Watcher',
      },
      {
        accessor: 'comment',
        description: 'Saat sebuah komentar dibuat',
      },
      {
        accessor: 'comment_mentioned_me',
        description: 'Saat komentar tersebut mention Saya',
      },
      {
        accessor: 'comment_assigned_me',
        description: 'Saat komentar di assign ke Saya',
      },
      {
        accessor: 'comment_unassigned_me',
        description: 'Saat komentar tersebut di unassign dari Saya',
      },
      {
        accessor: 'comment_assign_unassign',
        description: 'Saat komentar di assign / unassign kepada seseorang',
      },
      {
        accessor: 'comment_resolve_unresolve',
        description: 'Saat komentar di resolve / unresolve',
      },
    ],
  },
  {
    title: 'Tanggal Awal & Akhir',
    children: [
      {
        accessor: 'daily_end_date_summary',
        description: 'Saat laporan harian saat hari berkahir',
      },
      {
        accessor: 'when_job_become_overdue',
        description: 'Saat Job melewati batas waktu yang ditentukan',
      },
      {
        accessor: 'before_end_date_reminder',
        description: 'Notifikasi sebelum berakhirnya suatu Job',
      },
      {
        accessor: 'before_start_date_reminder',
        description: 'Notifikasi sebelum dimulainya suatu Job',
      },
    ],
  },
]

export const defaultNotification = {
  inAppOption: 0,
  emailOption: 0,
  desktopOption: 0,
  job: {
    comment: [1, 1],
    name_changed: [1, 1],
    tags_updated: [1, 1],
    delete_restore: [1, 1],
    status_changed: [1, 1],
    sub_job_changed: [1, 1],
    assignee_changed: [1, 1],
    due_date_changed: [1, 1],
    priority_changed: [1, 1],
    add_me_as_watcher: [1, 1],
    archive_unarchive: [1, 1],
    attachment_updated: [1, 1],
    job_assigned_to_me: [1, 1],
    parent_job_changed: [1, 1],
    start_date_changed: [1, 1],
    comment_assigned_me: [1, 1],
    description_updated: [1, 1],
    comment_mentioned_me: [1, 1],
    job_unassigned_to_me: [1, 1],
    remove_me_as_watcher: [1, 1],
    comment_unassigned_me: [1, 1],
    comment_assign_unassign: [1, 1],
    description_mentioned_me: [1, 1],
    comment_resolve_unresolve: [1, 1],
    custom_attribute_value_changed: [1, 1],
  },
  document: {
    comment: [1, 1],
    delete_restore: [1, 1],
    add_me_as_watcher: [1, 1],
    archive_unarchive: [1, 1],
    comment_assigned_me: [1, 1],
    comment_mentioned_me: [1, 1],
    remove_me_as_watcher: [1, 1],
    comment_unassigned_me: [1, 1],
    comment_assign_unassign: [1, 1],
    comment_resolve_unresolve: [1, 1],
  },
  expected_close_date: {
    daily_end_date_summary: [1, 1],
    when_job_become_overdue: [1, 1],
    before_end_date_reminder: [1, 1, 10],
    before_start_date_reminder: [1, 1, 10],
  },
}

export const importantNotification = {
  job: {
    comment: [1, 1],
    name_changed: [1, 1],
    tags_updated: [1, 1],
    delete_restore: [1, 1],
    status_changed: [1, 1],
    sub_job_changed: [1, 1],
    assignee_changed: [1, 1],
    due_date_changed: [1, 1],
    priority_changed: [1, 1],
    add_me_as_watcher: [1, 1],
    archive_unarchive: [1, 1],
    attachment_updated: [1, 1],
    job_assigned_to_me: [1, 1],
    parent_job_changed: [1, 1],
    start_date_changed: [1, 1],
    comment_assigned_me: [1, 1],
    description_updated: [1, 1],
    comment_mentioned_me: [1, 1],
    job_unassigned_to_me: [1, 1],
    remove_me_as_watcher: [1, 1],
    comment_unassigned_me: [1, 1],
    comment_assign_unassign: [1, 1],
    description_mentioned_me: [1, 1],
    comment_resolve_unresolve: [0, 1],
    custom_attribute_value_changed: [1, 1],
  },
  document: {
    comment: [1, 1],
    delete_restore: [1, 1],
    add_me_as_watcher: [1, 1],
    archive_unarchive: [1, 1],
    comment_assigned_me: [1, 1],
    comment_mentioned_me: [1, 1],
    remove_me_as_watcher: [1, 1],
    comment_unassigned_me: [1, 1],
    comment_assign_unassign: [0, 1],
    comment_resolve_unresolve: [1, 1],
  },
  expected_close_date: {
    daily_end_date_summary: [1, 1],
    when_job_become_overdue: [1, 1],
    before_end_date_reminder: [1, 1, 10],
    before_start_date_reminder: [1, 1, 10],
  },
}

export const offNotification = {
  job: {
    comment: [0, 0],
    name_changed: [0, 0],
    tags_updated: [0, 0],
    delete_restore: [0, 0],
    status_changed: [0, 0],
    sub_job_changed: [0, 0],
    assignee_changed: [0, 0],
    due_date_changed: [0, 0],
    priority_changed: [0, 0],
    add_me_as_watcher: [0, 0],
    archive_unarchive: [0, 0],
    attachment_updated: [0, 0],
    job_assigned_to_me: [0, 0],
    parent_job_changed: [0, 0],
    start_date_changed: [0, 0],
    comment_assigned_me: [0, 0],
    description_updated: [0, 0],
    comment_mentioned_me: [0, 0],
    job_unassigned_to_me: [0, 0],
    remove_me_as_watcher: [0, 0],
    comment_unassigned_me: [0, 0],
    comment_assign_unassign: [0, 0],
    description_mentioned_me: [0, 0],
    comment_resolve_unresolve: [0, 0],
    custom_attribute_value_changed: [0, 0],
  },
  document: {
    comment: [0, 0],
    delete_restore: [0, 0],
    add_me_as_watcher: [0, 0],
    archive_unarchive: [0, 0],
    comment_assigned_me: [0, 0],
    comment_mentioned_me: [0, 0],
    remove_me_as_watcher: [0, 0],
    comment_unassigned_me: [0, 0],
    comment_assign_unassign: [0, 0],
    comment_resolve_unresolve: [0, 0],
  },
  expected_close_date: {
    daily_end_date_summary: [0, 0],
    when_job_become_overdue: [0, 0],
    before_end_date_reminder: [0, 0, 10],
    before_start_date_reminder: [0, 0, 10],
  },
}
