import gql from 'graphql-tag'

const getUserByVerificationCode = gql`
  query getUserByVerificationCode(
    $email_verification_code: String
    $phone_verification_code: String
  ) {
    getUserByVerificationCode(
      email_verification_code: $email_verification_code
      phone_verification_code: $phone_verification_code
    ) {
      id
      phone
      email
      first_name
      last_name
      photo
    }
  }
`

export default getUserByVerificationCode
