import gql from 'graphql-tag'

const createRelationType = gql`
  mutation createRelationType($workspace_id: Float!, $relation_types: [paramCreateRelationType!]!) {
    createRelationType(workspace_id: $workspace_id, relation_types: $relation_types) {
      status
      msg
      data
    }
  }
`

export default createRelationType
