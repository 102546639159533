<template>
  <v-dialog
    v-model="isOpen"
    max-width="600"
  >
    <v-card class="pa-4">
      <div class="d-flex justify-space-between">
        <span class="text-h6 font-medium primary--text">Undang Pengguna</span>
        <v-icon @click="isOpen = false">
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <span class="d-block text-subtitle-2 mt-2 mb-4"
        >Undang email pengguna yang terdaftar di Oriens CRM. Kamu bisa mengundang lebih dari satu
        email, tekan enter atau tombol plus untuk menambahkan email.</span
      >

      <v-autocomplete
        v-model="emails"
        :items="emails"
        label="Email"
        placeholder="Input email pengguna"
        outlined
        dense
        multiple
        chips
        deletable-chips
        small-chips
        hide-details
        :search-input.sync="search"
        append-icon=""
        hide-no-data
        hide-selected
        @keydown="onKeyDown"
      >
        <template #no-data>
          <span class="ma-2 text-subtitle-2">Cari email pengguna...</span>
        </template>
        <template #append>
          <v-icon
            v-if="search && search.length > 4"
            color="primary"
            @click="addEmail"
          >
            {{ icons.mdiPlusCircle }}
          </v-icon>
        </template>
      </v-autocomplete>
      <div class="d-flex align-center">
        <v-select
          v-model="selectedRole"
          :items="roles"
          label="Role"
          class="my-4"
          item-text="text"
          item-value="value"
          placeholder="Pilih role pengguna"
          outlined
          dense
          hide-details
        />
        <v-btn
          class="ml-4"
          color="primary"
          :loading="loading"
          @click="submit"
        >
          Undang
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from 'vue'
import { mdiClose, mdiPlusCircle } from '@mdi/js'
import { emailValidator } from '@core/utils/validation'
import Vue from 'vue'
import { apolloClient } from '@/vue-apollo'
import { inviteToWorkspace } from '@/graphql/mutations'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'

export default {
  setup() {
    const selectedRole = ref(3)
    const isOpen = ref(false)
    const search = ref('')
    const emails = ref([])
    const loading = ref(false)

    const show = () => {
      isOpen.value = true
    }

    const close = () => {
      isOpen.value = false
    }
    const submit = () => {
      loading.value = true
      apolloClient
        .mutate({
          mutation: inviteToWorkspace,
          variables: {
            email_invite: emails.value,
            workspace_id: store.getters.getCurrentWorkspaceId,
            role_id: selectedRole.value,
          },
        })
        .then(({ data }) => {
          loading.value = false
          Vue.notify({
            type: data.inviteToWorkspace.status,
            title: 'Berhasil',
            text: data.inviteToWorkspace.msg,
          })
        })
        .catch(err => {
          errorHandling(err)
          loading.value = false
        })
    }
    const addEmail = () => {
      // using '=== true' because the validation is
      // returning either boolean true or invalid (string) message
      if (emailValidator(search.value) === true) {
        emails.value.push(search.value)
        search.value = ''
      } else {
        Vue.notify({
          title: 'Tidak valid',
          text: 'Email yang diinputkan tidak valid',
          type: 'warn',
        })
      }
    }

    const onKeyDown = data => {
      if (data.keyCode === 13 && search.value) {
        addEmail()
      }
    }

    const roles = [
      {
        text: 'ADMIN',
        value: 1,
      },
      {
        text: 'MEMBER',
        value: 2,
      },
      {
        text: 'GUEST',
        value: 3,
      },
    ]

    return {
      isOpen,
      emails,
      onKeyDown,
      search,
      addEmail,
      loading,
      submit,
      show,
      close,

      icons: {
        mdiClose,
        mdiPlusCircle,
      },

      selectedRole,
      roles,
    }
  },
}
</script>

<style scoped>
.v-autocomplete__content {
  display: none !important;
}
</style>
