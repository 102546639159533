/**
 * @module viewer/viewerediting
 */

import { Plugin } from 'ckeditor5/src/core'
import ViewerCommand from './viewercommand'

export default class ViewerEditing extends Plugin {
  static get pluginName() {
    return 'ViewerEditing'
  }

  init() {
    const { editor } = this

    editor.commands.add('viewer', new ViewerCommand(editor, 'viewer'))
  }
}
