<template>
  <div
    v-if="isAuthenticated"
    class="vertical-nav-menu-container"
    @mouseenter="isMouseHovered = true"
    @mouseleave="isMouseHovered = false"
  >
    <slot name="v-nav-menu-header">
      <XyzTransition
        appear
        xyz="fade up-3 delay-2"
      >
        <vertical-nav-menu-header @close-nav-menu="$emit('close-nav-menu')" />
      </XyzTransition>
    </slot>

    <!-- Shadow -->
    <div
      :class="{ 'd-block': shallShadowBottom }"
      class="shadow-bottom"
    />

    <perfect-scrollbar
      class="ps-nav-menu-items"
      :options="perfectScrollbarOptions"
      @ps-scroll-y="
        evt => {
          shallShadowBottom = evt.srcElement.scrollTop > 10
        }
      "
    >
      <v-list
        expand
        shaped
        class="vertical-nav-menu-items pt-1"
      >
        <v-menu
          v-if="!loadingMenu"
          v-model="isOpen"
          offset-y
        >
          <template #activator="{ on }">
            <v-slide-x-transition>
              <xyz-transition
                appear
                xyz="fade down-3 delay-2"
              >
                <v-btn
                  :class="!(menuIsVerticalNavMini && !isHovered) ? 'ml-2 ps-3' : 'ml-n1'"
                  class="mb-3 pr-0 mt-1 workspace-logo"
                  :min-height="44"
                  plain
                  block
                  v-on="on"
                  @click="isOpen = !isOpen"
                >
                  <div class="d-flex w-full align-center">
                    <v-avatar size="32">
                      <v-img
                        :src="
                          selectedWorkspace.workspace.photo
                            ? selectedWorkspace.workspace.photo
                            : 'https://dummyimage.com/200x200/EDF2FE/6185F6.png?text=Oriens'
                        "
                      />
                    </v-avatar>

                    <v-slide-x-transition>
                      <div
                        v-show="!(menuIsVerticalNavMini && !isHovered)"
                        class="ml-3 w-full"
                      >
                        <div class="d-flex justify-space-between align-center">
                          <div class="d-flex flex-column align-start">
                            <span class="text-caption workspace-label text--disabled">
                              Workspace
                            </span>
                            <span
                              class="d-inline-block text-truncate mb-0 text-subtitle-2"
                              style="max-width: 130px"
                            >
                              {{ selectedWorkspace.workspace.name }}
                            </span>
                          </div>

                          <v-icon class="ml-2 me-1">
                            {{ !isOpen ? icons.mdiChevronDown : icons.mdiChevronUp }}
                          </v-icon>
                        </div>
                      </div>
                    </v-slide-x-transition>
                  </div>
                </v-btn>
              </xyz-transition>
            </v-slide-x-transition>
          </template>

          <v-card
            max-height="80vh"
            width="375px"
            class="workspace-list pa-4 d-flex flex-column"
          >
            <span
              v-if="workspaces.length"
              class="text-caption mb-2"
            >
              Milik saya
            </span>

            <v-list
              nav
              class="pa-0"
            >
              <workspace-card
                v-for="workspace in workspaces"
                :key="workspace.workspace.id"
                :data="workspace"
                :outlined="workspace.workspace.id === selectedWorkspace.workspace.id"
                @change="changeWorkspace(workspace)"
                @detail="$refs.workspaceDetail.show(workspace.workspace.id)"
              />
            </v-list>

            <span
              v-if="othersWorkspaces.length"
              class="text-caption my-2"
            >
              Dibagikan
            </span>

            <v-list
              nav
              class="pa-0"
            >
              <workspace-card
                v-for="workspace in othersWorkspaces"
                :key="workspace.workspace.id"
                :data="workspace"
                @change="changeWorkspace(workspace)"
                @detail="$refs.workspaceDetail.show(workspace.workspace.id)"
              />
            </v-list>

            <v-btn
              text
              outlined
              small
              class="mt-3"
              @click="showWorkspaceForm = true"
            >
              Buat Workspace Baru
            </v-btn>
            <span class="text-caption primary--text cursor-pointer text-center mt-3">
              Apa itu workspace?
            </span>
          </v-card>
        </v-menu>

        <workspace ref="workspaceDetail" />

        <template v-if="!loadingMenu">
          <component
            :is="resolveNavItemComponent(item)"
            v-for="item in navMenuItems"
            :key="item.subheader || item.title"
            ref="menuItems"
            :item="item"
            @onChangeFolder="onChangeFolder"
            @onChangeMenuHover="onChangeMenuHover"
          />
        </template>
        <div v-else>
          <v-skeleton-loader
            v-for="i in 12"
            :key="i"
            height="60"
            type="list-item-two-line"
          />
        </div>
      </v-list>
    </perfect-scrollbar>

    <v-dialog
      v-model="showWorkspaceForm"
      max-width="800"
    >
      <workspace-form
        from-dashboard
        @success="handleSuccessWorkspaceAdd"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ref, provide, computed } from 'vue'
import { mdiChevronDown, mdiChevronUp, mdiCog } from '@mdi/js'
// SFCs
import VerticalNavMenuHeader from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-header/VerticalNavMenuHeader.vue'
import VerticalNavMenuSectionTitle from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-section-title/VerticalNavMenuSectionTitle.vue'
import VerticalNavMenuGroup from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import VerticalNavMenuLink from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuFolderWidget from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-folder-widget/VerticalNavMenuFolderWidget.vue'
import VerticalNavMenuFolderSectionTitle from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-folder-section-title/VerticalNavMenuFolderSectionTitle.vue'
import VerticalNavMenuNotification from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-notification/VerticalNavMenuNotification.vue'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import Workspace from '@/views/workspace/Workspace.vue'
import WorkspaceCard from '@/views/workspace/WorkspaceCard.vue'
import WorkspaceForm from '@/views/workspace/WorkspaceForm.vue'

// Composable
import useVerticalNavMenu from '@/@core/layouts/composable/vertical-nav/useVerticalNavMenu'
import useAppConfig from '@/@core/@app-config/useAppConfig'
import store from '@/store'
import router from '@/router'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuGroup,
    VerticalNavMenuLink,
    VerticalNavMenuSectionTitle,
    VerticalNavMenuFolderWidget,
    VerticalNavMenuFolderSectionTitle,
    VerticalNavMenuNotification,
    Workspace,
    WorkspaceCard,
    WorkspaceForm,

    // 3rd Party
    PerfectScrollbar,
  },
  props: {
    navMenuItems: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isOpen = ref(false)
    const { menuIsVerticalNavMini } = useAppConfig()
    const { resolveNavItemComponent, isMouseHovered, isMenuHovered, isHovered } =
      useVerticalNavMenu()
    provide('isHovered', isHovered)
    const onChangeMenuHover = value => {
      isMenuHovered.value = value
      emit('onChangeMenuHover', value)
    }

    const userData = computed(() => store.state.auth.userData)
    const isAuthenticated = computed(() => store.state.auth.isAuthenticated)
    const selectedWorkspace = computed(() => store.state.workspace.selectedWorkspace)
    const workspaces = computed(() =>
      store.state.workspace.workspaceList.filter(el => el.user.id === userData.value.id),
    )
    const othersWorkspaces = computed(() =>
      store.state.workspace.workspaceList.filter(el => el.user.id !== userData.value.id),
    )

    const perfectScrollbarOptions = {
      wheelPropagation: false,
    }

    const menuItems = ref(null)

    const onChangeFolder = () => {
      menuItems.value.forEach(el => {
        // eslint-disable-next-line no-underscore-dangle
        if (el.$options._componentTag === 'vertical-nav-menu-folder-widget') {
          el.getFolder()
        }
      })
    }

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('openGroups', ref([]))

    // wait 1 second before render menu to get current workspace param on useNav()
    const loadingMenu = ref(true)
    setTimeout(() => {
      loadingMenu.value = false
    }, 1000)

    const changeWorkspace = workspace => {
      const workspaceIdentifier = workspace.workspace.identifier_id

      // only proceed if not clicken on same current workspace
      if (router.currentRoute.params.workspace !== workspaceIdentifier) {
        loadingMenu.value = true
        setTimeout(() => {
          store.dispatch('setSelectedWorkspace', workspace)
          router.push({
            name: router.currentRoute.name,
            params: {
              workspace: workspaceIdentifier,
            },
          })
          loadingMenu.value = false
        }, 300)
      }

      isOpen.value = false
    }

    const showWorkspaceForm = ref(false)
    const handleSuccessWorkspaceAdd = event => {
      showWorkspaceForm.value = false
      const workspace = workspaces.value.find(el => el.workspace.identifier_id === event)

      if (workspace) {
        changeWorkspace(workspace)
      }
    }

    return {
      menuIsVerticalNavMini,
      resolveNavItemComponent,
      perfectScrollbarOptions,
      isMouseHovered,
      shallShadowBottom,
      isAuthenticated,
      workspaces,
      othersWorkspaces,
      selectedWorkspace,
      isOpen,
      icons: {
        mdiChevronDown,
        mdiChevronUp,
        mdiCog,
      },
      loadingMenu,
      changeWorkspace,
      showWorkspaceForm,
      handleSuccessWorkspaceAdd,
      menuItems,
      onChangeFolder,
      isMenuHovered,
      onChangeMenuHover,
      isHovered,
    }
  },
}
</script>

<style lang="scss">
@import '~vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
</style>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';
@import '~@core/preset/preset/mixins.scss';

.workspace-logo {
  transition: all 0.18s ease-in-out;
}

.app-navigation-menu {
  transition-duration: 0.4s !important;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1), ease !important;
  will-change: box-shadow, transform !important;

  .v-list-item,
  .v-subheader {
    padding: 0 18px 0 22px;
  }

  .v-list-item {
    margin-top: 0.375rem;
  }

  .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
    min-width: 24px;
  }

  .v-navigation-drawer__border {
    background-color: transparent !important;
  }

  // &.v-navigation-drawer--open-on-hover.v-navigation-drawer--is-mouseover {
  //   @include elevation(9);
  //   @include elevationTransition();
  // }
}

@include app-elevation(
  'app-navigation-menu.v-navigation-drawer--open-on-hover.v-navigation-drawer--is-mouseover',
  9
);

// Set Perfect Scrollbar Container Height
.vertical-nav-menu-container {
  height: 100%;
  .ps-nav-menu-items {
    height: calc(100% - 60px) !important;
  }

  // menu scroll shadow
  .shadow-bottom {
    display: none;
    position: absolute;
    z-index: 2;
    height: 60px;
    width: 100%;
    left: -6px;
    pointer-events: none;
    margin-top: -0.7rem;
    filter: blur(5px);
  }
}

@include theme--child(vertical-nav-menu-container) using ($material) {
  .shadow-bottom {
    background: linear-gradient(
      rgba(map-deep-get($material, 'cards'), 1) 30%,
      map-deep-get($material, 'background') 40%,
      rgba(map-deep-get($material, 'background'), 0.05)
    );
    margin-top: -0.5rem;
    filter: blur(4px);
  }
}

@include theme(vertical-nav-menu-items) using ($material) {
  @include ltr() {
    padding-right: 20px !important;
  }
  @include rtl() {
    padding-left: 20px !important;
  }
  @at-root .app-navigation-menu.v-navigation-drawer--mini-variant:not(
        .v-navigation-drawer--is-mouseover
      ) {
    .vertical-nav-menu-items {
      @include ltr() {
        padding-right: 5px !important;
      }
      @include rtl() {
        padding-left: 5px !important;
      }
    }
  }

  .v-subheader:not(:first-child) {
    margin-top: 18px;
  }

  .v-list-item__icon {
    &:first-child {
      @include ltr() {
        margin: 0 12px 0 0;
      }
      @include rtl() {
        margin: 0 0 0 12px;
      }
      align-self: center;
    }
    &.v-list-group__header__append-icon {
      @include ltr() {
        margin-left: 4px;
      }
      @include rtl() {
        margin-right: 4px;
      }
    }
  }

  // Center align small icon
  .v-list-group__items .v-list-item__icon {
    margin-top: 0px;
    @include ltr() {
      margin-left: 5px;
    }
    @include rtl() {
      margin-right: 5px;
    }
  }

  .v-list-item {
    @include app-states($material);

    height: 44px;
    min-height: 44px;
    color: map-deep-get($material, 'text', 'primary');
    // ? Reason: In collapsed mode due to modified mini width & flex grow icon change position while collapsing the drawer
    justify-content: flex-start !important;

    &.v-list-item--active {
      &:not(.v-list-group__header) {
        color: white;
        // background-image: linear-gradient(98deg, #c48eff, var(--v-primary-base) 94%);
      }
      &.v-list-group__header {
        background-color: rgba(
          map-deep-get($material, 'text', 'primary'),
          map-deep-get($material, 'states', 'selected')
        );
      }
    }

    &.vertical-nav-menu-link {
      .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

.workspace-label {
  line-height: 0.9rem !important;
}

.workspace-list {
  overflow-y: auto;

  @include style-scroll-bar();
}
</style>
