import gql from 'graphql-tag'

const customAttributeOptions = gql`
  query customAttributeOptions(
    $custom_attribute_id: [Float!]!
    $order: String
    $workspace_id: Float!
    $job_type_id: Float
  ) {
    customAttributeOptions(
      custom_attribute_id: $custom_attribute_id
      order: $order
      workspace_id: $workspace_id
      job_type_id: $job_type_id
    ) {
      id
      name
    }
  }
`

export default customAttributeOptions
