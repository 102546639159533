import { getField, setField } from 'vuex-use-fields'
import useTeam from '@/composables/useTeam'

export default {
  namespaced: true,
  state: {
    teams: [],
    loadingTeam: false,
    teamFilter: [],
    teamSort: [],
    teamSearch: '',
    teamPagination: {
      page: 1,
      itemsPerPage: 20,
    },
    requestInviteCount: 0,

    loadingChannels: false,
    activeChannel: null,
    threadListOffset: 0,
    currentTeam: null,
    channelList: [],
    archivedChannel: [],
    archivedThreadsList: [],
    pinnedThreadsList: [],
    closeMenuSearch: true,
    customSettingsQuill: {
      modules: {
        imageResize: {},
        toolbar: [
          [{ font: [] }, { header: [false, 1, 2, 3, 4, 5, 6] }],
          ['bold', 'italic', 'underline'],
          [{ color: [] }, { background: [] }],
          [{ script: 'super' }, { script: 'sub' }],
          ['blockquote', 'code-block'],
          [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['image', 'link'],
        ],
      },
      theme: 'snow',
    },
    channelFilter: {
      isArchived: false,
      channelOffset: 0,
      sort: {
        label: 'Nama Channel A-Z',
        field: 'name',
        type: 'ASC',
      },
    },
  },
  getters: {
    getField,
  },
  mutations: {
    setField,
  },
  actions: {
    resetSort({ state }) {
      state.teamSort = []
    },
    resetFilter({ state }) {
      state.teamFilter = []
    },
    async fetchTeams({ state }) {
      const { fetchTeams } = useTeam()

      state.loadingTeam = true

      try {
        const data = await fetchTeams({
          search: state.teamSearch,
          sort: state.teamSort,
          filter: state.teamFilter,
          pagination: {
            limit: 100,
            offset: 0,
          },
        })

        state.teams = data.teams
      } catch (error) {
        //
      } finally {
        state.loadingTeam = false
      }
    },
  },
}
