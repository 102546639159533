import gql from 'graphql-tag'

export const unarchiveDocument = gql`
  mutation unarchiveDocument($documentUuid: String!, $workspaceId: Float!) {
    unarchiveDocument(document_uuid: $documentUuid, workspace_id: $workspaceId) {
      status
      msg
      data
    }
  }
`
