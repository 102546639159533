import gql from 'graphql-tag'

export const assignComment = gql`
  mutation assignComment($comment_id: Float!, $workspace_id: Float!, $id_user: Float) {
    assignComment(comment_id: $comment_id, workspace_id: $workspace_id, id_user: $id_user) {
      status
      msg
      data
    }
  }
`
