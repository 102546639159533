import gql from 'graphql-tag'

const chatList = gql`
  query ($pagination: pagination!, $search: String, $room_id: Float!, $workspace_id: Float!) {
    chatList(
      pagination: $pagination
      search: $search
      room_id: $room_id
      workspace_id: $workspace_id
    ) {
      count
      chatList {
        id
        message_id
        content
        type
        sender {
          id
          name
        }
        reply_to
        file_id
        file_url
        caption
        additional_data
        file_name
        file_size
        read
        assign_user {
          id
          phone
          email
          name
          first_name
          last_name
          photo
        }
        created_at
        updated_at
      }
    }
  }
`

export default chatList
