import gql from 'graphql-tag'

const updateWorkspaceToken = gql`
  mutation updateWorkspaceToken(
    $id: Float!
    $connection_name: String!
    $access_token: String
    $refresh_token: String
    $workspace_id: Float!
  ) {
    updateWorkspaceToken(
      id: $id
      connection_name: $connection_name
      access_token: $access_token
      refresh_token: $refresh_token
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`

export default updateWorkspaceToken
