<template>
  <div>
    <v-menu
      offset-y
      left
      nudge-bottom="14"
      min-width="230"
      content-class="user-profile-menu-content"
    >
      <template #activator="{ on, attrs }">
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text ms-4"
          v-on="on"
        >
          <v-img
            v-if="user && user.photo !== null"
            :src="user.photo"
          />
          <v-icon
            v-else
            size="20"
            color="primary"
          >
            {{ icons.mdiAccount }}
          </v-icon>
        </v-avatar>
      </template>
      <v-list>
        <div
          v-if="user"
          class="pb-3 pt-2 d-flex flex-column"
        >
          <div class="d-flex align-center mb-3">
            <v-avatar
              offset-x="12"
              offset-y="12"
              size="40px"
              color="primary"
              class="ms-3 v-avatar-light-bg primary--text"
            >
              <v-img
                v-if="user.photo !== null"
                :src="user.photo"
              />
              <span v-else>
                {{ avatarText(`${user.first_name} ${user.last_name}`) }}
              </span>
            </v-avatar>
            <div
              class="d-flex flex-column justify-center ms-2"
              style="vertical-align: middle"
            >
              <span class="text-subtitle-2 text--primary font-weight-semibold mb-1"
                >{{ user.first_name }} {{ user.last_name }}</span
              >
              <v-chip
                x-small
                color="primary"
                class="align-self-start"
              >
                <span class="text-caption">ADMIN</span>
              </v-chip>
            </div>
          </div>
          <small class="text-capitalize ml-3">{{ user.email }}</small>
        </div>

        <v-divider class="my-2" />

        <!-- Logout -->
        <v-list-item @click="$store.dispatch('logout')">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiLogoutVariant }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { computed } from 'vue'
import { mdiLogoutVariant, mdiAccount } from '@mdi/js'
import { avatarText } from '@core/utils/filter'
import store from '@/store'

export default {
  components: {},
  setup() {
    const user = computed(() => store.state.auth.userData)
    const role = computed(() => store.state.workspace.selectedWorkspace.role)

    return {
      user,
      role,

      avatarText,

      icons: {
        mdiLogoutVariant,
        mdiAccount,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
