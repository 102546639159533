import { Ability } from '@casl/ability'
//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
export default new Ability([
  {
    action: 'read',
    subject: 'Navbar',
  },
])
