import Vue from 'vue'
import { ref } from 'vue'
import { apolloClient } from '@/vue-apollo'
import { chatBotCommandList, chatBotCommandType } from '@/graphql/queries'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import { deleteChatBotCommand } from '@/graphql/mutations'

const useChatCommand = () => {
  const workspaceId = store.getters.getCurrentWorkspaceId
  const loadingCommand = ref(false)
  const commandList = ref([])

  const getCommandList = async (filterWorkingHours = false) => {
    loadingCommand.value = true

    await apolloClient
      .query({
        query: chatBotCommandList,
        variables: {
          workspace_id: workspaceId,
          platform_id: 1,
        },
        fetchPolicy: 'no-cache',
      })
      .then(result => {
        loadingCommand.value = false
        commandList.value = result.data.chatBotCommandList

        if (filterWorkingHours) {
          commandList.value = commandList.value.filter(el => el.type.id !== 3)
        }
      })
      .catch(err => {
        loadingCommand.value = false
        errorHandling(err)
      })
  }

  const deleteCommand = id =>
    new Promise((resolve, reject) => {
      Vue.$dialog({
        title: 'Hapus command?',
        body: 'Konfirmasi jika anda ingin menghapus command',
      }).then(confirm => {
        if (confirm) {
          apolloClient
            .mutate({
              mutation: deleteChatBotCommand,
              variables: {
                id,
                workspace_id: workspaceId,
                platform_id: 1,
              },
            })
            .then(result => {
              Vue.notify({
                title: 'Sukses',
                text: 'Berhasil menghapus command!',
              })
              resolve(result)
            })
            .catch(err => {
              reject()
              errorHandling(err, 'Hapus Command')
            })
        } else {
          reject()
        }
      })
    })

  const commandTypeList = ref([])
  const loadingCommandType = ref(false)

  const getCommandTypeList = () => {
    loadingCommandType.value = true
    apolloClient
      .query({
        query: chatBotCommandType,
        fetchPolicy: 'no-cache',
      })
      .then(result => {
        loadingCommandType.value = false
        commandTypeList.value = result.data.chatBotCommandType.filter(el => el.id !== 3)
      })
      .catch(err => {
        loadingCommandType.value = false
        errorHandling(err)
      })
  }

  return {
    loadingCommand,
    commandList,

    getCommandList,
    deleteCommand,

    commandTypeList,
    loadingCommandType,

    getCommandTypeList,
  }
}

export default useChatCommand
