import gql from 'graphql-tag'

export const unarchiveJobType = gql`
  mutation unarchiveJobType($job_type_id: Float!, $workspace_id: Float!) {
    unarchiveJobType(job_type_id: $job_type_id, workspace_id: $workspace_id) {
      status
      msg
      data
    }
  }
`
