import gql from 'graphql-tag'

export const addSavedView = gql`
  mutation addSavedView(
    $name: String!
    $fields: [JSON!]!
    $default_mode: String!
    $is_default: Boolean!
    $is_public: Boolean!
    $is_view_locked: Boolean!
    $is_autosave: Boolean!
    $workspace_id: Float!
    $object_type_id: Float!
    $job_type_id: Float!
    $sort_state: [JSON!]
    $filter_state: JSON
    $group_state: JSON
  ) {
    addSavedView(
      name: $name
      fields: $fields
      default_mode: $default_mode
      is_default: $is_default
      is_public: $is_public
      is_view_locked: $is_view_locked
      is_autosave: $is_autosave
      workspace_id: $workspace_id
      object_type_id: $object_type_id
      job_type_id: $job_type_id
      sort_state: $sort_state
      filter_state: $filter_state
      group_state: $group_state
    ) {
      status
      msg
      data
    }
  }
`
