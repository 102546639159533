import { getField, setField } from 'vuex-use-fields'

export default {
  namespaced: true,
  state: {
    // TODO: refactor this filterActive and sortActive
    filterActive: false,
    filter: [
      {
        key: 'name',
        operator: 'IS',
        value: [''],
      },
    ],
    sortActive: false,
    sort: [],
    search: '',
    treeSearch: '',
    documentOptions: [
      {
        field: 'filter_search_children',
        label: 'Cari/Filter Sub-Dokumen',
        value: false,
      },
    ],
  },
  getters: {
    getField,
  },
  mutations: {
    setField,
  },
  actions: {
    resetSort(state) {
      state.state.sort = []
    },
    toggleSort(state, toggleState) {
      state.state.sortActive = toggleState
    },
    resetFilter(state) {
      state.state.filter = [
        {
          key: 'name',
          operator: 'IS',
          value: [''],
        },
      ]
    },
    toggleFilter(state, toggleState) {
      state.state.filterActive = toggleState
    },
  },
}
