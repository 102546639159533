import gql from 'graphql-tag'

const queryDeleteCustomAttributeOption = gql`
  query queryDeleteCustomAttributeOption(
    $delete_id: [Float!]!
    $object_type_id: Float!
    $workspace_id: Float!
    $job_type_id: Float
  ) {
    queryDeleteCustomAttributeOption(
      delete_id: $delete_id
      object_type_id: $object_type_id
      workspace_id: $workspace_id
      job_type_id: $job_type_id
    ) {
      count
      id
      name
    }
  }
`

export default queryDeleteCustomAttributeOption
