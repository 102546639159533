import gql from 'graphql-tag'

const deleteCustomAttributeOption = gql`
  mutation deleteCustomAttributeOption($id: [Float!]!) {
    deleteCustomAttributeOption(id: $id) {
      msg
      status
      data
    }
  }
`

export default deleteCustomAttributeOption
