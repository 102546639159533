import gql from 'graphql-tag'

const objectTriggerActionsV2 = gql`
  query objectTriggerActionsV2($object_id: Float!) {
    objectTriggerActionsV2(object_id: $object_id) {
      id
      name
      name_view
      description
      object {
        id
      }
      category {
        id
      }
      trigger_category {
        id
      }
      component_name
    }
  }
`

export default objectTriggerActionsV2
