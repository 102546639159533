import gql from 'graphql-tag'

const deleteChannel = gql`
  mutation ($channel_id: Float!) {
    deleteChannel(channel_id: $channel_id) {
      status
      msg
      data
    }
  }
`

export default deleteChannel
