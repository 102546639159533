import gql from 'graphql-tag'

export const batchRenameAttachments = gql`
  mutation batchRenameAttachments(
    $renameParam: [paramRenameAttach!]
    $job_type_id: Float!
    $workspace_id: Float!
  ) {
    batchRenameAttachments(
      renameParam: $renameParam
      job_type_id: $job_type_id
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`
