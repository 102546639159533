import gql from 'graphql-tag'

const tokopediaGetOrders = gql`
  query tokopediaGetOrders(
    $page: Float!
    $fromDate: String!
    $toDate: String!
    $per_page: Float!
    $connection_name: String!
    $workspace_id: Float!
  ) {
    tokopediaGetOrders(
      page: $page
      fromDate: $fromDate
      toDate: $toDate
      per_page: $per_page
      connection_name: $connection_name
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`

export default tokopediaGetOrders
