import gql from 'graphql-tag'

export const updateJobStatusUrutan = gql`
  mutation updateJobStatusUrutan(
    $params: [paramUpdateUrutan!]!
    $workspace_id: Float!
    $job_type_id: Float!
  ) {
    updateJobStatusUrutan(params: $params, workspace_id: $workspace_id, job_type_id: $job_type_id) {
      status
      msg
      data
    }
  }
`
