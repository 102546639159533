<template>
  <ckeditor
    v-model="editorData"
    :editor="editor"
    :config="editorConfig"
    :disabled="readOnly"
    @ready="onEditorReady"
  />
</template>

<script>
import { ref } from 'vue'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import { Alignment } from '@ckeditor/ckeditor5-alignment'
import { Autoformat } from '@ckeditor/ckeditor5-autoformat'
import {
  Bold,
  Code,
  Italic,
  Strikethrough,
  Subscript,
  Superscript,
  Underline,
} from '@ckeditor/ckeditor5-basic-styles'
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote'
import { InlineEditor } from '@ckeditor/ckeditor5-editor-inline'
import { Essentials } from '@ckeditor/ckeditor5-essentials'
import { Font } from '@ckeditor/ckeditor5-font'
import { Heading } from '@ckeditor/ckeditor5-heading'
import { Highlight } from '@ckeditor/ckeditor5-highlight'
import { HorizontalLine } from '@ckeditor/ckeditor5-horizontal-line'
import {
  AutoImage,
  Image,
  ImageInsert,
  ImageToolbar,
  ImageResizeEditing,
  ImageResizeHandles,
  ImageResizeButtons,
  ImageStyle,
} from '@ckeditor/ckeditor5-image'
import { Indent } from '@ckeditor/ckeditor5-indent'
import { Link } from '@ckeditor/ckeditor5-link'
import { List, TodoList } from '@ckeditor/ckeditor5-list'
import { MediaEmbed } from '@ckeditor/ckeditor5-media-embed'
import { PageBreak } from '@ckeditor/ckeditor5-page-break'
import { Paragraph } from '@ckeditor/ckeditor5-paragraph'
import { PasteFromOffice } from '@ckeditor/ckeditor5-paste-from-office'
import { RemoveFormat } from '@ckeditor/ckeditor5-remove-format'
import { StandardEditingMode } from '@ckeditor/ckeditor5-restricted-editing'
import {
  SpecialCharacters,
  SpecialCharactersEssentials,
} from '@ckeditor/ckeditor5-special-characters'
import { Table } from '@ckeditor/ckeditor5-table'
import { WordCount } from '@ckeditor/ckeditor5-word-count'

export default {
  name: 'CkeditorField',
  components: {
    ckeditor: CKEditor.component,
  },
  props: {
    content: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    ready: {
      type: Function,
      default: () => {},
    },
    configs: {
      type: Object,
      default: () => ({}),
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    const editorInstance = ref()

    const onEditorReady = editor => {
      editorInstance.value = editor

      props.ready(editor)
    }
    const focusEditor = () => {
      editorInstance.value.focus()
    }

    return {
      focusEditor,
      onEditorReady,
    }
  },
  data() {
    return {
      editor: InlineEditor,
      editorConfig: {
        placeholder: this.placeholder,
        plugins: [
          Essentials,
          Bold,
          Italic,
          Link,
          Paragraph,
          Alignment,
          Autoformat,
          BlockQuote,
          Font,
          Heading,
          Highlight,
          HorizontalLine,
          Image,
          Indent,
          List,
          PageBreak,
          PasteFromOffice,
          RemoveFormat,
          StandardEditingMode,
          SpecialCharacters,
          SpecialCharactersEssentials,
          Table,
          WordCount,
          Strikethrough,
          Underline,
          Subscript,
          Superscript,
          Code,
          TodoList,
          AutoImage,
          MediaEmbed,
          ImageInsert,
          ImageResizeEditing,
          ImageResizeHandles,
          ImageToolbar,
          ImageResizeButtons,
          ImageStyle,
          ...this.plugins,
        ],
        toolbar: {
          items: [
            {
              label: 'Fonts',
              icon: 'text',
              items: ['fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor'],
            },
            'highlight',
            'bold',
            'italic',
            'underline',
            {
              label: 'Special Format',
              icon: 'paragraph',
              items: ['strikethrough', 'subscript', 'superscript', 'code', 'blockQuote'],
            },
            '|',
            'heading',
            '|',
            'alignment',
            {
              label: 'Lists',
              icon: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 570 570">
                     <path d="M64 144a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM192 64c-17.7 0-32 14.3-32 
                     32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 
                     14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 
                     0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM64 
                     464a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm48-208a48 48 0 1 0 -96 0 48 48 0 1 0 96 0z"/>
                     </svg>`,
              items: ['bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'],
            },
            '|',
            'undo',
            'redo',
            'link',
            'insertTable',
            'mediaEmbed',
            '|',
            'removeFormat',
            'specialCharacters',
            'horizontalLine',
            '|',
            'uppyUpload',
          ],
        },
        image: {
          styles: {
            options: [
              {
                name: 'alignLeft',
                title: 'Left aligned image',
              },
              {
                name: 'alignRight',
                title: 'Right aligned image',
              },
              {
                name: 'alignCenter',
                title: 'Centered image',
              },
              {
                name: 'alignBlockLeft',
                title: 'Left aligned image',
              },
              {
                name: 'alignBlockRight',
                title: 'Right aligned image',
              },
            ],
          },
          resizeOptions: [
            {
              name: 'resizeImage:original',
              value: null,
              icon: 'original',
            },
            {
              name: 'resizeImage:25',
              value: '25',
              icon: 'small',
            },
            {
              name: 'resizeImage:50',
              value: '50',
              icon: 'medium',
            },
            {
              name: 'resizeImage:75',
              value: '75',
              icon: 'large',
            },
          ],
          toolbar: [
            {
              name: 'imageStyle:icons',
              title: 'Wrap Text',
              items: ['imageStyle:alignLeft', 'imageStyle:alignRight'],
              defaultItem: 'imageStyle:alignLeft',
            },
            {
              name: 'imageStyle:pictures',
              title: 'Break Text',
              items: [
                'imageStyle:alignBlockLeft',
                'imageStyle:alignCenter',
                'imageStyle:alignBlockRight',
              ],
              defaultItem: 'imageStyle:alignCenter',
            },
            'resizeImage',
            '|',
            'viewer',
          ],
        },
        ...this.configs,
      },
    }
  },
  computed: {
    editorData: {
      get() {
        return this.content
      },
      set(value) {
        this.$emit('update:content', value)
      },
    },
  },
}
</script>

<style lang="scss">
@import '~@/styles/ckeditor-lark.scss';

.ck-read-only {
  background-color: map-deep-get($material-light, 'cards') !important;
  color: map-deep-get($material-light, 'text', 'primary') !important;
  border: none !important;

  .dark-layout & {
    background-color: map-deep-get($material-dark, 'cards') !important;
    color: map-deep-get($material-dark, 'text', 'primary') !important;
  }
}

.ck-editor__editable_inline {
  max-height: 400px;
}
</style>
