<template>
  <div>
    <v-menu
      bottom
      right
      offset-y
      :nudge-bottom="8"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          small
          class="ml-2"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon size="28"> {{ icons.mdiPlus }} </v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list nav>
          <v-list-item
            v-for="(item, i) in quickAddItems"
            :key="i"
            class="d-flex pl-4 pr-6"
            @click="item.action"
          >
            <v-icon class="mr-4"> {{ item.icon }} </v-icon>
            <span class="text-subtitle-2"> {{ item.title }} </span>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <JobForm ref="jobForm" />
  </div>
</template>

<script>
import { ref } from 'vue'
import { mdiPlus, mdiTicketAccount } from '@mdi/js'
import JobForm from '@/views/job/components/JobForm.vue'

export default {
  components: {
    JobForm,
  },
  setup() {
    const jobForm = ref()

    const quickAddItems = [
      {
        title: 'Job',
        icon: mdiTicketAccount,
        action: () => {
          jobForm.value.show()
        },
      },
    ]

    return {
      jobForm,
      quickAddItems,

      icons: {
        mdiPlus,
      },
    }
  },
}
</script>

<style></style>
