import gql from 'graphql-tag'

const createChatBotCommand = gql`
  mutation createChatBotCommand(
    $name: String!
    $command: String
    $description: String!
    $typeId: Float!
    $respond: String!
    $workspace_token_id: Float!
    $platform_id: Float!
    $workspace_id: Float!
  ) {
    createChatBotCommand(
      name: $name
      command: $command
      description: $description
      typeId: $typeId
      respond: $respond
      workspace_token_id: $workspace_token_id
      platform_id: $platform_id
      workspace_id: $workspace_id
    ) {
      status
      data
      msg
    }
  }
`

export default createChatBotCommand
