import gql from 'graphql-tag'

const jobPriority = gql`
  query jobPriority($job_type_id: Float!, $order: String, $workspace_id: Float!) {
    jobPriority(job_type_id: $job_type_id, order: $order, workspace_id: $workspace_id) {
      id
      name
      color
      urutan
    }
  }
`

export default jobPriority
