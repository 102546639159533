<template>
  <div
    v-if="subscriptionData"
    class="me-4"
  >
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-chip
          label
          v-bind="attrs"
          v-on="on"
        >
          <div class="d-flex flex-column text-caption font-weight-bold">
            <span>{{ subscriptionData.name }}</span>
            <span v-if="subscriptionEndsAt">Sampai {{ formatDate(subscriptionEndsAt) }}</span>
          </div>
        </v-chip>
      </template>
      <span>Perpanjang Otomatis: {{ isAutoSubscribe ? 'Aktif' : 'Nonaktif' }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import store from '@/store'
import { onMounted, ref } from 'vue'
import { formatDate } from '@/@core/utils/filter'

export default {
  setup() {
    const isAutoSubscribe = ref(null)
    const subscriptionData = ref({})
    const subscriptionEndsAt = ref(null)

    const fetchProfileData = () => {
      /* eslint-disable camelcase */
      const { subscription, is_auto_subscribe, subscription_ends_at } = store.getters.getUserData

      isAutoSubscribe.value = is_auto_subscribe
      subscriptionData.value = subscription
      subscriptionEndsAt.value = subscription_ends_at
      /* eslint-enable camelcase */
    }

    onMounted(() => {
      fetchProfileData()
    })

    return {
      isAutoSubscribe,
      subscriptionData,
      subscriptionEndsAt,
      formatDate,
      fetchProfileData,
    }
  },
}
</script>
