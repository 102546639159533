<template>
  <v-dialog
    v-model="isOpen"
    max-width="720"
  >
    <v-card>
      <div class="d-flex justify-space-between text-h5 mb-4 pa-4">
        <div>Downgrade Plan</div>
        <v-icon @click="close()">
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-card-text>
        <div
          v-if="item"
          class="d-flex justify-center align-center flex-column text-h6"
        >
          <div>
            Plan anda saat ini adalah <strong>{{ userData.subscription.name }}</strong>
          </div>
          <div class="mt-1">Berlaku hingga {{ formatDate(userData.subscription_ends_at) }}</div>
          <div class="mt-3">
            Anda akan menurunkan plan tersebut menjadi <strong>{{ item.name }}</strong>
          </div>
          <div class="text-caption font-weight-bold">
            *Anda akan tetap mendapatkan benefit plan sebelumnya sampai masa berlaku plan tersebut
            habis*
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="mt-2">
        <v-spacer />
        <v-btn
          color="primary"
          large
          :loading="loadingDowngradeUser"
          :disabled="loadingDowngradeUser"
          @click="downgradePlan(item.id)"
        >
          Turunkan Plan
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { computed, ref } from 'vue'
import { formatDate } from '@core/utils/filter'
import { mdiClose } from '@mdi/js'
import store from '@/store'
import useUser from '@/composables/useUser'

export default {
  setup() {
    const isOpen = ref(false)
    const item = ref(null)
    const show = param => {
      isOpen.value = true
      item.value = param
    }
    const close = () => {
      isOpen.value = false
    }

    const { downgradeUserPlan, loadingDowngradeUser } = useUser()

    const downgradePlan = subId => {
      downgradeUserPlan(subId).then(() => {
        close()
      })
    }
    const userData = computed(() => store.state.auth.userData)

    return {
      isOpen,
      show,
      close,
      item,
      icons: {
        mdiClose,
      },
      userData,
      formatDate,
      downgradeUserPlan,
      loadingDowngradeUser,
      downgradePlan,
    }
  },
}
</script>
<style scoped>
.plan-duration-card-active {
  border: 2px solid var(--v-primary-base) !important;
  color: var(--v-primary-base) !important;
}
</style>
