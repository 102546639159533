import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import preset from '@/@core/preset/preset'
import * as directives from 'vuetify/es5/directives'

Vue.use(Vuetify, {
  directives,
})

export default new Vuetify({
  preset,
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
  },
})
