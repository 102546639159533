import gql from 'graphql-tag'

const referenceLogs = gql`
  query referenceLogs(
    $category_id: [Int!]
    $created_by: [Int!]
    $job_id: Float!
    $pagination: pagination!
    $custom_attribute_id: [Int!]
  ) {
    referenceLogs(
      pagination: $pagination
      category_id: $category_id
      created_by: $created_by
      job_id: $job_id
      custom_attribute_id: $custom_attribute_id
    ) {
      count
      referenceLogs {
        id
        category_id
        created_at
        custom_attribute {
          id
          name
          data_type {
            id
          }
        }
        created_by {
          id
          name
          photo
        }
        from
        to
      }
    }
  }
`

export default referenceLogs
