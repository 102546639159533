import gql from 'graphql-tag'

const assignTask = gql`
  mutation assignTask($reference_id: Float!, $user_id: [Int!], $team_id: [Int!]) {
    assignTask(reference_id: $reference_id, user_id: $user_id, team_id: $team_id) {
      status
      msg
      data
    }
  }
`
export default assignTask
