import gql from 'graphql-tag'

export const teamRequestInvite = gql`
  query teamRequestInvite($workspace_id: Float!, $team_id: Float) {
    teamRequestInvite(workspace_id: $workspace_id, team_id: $team_id) {
      team {
        id
        name
      }
      user {
        id
        name
        first_name
        last_name
        email
        phone
        photo
      }
      created_at
    }
  }
`
