import gql from 'graphql-tag'

const deleteCustomerTypes = gql`
  mutation ($id: Float!, $workspace_id: Float!) {
    deleteCustomerTypes(id: $id, workspace_id: $workspace_id) {
      status
      data
      msg
    }
  }
`

export default deleteCustomerTypes
