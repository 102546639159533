<template>
  <div class="pa-3">
    <div class="d-flex flex-wrap flex-column flex-md-row justify-space-between">
      <h3>Integrasi Google</h3>
      <v-btn
        color="#04AB0F"
        small
        class="mt-4 mt-md-0"
        :loading="loadingGoogle"
        @click="getUserConsent"
      >
        <span class="text-white">Tambah Integrasi Google</span>
      </v-btn>
    </div>
    <v-card
      class="mt-6"
      outlined
    >
      <v-alert
        v-if="!tokenDetail.length && !loadingDetail"
        border="left"
        color="warning"
        dense
        text
        class="mb-0 text-subtitle-2 py-2 rounded-b-0"
      >
        <span class="text-caption"
          >Google belum terhubung ke Oriens CRM. Ikuti instruksi di bawah untuk menghubungkan.</span
        >
      </v-alert>
      <div
        v-else-if="loadingDetail"
        class="d-flex justify-center"
      >
        <v-progress-circular
          indeterminate
          color="success"
          class="my-4"
        />
      </div>
      <div v-else>
        <GoogleConnectionCard
          v-for="token in tokenDetail"
          :key="token.id"
          :connection-data="token"
          @onDeleteSuccess="getTokenDetail(7)"
        />
      </div>
      <v-divider />
      <div class="pa-3 d-flex flex-column">
        <h5>Apa itu Google API?</h5>
        <span class="text-subtitle-2"
          >Google API adalah layanan API yang dirancang google agar developer bisa menghubungkan
          aplikasi mereka dengan semua layanan milik Google. Layanan API google yang tersedia di
          Oriens antara lain adalah Gmail, Google Sheets, Google Drive, dan Google Calendar.</span
        >
      </div>
      <v-divider />
      <div class="pa-3 d-flex flex-column">
        <h5>Cara Integrasi Google</h5>
        <span class="text-subtitle-2">1. Klik tombol Tambah Integrasi Google.</span>
        <span class="text-subtitle-2">2. Login ke Akun Google Anda.</span>
        <span class="text-subtitle-2">3. Pastikan anda men-centang semua akses yang diminta.</span>
        <span class="text-subtitle-2">4. Berikan akses akun Google anda.</span>
        <span class="text-subtitle-2"
          >5. Selamat! Akun Google anda sudah terintegrasi dengan Oriens.</span
        >
      </div>
    </v-card>
  </div>
</template>

<script>
import { onMounted } from 'vue'
import useIntegration from '@/composables/useIntegration'
import useGoogle from '@/composables/useGoogle'
import GoogleConnectionCard from './component/GoogleConnectionCard.vue'
import axios from 'axios'

export default {
  components: {
    GoogleConnectionCard,
  },
  setup() {
    const { codeClient, loadingGoogle, googleScope, googleClientId, handleCredentialResponse } =
      useGoogle()
    const {
      // eslint-disable-next-line no-unused-vars
      tokenDetail,
      getTokenDetail,
      loadingDetail,
      addWorkspaceTokenFunc,
    } = useIntegration()
    const { google } = window
    const initGoogle = () => {
      google.accounts.id.initialize({
        client_id: googleClientId,
        callback: handleCredentialResponse,
      })
      codeClient.value = google.accounts.oauth2.initCodeClient({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        scope: googleScope,
        callback: response => {
          console.log(response)
          if (response && response.code) {
            const authUrl =
              process.env.VUE_APP_GOOGLE_AUTH_URL || 'http://localhost:3500/auth/google'
            axios
              .post(
                authUrl,
                { code: response.code },
                {
                  headers: {
                    'X-Requested-With': 'XmlHttpRequest',
                  },
                },
              )
              .then(({ data }) => {
                const param = data.data
                console.log(param)
                addWorkspaceTokenFunc(
                  {
                    platform_id: 7,
                    connection_name: param.email,
                    access_token: param.access_token,
                    refresh_token: param.refresh_token,
                  },
                  () => {
                    loadingGoogle.value = false
                    getTokenDetail(7)
                  },
                  () => {
                    loadingGoogle.value = false
                  },
                )
              })
          }
        },
        error_callback: () => {
          loadingGoogle.value = false
        },
      })
    }

    // eslint-disable-next-line no-unused-vars
    onMounted(() => {
      getTokenDetail(7)
      initGoogle()
    })

    const getUserConsent = () => {
      loadingGoogle.value = true
      console.log('tes')
      codeClient.value.requestCode()
    }

    return {
      tokenDetail,
      getTokenDetail,
      loadingDetail,
      getUserConsent,
      loadingGoogle,
    }
  },
}
</script>

<style></style>
