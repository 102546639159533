import gql from 'graphql-tag'

export const editCustomAttributeValue = gql`
  mutation editCustomAttributeValue(
    $object_id: Float!
    $type_id: Float!
    $values: [customAttributeValues!]!
    $workspace_id: Float!
  ) {
    editCustomAttributeValue(
      objectId: $object_id
      typeId: $type_id
      values: $values
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`
