import gql from 'graphql-tag'

const chatBotCommandList = gql`
  query chatBotCommandList($platform_id: Float!, $workspace_id: Float!) {
    chatBotCommandList(platform_id: $platform_id, workspace_id: $workspace_id) {
      id
      name
      command
      description
      type {
        id
        name
      }
      respond
      token {
        id
        connection_name
      }
      platform {
        id
        name
        logo
      }
    }
  }
`

export default chatBotCommandList
