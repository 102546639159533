import gql from 'graphql-tag'

const changeAutoSubscribe = gql`
  mutation changeAutoSubscribe($value: Boolean!) {
    changeAutoSubscribe(value: $value) {
      status
      msg
      data
    }
  }
`

export default changeAutoSubscribe
