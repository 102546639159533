import gql from 'graphql-tag'

const updateTask = gql`
  mutation updateTask(
    $assign_user_id: [Int!]
    $assign_team_id: [Int!]
    $subject: String!
    $description: String!
    $start_date: DateTime
    $end_date: DateTime
    $job_id: Float
    $customer_id: Float
    $visibility: Float!
    $visibility_5_users: [Int!]
    $tag_id: [Int!]
    $task_id: Float!
    $workspace_id: Float!
  ) {
    updateTask(
      assign_user_id: $assign_user_id
      assign_team_id: $assign_team_id
      subject: $subject
      description: $description
      start_date: $start_date
      end_date: $end_date
      job_id: $job_id
      customer_id: $customer_id
      visibility: $visibility
      visibility_5_users: $visibility_5_users
      tag_id: $tag_id
      task_id: $id
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`
export default updateTask
