import gql from 'graphql-tag'

export const moveDocument = gql`
  mutation moveDocument($workspaceId: Float!, $folderId: Float, $documentUuids: [String!]!) {
    moveDocument(workspace_id: $workspaceId, folder_id: $folderId, document_uuids: $documentUuids) {
      status
      msg
      data
    }
  }
`
