import gql from 'graphql-tag'

export const countUnreadNotificationThreeGroups = gql`
  query CountUnreadNotificationThreeGroups($workspaceId: Float!) {
    countUnreadNotificationThreeGroups(workspace_id: $workspaceId) {
      countUnreadNormalNotification
      countUnreadSnoozedNotification
      countUnreadArchivedNotification
    }
  }
`
