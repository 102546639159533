import gql from 'graphql-tag'

const updateChatBotCommand = gql`
  mutation updateChatBotCommand(
    $id: Float!
    $name: String
    $command: String
    $description: String
    $typeId: Float
    $respond: String
    $platform_id: Float!
    $workspace_id: Float!
  ) {
    updateChatBotCommand(
      id: $id
      name: $name
      command: $command
      description: $description
      typeId: $typeId
      respond: $respond
      platform_id: $platform_id
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`

export default updateChatBotCommand
