import { joinWorkspace } from '@/graphql/mutations'
import invitationPage from '@/graphql/query/invitationPage'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import { apolloClient } from '@/vue-apollo'
import { ref } from 'vue'

const useWorkspace = () => {
  const loadingInvitationPage = ref(false)
  const loadingJoinWorkspace = ref(false)

  const workspaceData = ref(null)

  const WorkspacePermissionGuard = (data, role) => {
    if (data.workspace.owner.id === store.getters.getUserData.id) return true
    if (data.role.id <= role) return true

    return false
  }

  const getInvitationPage = inviteCode => {
    loadingInvitationPage.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: invitationPage,
          variables: {
            invite_code: inviteCode,
          },
        })
        .then(({ data }) => {
          loadingInvitationPage.value = false
          resolve(data.invitationPage)
          workspaceData.value = data.invitationPage
        })
        .catch(err => {
          loadingInvitationPage.value = false
          reject(err)
          errorHandling(err)
        })
    })
  }

  const joinToWorkspace = inviteCode => {
    loadingJoinWorkspace.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: joinWorkspace,
          variables: {
            invite_code: inviteCode,
          },
        })
        .then(({ data }) => {
          loadingJoinWorkspace.value = false
          resolve(data.joinWorkspace)
        })
        .catch(err => {
          loadingJoinWorkspace.value = false
          errorHandling(err)
          reject(err)
        })
    })
  }

  return {
    WorkspacePermissionGuard,

    getInvitationPage,
    loadingInvitationPage,
    workspaceData,

    joinToWorkspace,
    loadingJoinWorkspace,
  }
}

export default useWorkspace
