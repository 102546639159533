import gql from 'graphql-tag'

const userStatuses = gql`
  query {
    userStatuses {
      id
      name
    }
  }
`

export default userStatuses
