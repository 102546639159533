const colors = [
  {
    label: 'Biru',
    hex: '#2196F3',
  },
  {
    label: 'Merah',
    hex: '#F44336',
  },
  {
    label: 'Kuning',
    hex: '#FFB400',
  },
  {
    label: 'Hijau',
    hex: '#4CAF50',
  },
  {
    label: 'Abu Abu',
    hex: '#8A8D93',
  },
  {
    label: 'Biru Muda',
    hex: '#2EB9FF',
  },
  {
    label: 'Ungu',
    hex: '#AF62F8',
  },
  {
    label: 'Merah Muda',
    hex: '#FB85C1',
  },
  {
    label: 'Oranye',
    hex: '#F69035',
  },
  {
    label: 'Cokelat',
    hex: '#8B4513',
  },
]

const randomizeColor = () => colors[Math.floor(Math.random() * colors.length)]

export { colors, randomizeColor }
