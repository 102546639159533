/**
 * @module uppy-upload/uploadediting
 */

import { Plugin } from 'ckeditor5/src/core'
import UploadCommand from './uploadcommand'

export default class UploadEditing extends Plugin {
  static get pluginName() {
    return 'UploadEditing'
  }

  init() {
    const { editor } = this

    editor.commands.add('uppyUpload', new UploadCommand(editor, 'uppyUpload'))
  }
}
