import gql from 'graphql-tag'

const users = gql`
  query users($workspace_id: Float!, $document_uuid: String) {
    users(workspace_id: $workspace_id, document_uuid: $document_uuid) {
      id
      phone
      email
      first_name
      last_name
      name
      photo
      is_guest
      user_status {
        id
        name
      }
      availability
      last_seen_at
      created_at
      updated_at
      object_permission
      is_object_owner
      document_permissions {
        permission_type
        document {
          uuid
        }
      }
    }
  }
`

export default users
