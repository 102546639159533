<template>
  <v-dialog
    v-model="isOpen"
    max-width="800"
    scrollable
    :retain-focus="false"
    @input="!$event ? reset() : null"
  >
    <v-card min-height="400">
      <div class="d-flex justify-space-between pa-4 align-center">
        <v-row class="align-center">
          <v-col cols="2">
            <span class="text-h6 font-medium primary--text">
              {{ updateMode ? 'Ubah Job' : 'Buat Job' }}
            </span>
          </v-col>
          <v-col cols="5">
            <treeselect
              v-if="!loadingDetail && !loadingFolder"
              v-model="jobTypeId"
              :options="folderOptions"
              :multiple="false"
              :disable-branch-nodes="true"
              :normalizer="normalizer"
              :clearable="false"
              :required="true"
              placeholder="Pilih job type"
              :disabled="updateMode"
            />
          </v-col>
        </v-row>

        <v-btn
          icon
          @click="close()"
        >
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </div>

      <v-card-text class="job-form-content pt-3">
        <v-form
          v-if="!loadingDetail"
          ref="jobForm"
          lazy-validation
          @submit.prevent="submitForm"
        >
          <v-row class="mb-3">
            <v-col
              cols="12"
              md="6"
              class="py-1"
            >
              <span class="text-caption text--disabled"> Nama (wajib diisi) </span>
              <v-text-field
                v-model="formData.name"
                :rules="[required]"
                single-line
                placeholder="Masukkan nama"
                outlined
                dense
                hide-details="auto"
              />
            </v-col>

            <v-col
              cols="12"
              md="6"
              class="py-1"
            >
              <span class="text-caption text--disabled"> Tanggal Mulai - Tanggal Akhir </span>
              <date-range-picker
                :value="{
                  startDate: formData.startDate,
                  endDate: formData.expectedCloseDate,
                }"
                :placeholder="'Masukkan tanggal'"
                :rules="[required]"
                @update="setDateRange($event)"
                @select="
                  !formData.expectedCloseDate && !formData.startDate ? setDateRange($event) : null
                "
                @input="setDateRange($event)"
              />
            </v-col>

            <v-col
              cols="12"
              class="py-1"
            >
              <span class="text-caption text--disabled"> Deskripsi </span>
              <JobDescriptionEditor
                ref="jobDescField"
                :content.sync="formData.description"
                :job-id="formData.id"
                :job-type-id="formData.jobTypeId"
              />
            </v-col>

            <v-col
              cols="12"
              md="6"
              class="py-1"
            >
              <span class="text-caption text--disabled"> Prioritas </span>
              <v-select
                v-model="formData.jobPriorityId"
                :items="jobTypePriorities"
                placeholder="Pilih prioritas"
                outlined
                clearable
                item-text="name"
                item-value="id"
                dense
                hide-details="auto"
                :disabled="!updateMode && !jobTypeId"
              >
                <template #item="{ item }">
                  <v-chip
                    small
                    :color="item.color"
                  >
                    {{ item.name }}
                  </v-chip>
                </template>
              </v-select>
            </v-col>

            <v-col
              cols="12"
              md="6"
              class="py-1"
            >
              <span class="text-caption text--disabled"> Status </span>
              <v-select
                v-model="formData.jobStatusId"
                :items="jobTypeStatuses"
                placeholder="Pilih status"
                outlined
                item-text="name"
                item-value="id"
                dense
                hide-details="auto"
                :rules="[required]"
                :disabled="!jobTypeId"
              >
                <template #item="{ item }">
                  <v-chip
                    small
                    :color="item.color"
                  >
                    {{ item.name }}
                  </v-chip>
                </template>
              </v-select>
            </v-col>

            <v-col
              cols="12"
              class="py-1"
            >
              <span class="text-caption text--disabled"> Ditugaskan ke </span>
              <user-team-autocomplete
                v-if="!loadingDetail && jobTypeId && formData"
                v-model="formData.assignTo"
                object-type="job-type"
                :object-id="jobTypeId"
              />
            </v-col>

            <v-col
              v-if="customAttributesArray?.length"
              cols="12"
              class="pb-1 mt-1"
            >
              <v-card
                outlined
                class="d-flex flex-column"
              >
                <v-card-text class="pa-3 pb-2">
                  <span class="text-subtitle-2 font-medium">Atribut Lainnya</span>
                  <v-row class="mb-0 mt-2">
                    <v-col
                      v-for="(attribute, index) in customAttributesArray"
                      :key="attribute.custom_attribute.id"
                      cols="6"
                      class="py-0"
                    >
                      <job-info
                        v-if="attribute.custom_attribute.data_type.id !== 7 || updateMode"
                        :title="attribute.custom_attribute.name"
                        class="custom-attribute-detail mb-2"
                      >
                        <template>
                          <v-text-field
                            v-if="
                              (attribute.custom_attribute.data_type.id === 1 ||
                                attribute.custom_attribute.data_type.id === 2) &&
                              attribute.custom_attribute.formula === null
                            "
                            v-model="customAttributesArray[index].value"
                            :type="
                              attribute.custom_attribute.data_type.id === 1 ? 'text' : 'number'
                            "
                            label="-"
                            :placeholder="'Input ' + attribute.custom_attribute.name + ' job'"
                            single-line
                            dense
                            hide-details="auto"
                          />

                          <DateInput
                            v-if="
                              attribute.custom_attribute.data_type.id === 3 &&
                              attribute.custom_attribute.formula === null
                            "
                            block
                            :value="customAttributesArray[index].value"
                            @input="customAttributesArray[index].value = $event"
                          >
                            <span class="subtitle-2 font-weight-regular">
                              {{
                                customAttributesArray[index].value
                                  ? dateFormat(customAttributesArray[index].value, 3)
                                  : '-'
                              }}
                            </span>
                          </DateInput>

                          <v-autocomplete
                            v-if="attribute.custom_attribute.data_type.id === 4"
                            v-model="customAttributesArray[index].value"
                            :items="attribute.custom_attribute.options"
                            label="-"
                            :placeholder="'Pilih ' + attribute.custom_attribute.name + ' job'"
                            single-line
                            dense
                            clearable
                            hide-details="auto"
                            item-text="name"
                            item-value="id"
                          />

                          <v-autocomplete
                            v-if="attribute.custom_attribute.data_type.id === 5"
                            v-model="customAttributesArray[index].value"
                            :items="attribute.custom_attribute.options"
                            label="-"
                            :placeholder="'Pilih ' + attribute.custom_attribute.name + ' job'"
                            single-line
                            dense
                            clearable
                            hide-details="auto"
                            multiple
                            small-chips
                            item-text="name"
                            item-value="id"
                          >
                            <template #item="{ item }">
                              <v-chip small> {{ item.name }} </v-chip>
                            </template>
                          </v-autocomplete>

                          <v-checkbox
                            v-if="
                              attribute.custom_attribute.data_type.id === 6 &&
                              attribute.custom_attribute.formula === null
                            "
                            v-model="customAttributesArray[index].value"
                            dense
                            hide-details="auto"
                            true-value="true"
                            false-value="false"
                          />

                          <attachment-select
                            v-if="attribute.custom_attribute.data_type.id === 7"
                            :attachment-list="formData.attachment"
                            :selected-ids="customAttributesArray[index].value"
                            :job-type-id="formData.jobTypeId"
                            :job-id="formData.id"
                            :name="formData.name"
                            :attribute-id="attribute.custom_attribute.id"
                          />

                          <job-formula-preview
                            v-if="attribute.custom_attribute.formula !== null"
                            :custom-attributes="customAttributesArray"
                            :custom-attribute="attribute.custom_attribute"
                            :static-attributes="formData"
                            :status-options="jobTypeStatuses"
                            :job-detail="jobDetailData"
                            :priority-options="jobTypePriorities"
                          />
                        </template>
                      </job-info>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <div class="d-flex flex-column flex-md-row">
            <v-btn
              class="mb-2 mb-md-0 ml-md-auto"
              :style="
                !updateMode
                  ? { 'border-top-right-radius': '0%', 'border-bottom-right-radius': '0%' }
                  : null
              "
              color="primary"
              :loading="loadingSubmit"
              @click="submitForm()"
            >
              Simpan Job
            </v-btn>

            <v-menu
              v-if="!updateMode"
              v-model="isShortcutMenuOpen"
              offset-y
              top
              transition="slide-y-reverse-transition"
            >
              <template #activator="menu">
                <v-tooltip bottom>
                  <template #activator="tooltip">
                    <v-btn
                      color="primary"
                      outlined
                      class="px-0"
                      style="min-width: 40px"
                      :style="
                        !updateMode
                          ? { 'border-top-left-radius': '0%', 'border-bottom-left-radius': '0%' }
                          : null
                      "
                      v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                      v-on="{ ...menu.on, ...tooltip.on }"
                    >
                      <v-icon
                        class="light--text"
                        size="20"
                      >
                        {{ mdiMenuDown }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Opsi Simpan</span>
                </v-tooltip>
              </template>
              <v-list
                dense
                class="subtitle-2"
              >
                <v-list-item @click="submitForm('detail')">
                  Buat dan Buka
                  <span class="caption ml-auto ps-3">(Alt + Enter)</span>
                </v-list-item>
                <v-list-item @click="submitForm('open')">
                  Buat dan Mulai Lagi
                  <span class="caption ml-auto ps-3">(Ctrl + Enter)</span>
                </v-list-item>
                <v-list-item @click="submitForm('duplicate')">
                  Buat dan Duplikat
                  <span class="caption ml-auto ps-3">(Ctrl + Alt + Enter)</span>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-form>
      </v-card-text>
    </v-card>

    <v-overlay
      opacity="0.2"
      :value="loadingDetail"
    >
      <v-progress-circular
        indeterminate
        size="32"
        color="primary"
      />
    </v-overlay>
  </v-dialog>
</template>

<script setup>
import { required } from '@core/utils/validation'
import { mdiClose, mdiMenuDown } from '@mdi/js'
import { computed, ref, watch } from 'vue'
import JobDescriptionEditor from './JobDescriptionEditor.vue'
import Vue from 'vue'
import { onKeyStroke } from '@vueuse/core'
import JobFormulaPreview from '@/views/custom-attributes/formula/JobFormulaPreview.vue'
import DateRangePicker from '@/components/inputs/DateRangePicker.vue'
import useCustomAttributes from '@/composables/useCustomAttributes'
import UserTeamAutocomplete from './UserTeamAutocomplete.vue'
import errorHandling from '@/utils/errorHandling'
import Treeselect from '@riophae/vue-treeselect'
import AttachmentSelect from '@/components/misc/AttachmentSelect.vue'
import JobInfo from './JobDetailInfo.vue'
import { createFieldMapper } from 'vuex-use-fields'
import { getJobDetail, performAddJob, performUpdateJob } from '@/services/jobService'
import { performAssignObject } from '@/services/generalService'
import router from '@/router'
import useJobType from '@/composables/useJobType'
import DateInput from '@/components/inputs/editable/DateInput.vue'
import dateFormat from '@/utils/dateFormat'

const { folderList, loadingFolder } = {
  ...createFieldMapper({ getter: 'folder/getField', setter: 'folder/setField' })([
    'loadingFolder',
    'folderList',
  ]),
}
const { customAttributesArray, fetchCustomAttributes } = useCustomAttributes({
  objectTypeId: 2,
})
const { jobTypeStatuses, jobTypePriorities, fetchJobTypeDetail } = useJobType()

const emit = defineEmits(['success'])
const formData = ref({
  id: null,
  name: '',
  startDate: null,
  expectedCloseDate: null,
  description: '',
  jobTypeId: null,
  jobStatusId: null,
  jobPriorityId: null,
  assignTo: [],
})

const jobDescField = ref()
const jobDetailData = ref({})
const jobForm = ref()
const isOpen = ref(false)
const updateMode = ref(false)
const isShortcutMenuOpen = ref(null)

const status = ref()
const jobTypeId = ref()
const attributeOption = ref()
const loadingDetail = ref(false)
const loadingSubmit = ref(false)

const folderOptions = computed(() =>
  folderList.value.map(folder => ({
    ...folder,
    id: `f-${folder.id}`,
  })),
)

const normalizer = node => ({
  id: node.id,
  label: node.name,
  children: node.job_types,
})

const reset = () => {
  updateMode.value = false
  loadingSubmit.value = false
  loadingDetail.value = false
  attributeOption.value = null
  jobTypeId.value = null
  status.value = null

  formData.value = {
    id: null,
    name: '',
    description: '',
    startDate: null,
    jobTypeId: null,
    jobStatusId: null,
    jobPriorityId: null,
    expectedCloseDate: null,
    assignTo: [],
  }

  jobForm.value.reset()
  customAttributesArray.value = null
}
const restart = () => {
  updateMode.value = false
  loadingSubmit.value = false
  loadingDetail.value = false
  attributeOption.value = null
  status.value = null

  formData.value = {
    id: null,
    name: '',
    description: '',
    startDate: null,
    expectedCloseDate: null,
    jobTypeId: jobTypeId.value,
    jobStatusId: jobTypeStatuses.value[0].id,
    jobPriorityId: null,
    assignTo: [],
  }

  jobForm.value.resetValidation()
}
const close = () => {
  isOpen.value = false
  reset()
}

const show = (typeId, selectValue) => {
  if (typeId) jobTypeId.value = typeId
  if (selectValue) {
    if (selectValue.field === 'status') status.value = selectValue.id
    else if (selectValue.field === 'priority') formData.value.jobPriorityId = selectValue.id
    else attributeOption.value = selectValue
  }

  isOpen.value = true
}
const update = async id => {
  isOpen.value = true
  loadingDetail.value = true

  try {
    const detail = await getJobDetail({ jobId: id })
    jobDetailData.value = detail

    formData.value = {
      id,
      name: detail.name,
      startDate: detail.start_date,
      expectedCloseDate: detail.expected_close_date,
      description: detail.description,
      jobStatusId: detail.status.id,
      jobTypeId: detail.job_type.id,
      jobPriorityId: detail.priority?.id,
      assignTo: detail.assignedTo.map(el => el.user.id),
      attachment: detail.attachment,
    }

    fetchJobTypeDetail({ jobTypeId: detail.job_type.id })
    fetchCustomAttributes(detail.id, detail.job_type.id)

    updateMode.value = true
    jobTypeId.value = detail.job_type.id
  } catch (err) {
    errorHandling(err)
    close()
  } finally {
    loadingDetail.value = false
  }
}

const submitForm = async action => {
  if (jobForm.value.validate()) {
    loadingSubmit.value = true

    const { id, name, jobStatusId, jobPriorityId, assignTo, description } = formData.value
    const startDate = new Date(Date.parse(formData.value.startDate))
    const expectedCloseDate = new Date(Date.parse(formData.value.expectedCloseDate))

    const customAttribute = customAttributesArray.value.map(item => {
      let val

      const dataTypeId = item.custom_attribute.data_type.id
      const { value } = item

      switch (dataTypeId) {
        case 3:
          val = value ? new Date(value) : null
          break

        case 2:
        case 4:
          val = value?.toString() || null
          break

        case 5:
          val = value?.length ? JSON.stringify(value).replace(/ /g, '') : null
          break

        default:
          val = value || null
          break
      }

      return {
        id: item.custom_attribute.id,
        value: val,
      }
    })

    if (!updateMode.value) {
      try {
        const { data } = await performAddJob({
          name,
          description,
          jobTypeId: jobTypeId.value,
          jobStatusId,
          jobPriorityId,
          startDate,
          expectedCloseDate,
          customAttribute,
          assignUserId: assignTo,
        })

        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil membuat job!',
        })
        emit('success')

        if (action === 'detail') {
          router.push({ name: 'job-detail', params: { id: JSON.parse(data).id } })
        } else if (action === 'open') {
          restart()
        } else if (action !== 'duplicate') {
          close()
        }
      } catch (err) {
        errorHandling(err)
      } finally {
        loadingSubmit.value = false
      }

      return
    }

    try {
      await Promise.all([
        await performUpdateJob({
          id,
          name,
          description,
          jobTypeId: jobTypeId.value,
          jobStatusId,
          jobPriorityId,
          startDate,
          expectedCloseDate,
          customAttribute,
        }),
        await performAssignObject({
          jobId: id,
          assignUserId: assignTo,
        }),
      ])

      Vue.notify({
        title: 'Sukses!',
        text: 'Berhasil mengubah job!',
      })

      emit('success')
      close()
    } catch (err) {
      errorHandling(err)
    } finally {
      loadingSubmit.value = false
    }
  }
}

const setDateRange = event => {
  formData.value.startDate = event.startDate
  formData.value.expectedCloseDate = event.endDate
}

watch(jobTypeId, async () => {
  formData.value.jobTypeId = jobTypeId.value

  if (isOpen.value && !updateMode.value) {
    fetchJobTypeDetail({ jobTypeId: jobTypeId.value })
    await fetchCustomAttributes(null, jobTypeId.value)

    if (attributeOption.value) {
      customAttributesArray.value.forEach((item, index) => {
        if (item.custom_attribute.id === attributeOption.value.field) {
          customAttributesArray.value[index].value = attributeOption.value.id
        }
      })
    }
  }
})
onKeyStroke('Enter', e => {
  if (isOpen.value) {
    if (e.ctrlKey && e.altKey) submitForm('duplicate')
    else if (e.ctrlKey) submitForm('open')
    else if (e.altKey) submitForm('detail')
  }
})

defineExpose({
  show,
  update,
})
</script>

<style lang="scss">
@import '~@/styles/treeselect.scss';

.job-form-content {
  padding-right: 12px !important;
  overflow-x: hidden;
  overflow-y: scroll !important;
}

.custom-attribute-detail {
  .v-input {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  input {
    padding: 0 !important;
  }
}
</style>
