<template>
  <div>
    <v-alert
      v-if="
        parsedConnectionStatus &&
        !loadingConnectionStatus &&
        parsedConnectionStatus.status === 'Connected'
      "
      border="left"
      :color="parsedConnectionStatus.status === 'Connected' ? 'primary' : 'error'"
      dense
      text
      tile
      :type="parsedConnectionStatus.status === 'Connected' ? 'success' : 'error'"
      class="mb-0 text-subtitle-2 py-4"
    >
      <div class="d-flex flex-column flex-md-row align-md-center">
        <span
          v-if="parsedConnectionStatus.status === 'Connected'"
          class="text-caption"
          >Terhubung dengan {{ connection && connection.connection_name }}</span
        >
        <span
          v-else
          class="text-caption"
          >Koneksi {{ connection && connection.connection_name }} bermasalah</span
        >
        <div class="d-flex align-center mt-2 mt-md-0 ml-md-auto">
          <v-btn
            class="ml-md-auto mr-2"
            color="info"
            x-small
            outlined
            @click="checkConnection"
          >
            Cek Koneksi
          </v-btn>
          <v-btn
            color="error"
            x-small
            text
            @click="deleteGoogle"
          >
            Hapus
          </v-btn>
        </div>
      </div>
    </v-alert>
    <v-alert
      v-else-if="loadingConnectionStatus"
      border="left"
      dense
      text
      tile
      type="info"
      color="info"
      class="mb-0 text-subtitle-2 py-4"
    >
      <div class="d-flex flex-column flex-md-row align-md-center">
        <span class="text-caption"
          >Sedang mendapatkan status koneksi {{ connectionData.connection_name }}</span
        >
        <v-progress-circular
          indeterminate
          color="info"
          size="12"
          class="ml-4"
        />
      </div>
    </v-alert>
    <v-alert
      v-else
      border="left"
      color="warning"
      dense
      text
      type="warning"
      class="mb-0 text-subtitle-2 py-2 rounded-b-0"
    >
      <div class="d-flex flex-column flex-md-row align-md-center">
        <span class="text-caption"
          >Gagal mendapatkan status koneksi {{ connectionData.connection_name }}</span
        >
        <div class="d-flex align-center mt-2 mt-md-0 ml-md-auto">
          <v-btn
            class="ml-md-auto mr-2"
            color="info"
            x-small
            outlined
            @click="checkConnection"
          >
            Cek Koneksi
          </v-btn>
          <v-btn
            color="error"
            x-small
            text
            @click="deleteGoogle"
          >
            Hapus
          </v-btn>
        </div>
      </div>
    </v-alert>
  </div>
</template>
<script>
import { computed, inject, onMounted, ref, watch } from 'vue'
import useIntegration from '@/composables/useIntegration'
import Vue from 'vue'
import { apolloClient } from '@/vue-apollo'
import { deleteWorkspaceToken } from '@/graphql/mutations'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'

export default {
  props: {
    connectionData: {
      default: () => {},
      required: true,
      type: Object,
    },
  },
  setup(props, { emit }) {
    const connection = computed(() => props.connectionData)
    const { fetchConnectionChekcer, loadingConnectionStatus, connectionStatus } = useIntegration()
    const parsedConnectionStatus = ref(null)
    const loadingDeleteToken = inject('loadingDeleteToken')
    onMounted(() => {
      fetchConnectionChekcer(7, connection.value.connection_name)
    })

    watch(connectionStatus, () => {
      console.log(connectionStatus.value)
      parsedConnectionStatus.value = {
        ...connectionStatus.value,
        data: connectionStatus.value?.data && JSON.parse(connectionStatus.value.data),
      }
    })
    const deleteGoogle = () => {
      Vue.$dialog({
        title: 'Hapus token Google?',
        body: 'Konfirmasi jika anda ingin menghapus token Google.',
      }).then(confirm => {
        if (confirm) {
          loadingDeleteToken.value = true
          apolloClient
            .mutate({
              mutation: deleteWorkspaceToken,
              variables: {
                id: connection.value.id,
                workspace_id: store.getters.getCurrentWorkspaceId,
              },
            })
            .then(() => {
              loadingDeleteToken.value = false
              emit('onDeleteSuccess')
              Vue.notify({
                title: 'Sukses',
                text: 'Berhasil menghapus token Google!',
              })
            })
            .catch(err => {
              loadingDeleteToken.value = false
              errorHandling(err, 'Hapus Integrasi')
            })
        }
      })
    }

    const checkConnection = () => {
      fetchConnectionChekcer(7, connection.value.connection_name)
    }

    return {
      deleteGoogle,
      parsedConnectionStatus,
      fetchConnectionChekcer,
      loadingConnectionStatus,
      connectionStatus,
      checkConnection,
      connection,
    }
  },
}
</script>
