import { ref } from 'vue'
import {
  mdiAccountLock,
  mdiAccountTie,
  mdiAccountNetwork,
  mdiAccountChild,
  mdiAccountPlus,
  mdiAccountGroup,
} from '@mdi/js'
import { apolloClient } from '@/vue-apollo'
import { visibilities } from '@/graphql/queries'

const useVisibility = () => {
  const visibilityOptions = ref([])
  const loadingVisibility = ref(false)

  const resolveIconVisibility = id => {
    if (id === 1) return mdiAccountLock
    if (id === 2) return mdiAccountTie
    if (id === 3) return mdiAccountNetwork
    if (id === 4) return mdiAccountChild
    if (id === 5) return mdiAccountPlus
    if (id === 6) return mdiAccountGroup

    return null
  }

  const fetchVisibilities = async () => {
    loadingVisibility.value = true
    await apolloClient
      .query({
        query: visibilities,
      })
      .then(result => {
        loadingVisibility.value = false
        visibilityOptions.value = result.data.visibilities.map(el => ({
          ...el,
          icon: resolveIconVisibility(el.id),
        }))
      })
      .catch(() => {
        loadingVisibility.value = false
      })
  }

  return {
    visibilityOptions,
    loadingVisibility,

    fetchVisibilities,
    resolveIconVisibility,
  }
}

export default useVisibility
