import gql from 'graphql-tag'

const getMyAccurateUserData = gql`
  query getAccurateUserData($workspace_id: Float!) {
    getAccurateUserData(workspace_id: $workspace_id) {
      id
      connection_name
    }
  }
`

export default getMyAccurateUserData
