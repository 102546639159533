/**
 * @module uppy-upload/uploadcommand
 */
import { Command } from 'ckeditor5/src/core'

export default class UploadCommand extends Command {
  constructor(editor, attributeKey) {
    super(editor)
    this.attributeKey = attributeKey
    this.opened = false
    this.value = false
    this.isEnabled = true
  }

  execute() {
    this.opened = !this.opened
    this.value = this.opened

    return this.opened
  }

  getIsOpened() {
    console.log(this.opened)

    return this.opened
  }
}
