import gql from 'graphql-tag'

const deleteRelationType = gql`
  mutation deleteRelationType(
    $relation_type_id: Float!
    $is_delete: Boolean!
    $workspace_id: Float!
  ) {
    deleteRelationType(
      is_delete: $is_delete
      relation_type_id: $relation_type_id
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`

export default deleteRelationType
