import gql from 'graphql-tag'

const addPost = gql`
  mutation ($threadId: Float!, $html_comment: String!) {
    addPost(threadId: $threadId, html_comment: $html_comment) {
      status
      msg
      data
    }
  }
`

export default addPost
