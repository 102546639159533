<template>
  <div class="pa-3">
    <div class="d-flex flex-wrap flex-column flex-md-row justify-space-between">
      <h3>Integrasi Telegram</h3>
      <v-btn
        color="#0088cc"
        small
        class="mt-4 mt-md-0"
        @click="$refs.telegramForm.show()"
      >
        <span class="text-white">Tambah Integrasi Telegram</span>
      </v-btn>
    </div>
    <v-card
      class="mt-6"
      outlined
    >
      <v-alert
        v-if="!tokenDetail.length && !loadingDetail"
        border="left"
        color="warning"
        dense
        text
        class="mb-0 text-subtitle-2 py-2 rounded-b-0"
      >
        <span class="text-caption"
          >Telegram belum terhubung ke Oriens CRM. Ikuti instruksi di bawah untuk
          menghubungkan.</span
        >
      </v-alert>
      <div
        v-else-if="loadingDetail"
        class="d-flex justify-center"
      >
        <v-progress-circular
          indeterminate
          color="info"
          class="my-4"
        />
      </div>
      <v-divider />
      <div class="pa-3 d-flex flex-column">
        <h5>Apa itu Telegram?</h5>
        <span class="text-subtitle-2"
          >Telegram adalah sebuah aplikasi layanan pengirim pesan instan multiplatform berbasis
          cloud yang bersifat gratis dan nirlaba.</span
        >
      </div>
      <v-divider />
      <div class="pa-3 d-flex flex-column">
        <h5>Cara Integrasi Accurate</h5>
        <span class="text-subtitle-2"
          >1. Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, adipisci.</span
        >
        <span class="text-subtitle-2"
          >2. Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, adipisci.</span
        >
        <span class="text-subtitle-2"
          >3. Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, adipisci.</span
        >
        <span class="text-subtitle-2"
          >4. Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, adipisci.</span
        >
      </div>
    </v-card>
    <telegram-form
      ref="telegramForm"
      @success="getTokenDetail(1)"
    />
  </div>
</template>

<script>
import { onMounted } from 'vue'
import TelegramForm from './TelegramForm.vue'
import useIntegration from '@/composables/useIntegration'

export default {
  components: {
    TelegramForm,
  },
  setup() {
    const { getTokenDetail, loadingDetail, tokenDetail } = useIntegration()
    onMounted(() => {
      getTokenDetail(1)
    })

    return {
      loadingDetail,
      tokenDetail,
      getTokenDetail,
    }
  },
}
</script>

<style></style>
