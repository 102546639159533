<template>
  <div class="pa-3">
    <div class="d-flex flex-wrap flex-column flex-md-row justify-space-between">
      <h3>Integrasi Accurate</h3>
      <v-btn
        color="#E24230"
        small
        class="mt-4 mt-md-0"
        :loading="loadingAccurateAuthLink"
        @click="openAccurateForm"
      >
        <span class="text-white">Tambah Integrasi Accurate</span>
      </v-btn>
    </div>
    <v-card
      class="mt-6"
      outlined
    >
      <v-alert
        v-if="!tokenDetail.length"
        border="left"
        color="warning"
        dense
        text
        class="mb-0 text-subtitle-2 py-2 rounded-b-0"
      >
        <span class="text-caption"
          >Accurate belum terhubung ke Oriens CRM. Ikuti instruksi di bawah untuk
          menghubungkan.</span
        >
      </v-alert>
      <div v-else>
        <v-alert
          v-for="token in tokenDetail"
          :key="token.id"
          border="left"
          color="primary"
          dense
          text
          tile
          type="success"
          class="mb-0 text-subtitle-2 py-4"
        >
          <div class="d-flex flex-column flex-md-row align-md-center">
            <span class="text-caption">Terhubung dengan {{ token.connection_name }}</span>
            <v-btn
              class="ml-auto"
              color="error"
              x-small
              text
              @click="deleteAccurate(token.id)"
            >
              Hapus
            </v-btn>
          </div>
        </v-alert>
      </div>
      <v-divider />
      <div class="pa-3 d-flex flex-column">
        <h5>Apa itu Accurate?</h5>
        <span class="text-subtitle-2"
          >Accurate merupakan software yang di ciptakan bagi pencatatan dan pengelolaan keuangan
          pada setiap jenis perusahaan, baik perusahaan dagang, perusahaan jasa maupun pada
          perusahaan produksi.</span
        >
      </div>
      <v-divider />
      <div class="pa-3 d-flex flex-column">
        <h5>Cara Integrasi Accurate</h5>
        <span class="text-subtitle-2"
          >1. Pastikan anda sudah punya akun Accurate yang siap disambungkan.</span
        >
        <span class="text-subtitle-2">2. klik tombol "Tambah integrasi Accurate" di atas.</span>
        <span class="text-subtitle-2"
          >3. Anda akan diarahkan ke popup login, silahkan inputkan email dan password akun Accurate
          anda.</span
        >
        <span class="text-subtitle-2"
          >4. Selanjutnya, anda akan diarahkan ke halaman otorisasi, silahkan tekan tombol
          konfirmasi otorisasi.</span
        >
        <span class="text-subtitle-2">5. Selamat! Akun anda sudah terintegrasi dengan Oriens.</span>
      </div>
    </v-card>
  </div>
</template>

<script>
import { watch, onMounted, inject } from 'vue'
import { useWindowFocus } from '@vueuse/core'
import useIntegration from '@/composables/useIntegration'
import Vue from 'vue'
import { apolloClient } from '@/vue-apollo'
import { deleteAccurateToken } from '@/graphql/mutations'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'

export default {
  props: {
    platformId: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const windowVisibility = useWindowFocus()
    const {
      loadingAccurateAuthLink,
      accurateAuthLink,
      loadingDetail,
      tokenDetail,
      getTokenDetail,
    } = useIntegration()
    const loadingDeleteToken = inject('loadingDeleteToken')
    watch(windowVisibility, value => {
      console.log(value)
      if (value && loadingAccurateAuthLink.value) {
        emit('refetch')
        loadingAccurateAuthLink.value = false
      }
    })

    const openAccurateForm = () => {
      accurateAuthLink().then(link => {
        window
          .open(link, '_blank', 'location=yes,height=640,width=720,scrollbars=yes,status=yes')
          .focus()
      })
    }

    onMounted(() => {
      getTokenDetail(+props.platformId)
    })

    const deleteAccurate = tokenId => {
      Vue.$dialog({
        title: 'Hapus token Accurate?',
        body: 'Konfirmasi jika anda ingin menghapus token Accurate.',
      }).then(confirm => {
        if (confirm) {
          loadingDeleteToken.value = true
          apolloClient
            .mutate({
              mutation: deleteAccurateToken,
              variables: {
                token_id: tokenId,
                workspace_id: store.getters.getCurrentWorkspaceId,
              },
            })
            .then(() => {
              loadingDeleteToken.value = false
              getTokenDetail(+props.platformId)
              Vue.notify({
                title: 'Sukses',
                text: 'Berhasil menghapus token Accurate!',
              })
            })
            .catch(err => {
              loadingDeleteToken.value = false
              errorHandling(err, 'Hapus Integrasi')
            })
        }
      })
    }

    return {
      loadingAccurateAuthLink,
      loadingDetail,
      tokenDetail,

      getTokenDetail,
      deleteAccurate,
      openAccurateForm,
    }
  },
}
</script>

<style></style>
