import gql from 'graphql-tag'

export const updateDocumentPermission = gql`
  mutation UpdateDocumentPermission(
    $workspace_id: Float!
    $document_uuid: String!
    $is_public: Boolean!
    $user_permissions: [UserDocumentPermission!]!
  ) {
    updateDocumentPermission(
      workspace_id: $workspace_id
      document_uuid: $document_uuid
      is_public: $is_public
      user_permissions: $user_permissions
    ) {
      status
      msg
      data
    }
  }
`
