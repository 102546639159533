import gql from 'graphql-tag'

const assignObject = gql`
  mutation assignObject($assign_user_id: [Int!], $job_id: Float, $workspace_id: Float!) {
    assignObject(assign_user_id: $assign_user_id, job_id: $job_id, workspace_id: $workspace_id) {
      status
      msg
      data
    }
  }
`

export default assignObject
