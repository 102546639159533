import gql from 'graphql-tag'

const deleteWorkspace = gql`
  mutation deleteWorkspace($workspace_id: Float!) {
    deleteWorkspace(workspace_id: $workspace_id) {
      status
      msg
      data
    }
  }
`
export default deleteWorkspace
