import { mdiSortAlphabeticalAscending, mdiSortAlphabeticalDescending } from '@mdi/js'
import { getField, setField } from 'vuex-use-fields'

export default {
  namespaced: true,
  state: {
    loadingMember: false,
    memberList: [],
    memberCount: 0,
    sortCount: 0,
    filter: {
      search: '',
      roleSelect: [],
      statusSelect: [],
      sortSelect: {
        id: 0,
        text: 'Nama',
        value: 'name',
        sortType: [
          {
            type: 'ASC',
            icon: mdiSortAlphabeticalAscending,
          },
          {
            type: 'DESC',
            icon: mdiSortAlphabeticalDescending,
          },
        ],
      },
      isASC: 1,
    },
  },
  getters: {
    getField,
    getMemberList: state => state.memberList,
    getLoadingMember: state => state.loadingMember,
  },
  mutations: {
    setField,
  },
  actions: {
    resetFolderFilter(state) {
      state.state.filter = {
        search: '',
        role_id: '',
        user_status: {
          id: 1,
          text: 'All',
          value: null,
        },
        sort: {
          id: 0,
          text: 'Nama',
          value: 'name',
          sortType: [
            {
              type: 'ASC',
              icon: mdiSortAlphabeticalAscending,
            },
            {
              type: 'DESC',
              icon: mdiSortAlphabeticalDescending,
            },
          ],
        },
        isASC: 1,
      }
    },
  },
}
