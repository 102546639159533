import gql from 'graphql-tag'

export const addComment = gql`
  mutation addComment(
    $job_id: Float
    $attachment_id: String
    $document_uuid: String
    $comment: String!
    $workspace_id: Float!
    $reply_to_comment_id: Float
  ) {
    addComment(
      job_id: $job_id
      attachment_id: $attachment_id
      document_uuid: $document_uuid
      workspace_id: $workspace_id
      comment: $comment
      reply_to_comment_id: $reply_to_comment_id
    ) {
      status
      msg
      data
    }
  }
`
