import gql from 'graphql-tag'

export const addFolderPermission = gql`
  mutation addFolderPermission(
    $user_permissions: [UserFolderPermission!]!
    $folder_id: Float!
    $workspace_id: Float!
  ) {
    addFolderPermission(
      user_permissions: $user_permissions
      folder_id: $folder_id
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`
