var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"pa-2 horizontal-list-card detail-info-card mb-3",attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"py-0 px-0"},[_c('span',{staticClass:"text-caption text--disabled"},[_vm._v("Formula ")]),(!_vm.errorData.isError)?_c('div',[_c('span',{staticClass:"text-caption text--disabled"},[_vm._v("=")]),_c('span',{staticClass:"text-caption",staticStyle:{"white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(_vm.formulaValue)+" ")])]):_c('div',[_c('span',{staticClass:"text-caption",staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.errorData.msg))])]),_c('div',{class:`v-input v-input--hide-details v-input--is-label-active v-input--is-dirty v-input--dense theme--${
          _vm.isDark ? 'dark' : 'light'
        } v-text-field v-text-field--single-line v-text-field--is-booted v-text-field--placeholder`},[_c('div',{staticClass:"v-input__control"},[_c('div',{staticClass:"v-input__slot border-sm"},[_c('div',{staticClass:"v-text-field__slot",on:{"click":_vm.setEndCaret}},[_c('div',{ref:"elementInput",style:(`
                white-space: pre-wrap;
                overflow-wrap: anywhere !important;
                width: 100%;
                padding: 4px 0 2px;
                ${_vm.isDark ? 'color: rgba(231, 227, 252, 0.87)' : 'rgba(94, 86, 105, 0.87)'};
                outline: none;
                caret-color: #6285f6;
              `),attrs:{"contenteditable":"true"},on:{"keydown":_vm.processInput}})])])])]),_c('formula-list',{staticClass:"mt-3",attrs:{"selected-index":1,"search-string":_vm.searchString,"list-items":_vm.searchList,"seek-index":_vm.seekIndex},on:{"update-search":_vm.updateSelectedString,"handle-enter-list":_vm.handleEnterList,"update-seek-index":e => {
            _vm.seekIndex = e
          },"update-length-list":e => {
            _vm.lengthList = e.length
            _vm.fullValueList = e
          },"click-list":_vm.handleEnterList}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }