<template>
  <v-card-text class="d-flex flex-column h-full">
    <h4 class="mb-1">Utama</h4>
    <v-form
      ref="userForm"
      lazy-validation
      class="d-flex flex-column"
    >
      <v-row>
        <v-col
          cols="12"
          md="auto"
        >
          <span class="text-caption mb-1">Foto Profil</span>
          <v-card
            id="pick-avatar"
            width="110"
            height="110"
            outlined
            class="mt-1 d-flex align-center justify-center"
          >
            <!-- @click="$refs.profilePhotoPicker.click()" -->
            <v-img
              v-if="profileData.photoPreview"
              :src="profileData.photoPreview"
              height="110"
              width="110"
              class="rounded"
            />
            <!-- <v-icon
              v-else
              class="text--disabled"
              size="32"
            >
              {{ icons.mdiImage }}
            </v-icon> -->
            <v-avatar
              v-if="avatarText"
              size="36"
              class="primary"
            >
              <span class="white--text">
                {{ avatarText(`${user.first_name} ${user.last_name}`) }}
              </span>
            </v-avatar>
          </v-card>
          <!-- <input
            ref="profilePhotoPicker"
            type="file"
            class="d-none"
            accept=".jpg, .jpeg, .png"
            @change="onFileChange($event.target.files)"
          > -->
          <!-- <div
            class="border-t p-6 text-gray-500"
            v-text="message"
          /> -->
          <avatar-cropper
            v-model="showCropper"
            trigger="#pick-avatar"
            @changed="onFileChange2"
            @cancel="cancel"
          />
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          <v-row dense>
            <v-col
              cols="12"
              md="6"
              class="pr-md-2"
            >
              <span class="text-caption mb-1">Nama awal</span>
              <v-text-field
                v-model="profileData.first_name"
                outlined
                placeholder="Input nama awal"
                hide-details="auto"
                dense
                :rules="[required]"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="pl-md-2"
            >
              <span class="text-caption mb-1">Nama akhir</span>
              <v-text-field
                v-model="profileData.last_name"
                outlined
                placeholder="Input nama akhir"
                hide-details="auto"
                dense
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <span class="text-caption error--text mb-1">{{ errorPhoto }}</span>
      <div class="mt-6 ml-md-auto">
        <v-btn
          color="primary"
          :loading="loadingUpdate"
          @click="updateProfile"
        >
          Update Profil
        </v-btn>
      </div>
    </v-form>
    <!-- make horizontal line -->
    <v-divider class="my-6" />
    <!-- User Akun -->
    <h4 class="mb-1">User Akun</h4>
    <v-form
      ref="userForm"
      lazy-validation
      class="d-flex flex-column"
    >
      <v-row>
        <v-col
          cols="12"
          md="10"
        >
          <v-row dense>
            <v-col
              cols="3"
              class=""
            >
              <span class="text-caption mb-1">Nomor HP</span>
              <v-text-field
                v-model="profileData.phone"
                :disabled="true"
                type="tel"
                hide-details="auto"
                style="width: 160px"
                dense
                :rules="[required, phoneValidator]"
              />
            </v-col>
            <v-col
              cols="9"
              class=""
            >
              <template>
                <v-dialog
                  v-model="isOpenRequirePhone"
                  max-width="450px"
                  :close-on-content-click="false"
                  offset-y
                  outlined
                >
                  <template #activator="{ props }">
                    <v-btn
                      id="no-background-hover"
                      class="text-caption mt-6 pt-0 no-hover"
                      :ripple="false"
                      style="font-size: 60px; padding: 0%; color: #2c84ff"
                      v-bind="props"
                      color="#FFFFFF"
                      :elevation="0"
                      :hover="false"
                      @click="showRequirePhone"
                    >
                      Request Kode Untuk Merubah
                    </v-btn>
                  </template>

                  <v-card
                    class="mx-auto"
                    max-width="450"
                    location="bottom"
                  >
                    <v-card>
                      <v-card-title>
                        <v-spacer />
                        <v-icon
                          class=""
                          size="22"
                          align-items:
                          right
                          @click="closeRequirePhone"
                        >
                          {{ icons.mdiClose }}
                        </v-icon>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <span class="text-caption mb-1">Your current phone number is </span>
                            <span class="text-caption font-weight-bold mb-1 ml-1">
                              {{ profileData.phone }}
                            </span>
                            <v-col cols="12">
                              <v-form
                                ref="myPasswordPhoneForm"
                                lazy-validation
                                class="d-flex flex-column"
                              >
                                <span class="text-caption mt-0 mb-0 pb-0"
                                  >Please Enter Your Password</span
                                >
                                <v-text-field
                                  v-model="myPasswordPhone"
                                  outlined
                                  :type="showPassword ? 'text' : 'password'"
                                  placeholder="Password Lama"
                                  :append-icon="
                                    showPassword ? icons.mdiEyeOffOutline : icons.mdiEyeOutline
                                  "
                                  dense
                                  :rules="[required]"
                                  @click:append="showPassword = !showPassword"
                                />
                                <v-btn
                                  v-if="isPasswordPhoneConfirmed == false"
                                  color="primary"
                                  block
                                  @click="countinuePasswordPhone"
                                >
                                  Continue
                                </v-btn>
                              </v-form>
                              <v-form
                                v-if="isPasswordPhoneConfirmed"
                                ref="sendPhoneVerificationCodeForm"
                                lazy-validation
                                class="d-flex flex-column"
                              >
                                <span class="text-caption mt-0 mb-0 pb-0"
                                  >Please Enter a new Number Phone and we will send you a
                                  verification code</span
                                >
                                <v-text-field
                                  v-model="newPhone"
                                  outlined
                                  type="tel"
                                  placeholder="Masukkan No HP Baru Anda"
                                  dense
                                  :rules="[required, phoneValidator]"
                                  :disabled="isPhoneVericationCodeSended ? '' : disabled"
                                />
                                <v-btn
                                  v-if="isPhoneVericationCodeSended == false"
                                  color="primary"
                                  block
                                  @click="sendPhoneVerificationCode"
                                >
                                  Send Verification Code
                                </v-btn>
                              </v-form>
                              <v-form
                                v-if="isPhoneVericationCodeSended"
                                ref="changePhoneForm"
                                lazy-validation
                                class="d-flex flex-column"
                              >
                                <span class="text-caption mt-0 mb-0 pb-0"
                                  >We just sent you a temporary verification code to</span
                                >
                                <span class="text-caption font-weight-bold mb-1 ml-1">
                                  {{ newPhone }}
                                </span>
                                <v-text-field
                                  v-model="phoneCode"
                                  required
                                  outlined
                                  placeholder="Enter Verification Code"
                                  dense
                                />
                                <v-btn
                                  color="primary"
                                  block
                                  @click="changePhone"
                                >
                                  Change Number Phone
                                </v-btn>
                              </v-form>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-card>
                </v-dialog>
              </template>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
              cols="3"
              class=""
            >
              <span class="text-caption mb-1">Email</span>
              <v-text-field
                v-model="profileData.email"
                :disabled="true"
                type="email"
                hide-details="auto"
                dense
                style="width: 160px"
              />
            </v-col>
            <v-col
              cols="9"
              class=""
            >
              <template>
                <v-dialog
                  v-model="isOpenRequireEmail"
                  max-width="450px"
                  :close-on-content-click="false"
                  offset-y
                  outlined
                >
                  <template #activator="{ props }">
                    <v-btn
                      id="no-background-hover"
                      class="text-caption mt-6 pt-0 no-hover"
                      :ripple="false"
                      style="font-size: 60px; padding: 0%; color: #2c84ff"
                      v-bind="props"
                      color="#FFFFFF"
                      :elevation="0"
                      :hover="false"
                      @click="showRequireEmail"
                    >
                      Request Kode Untuk Merubah
                    </v-btn>
                  </template>

                  <v-card
                    class="mx-auto"
                    max-width="450"
                    location="bottom"
                  >
                    <v-card>
                      <v-card-title>
                        <v-spacer />
                        <v-icon
                          class=""
                          size="22"
                          align-items:
                          right
                          @click="closeRequireEmail"
                        >
                          {{ icons.mdiClose }}
                        </v-icon>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <span class="text-caption mb-1">Your current email is </span>
                            <span class="text-caption font-weight-bold mb-1 ml-1">
                              {{ profileData.email }}
                            </span>
                            <v-col cols="12">
                              <v-form
                                ref="myPasswordEmailForm"
                                lazy-validation
                                class="d-flex flex-column"
                              >
                                <span class="text-caption mt-0 mb-0 pb-0"
                                  >Please Enter Your Password</span
                                >
                                <v-text-field
                                  v-model="myPasswordEmail"
                                  outlined
                                  :type="showPassword ? 'text' : 'password'"
                                  placeholder="Password Lama"
                                  :append-icon="
                                    showPassword ? icons.mdiEyeOffOutline : icons.mdiEyeOutline
                                  "
                                  dense
                                  :rules="[required]"
                                  @click:append="showPassword = !showPassword"
                                />
                                <v-btn
                                  v-if="isPasswordEmailConfirmed == false"
                                  color="primary"
                                  block
                                  @click="countinuePasswordEmail"
                                >
                                  Continue
                                </v-btn>
                              </v-form>
                              <v-form
                                v-if="isPasswordEmailConfirmed"
                                ref="sendEmailVerificationCodeForm"
                                lazy-validation
                                class="d-flex flex-column"
                              >
                                <span class="text-caption mt-0 mb-0 pb-0"
                                  >Please Enter a new email and we will send you a verification
                                  code</span
                                >
                                <v-text-field
                                  v-model="newEmail"
                                  outlined
                                  placeholder="Masukkan Email Baru Anda"
                                  dense
                                  :rules="[required]"
                                  :disabled="isEmailVericationCodeSended ? '' : disabled"
                                />
                                <v-btn
                                  v-if="isEmailVericationCodeSended == false"
                                  color="primary"
                                  block
                                  @click="sendEmailVerificationCode"
                                >
                                  Send Verification Code
                                </v-btn>
                              </v-form>
                              <v-form
                                v-if="isEmailVericationCodeSended"
                                ref="changeEmailForm"
                                lazy-validation
                                class="d-flex flex-column"
                              >
                                <span class="text-caption mt-0 mb-0 pb-0"
                                  >We just sent you a temporary verification code to</span
                                >
                                <span class="text-caption font-weight-bold mb-1 ml-1">
                                  {{ newEmail }}
                                </span>
                                <v-text-field
                                  v-model="emailCode"
                                  required
                                  outlined
                                  placeholder="Enter Verification Code"
                                  dense
                                />
                                <v-btn
                                  color="primary"
                                  block
                                  @click="changeEmail"
                                >
                                  Change Email
                                </v-btn>
                              </v-form>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-card>
                </v-dialog>
              </template>
              <!-- <a
                href="/posts"
              >
                <button
                  class="text-caption mt-8"
                  style="font-size: 60px;"
                >Request Kode untuk Merubah</button>
              </a> -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- <span class="text-caption error--text mb-1">{{ errorPhoto }}</span> -->
      <!-- <div class="mt-6 ml-md-auto">
        <v-btn
          color="primary"
          :loading="loadingUpdate"
          @click="updateProfile"
        >
          Update Profil
        </v-btn>
      </div> -->
    </v-form>
    <v-divider class="my-6" />
    <h4 class="mb-1">Reset Password</h4>
    <ResetPassword />
  </v-card-text>
</template>

<script>
import Vue from 'vue'
import { ref, onMounted, computed } from 'vue'
import { mdiImage, mdiClose, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { avatarText } from '@core/utils/filter'
import { required, phoneValidator } from '@core/utils/validation'
import { apolloClient } from '@/vue-apollo'
import {
  updateProfileInfo,
  confirmPassword,
  sendVerificationCode,
  verifyNumberOrEmail,
} from '@/graphql/mutations'
import ResetPassword from './ResetPassword.vue'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'
import AvatarCropper from 'vue-avatar-cropper'

export default {
  components: {
    ResetPassword,
    AvatarCropper,
  },
  setup() {
    const user = computed(() => store.state.auth.userData)
    const userForm = ref()
    const loadingUpdate = ref(false)
    const isOpenRequirePhone = ref(false)
    const isOpenRequireEmail = ref(false)

    const myPasswordEmailForm = ref()
    const myPasswordPhoneForm = ref()
    const sendEmailVerificationCodeForm = ref()
    const sendPhoneVerificationCodeForm = ref()
    const changeEmailForm = ref()
    const changePhoneForm = ref()
    const myPasswordEmail = ref('')
    const myPasswordPhone = ref('')
    const showPassword = ref(false)
    const isPasswordEmailConfirmed = ref(false)
    const isPasswordPhoneConfirmed = ref(false)
    const newEmail = ref('')
    const newPhone = ref('')
    const isEmailVericationCodeSended = ref(false)
    const isPhoneVericationCodeSended = ref(false)
    const emailCode = ref('')
    const phoneCode = ref('')

    const showToast = (title, text, type = 'error') => {
      Vue.notify({
        title,
        type,
        text,
      })
    }
    const countinuePasswordEmail = () => {
      if (myPasswordEmailForm.value.validate()) {
        loadingUpdate.value = true

        apolloClient
          .mutate({
            mutation: confirmPassword,
            variables: {
              password: myPasswordEmail.value,
            },
          })
          .then(() => {
            isPasswordEmailConfirmed.value = true
            loadingUpdate.value = false
            myPasswordEmailForm.value.resetValidation()
          })
          .catch(() => {
            loadingUpdate.value = false

            showToast('password salah', 'password yang anda masukkan salah mohon tulis ulang')
          })
      }
    }

    const countinuePasswordPhone = () => {
      if (myPasswordPhoneForm.value.validate()) {
        loadingUpdate.value = true

        apolloClient
          .mutate({
            mutation: confirmPassword,
            variables: {
              password: myPasswordPhone.value,
            },
          })
          .then(() => {
            isPasswordPhoneConfirmed.value = true
            loadingUpdate.value = false
            myPasswordPhoneForm.value.resetValidation()
          })
          .catch(() => {
            loadingUpdate.value = false

            showToast('password salah', 'password yang anda masukkan salah mohon tulis ulang')
          })
      }
    }

    const sendEmailVerificationCode = () => {
      if (sendEmailVerificationCodeForm.value.validate()) {
        loadingUpdate.value = true

        apolloClient
          .mutate({
            mutation: sendVerificationCode,
            variables: {
              email: newEmail.value,
            },
          })
          .then(() => {
            isEmailVericationCodeSended.value = true
            loadingUpdate.value = false
            sendEmailVerificationCodeForm.value.resetValidation()
          })
          .catch(err => {
            loadingUpdate.value = false

            errorHandling(err)
          })
      }
    }

    const sendPhoneVerificationCode = () => {
      if (sendPhoneVerificationCodeForm.value.validate()) {
        loadingUpdate.value = true

        apolloClient
          .mutate({
            mutation: sendVerificationCode,
            variables: {
              phone: newPhone.value,
            },
          })
          .then(() => {
            isPhoneVericationCodeSended.value = true
            loadingUpdate.value = false
            sendPhoneVerificationCodeForm.value.resetValidation()
          })
          .catch(err => {
            loadingUpdate.value = false

            errorHandling(err)
          })
      }
    }

    const changeEmail = () => {
      if (changeEmailForm.value.validate()) {
        loadingUpdate.value = true
        apolloClient
          .mutate({
            mutation: verifyNumberOrEmail,
            variables: {
              emailVerificationCode: emailCode.value,
              email: newEmail.value,
            },
          })
          .then(() => {
            isEmailVericationCodeSended.value = true
            loadingUpdate.value = false
            changeEmailForm.value.resetValidation()
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil mengubah email!',
            })
            isOpenRequireEmail.value = false
          })
          .catch(err => {
            loadingUpdate.value = false

            errorHandling(err)
          })
      }
    }

    const changePhone = () => {
      if (changePhoneForm.value.validate()) {
        loadingUpdate.value = true
        apolloClient
          .mutate({
            mutation: verifyNumberOrEmail,
            variables: {
              phoneVerificationCode: phoneCode.value,
              phone: newPhone.value,
            },
          })
          .then(() => {
            isPhoneVericationCodeSended.value = true
            loadingUpdate.value = false
            changePhoneForm.value.resetValidation()
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil mengubah email!',
            })
            isOpenRequirePhone.value = false
          })
          .catch(err => {
            loadingUpdate.value = false

            errorHandling(err)
          })
      }
    }

    const label = ref({
      submit: 'Kirim',
      cancel: 'batal',
    })
    const profileData = ref({
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      photo: null,
      photoPreview: '',
    })
    const showCropper = ref(false)
    const errorPhoto = ref('')
    const showRequirePhone = () => {
      isOpenRequirePhone.value = true
    }
    const showRequireEmail = () => {
      isOpenRequireEmail.value = true
    }
    const closeRequirePhone = () => {
      isOpenRequirePhone.value = false
    }
    const closeRequireEmail = () => {
      isOpenRequireEmail.value = false
    }

    // const onFileChange = files => {
    //   errorPhoto.value = ''

    //   // check for error photo
    //   if (files.length) {
    //     if (!files[0].type.includes('image')) errorPhoto.value = 'File tidak valid'
    //     if (files[0].size / 1000 > 2048) errorPhoto.value = 'Ukuran melebihi batas. Maksimum 2mb.'

    //     profileData.value.photoPreview = URL.createObjectURL(files[0])

    //     // eslint-disable-next-line prefer-destructuring
    //     profileData.value.photo = files[0]
    //   }
    // }

    const onFileChange2 = file => {
      errorPhoto.value = ''

      // check for error photo
      if (file) {
        if (!file.type.includes('image')) errorPhoto.value = 'File tidak valid'
        if (file.size / 1000 > 2048) errorPhoto.value = 'Ukuran melebihi batas. Maksimum 2mb.'

        profileData.value.photoPreview = URL.createObjectURL(file)

        // eslint-disable-next-line prefer-destructuring
        profileData.value.photo = file
      }
    }

    const cancel = () => {
      profileData.value.photoPreview = URL.createObjectURL(store.getters.getUserData.photo)
      profileData.value.photo = store.getters.getUserData.photo
    }

    const updateProfile = () => {
      if (userForm.value.validate()) {
        loadingUpdate.value = true

        /* eslint-disable camelcase */
        const {
          first_name,
          last_name,
          // phone,
          photo,
          photoPreview,
        } = profileData.value

        const variables = {
          // phone,
          first_name,
          last_name,
        }

        // check if photo has been changed
        if (photoPreview !== store.getters.getUserData.photo) {
          variables.photo = photo
        }

        apolloClient
          .mutate({
            mutation: updateProfileInfo,
            variables,
          })
          .then(() => {
            Vue.notify({
              title: 'Sukses',
              text: 'Berhasil mengubah profil!',
            })
            loadingUpdate.value = false

            store.dispatch('getProfileInfo')
          })
          .catch(err => {
            loadingUpdate.value = false
            errorHandling(err)
          })
      }
    }

    onMounted(() => {
      const { first_name, last_name, phone, photo, email } = store.getters.getUserData

      profileData.value = {
        first_name,
        last_name,
        email,
        phone,
        photoPreview: photo,
      }
    })

    return {
      userForm,
      loadingUpdate,
      profileData,
      errorPhoto,
      label,
      countinuePasswordEmail,
      countinuePasswordPhone,
      isOpenRequirePhone,
      showRequirePhone,
      closeRequirePhone,
      isOpenRequireEmail,
      showRequireEmail,
      closeRequireEmail,
      myPasswordEmail,
      myPasswordPhone,

      onFileChange2,
      cancel,
      updateProfile,
      showCropper,

      icons: {
        mdiImage,
        mdiClose,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },

      required,
      phoneValidator,
      showPassword,
      myPasswordEmailForm,
      myPasswordPhoneForm,
      avatarText,
      user,
      isPasswordEmailConfirmed,
      isPasswordPhoneConfirmed,
      newEmail,
      newPhone,
      isEmailVericationCodeSended,
      isPhoneVericationCodeSended,
      emailCode,
      phoneCode,
      sendEmailVerificationCode,
      sendPhoneVerificationCode,
      changeEmail,
      changePhone,
      sendEmailVerificationCodeForm,
      sendPhoneVerificationCodeForm,
      changeEmailForm,
      changePhoneForm,
      showToast,
      verifyNumberOrEmail,
    }
  },
}
</script>

<style>
#no-background-hover::before {
  background-color: transparent !important;
}
</style>
