import gql from 'graphql-tag'

const timeline = gql`
  query timeline($job_id: Float, $prospect_id: Float, $pagination: pagination!) {
    timeline(job_id: $job_id, prospect_id: $prospect_id, pagination: $pagination) {
      count
      timelines {
        name
        type
        info
        created_at
      }
    }
  }
`
export default timeline
