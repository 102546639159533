import gql from 'graphql-tag'

export const confirmDeletePriority = gql`
  mutation confirmDeletePriority(
    $new_id: [Float!]
    $default_id: Float
    $delete_id: [Float!]!
    $workspace_id: Float!
    $job_type_id: Float!
  ) {
    confirmDeletePriority(
      new_id: $new_id
      default_id: $default_id
      delete_id: $delete_id
      workspace_id: $workspace_id
      job_type_id: $job_type_id
    ) {
      status
      msg
      data
    }
  }
`
