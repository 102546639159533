import gql from 'graphql-tag'

const getMyAccurateDBList = gql`
  query accurateDBList($token_id: Float!) {
    accurateDBList(token_id: $token_id) {
      trialEnd
      expired
      bgColor
      licenseEnd
      alias
      logo
      admin
      id
      accessibleUntil
      sample
      logoUrl
      trial
    }
  }
`

export default getMyAccurateDBList
