import gql from 'graphql-tag'

const jobsAssignedUser = gql`
  query jobsAssignedUser(
    $pagination: pagination
    $filter: paramQueryjobsAssignedUser!
    $workspaceId: Float!
    $userId: [Float!]!
  ) {
    jobsAssignedUser(
      pagination: $pagination
      filter: $filter
      workspace_id: $workspaceId
      user_id: $userId
    ) {
      count
      jobs {
        id
        name
        parent {
          id
          name
        }
        expected_close_date
        created_by {
          name
        }
        priority {
          name
        }
        status {
          name
        }
        job_type {
          id
          name
          folder {
            id
            name
          }
        }
      }
    }
  }
`

export default jobsAssignedUser
