import Vue from 'vue'
import { ref } from 'vue'
import { apolloClient } from '@/vue-apollo'
import {
  attachmentDetail as attachmentDetailQuery,
  attachments,
  queryDeleteAttachments,
} from '@/graphql/queries'
import { batchRenameAttachments, deleteAttachments, setCoverAttachment } from '@/graphql/mutations'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'

const useAttachment = ({ jobTypeId } = {}) => {
  const workspaceId = store.getters.getCurrentWorkspaceId
  const loadingAttachments = ref(false)
  const attachmentDetail = ref()
  const attachmentList = ref()
  const baseAttachmentUrl = `${new URL(process.env.VUE_APP_GRAPHQL_HTTP).origin}/attachments`

  const fetchAttachments = async jobId => {
    loadingAttachments.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: attachments,
          variables: {
            job_id: jobId,
            workspace_id: workspaceId,
          },
          fetchPolicy: 'no-cache',
        })
        .then(({ data }) => {
          loadingAttachments.value = false
          attachmentList.value = data.attachments
          resolve(data.attachments)
        })
        .catch(err => {
          loadingAttachments.value = false
          reject(err)
          errorHandling(err)
        })
    })
  }

  const fetchAttachmentDetail = async attId => {
    loadingAttachments.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: attachmentDetailQuery,
          variables: {
            attachment_id: attId,
            workspace_id: workspaceId,
          },
          fetchPolicy: 'no-cache',
        })
        .then(({ data }) => {
          attachmentDetail.value = data.attachmentDetail.attachment_detail
          attachmentList.value = data.attachmentDetail.attachments
          loadingAttachments.value = false
          resolve(data.attachments)
        })
        .catch(err => {
          loadingAttachments.value = false
          reject(err)
          errorHandling(err)
        })
    })
  }

  const fetchDeleteAttachments = async ({ attachIds }) =>
    new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: queryDeleteAttachments,
          variables: {
            attachIds,
            job_type_id: jobTypeId,
            workspace_id: workspaceId,
          },
          fetchPolicy: 'no-cache',
        })
        .then(({ data }) => {
          resolve(data.queryDeleteAttachments)
        })
        .catch(err => {
          reject(err)
          errorHandling(err)
        })
    })

  const batchRenameAttachment = renameParam =>
    new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: batchRenameAttachments,
          variables: {
            renameParam,
            workspace_id: workspaceId,
            job_type_id: jobTypeId,
          },
        })
        .then(({ data }) => {
          resolve(data.batchRenameAttachments)
          Vue.notify({
            title: 'Sukses',
            text: data.batchRenameAttachments.msg,
            type: 'success',
          })
        })
        .catch(err => {
          errorHandling(err, 'Rename Attachment')
          reject(err)
        })
    })

  const deleteAttachment = id =>
    new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: deleteAttachments,
          variables: {
            attachIds: [id],
            job_type_id: jobTypeId,
            workspace_id: workspaceId,
          },
        })
        .then(({ data }) => {
          Vue.notify({
            title: 'Sukses',
            text: data.deleteAttachments.msg,
            type: 'success',
          })
          resolve(data)
        })
        .catch(err => {
          errorHandling(err, 'Hapus Attachment')
          reject(err)
        })
    })

  const setCover = id => {
    apolloClient
      .mutate({
        mutation: setCoverAttachment,
        variables: {
          attachId: id,
        },
      })
      .then(({ data }) => {
        Vue.notify({
          title: 'Sukses',
          text: data.setCoverAttachment.msg,
          type: 'success',
        })
      })
      .catch(err => {
        errorHandling(err, 'Set Cover')
      })
  }

  return {
    baseAttachmentUrl,
    loadingAttachments,
    attachmentDetail,
    attachmentList,

    fetchDeleteAttachments,
    batchRenameAttachment,
    fetchAttachmentDetail,
    fetchAttachments,
    deleteAttachment,
    setCover,
  }
}

export default useAttachment
