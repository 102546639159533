var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"resetPasswordForm",staticClass:"d-flex flex-column",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"text-caption mb-1"},[_vm._v("Password Baru")]),_c('v-text-field',{attrs:{"outlined":"","type":_vm.showNewPassword ? 'text' : 'password',"placeholder":"Password Baru","append-icon":_vm.showNewPassword ? _vm.icons.mdiEyeOffOutline : _vm.icons.mdiEyeOutline,"hide-details":"auto","dense":"","rules":[
          _vm.required,
          // lengthValidator(newPassword, 8),
          _vm.checkPasswordStrength(_vm.newPassword),
        ]},on:{"click:append":function($event){_vm.showNewPassword = !_vm.showNewPassword}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"text-caption mb-1"},[_vm._v("Konfirmasi Password Baru")]),_c('v-text-field',{attrs:{"outlined":"","type":_vm.showConfirmPassword ? 'text' : 'password',"placeholder":"Konfirmasi Password","append-icon":_vm.showConfirmPassword ? _vm.icons.mdiEyeOffOutline : _vm.icons.mdiEyeOutline,"hide-details":"auto","dense":"","rules":[
          _vm.required,
          _vm.confirmedValidator(_vm.confirmPassword, _vm.newPassword),
          // lengthValidator(confirmPassword, 8),
        ]},on:{"click:append":function($event){_vm.showConfirmPassword = !_vm.showConfirmPassword}},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"text-caption mb-1"},[_vm._v("Password Lama")]),_c('v-text-field',{attrs:{"outlined":"","type":_vm.showPassword ? 'text' : 'password',"placeholder":"Password Lama","append-icon":_vm.showPassword ? _vm.icons.mdiEyeOffOutline : _vm.icons.mdiEyeOutline,"hide-details":"auto","dense":"","rules":[_vm.required]},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}})],1)],1),_c('div',{staticClass:"mt-6 ml-md-auto"},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.loadingUpdate},on:{"click":_vm.updatePass}},[_vm._v(" Reset Password ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }