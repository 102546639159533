import gql from 'graphql-tag'

export const unarchiveTeam = gql`
  mutation unarchiveTeam($team_id: Float!, $workspace_id: Float!) {
    unarchiveTeam(team_id: $team_id, workspace_id: $workspace_id) {
      data
      msg
      status
    }
  }
`
