import { apolloClient } from '@/vue-apollo'
import { jobTypes, jobTypeDetail } from '@/graphql/queries'
import {
  deleteJobType,
  archiveJobType,
  unarchiveJobType,
  updateJobTypePermission,
} from '@/graphql/mutations'

export const getJobTypes = async (folderId, filterJobType) => {
  const { data } = await apolloClient.query({
    query: jobTypes,
    fetchPolicy: 'no-cache',
    variables: {
      folder_id: folderId,
      filter: filterJobType,
    },
  })

  return data.jobTypes
}

export const getJobTypeDetail = async ({ jobTypeId, excludeClosedStatus }) => {
  const { data } = await apolloClient.query({
    query: jobTypeDetail,
    fetchPolicy: 'no-cache',
    variables: {
      job_type_id: jobTypeId,
      exclude_closed_status: excludeClosedStatus,
    },
  })

  return data.jobTypeDetail
}

export const performArchiveJobType = async jobTypeId => {
  const { data } = await apolloClient.mutate({
    mutation: archiveJobType,
    variables: {
      job_type_id: jobTypeId,
    },
  })

  return data.archiveJobType
}

export const performUnarchiveJobType = async jobTypeId => {
  const { data } = await apolloClient.mutate({
    mutation: unarchiveJobType,
    variables: {
      job_type_id: jobTypeId,
    },
  })

  return data.unarchiveJobType
}

export const performRemoveJobType = async jobTypeId => {
  const { data } = await apolloClient.mutate({
    mutation: deleteJobType,
    variables: {
      job_type_id: jobTypeId,
    },
  })

  return data.deleteJobType
}

export const performUpdateJobTypePermission = async ({ id, isPublic, userPermissions }) => {
  const { data } = await apolloClient.mutate({
    mutation: updateJobTypePermission,
    variables: {
      job_type_id: id,
      is_public: isPublic,
      user_permissions: userPermissions,
    },
  })

  return data.updateJobTypePermission
}
