import gql from 'graphql-tag'

export const updateJobPriority = gql`
  mutation updateJobPriority(
    $workspace_id: Float!
    $job_priority: [paramUpdateJobPriority!]!
    $job_type_id: Float!
  ) {
    updateJobPriority(
      workspace_id: $workspace_id
      job_priority: $job_priority
      job_type_id: $job_type_id
    ) {
      status
      data
      msg
    }
  }
`
