<template>
  <v-card-text class="d-flex flex-column h-full">
    <div class="d-flex justify-space-between mt-4 mb-6">
      <div class="d-flex align-items-start">
        <v-switch
          :value="isAutoSubscribe"
          inset
          flat
          hide-details
          label="Perpanjang Plan Otomatis"
          class="ml-5 mt-0"
          :loading="loadingChangeAutoSubscribe"
          :disabled="loadingChangeAutoSubscribe"
          readonly
          @click="changeAutoSubscribe"
        />
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-icon
              class="mt-1 ms-2"
              v-bind="attrs"
              size="25"
              v-on="on"
            >
              {{ icons.mdiHelpCircleOutline }}
            </v-icon>
          </template>
          <span
            >Jika aktif, maka setelah plan jatuh tempo, plan akan otomatis diperpanjang menggunakan
            data kartu kredit yang tersimpan</span
          >
        </v-tooltip>
      </div>

      <v-btn
        color="primary"
        @click="$refs.addCardForm.show()"
      >
        Tambahkan Kartu
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="userCardList"
      :items-per-page="10"
      item-key="id"
      disable-sort
      :footer-props="{
        'items-per-page-options': [10, 25, 50],
        'items-per-page-text': 'Jumlah data',
      }"
      :server-items-length="userCardList.length"
      mobile-breakpoint="0"
      :loading="loadingCardUser"
      :options.sync="cardPagination"
    >
      <template #[`item.masked_number`]="{ item }">
        <span class="font-weight-bold">{{ formatCreditCard(item.masked_number) }}</span>
      </template>
      <template #[`item.card_type`]="{ item }">
        <span>{{ title(item.card_type) }}</span>
      </template>
      <template #[`item.expired_at`]="{ item }">
        <span>{{ formatDate(item.expired_at) }}</span>
      </template>
      <template #[`item.action`]="{ item }">
        <v-btn
          color="error"
          outlined
          small
          :loading="loadingDeleteCardUser"
          :disabled="loadingDeleteCardUser"
          @click="confirmDeleteCard(item)"
        >
          Hapus
        </v-btn>
      </template>
    </v-data-table>
    <add-card-form
      ref="addCardForm"
      @success="getUserCards()"
    />
  </v-card-text>
</template>
<script>
import useUser from '@/composables/useUser'
import { onMounted, ref, watch } from 'vue'
import { formatDate, formatCreditCard, title } from '@core/utils/filter'
import AddCardForm from './AddCardForm.vue'
import Vue from 'vue'
import store from '@/store'
import { mdiHelpCircleOutline } from '@mdi/js'

export default {
  components: {
    AddCardForm,
  },
  setup(props, { emit }) {
    const {
      getUserCards,
      loadingCardUser,
      userCardList,
      deleteCard,
      loadingDeleteCardUser,
      updateAutoSubscribe,
      loadingChangeAutoSubscribe,
    } = useUser()

    const isAutoSubscribe = ref(false)

    const fetchProfileData = () => {
      const {
        // eslint-disable-next-line camelcase
        is_auto_subscribe,
      } = store.getters.getUserData

      // eslint-disable-next-line camelcase
      isAutoSubscribe.value = is_auto_subscribe
    }

    onMounted(() => {
      getUserCards()
      fetchProfileData()
    })

    const changeAutoSubscribe = () => {
      updateAutoSubscribe(!isAutoSubscribe.value).then(() => {
        store.dispatch('getProfileInfo')
        store.state.auth.userData.is_auto_subscribe = !isAutoSubscribe.value
        fetchProfileData()
        emit('changeSubscribe')
      })
    }

    const cardPagination = ref({})
    watch(userCardList.value, () => {
      console.log(userCardList.value)
    })
    const headers = ref([
      { text: 'No. Kartu', value: 'masked_number' },
      { text: 'Tipe Kartu', value: 'card_type' },
      { text: 'Tanggal Kadaluarsa', value: 'expired_at' },
      { text: 'Aksi', value: 'action' },
    ])

    const confirmDeleteCard = item => {
      Vue.$dialog({
        title: 'Konfirmasi',
        body: 'Apakah anda yakin ingin menghapus data kartu kredit ini?',
      }).then(result => {
        if (result) {
          deleteCard(item.id).then(() => {
            console.log('tes')
            getUserCards()
            store.dispatch('getProfileInfo').then(() => {
              fetchProfileData()
              emit('changeSubscribe')
            })
          })
        }
      })
    }

    return {
      loadingCardUser,
      userCardList,
      headers,
      cardPagination,
      formatDate,
      formatCreditCard,
      title,
      confirmDeleteCard,
      loadingDeleteCardUser,
      getUserCards,
      changeAutoSubscribe,
      isAutoSubscribe,
      loadingChangeAutoSubscribe,
      store,
      icons: {
        mdiHelpCircleOutline,
      },
    }
  },
}
</script>
