<template>
  <v-dialog
    v-model="isOpen"
    max-width="500"
    scrollable
  >
    <v-card>
      <div class="d-flex justify-space-between pa-4">
        <span class="text-h6 font-medium primary--text">Konfirmasi Hapus Attachments</span>
        <v-icon @click="close()">
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-card-text class="pt-2 job-form-content">
        <div class="mb-5 pb-5">
          <v-row>
            <v-col cols="6"> Nama Attachment </v-col>
            <v-col cols="6"> Jumlah Attachment Masih Digunakan </v-col>
          </v-row>
          <v-divider class="mt-2 mb-5" />
          <v-row
            v-for="attachment in deleteAttachmentList"
            :key="attachment.id"
            class="mt-3"
          >
            <v-col
              cols="6"
              class="my-auto"
            >
              <div class="d-flex">
                <span>
                  {{ attachment.file_name }}
                </span>
                <span class="ms-auto">
                  <v-icon>
                    {{ icons.mdiArrowRight }}
                  </v-icon>
                </span>
              </div>
            </v-col>
            <v-col cols="6">
              {{ attachment.count_comment }} Komen & {{ attachment.count_custom_attribute }} Atribut
              Kustom
            </v-col>
          </v-row>
        </div>
        <div class="d-flex flex-column flex-md-row mt-4">
          <v-btn
            class="mb-2 mb-md-0 mr-md-2 ml-md-auto"
            outlined
            color="error"
            @click="close()"
          >
            Batalkan
          </v-btn>
          <v-btn
            color="primary"
            @click="$emit('confirm')"
          >
            Konfirmasi
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref } from 'vue'
import { mdiClose, mdiArrowRight } from '@mdi/js'

export default {
  setup(props, { emit }) {
    const isOpen = ref(false)
    const deleteAttachmentList = ref(null)

    const show = deleteList => {
      isOpen.value = true
      deleteAttachmentList.value = deleteList
    }

    const close = () => {
      isOpen.value = false
    }

    const submitForm = async () => {
      emit('success')
      close()
    }

    return {
      isOpen,
      deleteAttachmentList,

      show,
      close,
      submitForm,

      icons: {
        mdiClose,
        mdiArrowRight,
      },
    }
  },
}
</script>
<style></style>
