import gql from 'graphql-tag'

export const deleteJobPriority = gql`
  mutation ($workspace_id: Float!, $delete_id: [Float!]!, $job_type_id: Float!) {
    deleteJobPriority(
      workspace_id: $workspace_id
      delete_id: $delete_id
      job_type_id: $job_type_id
    ) {
      status
      msg
      data
    }
  }
`
