import gql from 'graphql-tag'

const objectExecutorActionsV2 = gql`
  query objectExecutorActionsV2($object_id: Float!, $trigger_category_id: Float!) {
    objectExecutorActionsV2(object_id: $object_id, trigger_category_id: $trigger_category_id) {
      id
      name
      name_view
      description
      object {
        id
      }
      category {
        id
      }
      component_name
    }
  }
`

export default objectExecutorActionsV2
