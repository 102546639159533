import gql from 'graphql-tag'

export const archiveTeam = gql`
  mutation archiveTeam($team_id: Float!, $workspace_id: Float!) {
    archiveTeam(team_id: $team_id, workspace_id: $workspace_id) {
      data
      msg
      status
    }
  }
`
