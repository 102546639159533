<template>
  <div :class="selectedUserIds.length ? 'autocomplete-field' : ''">
    <v-row
      no-gutters
      class="align-center"
    >
      <!-- Chip Group -->
      <template v-if="userList.length">
        <v-col
          v-for="(item, index) in selectedUserIds
            .slice(0, 7)
            .map(id => userList.find(user => user.id === id))"
          :key="item.id"
          cols="auto"
          class="me-2"
        >
          <v-chip
            v-if="index !== 6"
            small
            close
            @click:close="$emit('remove', item.id)"
          >
            <span
              style="max-width: 60px; display: inline-block"
              class="text-truncate"
            >
              {{ item.name }}
            </span>
          </v-chip>
          <v-menu v-else>
            <template #activator="{ on, attrs }">
              <v-chip
                small
                v-bind="attrs"
                v-on="on"
              >
                ...
              </v-chip>
            </template>
            <v-card :max-width="width">
              <v-card-text class="pa-3">
                <v-chip
                  v-for="user in userList.filter(user =>
                    selectedUserIds.slice(6).includes(user.id),
                  )"
                  :key="user.id"
                  small
                  close
                  @click:close="$emit('remove', item.id)"
                >
                  {{ user.name.length > 9 ? `${user.name.substring(0, 9)}...` : user.name }}
                </v-chip>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-col>
      </template>

      <!-- Search Input -->
      <v-col
        class="flex-grow-1 autocomplete-search"
        style="min-width: 100px"
      >
        <v-text-field
          v-model="searchKeyword"
          placeholder="Cari User / Team"
          :outlined="!selectedUserIds.length"
          hide-details
          dense
          @click="$emit('click')"
          @blur="$emit('blur')"
        >
          <template #append>
            <v-icon
              v-if="selectedUserIds.length"
              size="20"
              class="my-auto"
              @click="$emit('clear')"
            >
              {{ icons.mdiCloseCircle }}
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { computed } from 'vue'
import { mdiCloseCircle } from '@mdi/js'

export default {
  props: {
    selectedUserIds: {
      type: Array,
      default: () => [],
    },
    userList: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 200,
    },
  },
  setup(props, { emit }) {
    const searchKeyword = computed({
      get() {
        return props.search
      },
      set(val) {
        return emit('input-search', val)
      },
    })

    return {
      searchKeyword,

      icons: {
        mdiCloseCircle,
      },
    }
  },
}
</script>

<style lang="scss">
.autocomplete-field {
  position: relative;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid;
  border-color: map-deep-get($material-light, 'text-fields', 'outlined');

  .theme--dark & {
    border-color: map-deep-get($material-dark, 'text-fields', 'outlined');
  }

  .autocomplete-search {
    .v-input {
      margin-top: 0 !important;
    }

    input {
      padding: 0 !important;
    }
  }
}
</style>
