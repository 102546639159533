<template>
  <v-menu
    v-model="isOpenSortir"
    max-width="350px"
    :close-on-content-click="false"
    offset-y
    outlined
  >
    <template #activator="{ on, attrs }">
      <v-btn
        height="72"
        :outlined="isBorder"
        text
        class="btn-block"
        block
        color="primary"
        v-bind="attrs"
        @click="showSortir"
        v-on="on"
      >
        {{ sortCount == 0 ? 'Sortir' : sortSelect.text }}

        <v-icon
          v-if="sortCount !== 0"
          class="me-1"
          size="26"
        >
          {{ sortSelect.sortType[isAsc].icon }}
        </v-icon>
      </v-btn>
    </template>
    <v-card
      class="mx-auto"
      max-width="400"
      location="bottom"
      outlined
    >
      <v-list>
        <v-list-item
          v-for="(item, index) in sortOptions"
          :key="index"
          :value="index"
        >
          <v-btn
            class="mt-1"
            width="200"
            :elevation="0"
            @click="$emit('addSortir', item.id)"
          >
            <v-row>
              <v-col
                cols="8"
                class="text-left"
              >
                {{ item.text }}
              </v-col>
              <v-col cols="4">
                <v-icon
                  v-if="item.id == sortSelect && sortSelect.id && sortCount != 0"
                  class="me-1"
                  size="26"
                >
                  {{ item.sortSelect[isAsc].icon }}
                </v-icon>
              </v-col>
            </v-row>
          </v-btn>
          <!-- <v-list-item-title @click="addSortir(index+1)">
                          {{ item.text }} {{ item.icon }}
                        </v-list-item-title> -->
        </v-list-item>
      </v-list>
    </v-card>
    <v-card
      class="mx-auto"
      max-width="400"
      location="bottom"
    >
      <!-- <div class="d-flex justify-space-between mb-4">
                  <span class="text-h6 font-medium primary--text" />
                  <v-icon
                    class="mr-2 mt-2"
                    @click="closeFilter"
                  >
                    {{ icons.mdiClose }}
                  </v-icon>
                </div> -->
    </v-card>
  </v-menu>
</template>

<script>
import { ref } from 'vue'
// import store from '@/store'
import {
  mdiSortAlphabeticalAscending,
  mdiSortAlphabeticalDescending,
  mdiSortClockAscending,
  mdiSortClockDescending,
} from '@mdi/js'

export default {
  props: {
    sortOptions: {
      type: Array,
      default: () => {},
      required: true,
    },
    sortSelect: {
      type: Object,
      default: () => {},
      required: false,
    },
    sortCount: {
      type: Number,
      default: 0,
      required: false,
    },
    isAsc: {
      type: Number,
      default: 0,
      required: false,
    },
    isBorder: {
      type: Boolean,
      default: true,
      required: false,
    },
    isHover: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  // data() {
  //   return {
  //     type: Array,
  //     default: () => [],
  //   }
  // },
  setup(props) {
    const isOpenSortir = ref(false)
    // const pagination = ref({})
    // const sortOptions = ref(props.sortOptionsProp)
    // const sortSelect = ref(props.sortSelectProp)
    // const sortCount = ref(props.sortCountProp)
    // const isAsc = ref(props.isAscProp)
    // console.log(sortOptions.value)
    // console.log('asdfasdrfas', sortSelect.value)
    console.log(props.sortOptionsProp)

    const showSortir = () => {
      isOpenSortir.value = true
      // console.log(isOpenSortir)
    }

    const closeSortir = () => {
      isOpenSortir.value = false
      // console.log(isOpenSortir)
    }

    return {
      showSortir,
      closeSortir,
      isOpenSortir,
      mdiSortAlphabeticalAscending,
      mdiSortAlphabeticalDescending,
      mdiSortClockAscending,
      mdiSortClockDescending,
      // sortOptions,
      // sortSelect,
      // sortCount,
      // isAsc,
    }
  },
}
</script>
<style lang="scss">
.btn-block {
  width: 100%;
  /* Make the button take up 100% of the parent's width */
  box-sizing: border-box;
  /* Include padding and border in the total width */
}
</style>
