import gql from 'graphql-tag'

export const updateCustomAttributeOptionValue = gql`
  mutation updateCustomAttributeOptionValue(
    $custom_attribute_id: Float!
    $type_id: Float!
    $object_id: Float!
    $option_id: Float
    $workspace_id: Float!
  ) {
    updateCustomAttributeOptionValue(
      custom_attribute_id: $custom_attribute_id
      type_id: $type_id
      object_id: $object_id
      option_id: $option_id
      workspace_id: $workspace_id
    ) {
      msg
      status
      data
    }
  }
`
