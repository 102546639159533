import gql from 'graphql-tag'

export const addDocumentTag = gql`
  mutation addDocumentTag($name: String!, $workspaceId: Float!) {
    addDocumentTag(name: $name, workspace_id: $workspaceId) {
      status
      msg
      data
    }
  }
`
