<template>
  <v-dialog
    v-model="isOpen"
    max-width="600"
    @input="!$event ? close() : null"
  >
    <v-card>
      <div class="d-flex justify-space-between pa-4">
        <span class="text-subtitle-1 font-medium primary--text">Telegram Form</span>
        <v-icon @click="close()">
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-card-text>
        <v-form
          ref="telegramForm"
          lazy-validation
          @submit.prevent="submitForm"
        >
          <v-text-field
            v-model="formData.accessToken"
            :rules="[required]"
            label="Access Token"
            placeholder="Input access token"
            dense
            outlined
            hide-details="auto"
          />
        </v-form>
        <div class="d-flex justify-end mt-4">
          <v-btn
            class="mr-2"
            outlined
            @click="reset"
          >
            Reset
          </v-btn>
          <v-btn
            color="primary"
            :loading="loadingForm"
            @click="submitForm"
          >
            Simpan
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from 'vue'
import { mdiClose } from '@mdi/js'
import { required } from '@core/utils/validation'
import useIntegration from '@/composables/useIntegration'

export default {
  setup(props, { emit }) {
    const telegramForm = ref()
    const isOpen = ref(false)
    const updateMode = ref(false)
    const formData = ref({
      id: null,
      accessToken: '',
      connectionName: '',
    })
    const loadingSubmit = ref(false)
    const { addWorkspaceTokenFunc, updateWorkspaceTokenFunc, loadingForm } = useIntegration()
    const show = () => {
      isOpen.value = true
    }
    const update = data => {
      console.log(data.id)
      formData.value.id = data.id
      formData.value.connectionName = data.connection_name
      updateMode.value = true
      isOpen.value = true
    }

    const reset = () => {
      loadingSubmit.value = false
      formData.value = {
        accessToken: '',
        id: null,
        connectionName: '',
      }

      telegramForm.value.reset()
    }

    const close = () => {
      isOpen.value = false

      reset()
    }

    const submitForm = () => {
      if (telegramForm.value.validate()) {
        loadingForm.value = true
        if (!updateMode.value) {
          addWorkspaceTokenFunc(
            { access_token: formData.value.accessToken, platform_id: 1 },
            () => {
              emit('success')
              close()
              loadingForm.value = false
            },
          )
        } else {
          updateWorkspaceTokenFunc(
            {
              id: formData.value.id,
              connection_name: formData.value.connectionName,
              access_token: formData.value.accessToken,
              platform_id: 1,
            },
            // eslint-disable-next-line camelcase
            connection_name => {
              emit('success', connection_name)
              close()
              loadingForm.value = false
            },
          )
        }
      }
    }

    return {
      telegramForm,
      isOpen,
      loadingSubmit,
      formData,
      loadingForm,

      show,
      update,
      close,
      submitForm,
      reset,

      icons: {
        mdiClose,
      },

      required,
    }
  },
}
</script>
