import { HyperFormula, FunctionPlugin, FunctionArgumentType } from 'hyperformula'
import { registerFunctions } from './hyperFormulaFunction'

const language = HyperFormula.getLanguage('enGB')

// Tujuan Untuk Menghapus Semua Fungsi dari hyperformula yang tidak digunakan pada Oriens
Object.getOwnPropertyNames(language.functions).forEach(e => {
  if (registerFunctions.filter(f => f.name === `${e}()`).length == 0 && e !== 'OFFSET') {
    language.functions[e] = undefined // ini untuk menghilangkan fungsi
    // language.functions[e] = e // ini menyalakan fungsi kembali
  }
})

// Create Class CustomPlugin Yang dibutuhkan Oriens
class OriensCustomPlugins extends FunctionPlugin {
  /* 
    @methods now 
    @description tujuannya untuk menimpa fungsi default dari hyperformula karena fungsi aslinya mengembalikan nilai millisecond 
    @return nilai tanggal dengan format "YYYY-MM-DD" / 2024-06-11
  */
  now(ast, state) {
    return this.runFunction(ast.args, state, this.metadata('NOW'), () => {
      const t = new Date()
      const monthBuilder = (t.getMonth() + 1).toString().padStart(2, '0')
      const dateBuilder = t.getDate().toString().padStart(2, '0')

      return `${t.getFullYear()}-${monthBuilder}-${dateBuilder}`
    })
  }

  floor(ast, state) {
    return this.runFunction(ast.args, state, this.metadata('FLOOR'), n => {
      return Math.floor(n)
    })
  }

  ceiling(ast, state) {
    return this.runFunction(ast.args, state, this.metadata('CEILING'), n => {
      return Math.ceil(n)
    })
  }

  round(ast, state) {
    return this.runFunction(ast.args, state, this.metadata('ROUND'), n => {
      return Math.round(n)
    })
  }
}

// Fungsi yang akan digunakan hyperFormula dari custom plugin
OriensCustomPlugins.implementedFunctions = {
  NOW: {
    method: 'now',
  },
  FLOOR: {
    method: 'floor',
    parameters: [{ argumentType: FunctionArgumentType.NUMBER }],
  },
  CEILING: {
    method: 'ceiling',
    parameters: [{ argumentType: FunctionArgumentType.NUMBER }],
  },
  ROUND: {
    method: 'round',
    parameters: [{ argumentType: FunctionArgumentType.NUMBER }],
  },
}

const OriensCustomPluginTranslations = {}

// Register Function Pada HyperFormula
HyperFormula.registerFunctionPlugin(OriensCustomPlugins, OriensCustomPluginTranslations)

const hyperFormula = HyperFormula.buildEmpty({
  licenseKey: 'gpl-v3',
  accentSensitive: true,
  caseFirst: 'upper',
  caseSensitive: true,
  dateFormats: ['YYYY-MM-DD'],
})

hyperFormula.addSheet('t')

export { hyperFormula }
