<template>
  <v-menu
    v-model="isOpen"
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        x-small
        v-bind="attrs"
        color="primary"
        v-on="on"
      >
        Invite
      </v-btn>
    </template>

    <v-card v-if="isOpen">
      <v-card-title>Invite ke {{ objectTypeName }}</v-card-title>
      <v-card-text>
        <v-select
          v-model="permissionValue"
          outlined
          dense
          label="Permission User"
          hide-details="auto"
          item-text="label"
          item-value="value"
          :items="isGuest ? [permissionList[permissionList.length - 1]] : permissionList"
          placeholder="Pilih permission"
        >
        </v-select>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="error"
          outlined
          x-small
          class="ml-auto"
          @click="isOpen = false"
        >
          Batal
        </v-btn>
        <v-btn
          color="primary"
          outlined
          x-small
          class="ml-2"
          :loading="isLoading"
          @click="handleInvite"
        >
          Invite
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import usePermission from '@/composables/usePermission'
import { computed, ref } from 'vue'
import Vue from 'vue'

export default {
  props: {
    objectType: {
      type: String,
      required: true,
      validator: value => ['document', 'job-type', 'folder'].includes(value),
    },
    objectDetail: {
      type: Object,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    isGuest: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const {
      folderPermissionList,
      jobTypePermissionList,
      documentPermissionList,

      addFolderPermission,
      addJobTypePermission,
      addDocumentPermission,
    } = usePermission()

    const objectTypeName = computed(() => {
      switch (props.objectType) {
        case 'folder':
          return 'Folder'
        case 'job-type':
          return 'Job Type'
        case 'document':
          return 'Document'
        default:
          return ''
      }
    })
    const permissionList = computed(() => {
      switch (props.objectType) {
        case 'folder':
          return folderPermissionList
        case 'job-type':
          return jobTypePermissionList
        case 'document':
          return documentPermissionList
        default:
          return []
      }
    })

    const isOpen = ref(false)
    const isLoading = ref(false)
    const permissionValue = ref(
      props.isGuest
        ? permissionList.value[permissionList.value.length - 1].value
        : permissionList.value[1].value,
    )

    const generatePermissionPayload = () => ({
      id: props.objectType === 'document' ? props.objectDetail.uuid : props.objectDetail.id,
      userPermissions: [
        {
          user_id: props.userId,
          permission_type: permissionList.value.find(perm => perm.value === permissionValue.value)
            .id,
        },
      ],
      onSuccess: () => {
        Vue.notify({
          type: 'success',
          title: 'Berhasil',
          text: `Berhasil memasukkan user ke ${objectTypeName.value.toLowerCase()}`,
        })
        emit(
          'success',
          permissionList.value.find(perm => perm.value === permissionValue.value).value,
        )
      },
    })

    const handleInvite = async () => {
      isLoading.value = true

      switch (props.objectType) {
        case 'folder':
          await addFolderPermission(generatePermissionPayload())
          break
        case 'job-type':
          await addJobTypePermission(generatePermissionPayload())
          break
        case 'document':
          await addDocumentPermission(generatePermissionPayload())
          break
        default:
          break
      }

      isLoading.value = false
      isOpen.value = false
    }

    return {
      isOpen,
      isLoading,

      objectTypeName,
      permissionList,
      permissionValue,

      handleInvite,
    }
  },
}
</script>
