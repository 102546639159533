import gql from 'graphql-tag'

export const deleteAttachments = gql`
  mutation deleteAttachments($attachIds: [String!]!, $job_type_id: Float!, $workspace_id: Float!) {
    deleteAttachments(
      attachIds: $attachIds
      job_type_id: $job_type_id
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`
