import gql from 'graphql-tag'

export const updateSnapshot = gql`
  mutation updateSnapshot($name: String!, $snapshotId: Float!, $workspaceId: Float!) {
    updateSnapshot(name: $name, snapshot_id: $snapshotId, workspace_id: $workspaceId) {
      status
      msg
      data
    }
  }
`
