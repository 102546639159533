<template>
  <v-card
    width="240px"
    class="pa-2 pt-1"
    style="overflow: hidden"
  >
    <v-text-field
      v-model="state.filter.value.search"
      label="Cari Folder"
      single-line
      hide-details
      dense
      flat
      outlined
      class="my-2"
      @keydown.enter="searchFolder"
    >
      <template #append>
        <v-icon
          class="m-0 p-0"
          size="26"
        >
          {{ icons.mdiMagnify }}
        </v-icon>
      </template>
    </v-text-field>

    <v-select
      v-model="state.filter.value.sort"
      outlined
      flat
      dense
      class="my-2"
      item-text="text"
      item-value="id"
      :items="sortOptions"
      hide-details="auto"
      label="Sortir"
      return-object
    >
      <template #item="{ item }">
        <div class="d-flex align-center justify-space-between flex-grow-1">
          <div>
            <v-icon
              class="me-1"
              size="24"
            >
              {{ item.icon }}
            </v-icon>
            <span class="subtitle-2"> {{ item.text }}</span>
          </div>
        </div>
      </template>
    </v-select>

    <v-select
      v-model="state.filter.value.my_folder"
      outlined
      flat
      dense
      class="my-2"
      item-text="text"
      item-value="id"
      :items="filterOwnerOptions"
      hide-details="auto"
      label="Pemilik"
      return-object
    />

    <v-select
      v-model="state.filter.value.show_archived"
      outlined
      flat
      dense
      class="my-2"
      item-text="text"
      item-value="id"
      :items="filterArchiveOptions"
      hide-details="auto"
      label="Tampilkan Folder"
      return-object
    />

    <v-row class="pb-2">
      <v-col
        cols="6"
        class="pe-1"
      >
        <v-btn
          color="primary"
          block
          outlined
          small
          @click="resetFolderFilter"
        >
          Reset
        </v-btn>
      </v-col>
      <v-col
        cols="6"
        class="ps-1"
      >
        <v-btn
          color="primary"
          block
          small
          @click="searchFolder"
        >
          Cari
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import {
  mdiMagnify,
  mdiSortAlphabeticalAscending,
  mdiSortAlphabeticalDescending,
  mdiSortClockAscending,
  mdiSortClockDescending,
} from '@mdi/js'
import { ref } from 'vue'
import useFolder from '@/composables/useFolder'
import { createFieldMapper } from 'vuex-use-fields'
import store from '@/store'

const useFieldFolder = createFieldMapper({ getter: 'folder/getField', setter: 'folder/setField' })

export default {
  setup() {
    const state = {
      ...useFieldFolder(['loadingFolder', 'folderList', 'filter']),
    }

    // eslint-disable-next-line no-unused-vars
    const { fetchFolder } = useFolder()
    const searchFolder = () => {
      state.loadingFolder.value = true
      fetchFolder({
        search: state.filter.value.search,
        sort: state.filter.value.sort.value,
        sortType: state.filter.value.sort.sortType,
        show_archived: state.filter.value.show_archived.value,
        my_folder: state.filter.value.my_folder.value,
      }).then(val => {
        state.loadingFolder.value = false
        state.folderList.value = val
      })
    }

    const resetFolderFilter = () => {
      console.log('resetFolderFilter')
      store.dispatch('folder/resetFolderFilter')
      searchFolder()
    }

    const sortOptions = ref([
      {
        id: 1,
        text: 'Nama (A-Z)',
        value: 'name',
        sortType: 'ASC',
        icon: mdiSortAlphabeticalAscending,
      },
      {
        id: 2,
        text: 'Nama (Z-A)',
        value: 'name',
        sortType: 'DESC',
        icon: mdiSortAlphabeticalDescending,
      },
      {
        id: 3,
        text: 'Tanggal Dibuat (Terbaru)',
        value: 'created_at',
        sortType: 'ASC',
        icon: mdiSortClockAscending,
      },
      {
        id: 4,
        text: 'Tanggal Dibuat (Terlama)',
        value: 'created_at',
        sortType: 'DESC',
        icon: mdiSortClockDescending,
      },
    ])

    const filterOwnerOptions = ref([
      {
        id: 1,
        text: 'Semua Folder',
        value: null,
      },
      {
        id: 2,
        text: 'Folder Saya',
        value: true,
      },
      {
        id: 3,
        text: 'Folder Lain',
        value: false,
      },
    ])

    const filterArchiveOptions = ref([
      {
        id: 1,
        text: 'Diarsipkan',
        value: 'IS SET',
      },
      {
        id: 2,
        text: 'Tidak Diarsipkan',
        value: 'IS NOT SET',
      },
      {
        id: 3,
        text: 'Semua Folder',
        value: 'ALL',
      },
    ])

    return {
      icons: {
        mdiMagnify,
        mdiSortAlphabeticalAscending,
        mdiSortAlphabeticalDescending,
        mdiSortClockAscending,
        mdiSortClockDescending,
      },
      searchFolder,
      state,
      sortOptions,
      filterOwnerOptions,
      filterArchiveOptions,
      resetFolderFilter,
    }
  },
}
</script>
