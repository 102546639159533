<template>
  <v-card
    class="px-5 py-4"
    max-height="360"
    outlined
  >
    <v-row>
      <v-col
        cols="4"
        class="ps-0"
      >
        <v-list
          lines="one"
          style="max-height: 320px; overflow-y: auto"
        >
          <v-list-item-group v-model="selectedString">
            <v-list-subtitle
              v-if="searchFormulas.properties.length > 0"
              class="text-substitle-2"
            >
              Properties
            </v-list-subtitle>

            <v-list-item
              v-for="(item, i) in searchFormulas.properties"
              :id="`${item.name}-${i}`"
              :key="`${item.name}-${i}`"
              :value="item"
              active
              style="min-height: 40px"
              class="px-1"
              color="primary"
              variant="plain"
              @mouseover="$emit('update-seek-index', i + 1)"
              @click="
                () => {
                  selectedString = item
                  $emit('click-list', item)
                }
              "
            >
              <v-list-item-subtitle>
                <v-icon size="18">{{ icons[item.icon] }}</v-icon>

                {{ item.name }}
              </v-list-item-subtitle>
            </v-list-item>

            <v-list-subtitle v-if="searchFormulas.functions.length > 0">Functions</v-list-subtitle>
            <v-list-item
              v-for="(item, i) in searchFormulas.functions"
              :id="`${item.name}-${searchFormulas.properties.length + i}`"
              :key="`${item.name}-${i}`"
              :value="item"
              color="primary"
              style="min-height: 40px"
              class="px-1"
              dense
              @mouseenter="$emit('update-seek-index', searchFormulas.properties.length + i + 1)"
              @click="
                () => {
                  selectedString = item
                  $emit('click-list', item)
                }
              "
            >
              <v-list-item-subtitle>
                <v-icon size="18">{{ icons[item.icon] }}</v-icon>

                {{ item.name }}
              </v-list-item-subtitle>
            </v-list-item>

            <v-list-subtitle v-if="searchFormulas.builtIns.length > 0">Built-ins</v-list-subtitle>
            <v-list-item
              v-for="(item, i) in searchFormulas.builtIns"
              :id="`${item.name}-${
                searchFormulas.properties.length + searchFormulas.functions.length + i
              }`"
              :key="`${item.name}-${i}`"
              :value="item"
              color="primary"
              style="min-height: 40px"
              class="px-1"
              dense
              @mouseenter="
                $emit(
                  'update-seek-index',
                  searchFormulas.properties.length + searchFormulas.functions.length + i + 1,
                )
              "
              @click="
                () => {
                  selectedString = item
                  $emit('click-list', item)
                }
              "
            >
              <v-list-item-subtitle>
                <v-icon size="18">{{ icons[item.icon] }}</v-icon>

                {{ item.name }}
              </v-list-item-subtitle>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col v-if="searchFormulas.fullValue.length != 0">
        <div>
          <h4>
            <v-icon>
              {{
                icons[searchFormulas.fullValue[seekIndex === 0 ? seekIndex : seekIndex - 1].icon]
              }}
            </v-icon>
            {{ searchFormulas.fullValue[seekIndex === 0 ? seekIndex : seekIndex - 1].name }}
          </h4>
          <div v-if="selectedData.type !== 'builtIns' && selectedData.type !== 'functions'">
            <small class="fs-6">{{ selectedData.example.title }}</small>
            <br />
            <small class="fs-6">{{ selectedData.example.description }}</small>
            <v-card
              v-for="(syntax, i) in selectedData.example.syntax"
              :key="i"
              outlined
              class="py-3 px-3 mt-2"
            >
              <div>
                <h5
                  class="mb-0"
                  v-html="syntax.preview"
                ></h5>
                <small> <span>=</span> {{ calculateFormula(`=${syntax.code}`) }}</small>

                <v-tooltip
                  :close-delay="1000"
                  :open-on-hover="false"
                  top
                >
                  <template #activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      style="position: absolute; top: 10px; right: 10px; cursor: pointer"
                    >
                      <v-icon
                        size="18"
                        @click="generateCopy(syntax.copy)"
                      >
                        {{ icons.mdiContentCopy }}
                      </v-icon>
                    </span>
                  </template>

                  <span>Berhasil men-copy</span>
                </v-tooltip>
              </div>
            </v-card>
          </div>
          <div v-else>
            <small class="fs-6">{{ selectedData.example.title }}</small>
            <br />
            <small class="fs-6">{{ selectedData.description }}</small>
            <v-card
              v-for="(syntax, i) in selectedData.example.syntax"
              :key="i"
              outlined
              class="py-3 px-3 mt-2"
            >
              <h5 class="mb-0">{{ syntax.code }}</h5>
              <small> <span>=</span> {{ calculateFormula(`=${syntax.code}`) }}</small>

              <v-tooltip
                :close-delay="1000"
                :open-on-hover="false"
                top
              >
                <template #activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    style="position: absolute; top: 10px; right: 10px; cursor: pointer"
                  >
                    <v-icon
                      @click="generateCopy(syntax.code, 'function')"
                      size="18"
                    >
                      {{ icons.mdiContentCopy }}
                    </v-icon>
                  </span>
                </template>

                <span>Berhasil men-copy</span>
              </v-tooltip>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { computed, ref } from 'vue'
import { hyperFormula } from '@/utils/hyperFormula'
import {
  mdiCalendarRange,
  mdiText,
  mdiNumeric,
  mdiArrowDownDropCircleOutline,
  mdiFormatListCheckbox,
  mdiSigma,
  mdiCheckboxMarked,
  mdiAttachment,
  mdiPound,
  mdiAccount,
  mdiContentCopy,
} from '@mdi/js'

export default {
  props: {
    listItems: {
      type: Object,
      required: true,
    },

    searchString: {
      type: String,
      required: true,
    },

    selectedIndex: {
      type: Number,
      required: true,
    },

    seekIndex: {
      type: Number,
      required: true,
    },
  },

  setup(props, { emit }) {
    const selectedString = ref({})
    const searchFormulas = computed({
      get() {
        const functions = props.listItems.functions.filter(e => {
          return e.name.toLowerCase().includes(props.searchString.toLowerCase())
        })

        const properties = props.listItems.properties
          .filter(e => {
            return e.name.toLowerCase().includes(props.searchString.toLowerCase())
          })
          .map(e => {
            let example = {}
            let name = ''
            let id = ''
            let copy = []

            if (e.type.startsWith('static')) {
              name = `▹${e.name}◃`
            } else {
              name = `▸${e.name}◂`
            }

            if (e.type.startsWith('static')) {
              id = `▹${e.name}◃`
              copy = { type: 'staticAttribute', text: name }
            } else {
              id = `▸${e.id}◂`
              copy = { type: 'customAttribute', text: name, id: id }
            }

            if (e.typeData === 1 || e.typeData === 5 || e.typeData === 4 || e.typeData === 7) {
              example = {
                description: 'Properti Bertipe Text',
                title: `${e.name}`,
                syntax: [
                  {
                    preview: `LEN(<span class="mt-0 v-chip v-size--small secondary"><span class="v-chip__content">${name}</span></span>)`,
                    code: `LEN("${e.value}")`,
                    copy: [{ type: 'text', text: 'LEN(' }, copy, { type: 'text', text: ')' }],
                  },
                  {
                    preview: `CONCATENATE(<span class="mt-0 v-chip v-size--small secondary"><span class="v-chip__content">${name}</span></span>,<span class="mt-0 v-chip v-size--small secondary"><span class="v-chip__content">${name}</span></span>)`,
                    code: `CONCATENATE("${e.value}","${e.value}")`,
                    copy: [
                      { type: 'text', text: 'CONCATENATE(' },
                      copy,
                      { type: 'text', text: ',' },
                      copy,
                      { type: 'text', text: ')' },
                    ],
                  },
                ],
              }
            }

            if (e.typeData === 2) {
              example = {
                description: 'Properti Bertipe Number',
                title: `${e.name}`,
                syntax: [
                  {
                    preview: `<span class="mt-0 v-chip v-size--small secondary"><span class="v-chip__content">${name}</span></span> + <span class="mt-0 v-chip v-size--small secondary"><span class="v-chip__content">${name}</span></span>`,
                    code: `${e.value} + ${e.value}`,
                    copy: [copy, { type: 'text', text: ' + ' }, copy],
                  },
                  {
                    preview: `SUM(<span class="mt-0 v-chip v-size--small secondary"><span class="v-chip__content">${name}</span></span>,<span class="mt-0 v-chip v-size--small secondary"><span class="v-chip__content">${name}</span></span>)`,
                    code: `SUM(${e.value},${e.value})`,
                    copy: [
                      { type: 'text', text: 'SUM(' },
                      copy,
                      { type: 'text', text: ',' },
                      copy,
                      { type: 'text', text: ')' },
                    ],
                  },
                ],
              }
            }

            if (e.typeData === 3) {
              example = {
                description: 'Properti Bertipe Date',
                title: `${e.name}`,
                syntax: [
                  {
                    preview: `YEAR(<span class="mt-0 v-chip v-size--small secondary"><span class="v-chip__content">${name}</span></span>)`,
                    code: `YEAR("${e.value ? e.value.split('T')[0] : 'NOW()'}")`,
                    copy: [{ type: 'text', text: 'YEAR(' }, copy, { type: 'text', text: ')' }],
                  },
                ],
              }
            }

            if (e.typeData === 6) {
              example = {
                description: 'Properti Bertipe Boolean',
                title: `${e.name}`,
                syntax: [
                  {
                    preview: `IF(<span class="mt-0 v-chip v-size--small secondary"><span class="v-chip__content">${name}</span></span>, "Ini Case True", "Ini Case False")`,
                    code: `IF("${e.value}", "Ini Case True", "Ini Case False")`,
                    copy: [
                      { type: 'text', text: 'IF(' },
                      copy,
                      { type: 'text', text: ', "Ini Case True", "Ini Case False")' },
                    ],
                  },
                  {
                    preview: `NOT(<span class="mt-0 v-chip v-size--small secondary"><span class="v-chip__content">${name}</span></span>)`,
                    code: `NOT("${e.value}")`,
                    copy: [{ type: 'text', text: 'NOT(' }, copy, { type: 'text', text: ')' }],
                  },
                ],
              }
            }

            return {
              ...e,
              example,
            }
          })

        const builder = {
          functions,
          properties,
          builtIns: props.listItems.builtIns,
          fullValue: [...properties, ...functions, ...props.listItems.builtIns],
        }

        emit('update-length-list', builder.fullValue)

        return builder
      },
    })

    const selectedData = computed(() => {
      const temp = searchFormulas.value.fullValue[props.seekIndex - 1]
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      selectedString.value = temp
      const el = document.getElementById(`${temp.name}-${props.seekIndex - 1}`)

      if (el) {
        el.scrollIntoViewIfNeeded({ behavior: 'smooth' })
      }

      return temp
    })

    const calculateFormula = s => hyperFormula.calculateFormula(s, 0)

    const generateCopy = (code, type = 'default') => {
      if (type === 'function') {
        navigator.clipboard.writeText(JSON.stringify([{ type: 'text', text: `${code}` }]))
      } else {
        navigator.clipboard.writeText(JSON.stringify(code))
      }
    }

    return {
      selectedData,
      selectedString,
      searchFormulas,
      calculateFormula,
      generateCopy,
      icons: {
        mdiCalendarRange,
        mdiText,
        mdiNumeric,
        mdiArrowDownDropCircleOutline,
        mdiFormatListCheckbox,
        mdiSigma,
        mdiCheckboxMarked,
        mdiAttachment,
        mdiPound,
        mdiAccount,
        mdiContentCopy,
      },
    }
  },
}
</script>
