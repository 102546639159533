import gql from 'graphql-tag'

const commentFiles = gql`
  query commentFiles($pagination: pagination!, $filter: paramQueryCommentFiles) {
    commentFiles(pagination: $pagination, filter: $filter) {
      id
      fileType
      file_name
      file_path
      created_at
    }
  }
`

export default commentFiles
