import gql from 'graphql-tag'

const verifyAccount = gql`
  mutation verifyAccount(
    $email_verification_code: String
    $phone_verification_code: String
    $password: String!
    $confirm_password: String!
  ) {
    verifyAccount(
      email_verification_code: $email_verification_code
      phone_verification_code: $phone_verification_code
      password: $password
      confirm_password: $confirm_password
    ) {
      msg
      status
      data
    }
  }
`

export default verifyAccount
