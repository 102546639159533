import gql from 'graphql-tag'

const confirmPassword = gql`
  mutation confirmPassword($password: String!) {
    confirmPassword(password: $password) {
      msg
      status
      data
    }
  }
`

export default confirmPassword
