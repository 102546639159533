import gql from 'graphql-tag'

const customers = gql`
  query (
    $pagination: pagination!
    $search: String
    $workspace_id: Float!
    $filter: filterCustomer
  ) {
    customers(
      pagination: $pagination
      search: $search
      workspace_id: $workspace_id
      filter: $filter
    ) {
      count
      customers {
        id
        name
        gender
        description
        address
        job_title
        birth_date
        custom_attribute_values
        phone_number
        whatsapp_number
        email
        instagram
        facebook
        type {
          id
          name
          color
        }
        created_at
        updated_at
        deleted_at
      }
    }
  }
`

export default customers
