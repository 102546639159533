import gql from 'graphql-tag'

export const archiveJobType = gql`
  mutation archiveJobType($job_type_id: Float!, $workspace_id: Float!) {
    archiveJobType(job_type_id: $job_type_id, workspace_id: $workspace_id) {
      status
      msg
      data
    }
  }
`
