import store from '@/store'
import { apolloClient } from '@/vue-apollo'
import {
  addFolderPermission as addFolderPermissionMutation,
  addJobTypePermission as addJobTypePermissionMutation,
} from '@/graphql/mutations'
import { addDocumentPermission as addDocumentPermissionMutation } from '@/graphql/mutation/document'
import errorHandling from '@/utils/errorHandling'

const usePermission = () => {
  const folderPermissionList = [
    {
      id: 1,
      value: 'Admin',
      label: 'Admin',
    },
    {
      id: 2,
      value: 'Editor',
      label: 'Editor',
    },
    {
      id: 3,
      value: 'CommentOnly',
      label: 'Comment Only',
    },
    {
      id: 4,
      value: 'ViewOnly',
      label: 'View Only',
    },
  ]
  const jobTypePermissionList = [
    {
      id: 1,
      value: 'Admin',
      label: 'Admin',
    },
    {
      id: 2,
      value: 'Editor',
      label: 'Editor',
    },
    {
      id: 3,
      value: 'CommentOnly',
      label: 'Comment Only',
    },
    {
      id: 4,
      value: 'ViewOnly',
      label: 'View Only',
    },
  ]
  const documentPermissionList = [
    {
      id: 1,
      value: 'MAINTAINER',
      label: 'Maintainer',
    },
    {
      id: 2,
      value: 'CONTRIBUTOR',
      label: 'Contributor',
    },
    {
      id: 3,
      value: 'COMMENTOR',
      label: 'Commentor',
    },
    {
      id: 4,
      value: 'VIEWER',
      label: 'Viewer',
    },
  ]
  const teamPermissionList = [
    {
      id: 1,
      value: 'ADMIN',
      label: 'Admin',
    },
    {
      id: 2,
      value: 'MEMBER',
      label: 'Member',
    },
    {
      id: 3,
      value: 'VIEWER',
      label: 'Viewer',
    },
  ]

  const addFolderPermission = async ({ id, userPermissions, onSuccess = () => {} }) => {
    await apolloClient
      .mutate({
        mutation: addFolderPermissionMutation,
        variables: {
          folder_id: id,
          user_permissions: userPermissions,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      })
      .then(({ data }) => {
        onSuccess(data.addFolderPermission)
      })
      .catch(err => {
        errorHandling(err)
      })
  }
  const addJobTypePermission = async ({ id, userPermissions, onSuccess = () => {} }) => {
    await apolloClient
      .mutate({
        mutation: addJobTypePermissionMutation,
        variables: {
          job_type_id: id,
          user_permissions: userPermissions,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      })
      .then(({ data }) => {
        onSuccess(data.addJobTypePermission)
      })
      .catch(err => {
        errorHandling(err)
      })
  }
  const addDocumentPermission = async ({ id, userPermissions, onSuccess = () => {} }) => {
    await apolloClient
      .mutate({
        mutation: addDocumentPermissionMutation,
        variables: {
          document_uuid: id,
          user_permissions: userPermissions,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      })
      .then(({ data }) => {
        onSuccess(data.addDocumentPermission)
      })
      .catch(err => {
        errorHandling(err)
      })
  }

  return {
    teamPermissionList,
    folderPermissionList,
    jobTypePermissionList,
    documentPermissionList,

    addFolderPermission,
    addJobTypePermission,
    addDocumentPermission,
  }
}

export default usePermission
