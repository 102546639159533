import gql from 'graphql-tag'

export const snoozeUnsnoozeNotification = gql`
  mutation SnoozeUnsnoozeNotification(
    $objectType: String!
    $objectId: String!
    $workspaceId: Float!
    $snoozedAt: DateTime
  ) {
    snoozeUnsnoozeNotification(
      object_type: $objectType
      object_id: $objectId
      workspace_id: $workspaceId
      snoozed_at: $snoozedAt
    ) {
      status
      msg
      data
    }
  }
`
