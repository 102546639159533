import gql from 'graphql-tag'

export const updateJobPriorityUrutan = gql`
  mutation updateJobPriorityUrutan(
    $params: [paramUpdateUrutan!]!
    $workspace_id: Float!
    $job_type_id: Float!
  ) {
    updateJobPriorityUrutan(
      params: $params
      workspace_id: $workspace_id
      job_type_id: $job_type_id
    ) {
      status
      msg
      data
    }
  }
`
