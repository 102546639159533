import gql from 'graphql-tag'

const updateProfileInfo = gql`
  mutation updateProfileInfo($first_name: String!, $last_name: String!, $photo: Upload) {
    updateProfileInfo(first_name: $first_name, last_name: $last_name, photo: $photo) {
      status
      msg
      data
    }
  }
`

export default updateProfileInfo
