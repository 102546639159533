import gql from 'graphql-tag'

const restoreTeam = gql`
  mutation restoreTeam($team_id: Float!, $workspace_id: Float!) {
    restoreTeam(team_id: $team_id, workspace_id: $workspace_id) {
      status
      msg
      data
    }
  }
`

export default restoreTeam
