import gql from 'graphql-tag'

const getTrelloLabels = gql`
  query getTrelloLabels($token: String!, $board_id: String!) {
    getTrelloLabels(token: $token, board_id: $board_id) {
      id
      name
      color
    }
  }
`

export default getTrelloLabels
