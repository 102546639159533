import gql from 'graphql-tag'

const updateWorkspace = gql`
  mutation updateWorkspace(
    $name: String!
    $identifier_id: String!
    $photo: Upload
    $workspace_id: Float!
  ) {
    updateWorkspace(
      name: $name
      identifier_id: $identifier_id
      photo: $photo
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`
export default updateWorkspace
