import gql from 'graphql-tag'

const updateLookup = gql`
  mutation updateLookup(
    $id: Float!
    $name: String
    $job_type_id: Float!
    $function_name: String
    $workspace_id: Float!
  ) {
    updateLookup(
      id: $id
      name: $name
      job_type_id: $job_type_id
      function_name: $function_name
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`

export default updateLookup
