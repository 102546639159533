import gql from 'graphql-tag'

const documentList = gql`
  query documents(
    $filter_search_children: Boolean!
    $sort: [docSort!]
    $filter: [docFilter!]
    $search: String
    $workspace_id: Float!
    $get_root_only: Boolean!
  ) {
    documents(
      filter_search_children: $filter_search_children
      sort: $sort
      search: $search
      filter: $filter
      workspace_id: $workspace_id
      get_root_only: $get_root_only
    ) {
      uuid
      name
      content
      archived_at
      created_at
      folder {
        id
        name
        color
        is_public
        folder_permissions {
          permission_type
          user {
            id
            name
          }
        }
      }
      created_by {
        id
        name
        photo
      }
      last_edited_at
      last_edited_by {
        id
        name
      }
      last_view {
        user {
          id
        }
        last_viewed_at
      }
      is_locked
      is_public
      document_tag_reference {
        document_tag {
          id
          name
          color
        }
      }
      contributor {
        user {
          id
          name
          photo
        }
      }
      document_permissions {
        id
        user {
          id
          name
        }
        document {
          uuid
        }
        permission_type
      }
      sub_doc_count
      comment_assigned_count
      comment_assigned_me_count
      lexorank
    }
  }
`

export default documentList
