import gql from 'graphql-tag'

const getAccurateExpenses = gql`
  query getAccurateExpenses(
    $token_id: Float!
    $db_id: Float!
    $pagination: pagination!
    $search: String!
  ) {
    getAccurateExpenses(
      token_id: $token_id
      db_id: $db_id
      pagination: $pagination
      search: $search
    ) {
      id
      number
      transDate
      dueDate
      amount
      paidAmount
      statusName
    }
  }
`

export default getAccurateExpenses
