import gql from 'graphql-tag'

const commentSearch = gql`
  query commentSearch(
    $search: String!
    $job_id: Float
    $attachment_id: String
    $workspace_id: Float!
  ) {
    commentSearch(
      search: $search
      job_id: $job_id
      attachment_id: $attachment_id
      workspace_id: $workspace_id
    ) {
      count
      comments {
        id
      }
    }
  }
`

export default commentSearch
