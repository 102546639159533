import gql from 'graphql-tag'

const subscriptionTypeList = gql`
  query {
    SubscriptionTypeList {
      id
      name
      price_month
      price_year
      limit_workspace
      limit_member
      max_automation_usage
    }
  }
`

export default subscriptionTypeList
