import gql from 'graphql-tag'

const getDriveFileList = gql`
  query getDriveFileList(
    $refresh_token: String!
    $search: String
    $page_token: String
    $parent_id: String
    $page_size: Float!
  ) {
    getDriveFileList(
      refresh_token: $refresh_token
      search: $search
      page_token: $page_token
      parent_id: $parent_id
      page_size: $page_size
    ) {
      incompleteSearch
      kind
      nextPageToken
      files {
        kind
        id
        parents
        name
        iconLink
      }
    }
  }
`

export default getDriveFileList
