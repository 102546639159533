import gql from 'graphql-tag'

const onOffInviteWorkspace = gql`
  mutation ($open_invite: Boolean!, $workspace_id: Float!) {
    onOffInviteWorkspace(open_invite: $open_invite, workspace_id: $workspace_id) {
      status
      msg
      data
    }
  }
`

export default onOffInviteWorkspace
