import gql from 'graphql-tag'

const createLookup = gql`
  mutation createLookup(
    $name: String!
    $job_type_id: Float!
    $workspace_id: Float!
    $attribute_key: String!
    $function_name: String!
    $relation_type_id: Float!
  ) {
    createLookup(
      name: $name
      job_type_id: $job_type_id
      workspace_id: $workspace_id
      attribute_key: $attribute_key
      function_name: $function_name
      relation_type_id: $relation_type_id
    ) {
      status
      msg
      data
    }
  }
`

export default createLookup
