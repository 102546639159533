import { gql } from 'graphql-tag'

export const updateCommentLexorank = gql`
  mutation UpdateCommentlexorank($id: Float!, $workspace_id: Float!, $lexorank: String) {
    updateCommentLexorank(id: $id, workspace_id: $workspace_id, lexorank: $lexorank) {
      status
      msg
      data
    }
  }
`
