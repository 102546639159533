import gql from 'graphql-tag'

const createWorkspace = gql`
  mutation ($name: String!, $identifier_id: String!, $photo: Upload) {
    createWorkspace(name: $name, identifier_id: $identifier_id, photo: $photo) {
      status
      msg
      data
    }
  }
`

export default createWorkspace
