import gql from 'graphql-tag'

const documentDetail = gql`
  query documentDetail($document_uuid: String!, $workspaceId: Float!) {
    documentDetail(document_uuid: $document_uuid, workspace_id: $workspaceId) {
      uuid
      name
      content
      yjs_doc
      is_locked
      is_public
      archived_at
      created_at
      created_by {
        id
        name
        photo
      }
      last_edited_at
      last_edited_by {
        id
        name
      }
      children {
        uuid
        name
        content
      }
      folder {
        id
        name
        color
      }
      document_tag_reference {
        document_tag {
          id
          name
          color
        }
      }
      contributor {
        user {
          id
          name
          photo
        }
      }
      document_permissions {
        id
        user {
          id
          name
        }
        document {
          uuid
        }
        permission_type
      }
    }
  }
`

export default documentDetail
