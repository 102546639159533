import { addJob, editJob, updateJob } from '@/graphql/mutations'
import { jobDetail } from '@/graphql/queries'
import { apolloClient } from '@/vue-apollo'

export const getJobDetail = async ({ jobId }) => {
  const { data } = await apolloClient.query({
    query: jobDetail,
    fetchPolicy: 'no-cache',
    variables: {
      job_id: +jobId,
    },
  })

  return data.jobDetail
}

export const performAddJob = async ({
  name,
  description,
  jobTypeId,
  jobStatusId,
  jobPriorityId,
  assignUserId,
  startDate,
  expectedCloseDate,
  customAttribute,
} = {}) => {
  const { data } = await apolloClient.mutate({
    mutation: addJob,
    variables: {
      name,
      description,
      job_type_id: jobTypeId,
      job_status_id: jobStatusId,
      job_priority_id: jobPriorityId,
      assign_user_id: assignUserId,
      start_date: startDate,
      expected_close_date: expectedCloseDate,
      custom_attribute: customAttribute,
    },
  })

  return data.addJob
}

export const performUpdateJob = async ({
  id,
  name,
  description,
  jobTypeId,
  jobStatusId,
  jobPriorityId,
  startDate,
  expectedCloseDate,
  customAttribute,
} = {}) => {
  const { data } = await apolloClient.mutate({
    mutation: updateJob,
    variables: {
      id,
      name,
      description,
      job_type_id: jobTypeId,
      job_status_id: jobStatusId,
      job_priority_id: jobPriorityId,
      start_date: startDate,
      expected_close_date: expectedCloseDate,
      custom_attribute: customAttribute,
    },
  })

  return data.updateJob
}

export const performEditJob = async ({
  id,
  name,
  description,
  jobStatusId,
  jobPriorityId,
  startDate,
  expectedCloseDate,
} = {}) => {
  const { data } = await apolloClient.mutate({
    mutation: editJob,
    variables: {
      id,
      name,
      description,
      start_date: startDate,
      job_status_id: jobStatusId,
      job_priority_id: jobPriorityId,
      expected_close_date: expectedCloseDate,
    },
  })

  return data.editJob
}
