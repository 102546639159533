import gql from 'graphql-tag'

export const updateDocumentAttribute = gql`
  mutation updateDocumentAttribute(
    $documentUuid: String!
    $name: String!
    $isLocked: Boolean!
    $workspaceId: Float!
  ) {
    updateDocumentAttribute(
      document_uuid: $documentUuid
      name: $name
      is_locked: $isLocked
      workspace_id: $workspaceId
    ) {
      status
      msg
      data
    }
  }
`
