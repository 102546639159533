<template>
  <swiper
    v-if="$vuetify.breakpoint.smAndUp"
    class="swiper-autoplay w-full"
    :options="swiperOption"
    style="max-height: 34px !important"
  >
    <swiper-slide>
      <div class="d-flex align-center justify-center w-full">
        <v-chip color="white">
          <span class="primary--text font-weight-bold">{{ $route.meta.pageTitle }}</span>
        </v-chip>
      </div>
    </swiper-slide>
    <swiper-slide
      v-for="(list, index) in lists"
      :key="index"
    >
      <div class="d-flex align-center justify-center w-full">
        <v-chip
          color="white"
          :close="list.closable"
          :close-icon="icons.mdiCloseCircle"
          class="chip-content"
          @click:close="closeFlash(index)"
        >
          <marquee-text
            v-if="list.text.length > 48"
            :duration="list.text.length / 10"
            :repeat="2"
          >
            <span
              class="font-weight-bold primary--text px-4 text-center"
              :class="list.variant"
              >{{ list.text }}</span
            >
          </marquee-text>
          <span
            v-else
            class="font-weight-bold primary--text px-4 text-center"
            :class="list.variant"
            >{{ list.text }}</span
          >
        </v-chip>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
import Vue from 'vue'
import { ref } from 'vue'
import { mdiCloseCircle } from '@mdi/js'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import MarqueeText from 'vue-marquee-text-component'
import 'swiper/css/swiper.css'

export default {
  components: {
    Swiper,
    SwiperSlide,
    MarqueeText,
  },
  setup() {
    const swiperOption = {
      speed: 800,
      direction: 'vertical',
      slidesPerView: 1,
      mousewheel: true,
      spaceBetween: 30,
      autoplay: {
        delay: 8000,
        disableOnInteraction: false,
      },
      loop: false,
      centeredSlides: true,
    }

    const lists = ref([
      {
        text: 'Selamat datang di Oriens CRM!',
        variant: '',
        closable: true,
      },
    ])
    const flash = data => {
      lists.value.push(data)
    }
    Vue.$flash = flash

    const closeFlash = index => {
      lists.value.splice(index, 1)
    }

    return {
      swiperOption,
      lists,
      flash,
      closeFlash,

      icons: {
        mdiCloseCircle,
      },
    }
  },
}
</script>

<style>
.v-chip:not(.v-chip--no-color) .v-icon {
  color: #333 !important;
}

.chip-content {
  width: auto;
  align-self: flex-start;
  max-width: 50vw;
}
</style>
