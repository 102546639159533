import gql from 'graphql-tag'

const getWorksheetHeader = gql`
  query getWorksheetHeader(
    $refresh_token: String!
    $spreadsheet_id: String!
    $worksheet_title: String!
  ) {
    getWorksheetHeader(
      refresh_token: $refresh_token
      spreadsheet_id: $spreadsheet_id
      worksheet_title: $worksheet_title
    ) {
      range
      majorDimension
      values
    }
  }
`

export default getWorksheetHeader
