import gql from 'graphql-tag'

export const markAllAsReadNotification = gql`
  mutation MarkAllAsReadNotification($workspaceId: Float!) {
    markAllAsReadNotification(workspace_id: $workspaceId) {
      status
      msg
      data
    }
  }
`
