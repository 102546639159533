<template>
  <div>
    <!-- Header -->
    <div
      v-if="name"
      class="grid-title-header pa-3"
    >
      <h3>Attachment untuk {{ name }}</h3>
    </div>

    <!-- Toolbar -->
    <div class="d-flex justify-space-between grid-toolbar subtitle-1">
      <!-- Left Side -->
      <div>
        <v-btn
          tile
          text
          class="px-3 font-weight-regular"
          @click="handleToolbarClick('edit')"
        >
          <v-icon left> {{ icons.mdiPencil }} </v-icon>
          Rename
        </v-btn>
        <v-btn
          v-show="editMode"
          tile
          text
          class="px-3 font-weight-regular"
          @click="handleToolbarClick('save')"
        >
          <v-icon left> {{ icons.mdiContentSaveAll }} </v-icon>
          Simpan
        </v-btn>
      </div>

      <!-- Right Side -->
      <div>
        <v-menu
          v-model="uploadMenu"
          :close-on-content-click="false"
          left
        >
          <template #activator="{ attrs, on }">
            <v-btn
              v-bind="attrs"
              tile
              text
              class="pe-6 px-3 font-weight-regular"
              v-on="on"
              @click.stop
            >
              Upload File
              <v-icon right> {{ icons.mdiUpload }} </v-icon>
            </v-btn>
          </template>
          <file-upload
            :meta="{
              workspace_id: $store.getters.getCurrentWorkspaceId,
              job_type_id: jobTypeId,
              job_id: jobId,
            }"
            @uploaded="handleFileUploaded"
          />
        </v-menu>
      </div>
    </div>

    <!-- Grid -->
    <ejs-grid
      v-if="attachmentList"
      id="grid"
      ref="grid"
      :data-source="attachmentList"
      :edit-settings="editSettings"
      :filter-settings="filterSettings"
      :selection-settings="selectionSettings"
      :allow-selection="allowSelection"
      :allow-keyboard="false"
      :allow-sorting="true"
      :allow-resizing="true"
      :allow-filtering="true"
      :show-column-chooser="true"
      :data-bound="dataBound"
      :header-cell-info="multiple ? null : handleHeaderCellInfo"
      :action-begin="handleActionBegin"
      :row-selecting="handleRowSelection"
      :context-menu-open="handleContextMenuOpen"
      :context-menu-items="contextMenuItems"
      :height="height"
      :width="width"
    >
      <e-columns>
        <e-column
          field="id"
          :is-primary-key="true"
          :visible="false"
        />
        <e-column
          v-if="allowSelection"
          type="checkbox"
          width="50"
        />
        <e-column
          field="file_name"
          header-text="Nama"
          width="200"
          text-align="Left"
          :template="'nameTemplate'"
        >
          <template #nameTemplate="{ data }">
            <span
              v-if="!editMode"
              class="font-medium primary--text"
            >
              <v-btn
                icon
                @click="$router.push({ name: 'attachment-detail', params: { id: data.id } })"
              >
                <v-img
                  :src="thumbnailURL(data)"
                  width="40"
                  height="40"
                  class="rounded elevation-1"
                  aspect-ratio="800/800"
                />
              </v-btn>
              <span
                style="cursor: pointer"
                class="ms-3"
                @click="$router.push({ name: 'attachment-detail', params: { id: data.id } })"
                >{{ data.file_name }}</span
              >
            </span>
            <span v-else>
              <v-text-field
                dense
                outlined
                hide-details
                placeholder="Nama File"
                :value="data.file_name.split('.').slice(0, -1).join('.')"
                @change="rename($event, data.id)"
              />
            </span>
          </template>
        </e-column>
        <e-column
          field="file_type"
          header-text="Tipe File"
          width="130"
          text-align="Left"
          :filter="{ type: 'CheckBox' }"
        />
        <e-column
          field="created_at"
          header-text="Dibuat pada"
          width="220"
          text-align="Left"
          type="dateTime"
          :format="{ type: 'dateTime', format: 'dd MMMM yyyy · hh:mm' }"
        />
        <e-column
          field="modify_at"
          header-text="Diubah pada"
          width="220"
          text-align="Left"
          type="dateTime"
          :format="{ type: 'dateTime', format: 'dd MMMM yyyy · hh:mm' }"
        />
        <e-column
          field="user.name"
          header-text="Dibuat Oleh"
          width="150"
          :filter="{ type: 'CheckBox' }"
          text-align="Left"
          :template="'createdByTemplate'"
        >
          <template #createdByTemplate="{ data }">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-avatar
                  v-bind="attrs"
                  size="36"
                  class="primary"
                  style="cursor: pointer"
                  v-on="on"
                  @click="$router.push({ name: 'user-detail', params: { id: data.user.id } })"
                >
                  <v-img
                    v-if="data.user.photo"
                    :src="data.user.photo"
                  />
                  <span
                    v-else
                    class="white--text"
                  >
                    {{ avatarText(`${data.user.name}`) }}
                  </span>
                </v-avatar>
              </template>
              <span>{{ data.user.name }}</span>
            </v-tooltip>
          </template>
        </e-column>
      </e-columns>
    </ejs-grid>
    <v-sheet
      v-else
      :height="height"
      :color="`grey darken-2`"
      class="pa-3"
    >
      <v-skeleton-loader type="table-heading, list-item-two-line, list-item, table-tfoot" />
    </v-sheet>

    <!-- Footer -->
    <div
      v-if="allowSelection"
      class="d-flex justify-space-between grid-toolbar subtitle-1"
    >
      <template>
        <v-btn
          color="primary"
          small
          class="px-3 mx-3 my-2 ms-auto"
          @click="
            handleSubmitSelect($refs.grid.getSelectedRecords(), $refs.grid.getSelectedRowIndexes())
          "
        >
          Pilih
        </v-btn>
      </template>
    </div>

    <!-- Context Menu -->
    <v-menu
      v-if="selectedRow"
      v-model="menu"
      :position-x="menuX"
      :position-y="menuY"
      :close-on-content-click="false"
      absolute
      offset-y
    >
      <v-list dense>
        <v-list-item
          @click="$router.push({ name: 'attachment-detail', params: { id: selectedRow.id } })"
        >
          <v-list-item-title>View</v-list-item-title>
        </v-list-item>
        <v-divider class="my-1" />
        <v-list-item @click="downloadAttachment(selectedRow)">
          <v-list-item-title>Download</v-list-item-title>
        </v-list-item>
        <v-list-item @click="setCover(selectedRow.id)">
          <v-list-item-title>Set Cover</v-list-item-title>
        </v-list-item>
        <v-divider class="my-1" />
        <v-list-item
          v-if="$can('del', 'Job')"
          class="error--text"
          @click="handleDeleteAttachment"
        >
          <v-list-item-title>Hapus</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <attachment-delete-confirmation
      ref="attachmentDeleteConfirmation"
      @confirm="confirmDeleteAttachment"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { ref, onMounted } from 'vue'
import {
  Sort,
  Edit,
  Resize,
  Filter,
  ContextMenu,
  ColumnChooser,
  ForeignKey,
} from '@syncfusion/ej2-vue-grids'
import { mdiChevronDown, mdiPencil, mdiUpload, mdiContentSaveAll } from '@mdi/js'
import FileUpload from '@/components/inputs/FileUpload.vue'
import useAttachment from '@/composables/useAttachment'
import { avatarText } from '@core/utils/filter'
import AttachmentDeleteConfirmation from './AttachmentDeleteConfirmation.vue'

export default {
  components: {
    FileUpload,
    AttachmentDeleteConfirmation,
  },
  provide: {
    grid: [Sort, Resize, Edit, ColumnChooser, ContextMenu, Filter, ForeignKey],
  },
  props: {
    selectedAttachments: {
      type: Array,
      default: () => [],
    },
    jobId: {
      type: Number,
      required: true,
    },
    jobTypeId: {
      type: Number,
      required: true,
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: 'auto',
    },
    allowSelection: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, { emit }) {
    const grid = ref()
    const uploadMenu = ref(false)
    const menu = ref(false)
    const menuX = ref(0)
    const menuY = ref(0)
    const selectedRow = ref(null)
    const editMode = ref(false)
    const editData = ref([])
    const attachmentDeleteConfirmation = ref()

    const {
      attachmentList,
      baseAttachmentUrl,
      loadingAttachments,

      fetchDeleteAttachments,
      batchRenameAttachment,
      fetchAttachments,
      deleteAttachment,
      setCover,
    } = useAttachment({ jobTypeId: props.jobTypeId })

    const editSettings = ref({
      allowEditing: true,
      allowDeleting: true,
      mode: 'Normal',
    })
    const filterSettings = ref({
      type: 'Menu',
    })
    const selectionSettings = ref({
      type: props.multiple ? 'Multiple' : 'Single',
      persistSelection: true,
      checkboxOnly: true,
    })
    const contextMenuItems = ref([
      'AutoFit',
      'AutoFitAll',
      'Cancel',
      'FirstPage',
      'PrevPage',
      'LastPage',
      'NextPage',
    ])

    const toDataURL = async url => {
      const blob = await fetch(url).then(res => res.blob())

      return URL.createObjectURL(blob)
    }

    const rename = (name, id) => {
      const record = editData.value.find(el => el.id === id)
      record.file_name = name
      record.edited = true
    }

    const downloadAttachment = async data => {
      const a = document.createElement('a')

      if (data.file_type !== 'image') {
        a.href = `${baseAttachmentUrl}/${props.jobTypeId}/${data.id}/${data.file_name}`
        a.target = '_blank'
      } else {
        a.href = await toDataURL(
          `${baseAttachmentUrl}/${props.jobTypeId}/${data.id}/${data.file_name}`,
        )
      }

      a.download = data.file_name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }

    const flag = ref(true)
    const dataBound = () => {
      if (props.allowSelection && flag.value) {
        const selectedIds = props.selectedAttachments.map(el => el.id)

        grid.value.selectRows(
          attachmentList.value
            .map((el, index) => {
              if (selectedIds.includes(el.id)) {
                return index
              }

              return false
            })
            .filter(el => el || el === 0),
        )

        flag.value = false
      }
    }

    const handleContextMenuOpen = args => {
      /* eslint-disable no-param-reassign */
      if (grid.value.getContentTable().contains(args.event.target)) {
        args.cancel = true
        menuX.value = args.left
        menuY.value = args.top - window.scrollY
        selectedRow.value = args.rowInfo.rowData
        menu.value = true
      }
      /* eslint-enable no-param-reassign */
    }

    const handleToolbarClick = action => {
      if (action === 'edit') {
        editData.value = attachmentList.value.map(el => ({
          id: el.id,
          file_name: el.file_name,
          edited: false,
        }))
        editMode.value = !editMode.value
      } else if (action === 'save') {
        batchRenameAttachment(
          editData.value
            .filter(el => el.edited)
            .map(el => ({
              id: el.id,
              file_name: el.file_name,
            })),
        ).then(() => {
          flag.value = true
          fetchAttachments(props.jobId)

          editData.value = []
          editMode.value = false
        })
      }
    }

    const handleActionBegin = args => {
      /* eslint-disable no-param-reassign */
      if (args.requestType === 'beginEdit') {
        args.cancel = true
        emit('edit', args.rowData.id)
      }
      /* eslint-enable no-param-reassign */
    }

    const handleRowSelection = args => {
      if (!args.target && !flag.value) {
        // eslint-disable-next-line no-param-reassign
        args.cancel = true

        return
      }

      if (args.target && args.target.classList.contains('e-rowcell')) {
        // eslint-disable-next-line no-param-reassign
        args.cancel = true

        return
      }

      if (!props.multiple && grid.value.getSelectedRecords().length) {
        grid.value.clearSelection()
      }
    }

    const thumbnailURL = data => {
      if (data.file_type !== 'image') {
        return `/filetypes/${data.file_name.split('.').pop()}.png`
      }

      // eslint-disable-next-line camelcase
      const { id, file_name } = data
      const fullname = file_name.split('.')
      const extension = fullname.pop()
      const name = fullname.join('.')

      return `${baseAttachmentUrl}/${props.jobTypeId}/${id}/${name}_thumbnail.${extension}`
    }

    const confirmDeleteAttachment = () => {
      deleteAttachment(selectedRow.value.id).then(() => {
        flag.value = true
        fetchAttachments(props.jobId)
      })
    }

    const handleDeleteAttachment = () => {
      menu.value = false

      Vue.$dialog({
        title: 'Hapus attachment?',
        body: 'Konfirmasi jika anda ingin menghapus attachment ini.',
      }).then(confirm => {
        if (confirm) {
          fetchDeleteAttachments({ attachIds: [selectedRow.value.id] }).then(data => {
            if (data.length) {
              attachmentDeleteConfirmation.value.show(data)

              return
            }

            confirmDeleteAttachment()
          })
        }
      })
    }

    const handleFileUploaded = () => {
      flag.value = true
      fetchAttachments(props.jobId)
      uploadMenu.value = false
    }

    const handleHeaderCellInfo = args =>
      args.node.getElementsByClassName('e-checkbox-wrapper')[0] &&
      args.node.getElementsByClassName('e-checkbox-wrapper')[0].remove()

    const handleSubmitSelect = (data, indexes) => {
      emit(
        'select',
        data
          .map((el, index) => {
            const sortedIndex = indexes[index]

            return {
              ...el,
              sortedIndex,
            }
          })
          .sort((a, b) => a.sortedIndex - b.sortedIndex),
      )
    }

    onMounted(async () => {
      await fetchAttachments(props.jobId)
    })

    return {
      grid,
      menu,
      menuX,
      menuY,
      uploadMenu,
      editMode,
      selectedRow,
      editSettings,
      filterSettings,
      selectionSettings,
      contextMenuItems,
      attachmentList,
      loadingAttachments,
      attachmentDeleteConfirmation,

      avatarText,
      confirmDeleteAttachment,
      dataBound,
      downloadAttachment,
      fetchAttachments,
      handleActionBegin,
      handleContextMenuOpen,
      handleDeleteAttachment,
      handleFileUploaded,
      handleHeaderCellInfo,
      handleRowSelection,
      handleSubmitSelect,
      handleToolbarClick,
      rename,
      setCover,
      thumbnailURL,

      icons: {
        mdiUpload,
        mdiPencil,
        mdiChevronDown,
        mdiContentSaveAll,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/syncfusion/material/individual-scss/grids/grid.scss';
@import '~@/styles/syncfusion-overrides.scss';

.grid-title-header {
  background: #ffffff;

  .theme--dark & {
    background: #312d4b;
  }
}
</style>
