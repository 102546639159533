import gql from 'graphql-tag'

const downgradePlanSubscription = gql`
  mutation downgradePlanSubscription($subscription_id: Float!) {
    downgradePlanSubscription(subscription_id: $subscription_id) {
      status
      msg
      data
    }
  }
`

export default downgradePlanSubscription
