import gql from 'graphql-tag'

const getFolders = gql`
  query folders($workspace_id: Float!, $filter: ParamFolder!) {
    folders(workspace_id: $workspace_id, filter: $filter) {
      id
      name
      color
      created_at
      updated_at
      archived_at
      owner {
        id
        name
        photo
      }
      unfinished_job_count
      my_role
      is_public
      job_types {
        id
        name
      }
      documents {
        uuid
        name
      }
      folder_permissions {
        user {
          id
          name
        }
        permission_type
      }
    }
  }
`

export default getFolders
