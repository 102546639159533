import gql from 'graphql-tag'

const chatBotCommandDetail = gql`
  query chatBotCommandDetail($id: Float, $type_id: Float, $workspace_id: Float!) {
    chatBotCommandDetail(id: $id, type_id: $type_id, workspace_id: $workspace_id) {
      id
      name
      command
      description
      type {
        id
        name
      }
      respond
      token {
        id
        connection_name
      }
      platform {
        id
        name
        logo
      }
    }
  }
`

export default chatBotCommandDetail
