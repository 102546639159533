import { globalSearch } from '@/graphql/queries'
import errorHandling from '@/utils/errorHandling'
import { apolloClient } from '@/vue-apollo'
import { ref } from 'vue'

const useGlobalSearch = () => {
  const loadingGlobalSearch = ref(false)

  const fetchGlobalSearch = async (param, pagination) => {
    loadingGlobalSearch.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: globalSearch,
          variables: {
            ...param,
            pagination,
          },
          fetchPolicy: 'no-cache',
        })
        .then(({ data }) => {
          loadingGlobalSearch.value = false
          resolve(data.globalSearch)
        })
        .catch(err => {
          loadingGlobalSearch.value = false
          reject(err)
          errorHandling(err)
        })
    })
  }

  return {
    fetchGlobalSearch,
    loadingGlobalSearch,
  }
}

export default useGlobalSearch
