import gql from 'graphql-tag'

const getSpreadsheetData = gql`
  query getSpreadsheetData($refresh_token: String!, $spreadsheet_id: String!) {
    getSpreadsheetData(refresh_token: $refresh_token, spreadsheet_id: $spreadsheet_id) {
      spreadsheetId
      spreadsheetUrl
      sheets {
        properties {
          sheetId
          title
          index
          sheetType
        }
      }
    }
  }
`

export default getSpreadsheetData
