import gql from 'graphql-tag'

const updateCustomerTypes = gql`
  mutation ($id: Float!, $name: String!, $color: String!, $workspace_id: Float!) {
    updateCustomerTypes(id: $id, name: $name, color: $color, workspace_id: $workspace_id) {
      status
      data
      msg
    }
  }
`

export default updateCustomerTypes
