import { assignObject } from '@/graphql/mutations'
import { apolloClient } from '@/vue-apollo'

export const performAssignObject = async ({ jobId, assignUserId } = {}) => {
  const { data } = await apolloClient.mutate({
    mutation: assignObject,
    variables: {
      job_id: jobId,
      assign_user_id: assignUserId,
    },
  })

  return data.assignObject
}
