import gql from 'graphql-tag'

const confirmDeleteCustomAttributeOption = gql`
  mutation confirmDeleteCustomAttributeOption(
    $delete_id: [Float!]
    $object_type_id: Float!
    $workspace_id: Float!
    $job_type_id: Float
  ) {
    confirmDeleteCustomAttributeOption(
      delete_id: $delete_id
      object_type_id: $object_type_id
      workspace_id: $workspace_id
      job_type_id: $job_type_id
    ) {
      status
      msg
      data
    }
  }
`

export default confirmDeleteCustomAttributeOption
