import gql from 'graphql-tag'

export const updateTeamMember = gql`
  mutation updateTeamMember(
    $workspace_id: Float!
    $team_id: Float!
    $is_public: Boolean!
    $member: [UserTeamPermission!]!
  ) {
    updateTeamMember(
      workspace_id: $workspace_id
      team_id: $team_id
      is_public: $is_public
      member: $member
    ) {
      status
      msg
      data
    }
  }
`
