import gql from 'graphql-tag'

const workspaceList = gql`
  query workspaceList {
    workspaceList {
      workspace {
        id
        name
        identifier_id
        invite_code
        photo
        invite_code
        open_invite
        members {
          user {
            id
            name
            first_name
            last_name
            email
            photo
            phone
            user_status {
              id
              name
            }
          }
          role {
            id
            name
          }
        }
        working_hours
        out_of_working_hours_autoreply
        owner {
          id
        }
      }
      user {
        id
        first_name
        last_name
        email
        photo
      }
      role {
        id
        name
      }
    }
  }
`

export default workspaceList
