import {
  mdiViewDashboard,
  mdiFileDocumentMultiple,
  mdiAccount,
  mdiAccountGroup,
  mdiFolderSync,
  mdiClipboardFlow,
  mdiStore,
} from '@mdi/js'

const adminNavItems = [
  {
    action: 'read',
    resource: 'Navbar',
    title: 'Dashboard',
    icon: mdiViewDashboard,
    to: 'admin-dashboard',
  },
  {
    action: 'read',
    resource: 'Navbar',
    title: 'Users',
    icon: mdiAccount,
    to: 'admin-user',
  },
  {
    action: 'read',
    resource: 'Navbar',
    title: 'Laporan Workflow',
    icon: mdiClipboardFlow,
    to: 'admin-workflow',
  },
  {
    action: 'read',
    resource: 'Navbar',
    title: 'Integrasi Tokopedia',
    icon: mdiStore,
    to: 'admin-tokopedia',
  },
]

const userNavItems = [
  {
    action: 'read',
    resource: 'Navbar',
    title: 'Dashboard',
    icon: mdiViewDashboard,
    to: 'workspace-root',
  },
  {
    action: 'read',
    resource: 'Navbar',
    title: 'Dokumen',
    icon: mdiFileDocumentMultiple,
    to: 'documents',
  },

  // ? Notes: badge & badgeColor property only available on notification widget
  {
    action: 'read',
    widget: 'notification',
    title: 'Notifikasi',
    resource: 'Navbar',
    to: 'notifications',
    badge: true,
    badgeColor: 'error',
  },
  {
    subheader: 'Manajemen',
    action: 'read',
    resource: 'Navbar',
  },
  {
    widget: 'folder',
    action: 'read',
    resource: 'Navbar',
  },
  {
    action: 'read',
    resource: 'Navbar',
    title: 'Shared Job Type',
    icon: mdiFolderSync,
    to: 'shared-job-type',
  },
  {
    subheader: 'Ekstra',
    action: 'read',
    resource: 'Navbar',
  },
  {
    action: 'read',
    resource: 'Navbar',
    title: 'Workflow',
    icon: mdiClipboardFlow,
    to: 'workflows',
  },
  {
    subheader: 'Administrator',
    action: 'read',
    resource: 'Navbar',
  },
  {
    action: 'read',
    resource: 'Team',
    title: 'Tim',
    icon: mdiAccountGroup,
    to: 'teams',
  },
]

export { adminNavItems, userNavItems }
