import gql from 'graphql-tag'

const myRole = gql`
  query myRole($workspace_id: Float!) {
    myRole(workspace_id: $workspace_id) {
      id
      name
    }
  }
`
export default myRole
