import gql from 'graphql-tag'

const pinChannel = gql`
  mutation ($channel_id: Float!) {
    pinChannel(channel_id: $channel_id) {
      status
      msg
      data
    }
  }
`

export default pinChannel
