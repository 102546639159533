import gql from 'graphql-tag'

const savedViews = gql`
  query savedViews($workspace_id: Float!, $object_type_id: Float!, $job_type_id: Float) {
    savedViews(
      workspace_id: $workspace_id
      object_type_id: $object_type_id
      job_type_id: $job_type_id
    ) {
      id
      name
      created_by {
        id
        name
      }
      fields
      filter_state
      sort_state
      group_state
      default_mode
      is_default
      is_public
      is_view_locked
      is_autosave
      job_type {
        id
        name
      }
      object_type {
        id
        name
      }
      selected_view {
        saved_view {
          id
          name
        }
        user {
          id
          name
        }
      }
    }
  }
`

export default savedViews
