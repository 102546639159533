import store from '@/store'
import { apolloClient } from '@/vue-apollo'
import { getField, setField } from 'vuex-use-fields'
import { comments } from '@/graphql/queries'
import { COMMENT_TYPES, stateMapping } from '@/constants/comment'

const initPagination = () => ({ limit: 20, offset: 0 })

export default {
  namespaced: true,
  state: {
    jobCommentList: null,
    documentCommentList: null,
    attachmentCommentList: null,

    jobCommentCount: 0,
    documentCommentCount: 0,
    attachmentCommentCount: 0,

    jobCommentPagination: initPagination(),
    documentCommentPagination: initPagination(),
    attachmentCommentPagination: initPagination(),

    jobCommentLoading: false,
    documentCommentLoading: false,
    attachmentCommentLoading: false,
  },
  getters: {
    getField,
  },
  mutations: {
    setField,
  },
  actions: {
    resetComment({ state }, { type }) {
      const mapping = stateMapping[type]

      if (!mapping) return

      state[mapping.list] = null
      state[mapping.count] = 0
      state[mapping.loading] = false
      state[mapping.pagination] = initPagination()
    },
    async loadComments({ state }, { type, id, fetchMore = false, targetId }) {
      const mapping = stateMapping[type]

      if (!mapping) return

      state[mapping.loading] = true

      const { data } = await apolloClient.query({
        query: comments,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          pagination: state[mapping.pagination],
          ...(type === COMMENT_TYPES.JOB && { job_id: id }),
          ...(type === COMMENT_TYPES.DOCUMENT && { document_uuid: id }),
          ...(type === COMMENT_TYPES.ATTACHMENT && { attachment_id: id }),
          target_id: targetId,
          sort: {
            field: 'created_at',
            order: 'DESC',
          },
        },
      })

      if (fetchMore) state[mapping.list].push(...data.comments.comments)
      else state[mapping.list] = data.comments.comments

      state[mapping.count] = data.comments.count
      state[mapping.loading] = false
    },
  },
}
