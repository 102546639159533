import gql from 'graphql-tag'

export const updateCustomAttribute = gql`
  mutation updateCustomAttribute(
    $id: Float!
    $name: String!
    $options: [customAttributeOptions!]
    $delete_options: [Int!]
    $workspace_id: Float!
  ) {
    updateCustomAttribute(
      id: $id
      name: $name
      options: $options
      delete_options: $delete_options
      workspace_id: $workspace_id
    ) {
      msg
      status
      data
    }
  }
`
