import gql from 'graphql-tag'

export const toggleNotificationReference = gql`
  mutation toggleNotificationReference(
    $user_ids: [Float!]!
    $workspace_id: Float!
    $document_uuid: String
    $job_id: Float
  ) {
    toggleNotificationReference(
      user_ids: $user_ids
      workspace_id: $workspace_id
      document_uuid: $document_uuid
      job_id: $job_id
    ) {
      status
      msg
      data
    }
  }
`
