<template>
  <div>
    <v-form
      ref="folderForm"
      @submit.prevent="onSubmit"
    >
      <v-row
        class="mt-0"
        no-gutters
      >
        <v-col
          cols="12"
          md="4"
        >
          <div class="mb-1">
            <span class="text-caption">Hak akses</span>
          </div>
          <v-btn
            min-height="110"
            min-width="110"
            outlined
            class="me-4"
            :text="!formData.is_public"
            :color="!formData.is_public ? null : 'primary'"
            @click="setVisibility(true)"
          >
            <div class="d-flex flex-column align-center">
              <v-icon
                class="text--disabled mb-2"
                size="40"
              >
                {{ icons.mdiAccountGroup }}
              </v-icon>
              <span :class="!formData.is_public ? 'text--disabled' : ''">Publik</span>
            </div>
          </v-btn>
          <v-btn
            min-height="110"
            min-width="110"
            outlined
            :text="formData.is_public"
            :color="formData.is_public ? null : 'primary'"
            @click="setVisibility(false)"
          >
            <div class="d-flex flex-column align-center">
              <v-icon
                class="text--disabled mb-2"
                size="40"
              >
                {{ icons.mdiShieldLock }}
              </v-icon>
              <span :class="formData.is_public ? 'text--disabled' : ''">Kustom</span>
            </div>
          </v-btn>
        </v-col>

        <v-col
          v-if="!formData.is_public"
          cols="12"
          md="8"
        >
          <div class="mb-1">
            <span class="text-caption">User Permission</span>
          </div>

          <div :class="selectedUserIds.length ? 'autocomplete-field' : ''">
            <chip-search-input
              object-type="user"
              :selectedObjectIds="selectedUserIds"
              :objectList="userList"
              :width="400"
              :max-items="12"
              :search.sync="search"
              :selected-keys="selectedKeys"
              :outlined="!selectedUserIds.length"
              @input-search="search = $event"
              @remove="handleChipDelete"
              @clear="selectedKeys = []"
            />
          </div>
        </v-col>
      </v-row>

      <div
        v-if="updateMode"
        class="mt-4 mb-1"
      >
        <div class="mb-1">
          <span class="text-caption">Pemilik Folder</span>
        </div>
        <v-avatar
          size="30"
          class="mr-2 v-avatar-light-bg"
        >
          <v-img
            v-if="formData.owner.photo"
            :src="formData.owner.photo"
          />
          <span v-else>{{ avatarText(formData.owner.name) }}</span>
        </v-avatar>
        <span>{{ formData.owner.name }}</span>
      </div>

      <template v-if="!formData.is_public">
        <multi-tree-view
          v-if="hierarchyList.length"
          v-model="selectedUserIds"
          item-key="key"
          item-children="users"
          :items="hierarchyList"
          :search="search"
          selectable
          dense
          hoverable
          selection-type="leaf"
          return-object
          style="min-height: 40vh"
          class="text-subtitle-2 pt-3"
        >
          <template #prepend="{ item }">
            <v-checkbox
              v-model="selectedKeys"
              :value="item.key"
              :indeterminate="isIndeterminate(item)"
              :disabled="item.disabled"
              hide-details
              class="my-0 py-0 mx-auto ps-1"
              color="primary"
              dense
              :value-comparator="comparator"
              @change="handleSelect(item, $event)"
            />
          </template>

          <template #label="{ item }">
            <div
              v-if="!item.users"
              class="my-2"
            >
              <v-avatar
                size="30"
                class="mr-2 v-avatar-light-bg"
              >
                <v-img
                  v-if="item.photo"
                  :src="item.photo"
                />
                <span v-else>{{ avatarText(item.name) }}</span>
              </v-avatar>
              <span>{{ item.name }}</span>
            </div>
            <span v-else>{{ item.name }}</span>
          </template>

          <template #append="{ item }">
            <div v-if="isTeam(item.key) && !isJoinedTeam(item.status)">
              <v-btn
                v-if="item.is_public"
                x-small
                color="primary"
                @click="handleJoinTeam({ id: item.id, is_public: item.is_public })"
              >
                Join
              </v-btn>
              <v-btn
                v-else
                x-small
                color="primary"
                @click="handleJoinTeam({ id: item.id, is_public: item.is_public })"
              >
                Request Join
              </v-btn>
            </div>
            <div
              v-else-if="!isTeam(item.key) && isSelected(item.id)"
              style="max-width: 150px"
            >
              <v-select
                v-model="userList.find(user => user.id === item.id).permission_type"
                :items="permissionList"
                :disabled="!isSelected(item.id) || item.is_guest ? true : false"
                item-text="label"
                item-value="value"
                placeholder="Permission"
                :rules="[required]"
                dense
                outlined
                hide-details="auto"
                class="my-0"
                @change="handleChangePermission($event, item.id)"
              />
            </div>
          </template>
        </multi-tree-view>
        <div
          v-else
          class="d-flex flex-column align-center justify-center pa-5"
          height="200px"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            size="64"
          />
        </div>
      </template>
    </v-form>
  </div>
</template>
<script>
import { ref, computed, watch, onMounted } from 'vue'
import { avatarText } from '@core/utils/filter'
import useTeam from '@/composables/useTeam'
import useFolder from '@/composables/useFolder'
import { mdiClose, mdiAccountGroup, mdiShieldLock, mdiCloseCircle } from '@mdi/js'
import ChipSearchInput from '@/components/inputs/user-team-selector/components/ChipSearchInput.vue'
import MultiTreeView from '@/components/inputs/user-team-selector/components/treeview/MultiTreeView.vue'
import { required } from '@core/utils/validation'
import usePermission from '@/composables/usePermission'
import store from '@/store'

const TEAM_PREFIX = 't'
const USER_PREFIX = 'u'

export default {
  components: {
    MultiTreeView,
    ChipSearchInput,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    updateMode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { joinTeam, requestJoin, fetchTeamHiearchy } = useTeam()
    const { updateFolderPermission, loadingEditFolder } = useFolder()
    const { folderPermissionList: permissionList } = usePermission()

    const loadingSubmit = computed(() => loadingEditFolder.value)
    const folderForm = ref()
    const folderData = ref()
    const selectedKeys = ref([])
    const hierarchyList = ref([])
    const userList = ref([])
    const ownerId = ref()
    const search = ref('')
    const formData = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })

    const getPermissionIdByEnum = enumValue => permissionList.find(el => el.value === enumValue).id
    const mapUserIds = keys =>
      keys.filter(key => !isTeam(key)).map(key => Number(key.split('-')[1]))
    const mapUserPermissions = userIds =>
      userIds.map(id => ({
        user_id: id,
        permission_type: getPermissionIdByEnum(
          userList.value.find(user => user.id === id).permission_type,
        ),
      }))

    const fetchUsersTeams = async ({ folderId }) => {
      const teamHierarchy = await fetchTeamHiearchy({ folderId })

      formData.value.user_permissions = []

      hierarchyList.value = teamHierarchy.teams.map(team => ({
        key: `${TEAM_PREFIX}-${team.id}`,
        id: team.id,
        name: team.name,
        status: team.status,
        is_public: team.is_public,
        disabled: team.status !== 'joined',
        is_object_owner: false,
        email: '',
        users: team.users
          .map(item => {
            const {
              id,
              name,
              email,
              photo,
              is_guest,
              is_object_owner: isObjectOwner,
              folder_permissions: folderPermissions,
            } = item.user

            const key = `${USER_PREFIX}-${id}`
            let permissionEnum = folderPermissions?.find(
              el => el.folder?.id === formData.value.id,
            )?.permission_type

            if (isObjectOwner) ownerId.value = id
            if (permissionEnum && !selectedKeys.value.includes(key) && !isObjectOwner) {
              selectedKeys.value.push(key)
              selectedKeys.value.push(key)
              selectedUserIds.value.push(id)
              formData.value.user_permissions.push({
                user_id: id,
                permission_type: getPermissionIdByEnum(permissionEnum),
              })
            }

            if (is_guest) permissionEnum = 'ViewOnly'

            return {
              id,
              key,
              name,
              email,
              photo,
              is_guest,
              is_object_owner: isObjectOwner,
              permission_type: permissionEnum || 'Editor',
              is_public: team.is_public,
              disabled: team.status !== 'joined',
            }
          })
          .filter(u => {
            if (props.updateMode) return !u.is_object_owner

            return u.id !== store.getters.getUserData.id
          }),
      }))

      userList.value = hierarchyList.value
        .flatMap(item => item.users)
        .reduce(
          (unique, user) =>
            unique.findIndex(uniqueUser => uniqueUser.id === user.id) < 0
              ? [...unique, user]
              : unique,
          [],
        )
    }

    const comparator = (a, b) => {
      if (b.startsWith(TEAM_PREFIX)) {
        const { users } = hierarchyList.value.find(team => team.key === b)

        return users.every(user => selectedKeys.value.includes(user.key))
      }

      if (a === b) return true

      return false
    }

    const isCurrentUserOwner = computed(() => store.getters.getUserData.id === ownerId.value)
    const isTeam = key => key.startsWith(TEAM_PREFIX)
    const isJoinedTeam = status => status === 'joined'
    const isIndeterminate = item => {
      if (!item.key.startsWith(TEAM_PREFIX)) return false

      const keysSet = new Set(selectedKeys.value)

      if (keysSet.has(item.key)) return false
      if (item.users.every(user => keysSet.has(user.key))) return false

      return item.users.some(user => keysSet.has(user.key))
    }

    const selectedUserIds = ref(
      selectedKeys.value.filter(key => !isTeam(key)).map(key => Number(key.split('-')[1])),
    )

    const isSelected = id => selectedUserIds.value.includes(id)

    const setVisibility = isPublic => {
      emit('input', { ...formData.value, is_public: isPublic })
    }

    onMounted(() => {
      fetchUsersTeams({ folderId: formData.value.id })
    })

    const reset = () => {
      emit('input', {
        ...formData.value,
        is_public: formData.value.is_public,
        user_permissions: [],
      })
      folderForm.value.reset()
    }

    const submitForm = () => {
      if (folderForm.value.validate() && !formData.value.is_public) {
        const userPermissions = formData.value.user_permissions

        if (props.updateMode) {
          userPermissions.push({
            user_id: ownerId.value,
            permission_type: 1,
          })
        }

        updateFolderPermission({
          userPermissions,
          id: formData.value.id,
          isPublic: formData.value.is_public,
        }).then(() => {
          emit('onSubmit')
        })
      }
    }
    const handleSelect = (item, event) => {
      if (!item.key.startsWith(TEAM_PREFIX)) return

      const users = item.users.filter(el => !el.disabled).map(user => user.id)

      if (event.includes(item.key)) {
        selectedKeys.value = Array.from(
          new Set([...selectedKeys.value, ...users.map(id => `${USER_PREFIX}-${id}`)]),
        )
      } else {
        selectedKeys.value = selectedKeys.value.filter(
          key => !users.includes(Number(key.split('-')[1])),
        )
      }
    }
    const handleChipDelete = userId => {
      selectedKeys.value = selectedKeys.value.filter(key => key !== `${USER_PREFIX}-${userId}`)
    }
    const handleJoinTeam = async ({ id, isPublic }) => {
      if (isPublic) await joinTeam(id)
      else await requestJoin(id)
    }
    const handleInvite = () => console.log('invite to object')
    const handleChangePermission = (permission, userId) => {
      formData.value.user_permissions.find(el => el.user_id === userId).permission_type =
        permissionList.find(el => el.value === permission).id
    }

    watch(selectedKeys, newVal => {
      const userIds = mapUserIds(newVal)

      selectedUserIds.value = userIds
      formData.value.user_permissions = mapUserPermissions(userIds)
    })

    return {
      formData,
      hierarchyList,
      userList,
      folderData,
      folderForm,
      permissionList,

      search,
      ownerId,
      selectedKeys,
      selectedUserIds,

      isTeam,
      isSelected,
      isJoinedTeam,
      isIndeterminate,
      isCurrentUserOwner,
      loadingSubmit,

      handleSelect,
      handleInvite,
      handleJoinTeam,
      handleChipDelete,
      handleChangePermission,

      required,
      avatarText,
      comparator,

      reset,
      submitForm,
      setVisibility,

      icons: {
        mdiClose,
        mdiShieldLock,
        mdiCloseCircle,
        mdiAccountGroup,
      },
    }
  },
}
</script>

<style lang="scss">
.autocomplete-field {
  position: relative;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid;
  border-color: map-deep-get($material-light, 'text-fields', 'outlined');

  .theme--dark & {
    border-color: map-deep-get($material-dark, 'text-fields', 'outlined');
  }

  .autocomplete-search {
    input {
      padding: 0 !important;
    }
  }
}
</style>
