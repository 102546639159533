import gql from 'graphql-tag'

export const updateComment = gql`
  mutation updateComment($comment: String!, $id: Float!, $workspace_id: Float!) {
    updateComment(comment: $comment, id: $id, workspace_id: $workspace_id) {
      status
      msg
      data
    }
  }
`
