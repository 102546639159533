import gql from 'graphql-tag'

const updateProductPhoto = gql`
  mutation ($productId: Float!, $photoOrder: [productPhotoOrder!]!, $workspace_id: Float!) {
    updateProductPhoto(
      productId: $productId
      photoOrder: $photoOrder
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`

export default updateProductPhoto
