import { ref, computed } from 'vue'

export default () => {
  const resolveNavItemComponent = item => {
    if (item.subheader) return 'vertical-nav-menu-section-title'
    if (item.children) return 'vertical-nav-menu-group'
    if (item.widget === 'notification') return 'vertical-nav-menu-notification'
    if (item.widget === 'folder') return 'vertical-nav-menu-folder-widget'

    return 'vertical-nav-menu-link'
  }

  const isMouseHovered = ref(false)
  const isMenuHovered = ref(false)

  const isHovered = computed(() => isMouseHovered.value || isMenuHovered.value)

  return {
    isMouseHovered,
    isMenuHovered,
    isHovered,
    resolveNavItemComponent,
  }
}
