import gql from 'graphql-tag'

const documentParentHierarchy = gql`
  query documents(
    $filter_search_children: Boolean!
    $sort: [docSort!]
    $filter: [docFilter!]
    $search: String
    $workspace_id: Float!
    $get_root_only: Boolean!
  ) {
    documents(
      filter_search_children: $filter_search_children
      sort: $sort
      search: $search
      filter: $filter
      workspace_id: $workspace_id
      get_root_only: $get_root_only
    ) {
      uuid
      name
      is_public
      document_permissions {
        user {
          id
        }
      }
      parent {
        ...recursiveParents
      }
      created_by {
        id
        name
        photo
      }
    }
  }

  fragment parentDetail on Document {
    name
  }

  fragment recursiveParents on Document {
    ...parentDetail
    parent {
      ...parentDetail
      parent {
        ...parentDetail
        parent {
          ...parentDetail
          parent {
            ...parentDetail
            parent {
              ...parentDetail
              parent {
                ...parentDetail
                parent {
                  ...parentDetail
                  parent {
                    ...parentDetail
                    parent {
                      ...parentDetail
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default documentParentHierarchy
