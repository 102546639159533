import gql from 'graphql-tag'

const connectionChecker = gql`
  query connectionChecker($platformId: Float!, $connection_name: String!, $workspace_id: Float!) {
    connectionChecker(
      platformId: $platformId
      connection_name: $connection_name
      workspace_id: $workspace_id
    ) {
      data
      msg
      status
    }
  }
`
export default connectionChecker
