import gql from 'graphql-tag'

const getTelegramBotList = gql`
  query getTelegramBotList($workspace_id: Float!) {
    getTelegramBotList(workspace_id: $workspace_id) {
      id
      connection_name
    }
  }
`

export default getTelegramBotList
