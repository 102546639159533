import gql from 'graphql-tag'

const joinTeam = gql`
  mutation ($workspace_id: Float!, $team_id: Float!) {
    joinTeam(workspace_id: $workspace_id, team_id: $team_id) {
      status
      msg
      data
    }
  }
`

export default joinTeam
