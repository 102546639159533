<template>
  <div>
    <v-slide-y-transition>
      <v-row>
        <v-col
          cols="12"
          md="6"
          lg="6"
          xs="12"
          sm="12"
          class="ma-0 mt-0 pt-0 pb-0"
        >
          <v-text-field
            v-model="state.filter.value.search"
            dense
            outlined
            hide-details
            hint="Cari pengguna..."
            label="Filter Berdasarkan Nama"
            class="mt-6"
            @input="debouncedFetchWorkspaceMember"
          >
            <template #append>
              <div class="d-flex align-center">
                <v-icon
                  class="mr-2"
                  size="22"
                >
                  {{ icons.mdiMagnify }}
                </v-icon>
              </div>
            </template>
          </v-text-field>
        </v-col>
        <!-- FILTER -->
        <v-col
          cols="12"
          md="3"
          lg="3"
          xs="12"
          sm="12"
          class="ml-auto mr-0 mb-0 ma-0 mt-6 pt-0 pb-0"
        >
          <FilterMenu :workspace="workspace" />
        </v-col>

        <!-- SORTIR -->
        <v-col
          cols="12"
          md="3"
          lg="3"
          sm="12"
          xs="12"
          class="ml-0 mr-0 mb-0 ma-0 mt-6 pt-0"
        >
          <SortButton
            :sort-options="sortOptions"
            :sort-select="state.filter.value.sortSelect"
            :sort-count="state.sortCount.value"
            :is-hover="True"
            :is-asc="state.filter.value.isASC"
            @addSortir="addSortir"
          />
        </v-col>
      </v-row>
    </v-slide-y-transition>

    <v-data-iterator
      :items="state.memberList.value"
      :items-per-page="10"
      show-select
      disable-sort
      :footer-props="{
        'items-per-page-options': [10, 20, 30],
        'items-per-page-text': 'Jumlah data',
      }"
      :server-items-length="state.memberCount.value"
      mobile-breakpoint="0"
      :loading="state.loadingMember.value"
      :options.sync="pagination"
    >
      <template #default="props">
        <PerfectScrollbar
          :options="{ suppressScrollX: true, useBothWheelAxes: false }"
          class="ps-user-workspace mt-2 pa-2"
        >
          <v-row>
            <v-col
              v-for="(member, index) in props.items"
              :key="index"
              cols="12"
              lg="6"
              class="pa-1"
            >
              <v-card outlined>
                <div class="d-flex pa-3 justify-space-between">
                  <div class="d-flex">
                    <v-avatar size="35">
                      <!-- <v-img src="@/assets/images/avatars/5.png" /> -->
                      <v-img
                        v-if="member.user.photo"
                        :src="getUrl(member.user.id, member.user.photo)"
                        class="rounded"
                      />
                      <v-avatar
                        v-else-if="avatarText"
                        class="primary"
                        @click="on"
                      >
                        <span class="white--text">
                          {{ avatarText(`${member.user.first_name} ${member.user.last_name}`) }}
                        </span>
                      </v-avatar>
                    </v-avatar>
                    <div class="ml-4">
                      <div>
                        <p
                          class="mb-0 mr-2 body-2 font-weight-semibold d-inline-block text-truncate"
                          style="max-width: 200px"
                        >
                          {{ member.user ? member.user.name : member.email }}
                        </p>
                        <v-chip
                          v-show="member.user && userLogin.id === member.user.id"
                          x-small
                          color="primary"
                        >
                          Anda
                        </v-chip>
                      </div>
                      <span class="text-caption text--disabled">{{
                        member.user ? member.user.email : member.email
                      }}</span>
                      <v-select
                        style="max-width: 200px"
                        dense
                        :items="roles"
                        :value="member.role.id"
                        :disabled="!$can('update', 'Role') || member.role.id === 1"
                        item-value="id"
                        item-text="name"
                        return-object
                        class="mt-1"
                        hide-details="auto"
                        @change="data => updateUserRole(member.user, data)"
                      >
                        <template #item="{ item, on, attr }">
                          <v-list-item
                            v-show="item.id !== 1"
                            :value="item"
                            v-bind="attr"
                            @click="on"
                          >
                            {{ item.name }}
                          </v-list-item>
                        </template>
                      </v-select>
                    </div>
                  </div>
                  <div class="d-flex align-center">
                    <div v-if="member.is_pending">
                      <v-chip
                        small
                        color="warning"
                        outlined
                        label
                      >
                        Pending
                      </v-chip>
                    </div>
                    <v-menu
                      v-if="
                        member.user &&
                        userLogin.id !== member.user.id &&
                        member.role.id > 2 &&
                        userLoginRole.id <= 1
                      "
                      offset-y
                    >
                      <template #activator="{ on, attrs }">
                        <v-btn
                          plain
                          icon
                          v-bind="attrs"
                          @click="on"
                        >
                          <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item>
                          <v-list-item-title
                            class="error--text"
                            @click="removeUser(member.user)"
                          >
                            Keluarkan dari Workspace
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </PerfectScrollbar>
      </template>
    </v-data-iterator>
  </div>
</template>
<script>
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import {
  mdiDotsVertical,
  mdiMagnify,
  mdiClose,
  mdiSortAlphabeticalAscending,
  mdiSortAlphabeticalDescending,
  mdiSortClockAscending,
  mdiSortClockDescending,
} from '@mdi/js'
import Vue from 'vue'
// import { users } from '../variable'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { roleList, workspaceMembers } from '@/graphql/queries'
import { apolloClient } from '@/vue-apollo'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import { removeFromWorkspace, updateStatusRole } from '@/graphql/mutations'
import { useDebounceFn } from '@vueuse/core'
import { avatarText } from '@core/utils/filter'
import SortButton from '@/components/filters/SortButton.vue'
import FilterMenu from './FilterMenu.vue'
import { createFieldMapper } from 'vuex-use-fields'

// import _ from 'lodash'
const useFieldWorkspaceMember = createFieldMapper({
  getter: 'workspaceMember/getField',
  setter: 'workspaceMember/setField',
})

export default defineComponent({
  name: 'ActiveSharedUser',
  components: {
    PerfectScrollbar,
    SortButton,
    FilterMenu,
  },
  props: {
    workspace: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  setup(props) {
    const userLoginRole = computed(() => store.getters.getUserRole)
    const pagination = ref({})
    const roles = ref([])
    const userLogin = computed(() => store.getters.getUserData)
    // const isOpenSortir = ref(false)
    const attrs = ref('')
    const state = {
      ...useFieldWorkspaceMember([
        'loadingMember',
        'memberList',
        'sortCount',
        'filter',
        'memberCount',
        'roleSelect',
        'statusSelect',
      ]),
    }

    const on = () => {
      console.log('hahaha')
    }

    const sortOptions = ref([
      {
        id: 0,
        text: 'Nama',
        value: 'name',
        sortType: [
          {
            type: 'ASC',
            icon: mdiSortAlphabeticalAscending,
          },
          {
            type: 'DESC',
            icon: mdiSortAlphabeticalDescending,
          },
        ],
      },
      {
        id: 1,
        text: 'Tanggal Dibuat',
        value: 'created_at',
        sortType: [
          {
            type: 'ASC',
            icon: mdiSortClockAscending,
          },
          {
            type: 'DESC',
            icon: mdiSortClockDescending,
          },
        ],
      },
      {
        id: 2,
        text: 'Terakhir Dilihat',
        value: 'last_seen_at',
        sortType: [
          {
            type: 'ASC',
            icon: mdiSortClockAscending,
          },
          {
            type: 'DESC',
            icon: mdiSortClockDescending,
          },
        ],
      },
    ])

    const getUrl = (userId, photo) =>
      `${new URL(process.env.VUE_APP_GRAPHQL_HTTP).origin}/img/user/${userId}/${photo}`

    const updateUserRole = (user, data) => {
      Vue.$dialog({
        title: 'Ganti Role?',
        body: `Yakin ingin mengganti role untuk akun ${user.name} menjadi ${data.name}?`,
      }).then(result => {
        if (result) {
          apolloClient
            .mutate({
              mutation: updateStatusRole,
              variables: {
                workspace_id: props.workspace.id,
                user_id: user.id,
                role_id: data.id,
              },
            })
            .then(() => {
              Vue.notify({
                title: 'Sukses!',
                text: 'Berhasil update role pengguna!',
              })
            })
            .catch(err => {
              state.loadingMember.value = false
              errorHandling(err)
            })
        }
      })
    }
    const fetchWorkspaceMember = () => {
      console.log('hahahahaha', state.sortCount.value)
      state.loadingMember.value = true
      apolloClient
        .query({
          query: workspaceMembers,
          fetchPolicy: 'no-cache',
          variables: {
            search: state.filter.value.search,
            workspace_id: props.workspace.id,
            pagination: {
              limit: pagination.value.itemsPerPage ? pagination.value.itemsPerPage : 10,
              offset:
                pagination.value.page === 1
                  ? 0
                  : (pagination.value.page - 1) * pagination.value.itemsPerPage ?? 0,
            },
            filter: {
              role_id: state.filter.value.roleSelect.map(val => val + 1),
              user_status: state.filter.value.statusSelect.map(val => val + 1),
              sort: state.filter.value.sortSelect.value,
              order: state.filter.value.sortSelect.sortType[state.filter.value.isASC].type,
            },
          },
        })
        .then(({ data }) => {
          state.memberList.value = data.workspaceMembers.members
          let newData = []
          state.memberList.value = state.memberList.value.map(val => {
            newData = val
            // newData.user.photoPreview = URL.createObjectURL(val.user.photo)
            // console.log(val.user.photo, newData.user.photoPreview)

            return newData
          })

          state.memberCount.value = data.workspaceMembers.users_count
          state.loadingMember.value = false
        })
        .catch(err => {
          state.loadingMember.value = false
          errorHandling(err)
        })
    }

    const addSortir = val => {
      // console.log('hausdhausd1', state.filter.value.isASC)
      // console.log('hausdhausd2', state.filter.value.sortSelect.id)
      // console.log('hausdhausd3', val)
      if (state.filter.value.sortSelect.id === val) {
        state.filter.value.isASC = state.filter.value.isASC === 0 ? 1 : 0
      } else {
        state.filter.value.isASC = 0
      }
      state.sortCount.value += 1
      state.filter.value.sortSelect = sortOptions.value[val]
      fetchWorkspaceMember()
    }

    const removeUser = userData => {
      Vue.$dialog({
        title: 'Keluarkan Pengguna?',
        body: `Yakin ingin mengeluarkan ${userData.name}?`,
      }).then(result => {
        if (result) {
          state.loadingMember.value = true
          apolloClient
            .mutate({
              mutation: removeFromWorkspace,
              variables: {
                workspace_id: props.workspace.id,
                user_id: userData.id,
              },
            })
            .then(() => {
              Vue.notify({
                title: 'Sukses!',
                text: 'Berhasil mengeluarkan pengguna!',
              })
              state.loadingMember.value = false
              fetchWorkspaceMember()
            })
            .catch(err => {
              state.loadingMember.value = false
              errorHandling(err)
            })
        }
      })
    }
    const fetchRoles = () => {
      state.loadingMember.value = true
      apolloClient
        .query({
          query: roleList,
          variables: {
            workspace_id: props.workspace.id,
          },
        })
        .then(result => {
          state.loadingMember.value = false
          roles.value = result.data.roleList
        })
    }
    watch(pagination, () => {
      fetchWorkspaceMember()
    })
    onMounted(() => {
      fetchRoles()
      fetchWorkspaceMember()
    })

    const debouncedFetchWorkspaceMember = useDebounceFn(fetchWorkspaceMember, 500)

    return {
      pagination,
      userLoginRole,
      updateUserRole,
      removeUser,
      roles,
      avatarText,
      userLogin,
      icons: {
        mdiDotsVertical,
        mdiMagnify,
        mdiClose,
      },
      debouncedFetchWorkspaceMember,
      getUrl,
      attrs,
      on,
      fetchWorkspaceMember,
      state,
      sortOptions,
      addSortir,
    }
  },
})
</script>
<style lang="scss">
.userlist-footer {
  position: fixed;
  top: 16px;
  right: 0;
  margin: 0 24px;
  z-index: 20;

  .userlist-pagination {
    .v-data-footer__select {
      margin-left: 0 !important;
    }

    .v-data-footer__pagination {
      margin-left: auto !important;
    }
  }
}

.ps-user-workspace {
  max-height: 24rem;
}

.sortir-select {
  .v-input__control {
    max-width: 164px;
  }
}

.align-self-center {
  .v-badge__wrapper {
    .v-badge__badge {
      // inset: auto auto calc(100% - 35px) calc(100% - 12px);
      // inset-inline: -20px;
      inset-inline-end: -80px;
      inset-block: -20px;
    }
  }
}

// .paddsort {
//   .mr-0.col-md-3.col-3 {
//     padding-left: 45px;
//     background-color: red;
//   }
// }
</style>
