import gql from 'graphql-tag'

export const updateTagToDocument = gql`
  mutation updateTagToDocument($documentId: String!, $tagIds: [Float!]!) {
    updateTagToDocument(document_id: $documentId, tag_ids: $tagIds) {
      status
      msg
      data
    }
  }
`
