import gql from 'graphql-tag'

const editWorkflow = gql`
  mutation updateWorkflow(
    $trigger_id: Float!
    $trigger: CreateAutomationParamsTriggerV2!
    $filters: [CreateAutomationParamsFilterV2!]!
    $executors: [CreateAutomationParamsExecutorV2!]!
  ) {
    updateWorkflow(
      trigger_id: $trigger_id
      trigger: $trigger
      filters: $filters
      executors: $executors
    ) {
      status
      msg
    }
  }
`

export default editWorkflow
