/**
 * @module viewer/viewer
 */

import { Plugin } from '@ckeditor/ckeditor5-core'
import ViewerUI from './viewerui'
import ViewerEditing from './viewerediting'

export default class Viewer extends Plugin {
  static get pluginName() {
    return 'Viewer'
  }

  static get requires() {
    return [ViewerEditing, ViewerUI]
  }
}
