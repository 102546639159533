import gql from 'graphql-tag'

export const updateTag = gql`
  mutation updateTag($color: String!, $name: String!, $id: Float!, $workspace_id: Float!) {
    updateTag(color: $color, name: $name, id: $id, workspace_id: $workspace_id) {
      status
      msg
      data
    }
  }
`
