import gql from 'graphql-tag'

const refreshWorkspaceInviteCode = gql`
  mutation ($workspace_id: Float!) {
    refreshWorkspaceInviteCode(workspace_id: $workspace_id) {
      status
      msg
      data
    }
  }
`

export default refreshWorkspaceInviteCode
