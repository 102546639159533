import gql from 'graphql-tag'

const chatBotCommandType = gql`
  query chatBotCommandType {
    chatBotCommandType {
      id
      name
    }
  }
`

export default chatBotCommandType
