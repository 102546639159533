import gql from 'graphql-tag'

export const addDocumentPermission = gql`
  mutation addDocumentPermission(
    $user_permissions: [UserDocumentPermission!]!
    $document_uuid: String!
    $workspace_id: Float!
  ) {
    addDocumentPermission(
      user_permissions: $user_permissions
      document_uuid: $document_uuid
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`
