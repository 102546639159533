import gql from 'graphql-tag'

const notificationSubscription = gql`
  subscription NotificationSubscription($user_id: Float!, $workspaceId: Float) {
    notificationSubscription(user_id: $user_id, workspace_id: $workspaceId) {
      id
      created_at

      recipient_user {
        id
        name
      }

      read_at
      archived_at
      snoozed_at
      unsnoozed_at
      category_id

      static_data

      job {
        id
        name
        job_type {
          id
        }
      }

      document {
        uuid
        name
        last_edited_at
        last_edited_by {
          id
          name
          photo
        }
        deleted_at
        archived_at
      }

      referenceLog {
        id
        category_id
        created_at
        job {
          id
          name
          job_type {
            id
          }
        }
        custom_attribute {
          id
          name
          data_type {
            id
          }
        }
        created_by {
          id
          name
          photo
        }
        from
        to
      }

      comment {
        id
        comment
        strip_comment
        user {
          id
          phone
          email
          name
          first_name
          last_name
          photo
        }
        created_at
        updated_at
        deleted_at
        resolved_at
        lexorank
        assigned_user {
          id
          email
          name
          photo
        }

        parent {
          id
          comment
          strip_comment
          user {
            id
            phone
            email
            name
            first_name
            last_name
            photo
          }
          created_at
          updated_at
          deleted_at
          resolved_at
          lexorank
          assigned_user {
            id
            email
            name
            photo
          }

          job {
            id
            name
            job_type {
              id
            }
          }

          attachment {
            id
            file_name
            file_type
          }
        }

        job {
          id
          name
          job_type {
            id
          }
        }

        attachment {
          id
          file_name
          file_type
        }

        document {
          uuid
          name
        }
      }

      workspace {
        id
        name
      }

      team {
        id
        name
      }
    }
  }
`

export default notificationSubscription
