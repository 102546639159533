<template>
  <v-card-text class="pt-2">
    <div class="d-flex flex-column">
      <v-alert
        border="left"
        color="primary"
        dense
        text
        type="info"
        class="mb-4 text-subtitle-2 py-2"
      >
        Daftar pengguna di {{ workspace.name }}. Kamu bisa mengundang hingga 5 pengguna di workspace
        ini.
      </v-alert>
      <v-row class="mb-1">
        <v-col
          cols="12"
          md="auto"
        >
          <v-btn
            v-if="userRole.id <= 2"
            outlined
            color="primary"
            small
            @click="$refs.emailInvite.show()"
          >
            Undang Pengguna
          </v-btn>
          <span
            v-else
            class="mr-2 text-subtitle-2 mt-1"
            >Tautan Undangan</span
          >
        </v-col>
        <v-col
          cols="12"
          md="auto"
          class="ml-md-auto"
        >
          <div
            v-if="userRole.id <= 2"
            class="d-flex"
          >
            <span class="mr-4 text-subtitle-2 mt-1">Tautan Undangan</span>
            <v-switch
              v-model="invitationSwitch"
              inset
              :loading="loading"
              class="ma-0"
              hide-details
            />
          </div>
        </v-col>
      </v-row>
      <v-slide-y-transition>
        <v-text-field
          v-if="invitationSwitch"
          v-model="inviteUrl"
          dense
          outlined
          readonly
          hide-details
        >
          <template #append>
            <div class="d-flex align-center">
              <v-icon
                class="mr-2"
                size="22"
                @click="refreshCode"
              >
                {{ icons.mdiRefresh }}
              </v-icon>
              <v-icon
                size="22"
                @click="copyClipboard"
              >
                {{ icons.mdiContentCopy }}
              </v-icon>
            </div>
          </template>
        </v-text-field>
      </v-slide-y-transition>
    </div>
    <ActiveSharedUser :workspace="workspace" />

    <email-invite ref="emailInvite" />
  </v-card-text>
</template>
<script>
import { defineComponent, ref, watch, onMounted } from 'vue'
import Vue from 'vue'
import {
  mdiAccountMultiplePlusOutline,
  mdiDotsVertical,
  mdiContentCopy,
  mdiLogout,
  mdiRefresh,
} from '@mdi/js'
import { useStorage } from '@vueuse/core'
import ActiveSharedUser from '@/views/workspace/component/ActiveSharedUser.vue'
import EmailInvite from './EmailInvite.vue'
import { apolloClient } from '@/vue-apollo'
import { onOffInviteWorkspace, refreshWorkspaceInviteCode } from '@/graphql/mutations'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'

export default defineComponent({
  components: {
    ActiveSharedUser,
    EmailInvite,
  },
  props: {
    workspace: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const invitationSwitch = useStorage('invitationlink', true)
    const inviteUrl = ref('')
    const userRole = store.getters.getUserRole
    const copyClipboard = () => {
      navigator.clipboard.writeText(inviteUrl.value)
      Vue.notify({
        type: 'success',
        title: 'Berhasil disalin!',
        text: 'Tautan undangan berhasil disalin',
      })
    }
    const onOffInvite = value => {
      loading.value = true
      apolloClient
        .mutate({
          mutation: onOffInviteWorkspace,
          variables: {
            open_invite: value,
            workspace_id: props.workspace.id,
          },
        })
        .then(() => {
          loading.value = false
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil mengubah status invitation!',
          })
        })
        .catch(err => {
          loading.value = false
          errorHandling(err)
        })
    }

    const refreshCode = () => {
      loading.value = true
      apolloClient
        .mutate({
          mutation: refreshWorkspaceInviteCode,
          variables: {
            workspace_id: props.workspace.id,
          },
        })
        .then(() => {
          loading.value = false
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil mengubah kode undangan',
          })
          emit('refetch')
        })
        .catch(err => {
          loading.value = false
          errorHandling(err)
        })
    }

    watch(invitationSwitch, value => {
      onOffInvite(value)
    })

    onMounted(() => {
      if (props.workspace && props.workspace.invite_code) {
        inviteUrl.value = `${window.location.origin}/invite/${props.workspace.invite_code}`
      }
    })

    return {
      copyClipboard,
      refreshCode,
      userRole,

      inviteUrl,
      invitationSwitch,
      loading,

      icons: {
        mdiAccountMultiplePlusOutline,
        mdiDotsVertical,
        mdiContentCopy,
        mdiLogout,
        mdiRefresh,
      },
    }
  },
})
</script>
