import gql from 'graphql-tag'

export const createCustomAttribute = gql`
  mutation CreateCustomAttribute(
    $name: String!
    $workspace_id: Float!
    $data_type_id: Float!
    $object_type_id: Float!
    $job_type_id: Float
    $formula: String
    $options: [String!]
  ) {
    createCustomAttribute(
      name: $name
      workspace_id: $workspace_id
      data_type_id: $data_type_id
      object_type_id: $object_type_id
      job_type_id: $job_type_id
      formula: $formula
      options: $options
    ) {
      data
      msg
      status
    }
  }
`
