import gql from 'graphql-tag'

export const deleteTag = gql`
  mutation deleteTag($tag_id: Float!, $workspace_id: Float!) {
    deleteTag(tag_id: $tag_id, workspace_id: $workspace_id) {
      status
      data
      msg
    }
  }
`
