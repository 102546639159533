import gql from 'graphql-tag'

export const archiveAllNotification = gql`
  mutation ArchiveAllNotification($workspaceId: Float!) {
    archiveAllNotification(workspace_id: $workspaceId) {
      status
      msg
      data
    }
  }
`
