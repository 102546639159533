import gql from 'graphql-tag'

const phoneChecker = gql`
  mutation ($phone: String!) {
    phoneChecker(phone: $phone) {
      status
      msg
      data
    }
  }
`

export default phoneChecker
