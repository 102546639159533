import gql from 'graphql-tag'

const dashboardJobs = gql`
  query dashboardJobs($pagination: pagination!) {
    dashboardJobs(pagination: $pagination) {
      new {
        count
        jobs {
          id
          name
          priority {
            id
            name
          }
          description
          expected_close_date
          created_by {
            id
            name
          }
          created_at
          updated_at
          deleted_at
          assignedTo {
            id
            user {
              id
              name
              photo
            }
          }
          customer {
            id
            name
            email
            phone_number
          }
        }
      }
      today {
        count
        jobs {
          id
          name
          priority {
            id
            name
          }
          description
          expected_close_date
          created_by {
            id
            name
          }
          created_at
          updated_at
          deleted_at
          assignedTo {
            id
            user {
              id
              name
              photo
            }
          }
          customer {
            id
            name
            email
            phone_number
          }
        }
      }
      tomorrow {
        count
        jobs {
          id
          name
          priority {
            id
            name
          }
          description
          expected_close_date
          created_by {
            id
            name
          }
          created_at
          updated_at
          deleted_at
          assignedTo {
            id
            user {
              id
              name
              photo
            }
          }
          customer {
            id
            name
            email
            phone_number
          }
        }
      }
      late {
        count
        jobs {
          id
          name
          priority {
            id
            name
          }
          description
          expected_close_date
          created_by {
            id
            name
          }
          created_at
          updated_at
          deleted_at
          assignedTo {
            id
            user {
              id
              name
              photo
            }
          }
          customer {
            id
            name
            email
            phone_number
          }
        }
      }
      unscheduled {
        count
        jobs {
          id
          name
          priority {
            id
            name
          }
          description
          expected_close_date
          created_by {
            id
            name
          }
          created_at
          updated_at
          deleted_at
          assignedTo {
            id
            user {
              id
              name
              photo
            }
          }
          customer {
            id
            name
            email
            phone_number
          }
        }
      }
    }
  }
`

export default dashboardJobs
