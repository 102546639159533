<template>
  <div>
    <v-expansion-panels
      v-model="panel"
      flat
      focusable
    >
      <v-expansion-panel class="v-expansion-panel-folder">
        <v-expansion-panel-header
          class="pa-0 vertical-nav-menu-link v-list-item v-list-item--link"
          style="padding-left: 22px !important"
          ripple
          expand-icon=""
        >
          <div class="d-flex align-center">
            <v-icon style="margin-right: 12px">{{ mdiFolder }}</v-icon>
            <span v-if="!menuIsVerticalNavMini || isHovered"> Folders </span>
          </div>

          <template #actions>
            <span
              v-if="!menuIsVerticalNavMini || isHovered"
              class="action-wrapper d-flex"
            >
              <v-menu
                v-model="showSearchForm"
                :close-on-content-click="false"
                offset-x
              >
                <template #activator="menu">
                  <v-tooltip top>
                    <template #activator="tooltip">
                      <v-btn
                        v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                        icon
                        small
                        class="me-1"
                        v-on="{ ...menu.on, ...tooltip.on }"
                        @click.stop="menuIsVerticalNavMini = false"
                      >
                        <v-badge
                          :value="folderFilterCount > 0"
                          color="primary"
                          :content="folderFilterCount"
                          overlap
                          class="align-self-center"
                        >
                          <v-icon size="20"> {{ mdiMagnify }} </v-icon>
                        </v-badge>
                      </v-btn>
                    </template>
                    <span>Cari Folder</span>
                  </v-tooltip>
                </template>

                <SearchFolderForm />
              </v-menu>

              <v-tooltip top>
                <template #activator="tooltip">
                  <v-btn
                    v-bind="tooltip.attrs"
                    icon
                    small
                    class="me-1"
                    v-on="tooltip.on"
                    @click.stop="
                      $refs.folderForm.show()
                      menuIsVerticalNavMini = false
                    "
                  >
                    <v-icon>{{ mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>Tambah Folder</span>
              </v-tooltip>

              <FolderForm
                ref="folderForm"
                @onChangeFolder="$emit('onChangeFolder')"
              />
            </span>

            <v-icon
              v-if="!menuIsVerticalNavMini || isHovered"
              class="me-2"
            >
              {{ mdiChevronDown }}
            </v-icon>
          </template>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <template v-if="!loadingFolder">
            <v-list-item-group
              v-for="(folder, index) in folderList"
              :key="folder.id"
            >
              <v-hover v-slot="{ hover: isFolderHovered }">
                <v-list-item
                  v-bind="folderLinkProps[index]"
                  class="vertical-nav-menu-link pe-0 ps-6"
                  active-class="bg-gradient-primary"
                >
                  <v-list-item-icon class="me-2">
                    <v-badge
                      v-if="!isFolderHovered"
                      color="#CC3C40"
                      overlap
                      offset-y="10"
                      offset-x="10"
                      class="folder-count-badge"
                      :value="folder.unfinished_job_count > 0"
                    >
                      <v-icon
                        :color="folder.color"
                        size="22"
                      >
                        {{ folder.is_public ? mdiFolder : mdiFolderLock }}
                      </v-icon>

                      <template #badge>
                        <span style="font-size: 10px">{{ folder.unfinished_job_count }}</span>
                      </template>
                    </v-badge>
                    <v-btn
                      v-else
                      icon
                      x-small
                      class="folder-expand-document"
                      @click.prevent="toggleDocumentList(folder.id)"
                    >
                      <v-icon>{{
                        openedDocumentList.includes(folder.id) ? mdiChevronUp : mdiChevronDown
                      }}</v-icon>
                    </v-btn>
                  </v-list-item-icon>

                  <v-list-item-title class="text-caption">{{ folder.name }}</v-list-item-title>

                  <v-slide-x-reverse-transition mode="out-in">
                    <v-list-item-action
                      v-if="!folder.is_public || folderPermissionGuard(folder, 1)"
                      v-show="isFolderHovered"
                      class="my-0 me-2 justify-end"
                    >
                      <v-menu>
                        <template #activator="{ on, attrs }">
                          <v-btn
                            icon
                            x-small
                            class="me-1"
                            v-bind="attrs"
                            v-on="on"
                            @click.prevent="menuIsVerticalNavMini = false"
                          >
                            <v-icon>{{ mdiDotsHorizontal }}</v-icon>
                          </v-btn>
                        </template>

                        <v-list dense>
                          <v-list-item @click="$refs.editFolderAttributeForm.show(folder)">
                            <v-list-item-title>Rename & Edit Color</v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            @click="
                              formFolderId = folder.id
                              isPermissionOpen = true
                            "
                          >
                            <v-list-item-title>Sharing & Permission</v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            class="warning--text"
                            :disabled="!folderPermissionGuard(folder, 0)"
                            @click="
                              !folder.archived_at
                                ? confirmArchiveFolder(folder)
                                : confirmUnarchiveFolder(folder)
                            "
                          >
                            <v-list-item-title>{{
                              !folder.archived_at ? 'Archive' : 'Unarchive'
                            }}</v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            class="error--text"
                            :disabled="!folderPermissionGuard(folder, 0)"
                            @click="confirmDeleteFolder(folder)"
                          >
                            <v-list-item-title>Delete</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-slide-x-reverse-transition>
                </v-list-item>
              </v-hover>

              <v-expand-transition>
                <v-list
                  dense
                  class="pa-0"
                  v-show="openedDocumentList.includes(folder.id)"
                >
                  <template v-if="!loadingDocumentList">
                    <template v-if="folder.documents.length">
                      <v-list-item
                        v-for="document in folder.documents"
                        :key="document.uuid"
                        class="vertical-nav-menu-link"
                        :class="isHovered || !menuIsVerticalNavMini ? 'ps-10' : 'ps-6'"
                        style="transition: all 0.18s ease-in-out"
                        active-class="bg-gradient-primary"
                        :to="{
                          name: 'document-detail',
                          params: { id: document.uuid, refresh: true },
                        }"
                      >
                        <v-list-item-icon>
                          <v-icon size="20">{{ mdiFileDocumentOutline }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ document.name }}</v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-else>
                      <v-expand-transition>
                        <div
                          v-show="isHovered"
                          class="ps-10 caption text--disabled text-truncate"
                        >
                          Tidak ada dokumen
                        </div>
                      </v-expand-transition>
                    </template>
                  </template>

                  <div
                    v-else
                    class="text-center"
                  >
                    <v-progress-circular
                      indeterminate
                      size="20"
                      color="primary"
                    />
                  </div>
                </v-list>
              </v-expand-transition>
            </v-list-item-group>
          </template>
          <div
            v-else
            class="text-center"
          >
            <v-progress-circular
              indeterminate
              size="24"
              color="primary"
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <EditFolderAttributeForm
      ref="editFolderAttributeForm"
      @onChangeFolder="$emit('onChangeFolder')"
    />
    <EditFolderPermissionForm
      ref="editFolderPermissionForm"
      @onChangeFolder="$emit('onChangeFolder')"
    />

    <v-dialog
      v-model="isPermissionOpen"
      max-width="850"
      scrollable
      @click:outside="folderPermissionForm.reset()"
    >
      <v-card v-if="isPermissionOpen">
        <v-card-text class="pt-4">
          <div class="d-flex justify-space-between pb-4">
            <span class="text-h6 font-medium primary--text">
              Edit Permission Folder -
              {{ folderList.find(folder => folder.id === formFolderId).name }}
            </span>
            <v-icon @click="isPermissionOpen = false">{{ mdiClose }}</v-icon>
          </div>

          <FolderPermissionForm
            ref="folderPermissionForm"
            v-model="folderList[folderList.findIndex(folder => folder.id === formFolderId)]"
            :update-mode="true"
          />
        </v-card-text>

        <v-card-actions>
          <div class="mt-4 ml-md-auto">
            <v-btn
              color="primary"
              :loading="folderPermissionForm && folderPermissionForm.loadingSubmit"
              @click="
                folderPermissionForm.submitForm()
                folderPermissionForm.reset()
                isPermissionOpen = false
              "
            >
              Simpan
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { computed, inject, onMounted, ref } from 'vue'
import {
  mdiPlus,
  mdiMagnify,
  mdiFolder,
  mdiChevronDown,
  mdiFolderLock,
  mdiClose,
  mdiDotsHorizontal,
  mdiFileDocumentOutline,
  mdiChevronUp,
} from '@mdi/js'
import useFolder from '@/composables/useFolder'
import EditFolderAttributeForm from '@/views/folder/EditFolderAttributeForm.vue'
import EditFolderPermissionForm from '@/views/folder/EditFolderPermissionForm.vue'
import Vue from 'vue'
import { createFieldMapper } from 'vuex-use-fields'
import useAppConfig from '@core/@app-config/useAppConfig'
import FolderPermissionForm from '@/views/folder/FolderPermissionForm.vue'
import SearchFolderForm from '@/views/folder/SearchFolderForm.vue'
import FolderForm from '@/views/folder/FolderForm.vue'

const emit = defineEmits(['onChangeFolder'])
defineProps({
  item: {
    type: Object,
    required: true,
  },
})

const { loadingFolder, folderList, filter } = createFieldMapper({
  getter: 'folder/getField',
  setter: 'folder/setField',
})(['loadingFolder', 'folderList', 'filter'])

const {
  fetchFolder,
  updateDeleteFolder,
  updateArchiveFolder,
  updateUnarchiveFolder,
  folderPermissionGuard,
} = useFolder()

const panel = ref(0)
const showSearchForm = ref(false)
const isPermissionOpen = ref(false)
const openedDocumentList = ref([])
const loadingDocumentList = ref(false)

const formFolderId = ref(null)
const folderForm = ref()
const folderPermissionForm = ref()
const editFolderAttributeForm = ref()
const editFolderPermissionForm = ref()

// const { canViewVerticalNavMenuLink } = useAclUtils()
const { menuIsVerticalNavMini } = useAppConfig()
const isHovered = inject('isHovered')

const folderLinkProps = computed(() => {
  return folderList.value.map(folder => ({
    to: {
      name: 'folder-detail',
      params: { id: folder.id },
    },
  }))
})
const folderFilterCount = computed(() => {
  let count = 0
  if (filter.value.search !== '') count += 1
  if (filter.value.sort.id !== 1) count += 1
  if (filter.value.show_archived.id !== 2) count += 1
  if (filter.value.my_folder.id !== 1) count += 1

  return count
})

const getFolder = () => {
  fetchFolder({
    search: filter.value.search,
    sort: filter.value.sort.value,
    sortType: filter.value.sort.sortType,
    show_archived: filter.value.show_archived.value,
    my_folder: filter.value.my_folder.value,
  })
}

const confirmArchiveFolder = folder => {
  Vue.$dialog({
    title: 'Archive Folder',
    body: 'Yakin ingin mengarsipkan folder ini?',
  }).then(result => {
    if (result) {
      updateArchiveFolder(folder.id).then(() => {
        emit('onChangeFolder')
      })
    }
  })
}
const confirmUnarchiveFolder = folder => {
  Vue.$dialog({
    title: 'Unarchive Folder',
    body: 'Yakin ingin memulihkan folder ini?',
  }).then(result => {
    if (result) {
      updateUnarchiveFolder(folder.id).then(() => {
        emit('onChangeFolder')
      })
    }
  })
}
const confirmDeleteFolder = folder => {
  Vue.$dialog({
    title: 'Delete Folder',
    body: 'Yakin ingin menghapus folder ini?',
  }).then(result => {
    if (result) {
      updateDeleteFolder(folder.id).then(() => {
        emit('onChangeFolder')
      })
    }
  })
}

const toggleDocumentList = async id => {
  if (openedDocumentList.value.includes(id)) {
    openedDocumentList.value = openedDocumentList.value.filter(el => el !== id)

    return
  }

  openedDocumentList.value.push(id)
}

onMounted(() => {
  getFolder()
})
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.vertical-nav-menu-link {
  &.v-list-item--active {
    box-shadow: 0 5px 10px -4px rgba(94, 86, 105, 0.42);
    @include elevationTransition();
  }
}

.folder-count-badge {
  .v-badge__badge {
    padding: 1px 4px;
    min-width: auto;
    height: auto;
  }
}

.v-expansion-panel-folder::before {
  box-shadow: none !important;
}

.v-application.theme--dark .v-expansion-panel.v-expansion-panel--active {
  box-shadow: none !important;
}

.v-application.theme--light .v-expansion-panel.v-expansion-panel--active {
  box-shadow: none !important;
}

.v-expansion-panel-folder {
  background-color: transparent !important;
  .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }
  .v-expansion-panel-header--active {
    min-height: 44px !important;

    .v-expansion-panel-header__icon {
      .action-wrapper .v-icon {
        transform: none;
      }
    }
  }
  .vertical-nav-menu-link.v-list-item--active {
    .v-list-item__action {
      .v-icon {
        color: map-deep-get($material-dark, 'icons', 'active') !important;
      }
    }
    .folder-expand-document {
      .v-icon {
        color: map-deep-get($material-dark, 'icons', 'active') !important;
      }
    }
  }
}
</style>
