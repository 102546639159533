<template>
  <div class="pa-3">
    <h3>Integrasi WhatsApp</h3>
    <v-card
      class="mt-6"
      outlined
    >
      <div class="pa-3 d-flex flex-column">
        <h5>Apa itu Whatsapp?</h5>
        <span class="text-subtitle-2"
          >WhatsApp adalah aplikasi berkirim pesan dan panggilan yang sederhana, aman, dan reliabel,
          serta dapat diunduh ke ponsel di seluruh dunia secara gratis.</span
        >
      </div>
      <v-divider />
      <div class="pa-3 d-flex flex-column">
        <h5>Cara Integrasi Whatsapp</h5>
        <span class="text-subtitle-2"
          >Untuk Whatsapp, anda tidak perlu mengintegrasikan akun whatsapp anda, karena akan
          menggunakan nomor yang disediakan oleh Oriens.</span
        >
      </div>
    </v-card>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
