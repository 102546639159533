import gql from 'graphql-tag'

const unpinThread = gql`
  mutation ($thread_id: Float!) {
    unpinThread(thread_id: $thread_id) {
      status
      msg
      data
    }
  }
`

export default unpinThread
