const registerFunctions = [
  {
    id: 1,
    name: 'CONCATENATE()',
    type: 'functions',
    icon: 'mdiText',
    description: 'Menggabungkan beberapa string teks menjadi satu string.',
    example: {
      title: 'CONCATENATE("Text1", ..., "Text30")',
      syntax: [
        {
          code: 'CONCATENATE("Selamat", "Datang")',
        },
        {
          code: 'CONCATENATE("Coba", "Lagi")',
        },
      ],
    },
  },
  {
    id: 2,
    name: 'LOWER()',
    type: 'functions',
    icon: 'mdiText',
    description: 'Mengembalikan teks yang dikonversi menjadi huruf kecil.',
    example: {
      title: 'LOWER("Text1")',
      syntax: [
        {
          code: 'LOWER("HELLO WORLDS")',
        },
      ],
    },
  },
  {
    id: 3,
    name: 'UPPER()',
    type: 'functions',
    icon: 'mdiText',
    description: 'Mengembalikan teks yang dikonversi menjadi huruf besar.',
    example: {
      title: 'UPPER("Text1")',
      syntax: [
        {
          code: 'UPPER("hello worlds")',
        },
      ],
    },
  },
  {
    id: 4,
    name: 'LEN()',
    type: 'functions',
    icon: 'mdiNumeric',
    description: 'Mengembalikan panjang teks tertentu.',
    example: {
      title: 'LEN("Text1")',
      syntax: [
        {
          code: 'LEN("Hello Worlds")',
        },
      ],
    },
  },
  {
    id: 5,
    name: 'PROPER()',
    type: 'functions',
    icon: 'mdiText',
    description: 'Mengkapitalisasi kata-kata yang diberikan string teks.',
    example: {
      title: 'PROPER("Text1")',
      syntax: [
        {
          code: 'PROPER("SELAMAT DATANG")',
        },
        {
          code: 'PROPER("halo dunia")',
        },
      ],
    },
  },
  {
    id: 42,
    name: 'SUBSTITUTE()',
    type: 'functions',
    icon: 'mdiText',
    description:
      'Mengembalikan string di mana kemunculan Old_text digantikan oleh New_text. Hanya mengganti kejadian tertentu jika parameter terakhir disediakan.	',
    example: {
      title: 'SUBSTITUTE("text", "Old_text", "New_text")',
      syntax: [
        {
          code: 'SUBSTITUTE("Selamat Datang", "Datang", "Siang")',
        },
      ],
    },
  },
  {
    id: 6,
    name: 'MID()',
    type: 'functions',
    icon: 'mdiText',
    description:
      'Mengembalikan substring dengan panjang tertentu yang dimulai dari start_position.',
    example: {
      title: 'MID("Text1", start_text , length_text)',
      syntax: [
        {
          code: 'MID("HelloWorlds", 1, 5)',
        },
        {
          code: 'MID("HelloWorlds", 6,6)',
        },
      ],
    },
  },
  {
    id: 7,
    name: 'EXACT()',
    type: 'functions',
    description: 'Mengembalikan TRUE jika kedua string teks sama persis.',
    icon: 'mdiCheckboxMarked',
    example: {
      title: 'EXACT("Text1", "Text2")',
      syntax: [
        {
          code: 'EXACT("Hello", "Hello")',
        },
        {
          code: 'EXACT("Hello", "Worlds")',
        },
      ],
    },
  },
  {
    id: 8,
    name: 'NOW()',
    type: 'functions',
    icon: 'mdiCalendarRange',
    description: 'Mengembalikan Tanggal hari ini berupa text',
    example: {
      title: 'NOW()',
      syntax: [
        {
          code: 'NOW()',
        },
      ],
    },
  },
  {
    id: 9,
    name: 'DAY()',
    type: 'functions',
    icon: 'mdiNumeric',
    description: 'Mengembalikan hari dari nilai tanggal tertentu.',
    example: {
      title: 'DAY(Date_Type)',
      syntax: [
        {
          code: 'DAY(NOW())',
        },
      ],
    },
  },
  {
    id: 32,
    name: 'WEEKDAY()',
    type: 'functions',
    icon: 'mdiNumeric',
    description: 'Menghitung angka antara 1-7 yang mewakili hari dalam seminggu.',
    example: {
      title: 'WEEKDAY(Date_Type, type)',
      syntax: [
        {
          code: 'WEEKDAY(NOW(), 1)',
        },
        {
          code: 'WEEKDAY(NOW(), 2)',
        },
      ],
    },
  },
  {
    id: 33,
    name: 'WEEKNUM()',
    type: 'functions',
    icon: 'mdiNumeric',
    description: 'Mengembalikan angka minggu yang berhubungan dengan minggu dalam setahun.',
    example: {
      title: 'WEEKNUM(Date_Type, type)',
      syntax: [
        {
          code: 'WEEKNUM("2024-01-01", 1)',
        },
        {
          code: 'WEEKNUM(NOW(), 1)',
        },
        {
          code: 'WEEKNUM(NOW(), 2)',
        },
      ],
    },
  },
  {
    id: 10,
    name: 'MONTH()',
    type: 'functions',
    icon: 'mdiNumeric',
    description: 'Mengembalikan bulan untuk nilai tanggal tertentu.',
    example: {
      title: 'MONTH(Date_Type)',
      syntax: [
        {
          code: 'MONTH(NOW())',
        },
      ],
    },
  },
  {
    id: 11,
    name: 'YEAR()',
    type: 'functions',
    icon: 'mdiNumeric',
    description: 'Mengembalikan tahun sebagai angka sesuai dengan aturan perhitungan internal.',
    example: {
      title: 'YEAR(Date_Type)',
      syntax: [
        {
          code: 'YEAR(NOW())',
        },
      ],
    },
  },
  {
    id: 30,
    name: 'DATEDIF()',
    type: 'functions',
    icon: 'mdiNumeric',
    description: 'Menghitung jarak antara dua tanggal, dalam parameter unit yang disediakan.',
    example: {
      title: 'DATEDIF(start_date, end_date, unit)',
      syntax: [
        {
          code: 'DATEDIF("2024-06-11", NOW(), "D")',
        },
      ],
    },
  },
  {
    id: 12,
    name: 'PI()',
    type: 'functions',
    icon: 'mdiNumeric',
    description:
      'Mengembalikan 3,14159265358979, nilai konstanta matematika PI ke 14 tempat desimal.',
    example: {
      title: 'PI()',
      syntax: [
        {
          code: 'PI()',
        },
      ],
    },
  },
  {
    id: 13,
    name: 'LOG()',
    icon: 'mdiNumeric',
    type: 'functions',
    description: 'Mengembalikan logaritma bilangan ke basis yang ditentukan.',
    example: {
      title: 'LOG(number, ?base)',
      syntax: [
        {
          code: 'LOG(10)',
        },
        {
          code: 'LOG(16, 2)',
        },
      ],
    },
  },
  {
    id: 14,
    name: 'LOG10()',
    icon: 'mdiNumeric',
    type: 'functions',
    description: 'Mengembalikan logaritma basis 10 dari suatu bilangan.',
    example: {
      title: 'LOG10(number)',
      syntax: [
        {
          code: 'LOG10(10)',
        },
        {
          code: 'LOG10(100)',
        },
      ],
    },
  },
  {
    id: 15,
    name: 'ABS()',
    type: 'functions',
    icon: 'mdiNumeric',
    description: 'Mengembalikan nilai absolut dari suatu bilangan.',
    example: {
      title: 'ABS(number)',
      syntax: [
        {
          code: 'ABS(10)',
        },
        {
          code: 'ABS(-10)',
        },
      ],
    },
  },
  {
    id: 36,
    name: 'SIN()',
    type: 'functions',
    icon: 'mdiNumeric',
    description: 'Mengembalikan sinus dari sudut tertentu (dalam radian).',
    example: {
      title: 'SIN(number)',
      syntax: [
        {
          code: 'SIN(90)',
        },
        {
          code: 'SIN(180)',
        },
      ],
    },
  },
  {
    id: 34,
    name: 'COS()',
    type: 'functions',
    icon: 'mdiNumeric',
    description: 'Mengembalikan kosinus dari sudut tertentu (dalam radian).',
    example: {
      title: 'COS(number)',
      syntax: [
        {
          code: 'COS(90)',
        },
        {
          code: 'COS(180)',
        },
      ],
    },
  },
  {
    id: 35,
    name: 'TAN()',
    type: 'functions',
    icon: 'mdiNumeric',
    description: 'Mengembalikan garis singgung dari sudut tertentu (dalam radian).',
    example: {
      title: 'TAN(number)',
      syntax: [
        {
          code: 'TAN(90)',
        },
        {
          code: 'TAN(180)',
        },
      ],
    },
  },
  {
    id: 16,
    name: 'MOD()',
    icon: 'mdiNumeric',
    type: 'functions',
    description:
      'Mengembalikan sisanya bila satu bilangan bulat dibagi dengan bilangan bulat lainnya.',
    example: {
      title: 'MOD(Dividend, Divisor)',
      syntax: [
        {
          code: 'MOD(20,6)',
        },
        {
          code: 'MOD(31,4)',
        },
      ],
    },
  },
  {
    id: 17,
    name: 'FLOOR()',
    type: 'functions',
    icon: 'mdiNumeric',
    description: 'Membulatkan angka ke bawah ke kelipatan Signifikansi terdekat.',
    example: {
      title: 'FLOOR(number)',
      syntax: [
        {
          code: 'FLOOR(12.88)',
        },
        {
          code: 'FLOOR(8.28)',
        },
      ],
    },
  },
  {
    id: 18,
    name: 'CEILING()',
    type: 'functions',
    icon: 'mdiNumeric',
    description: 'Membulatkan angka ke atas ke kelipatan Signifikansi terdekat.',
    example: {
      title: 'CEILING(number)',
      syntax: [
        {
          code: 'CEILING(12.88)',
        },
        {
          code: 'CEILING(8.28)',
        },
      ],
    },
  },
  {
    id: 19,
    name: 'ROUND()',
    type: 'functions',
    icon: 'mdiNumeric',
    description: 'Membulatkan angka ke sejumlah tempat desimal tertentu.',
    example: {
      title: 'ROUND(number)',
      syntax: [
        {
          code: 'ROUND(12.88)',
        },
        {
          code: 'ROUND(8.28)',
        },
      ],
    },
  },
  {
    id: 20,
    name: 'POWER()',
    type: 'functions',
    icon: 'mdiNumeric',
    description: 'Mengembalikan angka yang dipangkatkan ke angka lain',
    example: {
      title: 'POWER(Number,base)',
      syntax: [
        {
          code: 'POWER(8,2)',
        },
        {
          code: 'POWER(2,3)',
        },
      ],
    },
  },
  {
    id: 29,
    name: 'SIGN()',
    type: 'functions',
    icon: 'mdiNumeric',
    description: 'Mengembalikan angka sign.',
    example: {
      title: 'SIGN(Number)',
      syntax: [
        {
          code: 'SIGN(-10)',
        },
        {
          code: 'SIGN(10)',
        },
      ],
    },
  },
  {
    id: 21,
    name: 'SQRT()',
    type: 'functions',
    icon: 'mdiNumeric',
    description: 'Mengembalikan akar kuadrat positif dari suatu bilangan.',
    example: {
      title: 'SQRT(Number)',
      syntax: [
        {
          code: 'SQRT(4)',
        },
        {
          code: 'SQRT(8)',
        },
      ],
    },
  },
  {
    id: 40,
    name: 'EXP()',
    type: 'functions',
    icon: 'mdiNumeric',
    description: 'Mengembalikan konstanta e yang dipangkatkan ke bilangan yang dipangkatkan.',
    example: {
      title: 'EXP(number)',
      syntax: [
        {
          code: 'EXP(2)',
        },
        {
          code: 'EXP(3)',
        },
      ],
    },
  },
  {
    id: 41,
    name: 'FACT()',
    type: 'functions',
    icon: 'mdiNumeric',
    description: 'Mengembalikan faktorial dari suatu bilangan.',
    example: {
      title: 'FACT(number)',
      syntax: [
        {
          code: 'FACT(5)',
        },
        {
          code: 'FACT(3)',
        },
      ],
    },
  },
  {
    id: 26,
    name: 'MIN()',
    type: 'functions',
    icon: 'mdiNumeric',
    description: 'Mengembalikan nilai minimum dalam daftar argumen.',
    example: {
      title: 'MIN(number1, number2, ...)',
      syntax: [
        {
          code: 'MIN(2,0,5)',
        },
        {
          code: 'MIN(2,1,6,7)',
        },
      ],
    },
  },
  {
    id: 27,
    name: 'MAX()',
    type: 'functions',
    icon: 'mdiNumeric',
    description: 'Mengembalikan nilai maksimum dalam daftar argumen.',
    example: {
      title: 'MAX(number1, number2, ...)',
      syntax: [
        {
          code: 'MAX(2,3,5,1)',
        },
        {
          code: 'MAX(2,5,1,7)',
        },
      ],
    },
  },
  {
    id: 28,
    name: 'MEDIAN()',
    type: 'functions',
    icon: 'mdiNumeric',
    description: 'Mengembalikan median dari sekumpulan angka.',
    example: {
      title: 'MEDIAN(number1, number2, ...)',
      syntax: [
        {
          code: 'MEDIAN(2,3,5)',
        },
        {
          code: 'MEDIAN(2,9,6,7)',
        },
      ],
    },
  },
  {
    id: 39,
    name: 'AVERAGE()',
    type: 'functions',
    icon: 'mdiNumeric',
    description: 'Mengembalikan rata-rata sekumpulan angka.',
    example: {
      title: 'AVERAGE(number1, number2, ...)',
      syntax: [
        {
          code: 'AVERAGE(1,2,3)',
        },
        {
          code: 'AVERAGE(1,2,3,4)',
        },
      ],
    },
  },
  {
    id: 37,
    name: 'SUM()',
    type: 'functions',
    icon: 'mdiNumeric',
    description: 'Mengembalikan nilai dari menjumlahkan sekumpulan angka.',
    example: {
      title: 'SUM(number1, number2, ...)',
      syntax: [
        {
          code: 'SUM(1,2,3)',
        },
        {
          code: 'SUM(2,4,8,10)',
        },
      ],
    },
  },
  {
    id: 38,
    name: 'PRODUCT()',
    type: 'functions',
    icon: 'mdiNumeric',
    description: 'Mengembalikan nilai dari mengkalikan sekumpulan angka.',
    example: {
      title: 'PRODUCT(number1, number2, ...)',
      syntax: [
        {
          code: 'PRODUCT(1,2,3)',
        },
        {
          code: 'PRODUCT(1,2,3,4)',
        },
      ],
    },
  },
  {
    id: 22,
    name: 'IF()',
    type: 'functions',
    description: 'Menentukan tes logis yang akan dilakukan.',
    icon: 'mdiSigma',
    example: {
      title: 'IF(logical_value, true_condition, false_condition)',
      syntax: [
        {
          code: 'IF(4 > 2 , "True", "False")',
        },
        {
          code: 'IF(4 < 2 , "True", "False")',
        },
      ],
    },
  },
  {
    id: 31,
    name: 'CHOOSE()',
    type: 'functions',
    icon: 'mdiSigma',
    description: 'Menggunakan indeks untuk mengembalikan nilai dari daftar',
    example: {
      title: 'CHOOSE(index, data_1, data_2, data_3, ...)',
      syntax: [
        {
          code: 'CHOOSE(1,"Apel","Nanas","Rambutan")',
        },
        {
          code: 'CHOOSE(3,"Apel","Nanas","Rambutan")',
        },
      ],
    },
  },
  {
    id: 23,
    name: 'NOT()',
    type: 'functions',
    icon: 'mdiCheckboxMarked',
    description: 'Membalikkan nilai logika.',
    example: {
      title: 'NOT(logic)',
      syntax: [
        {
          code: 'NOT(4 = 4)',
        },
        {
          code: 'NOT(4 = 2)',
        },
      ],
    },
  },
  {
    id: 24,
    name: 'AND()',
    type: 'functions',
    icon: 'mdiCheckboxMarked',
    description: 'Mengembalikan TRUE jika semua argumen adalah TRUE.',
    example: {
      title: 'AND(logicalValue1, logicalValue2, ...)',
      syntax: [
        {
          code: 'AND(4 = 4, 2 = 2)',
        },
        {
          code: 'AND(4 = 2, 2 = 2)',
        },
      ],
    },
  },
  {
    id: 25,
    name: 'OR()',
    type: 'functions',
    icon: 'mdiCheckboxMarked',
    description: 'Mengembalikan TRUE jika setidaknya satu argumen adalah TRUE.',
    example: {
      title: 'OR(logicalValue1, logicalValue2, ...)',
      syntax: [
        {
          code: 'OR(4 = 3, 2 = 2)',
        },
        {
          code: 'OR(4 = 2, 1 = 2)',
        },
      ],
    },
  },
]

export { registerFunctions }
