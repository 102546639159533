<template>
  <div>
    <div style="display: flex; justify-content: space-between">
      <div
        class="py-1"
        style="overflow-x: hidden; width: 90%"
      >
        {{ customAttribute.formula }}
      </div>

      <v-tooltip text="Tooltip">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            color="warning"
            x-small
            fab
            @click="showDialog = !showDialog"
          >
            <v-icon size="18"> {{ icons.mdiPencil }} </v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </div>

    <!-- Dialog -->
    <v-dialog
      v-model="showDialog"
      max-width="700"
      scrollable
    >
      <v-card>
        <div class="d-flex align-start pa-4">
          <span class="subtitle-1 text-center font-medium primary--text"> Update Formula </span>
          <v-icon
            class="ml-auto mt-1"
            @click="showDialog = !showDialog"
          >
            {{ icons.mdiClose }}
          </v-icon>
        </div>

        <v-divider />
        <v-card-text>
          <v-row class="my-3 mx-2">
            <v-col cols="12">
              <formula-input
                :edit="true"
                :custom-attributes="customAttributesBuild"
                :custom-attribute="customAttribute"
                :static-attribute="staticAttributes"
                :status-options="statusOptions"
                :job-detail="jobDetail"
                :priority-options="priorityOptions"
                @update-datatype="onUpdateDatatype"
                @update-formula="
                  f => {
                    customAttribute.formula = f
                  }
                "
              />
            </v-col>
            <v-col cols="12">
              <v-btn
                @click="updateFormula"
                class="ms-auto"
                style="display: block"
                color="success"
                >Save Formula</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Component ini digunakan khusus pada component JobForm.vue
import { mdiPencil, mdiClose } from '@mdi/js'
import { ref } from 'vue'
import FormulaInput from './FormulaInput.vue'
import { apolloClient } from '@/vue-apollo'
import { updateCustomAttributeFormula } from '@/graphql/mutations'
import store from '@/store'
import Vue from 'vue'

export default {
  components: {
    FormulaInput,
  },

  props: {
    customAttribute: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    customAttributes: {
      type: Array,
      required: true,
    },
    staticAttributes: {
      type: Object,
      required: false,
    },
    jobDetail: {
      type: Object,
      required: false,
    },
    priorityOptions: {
      type: Array,
      required: false,
    },
    statusOptions: {
      type: Array,
      required: false,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const showDialog = ref(false)
    const updatedId = ref(0)
    const customAttributesBuild = ref(props.customAttributes)

    if (props.isDetail) {
      customAttributesBuild.value = customAttributesBuild.value.map(e => ({
        custom_attribute: e,
        value: props.jobDetail.custom_attribute_values[e.id],
      }))
    }

    const updateFormula = () => {
      apolloClient
        .mutate({
          mutation: updateCustomAttributeFormula,
          fetchPolicy: 'no-cache',
          variables: {
            jobTypeId: props.jobDetail.job_type.id,
            workspaceId: store.getters.getCurrentWorkspaceId,
            update: {
              data_type_id: updatedId.value,
              formula: props.customAttribute.formula,
              id: props.customAttribute.id,
              name: props.customAttribute.name,
              options: null,
              order: props.customAttribute.order,
            },
          },
        })
        .then(() => {
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil Menyimpan Formula',
          })

          showDialog.value = false
        })
    }

    const onUpdateDatatype = id => {
      updatedId.value = id
    }

    return {
      showDialog,
      customAttributesBuild,
      updateFormula,
      onUpdateDatatype,

      icons: {
        mdiPencil,
        mdiClose,
      },
    }
  },
}
</script>
