import gql from 'graphql-tag'

const deleteFolder = gql`
  mutation deleteFolder($workspace_id: Float!, $folder_id: Float!) {
    deleteFolder(workspace_id: $workspace_id, folder_id: $folder_id) {
      status
      msg
      data
    }
  }
`

export default deleteFolder
