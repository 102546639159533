import store from '@/store'

export const notificationAccessor = [
  {
    id: 0,
    name: 'Penting',
    state: () => store.state.notification.normalNotificationList,
    path: 'normalNotificationList',
    badge: 'unreadNormalNotification',
  },
  {
    id: 1,
    name: 'Tersnooze',
    state: () => store.state.notification.snoozedNotificationList,
    path: 'snoozedNotificationList',
    badge: 'unreadSnoozedNotification',
  },
  {
    id: 2,
    name: 'Terarsipkan',
    state: () => store.state.notification.archivedNotificationList,
    path: 'archivedNotificationList',
    badge: 'unreadArchivedNotification',
  },
]
