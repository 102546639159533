import gql from 'graphql-tag'

export const deleteCustomAttribute = gql`
  mutation deleteCustomAttribute($id: Float!) {
    deleteCustomAttribute(id: $id) {
      msg
      status
      data
    }
  }
`
