import gql from 'graphql-tag'

const assignCustomerTypes = gql`
  mutation ($customer_id: Float!, $customerType: [Int!]!, $workspace_id: Float!) {
    assignCustomerType(
      customer_id: $customer_id
      customerType: $customerType
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`

export default assignCustomerTypes
