import gql from 'graphql-tag'

export const updateJobTypeAttribute = gql`
  mutation ($name: String!, $color: String!, $job_type_id: Float!, $workspace_id: Float!) {
    updateJobTypeAttribute(
      name: $name
      color: $color
      job_type_id: $job_type_id
      workspace_id: $workspace_id
    ) {
      status
      data
      msg
    }
  }
`
