/**
 * @module viewer/viewerui
 */

import { ButtonView } from '@ckeditor/ckeditor5-ui'
import { Plugin } from '@ckeditor/ckeditor5-core'

export default class ViewerUI extends Plugin {
  static get pluginName() {
    return 'ViewerUI'
  }

  init() {
    const { editor } = this
    const { t } = editor

    editor.ui.componentFactory.add('viewer', () => {
      const command = editor.commands.get('viewer')
      const view = new ButtonView()

      view.set({
        label: t('View File'),
        withText: true,
        tooltip: true,
        isToggleable: true,
      })

      view.bind('isOn', 'isEnabled').to(command, 'value', 'isEnabled')

      this.listenTo(view, 'execute', () => {
        editor.execute('viewer')
        editor.editing.view.focus()
      })

      return view
    })
  }
}
