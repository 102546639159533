import gql from 'graphql-tag'

const insertBatchCustomer = gql`
  mutation ($data: [paramCustomerBatch!]!, $workspace_id: Float!) {
    insertBatchCustomer(data: $data, workspace_id: $workspace_id) {
      status
      msg
      data
    }
  }
`

export default insertBatchCustomer
