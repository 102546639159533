import gql from 'graphql-tag'

const deleteUser = gql`
  mutation ($id: Float!) {
    deleteUser(id: $id) {
      status
      msg
      data
    }
  }
`

export default deleteUser
