import gql from 'graphql-tag'

const productDetail = gql`
  query ($product_id: Float!, $workspace_id: Float!) {
    productDetail(product_id: $product_id, workspace_id: $workspace_id) {
      id
      name
      description
      code_sku
      price
      status
      stock
      photo {
        id
        photo
        order_photo
      }
      created_at
      updated_at
      deleted_at
    }
  }
`

export default productDetail
