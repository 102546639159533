import gql from 'graphql-tag'

export const editFolderPermission = gql`
  mutation updateFolderPermission(
    $workspace_id: Float!
    $folder_id: Float!
    $is_public: Boolean!
    $user_permissions: [UserFolderPermission!]!
  ) {
    updateFolderPermission(
      workspace_id: $workspace_id
      folder_id: $folder_id
      is_public: $is_public
      user_permissions: $user_permissions
    ) {
      status
      msg
      data
    }
  }
`
