import gql from 'graphql-tag'

const activityComment = gql`
  query activityComment(
    $pagination: pagination!
    $is_job: Boolean
    $is_task: Boolean
    $workspace_id: Float!
  ) {
    activityComment(
      pagination: $pagination
      is_job: $is_job
      is_task: $is_task
      workspace_id: $workspace_id
    ) {
      job_id
      task_id
      name
    }
  }
`

export default activityComment
