import gql from 'graphql-tag'

const cities = gql`
  query ($search: String) {
    cities(search: $search) {
      id
      name
    }
  }
`

export default cities
