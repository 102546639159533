import gql from 'graphql-tag'

const getAccurateAccounts = gql`
  query getAccurateAccounts(
    $token_id: Float!
    $db_id: Float!
    $type: String!
    $pagination: pagination!
    $search: String!
  ) {
    getAccurateAccounts(
      token_id: $token_id
      db_id: $db_id
      type: $type
      pagination: $pagination
      search: $search
    ) {
      no
      accountType
      name
      accountTypeName
      id
      currency {
        code
        id
      }
    }
  }
`

export default getAccurateAccounts
