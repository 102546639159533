import gql from 'graphql-tag'

const corpTokenList = gql`
  query corpTokenList($platformId: Float) {
    corpTokenList(platformId: $platformId) {
      id
      access_token
      refresh_token
      platform {
        id
        name
      }
      created_at
      updated_at
      deleted_at
    }
  }
`

export default corpTokenList
