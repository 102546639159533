import gql from 'graphql-tag'

export const changeTeamOwnership = gql`
  mutation changeTeamOwnership($user_id: Float!, $team_id: Float!, $workspace_id: Float!) {
    changeTeamOwnership(user_id: $user_id, team_id: $team_id, workspace_id: $workspace_id) {
      status
      msg
      data
    }
  }
`
