import gql from 'graphql-tag'

const connectProfileToCustomer = gql`
  mutation connectProfileToCustomer(
    $customer_id: Float!
    $profile_id: Float!
    $workspace_id: Float!
  ) {
    connectProfileToCustomer(
      customer_id: $customer_id
      profile_id: $profile_id
      workspace_id: $workspace_id
    ) {
      status
      msg
      data
    }
  }
`

export default connectProfileToCustomer
