import gql from 'graphql-tag'

const jobDetail = gql`
  query jobDetail($job_id: Float!, $workspace_id: Float!) {
    jobDetail(job_id: $job_id, workspace_id: $workspace_id) {
      id
      name
      description
      job_type {
        id
        name
        folder {
          id
        }
      }
      status {
        id
        name
        color
      }
      priority {
        id
        name
        color
      }
      referenceTag {
        id
        tag {
          id
          name
          color
        }
      }
      products {
        product {
          id
          name
          description
          code_sku
          price
          status
          stock
          photo {
            id
            photo
            order_photo
          }
          created_at
          updated_at
        }
        quantity
      }
      start_date
      expected_close_date
      customer {
        id
        name
        gender
        description
        address
        job_title
        birth_date
        phone_number
        whatsapp_number
        email
        instagram
        facebook
        type {
          id
          name
          color
        }
      }
      created_by {
        id
        phone
        email
        name
        first_name
        last_name
        photo
      }
      created_at
      updated_at
      deleted_at
      assignedTo {
        id
        user {
          id
          phone
          email
          name
          first_name
          last_name
          photo
        }
      }
      relation_type {
        id
        name
        origin
        multiple_record
        color
        relate_to_job_type {
          name
          id
        }
        relation {
          relate_to_job {
            ...RelationJob
          }
        }
      }
      lookup {
        id
        name
        function_name
        value
      }
      custom_attribute_values
      sub_job {
        id
        name
        description
        strip_description
        job_type {
          id
          name
        }
        parent {
          id
        }
        status {
          id
          name
          color
          urutan
        }
        priority {
          id
          name
          color
          urutan
        }
        start_date
        expected_close_date
        customer {
          id
          name
        }
        created_by {
          id
          first_name
          last_name
          name
        }
        created_at
        updated_at
        deleted_at
        assignedTo {
          id
          user {
            id
            phone
            email
            name
            first_name
            last_name
            photo
          }
        }
        lexorank
        custom_attribute_values
      }
      attachment {
        id
        file_name
        file_type
        file_size
        is_cover
        created_at
        modify_at
        user {
          id
          name
        }
      }
      parent {
        name
      }
      notification_reference {
        user {
          name
        }
      }
    }
  }

  fragment RelationJob on Job {
    id
    name
    description
    strip_description
    attachment {
      file_name
      id
      file_size
      file_type
      created_at
    }
    job_type {
      id
      name
    }
    status {
      id
      name
      color
      urutan
    }
    priority {
      id
      name
      color
      urutan
    }
    start_date
    expected_close_date
    customer {
      id
      name
    }
    created_by {
      id
      first_name
      last_name
      name
    }
    created_at
    updated_at
    deleted_at
    assignedTo {
      id
      user {
        id
        phone
        email
        name
        first_name
        last_name
        photo
      }
    }
    lexorank
    custom_attribute_values
    parent {
      id
    }
    sub_job {
      id
      name
      description
      strip_description
      job_type {
        id
        name
      }
      parent {
        id
      }
      status {
        id
        name
        color
        urutan
      }
      priority {
        id
        name
        color
        urutan
      }
      start_date
      expected_close_date
      customer {
        id
        name
      }
      created_by {
        id
        first_name
        last_name
        name
      }
      created_at
      updated_at
      deleted_at
      assignedTo {
        id
        user {
          id
          phone
          email
          name
          first_name
          last_name
          photo
        }
      }
      lexorank
      custom_attribute_values
    }
  }
`

export default jobDetail
