import gql from 'graphql-tag'

const updateChannel = gql`
  mutation (
    $channel_id: Float!
    $name: String!
    $comment_permission: Boolean!
    $channel_notification: Boolean!
    $is_pinned: Boolean!
  ) {
    updateChannel(
      channel_id: $channel_id
      name: $name
      comment_permission: $comment_permission
      channel_notification: $channel_notification
      is_pinned: $is_pinned
    ) {
      status
      msg
      data
    }
  }
`

export default updateChannel
