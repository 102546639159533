import gql from 'graphql-tag'

const commentsAssigned = gql`
  query CommentsAssigned(
    $documentId: String
    $jobId: Float
    $pagination: pagination
    $isResolved: String!
    $userId: [Float!]!
    $workspaceId: Float!
    $filter: paramCommentsAssigned!
  ) {
    commentsAssigned(
      document_id: $documentId
      job_id: $jobId
      pagination: $pagination
      is_resolved: $isResolved
      user_id: $userId
      workspace_id: $workspaceId
      filter: $filter
    ) {
      count
      comments {
        id
        comment
        resolved_at
        created_at
        updated_at
        job {
          id
          name
          parent {
            id
            name
          }
          job_type {
            id
            name
            folder {
              id
              name
            }
          }
        }
        document {
          uuid
          name
          folder {
            id
            name
          }
        }
        attachment {
          id
          file_name
        }
      }
    }
  }
`

export default commentsAssigned
