/**
 * @module uppy-upload/uploadui
 */

import { ButtonView } from '@ckeditor/ckeditor5-ui'
import { Plugin, icons } from '@ckeditor/ckeditor5-core'

export default class UploadUI extends Plugin {
  static get pluginName() {
    return 'UploadUI'
  }

  init() {
    const { editor } = this
    const { t } = editor

    editor.ui.componentFactory.add('uppyUpload', () => {
      const command = editor.commands.get('uppyUpload')
      const view = new ButtonView()

      view.set({
        label: t('Upload File'),
        icon: icons.importExport,
        tooltip: true,
        isToggleable: true,
      })

      view.bind('isOn', 'isEnabled').to(command, 'value', 'isEnabled')

      this.listenTo(view, 'execute', () => {
        editor.execute('uppyUpload')
        editor.editing.view.focus()
      })

      return view
    })
  }
}
