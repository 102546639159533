import gql from 'graphql-tag'

export const deleteSavedView = gql`
  mutation deleteSavedView($saved_view_id: Float!, $workspace_id: Float!) {
    deleteSavedView(saved_view_id: $saved_view_id, workspace_id: $workspace_id) {
      status
      msg
    }
  }
`
