import gql from 'graphql-tag'

const unarchiveChannel = gql`
  mutation ($channel_id: Float!) {
    unarchiveChannel(channel_id: $channel_id) {
      status
      msg
      data
    }
  }
`

export default unarchiveChannel
