import gql from 'graphql-tag'

export const duplicateDocument = gql`
  mutation duplicateDocument($documentUuid: String!, $workspaceId: Float!) {
    duplicateDocument(document_uuid: $documentUuid, workspace_id: $workspaceId) {
      status
      msg
      data
    }
  }
`
