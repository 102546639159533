import gql from 'graphql-tag'

export const createTeam = gql`
  mutation createTeam(
    $name: String!
    $team_id: Float
    $is_public: Boolean!
    $description: String!
    $workspace_id: Float!
    $channel_permission: Boolean!
    $is_included_member: Boolean!
    $member: [UserTeamPermission!]!
  ) {
    createTeam(
      name: $name
      member: $member
      team_id: $team_id
      is_public: $is_public
      description: $description
      workspace_id: $workspace_id
      channel_permission: $channel_permission
      is_included_member: $is_included_member
    ) {
      data
      msg
      status
    }
  }
`
