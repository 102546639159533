<template>
  <div class="pa-3">
    <div class="d-flex flex-wrap flex-column flex-md-row justify-space-between">
      <h3>Integrasi Telegram BOT</h3>
      <v-btn
        color="#0088cc"
        small
        class="mt-4 mt-md-0"
        @click="$refs.telegramForm.show()"
      >
        <span class="text-white">Tambah Integrasi BOT Telegram</span>
      </v-btn>
    </div>
    <v-card
      class="mt-6"
      outlined
    >
      <v-alert
        v-if="!tokenDetail.length && !loadingDetail"
        border="left"
        color="warning"
        dense
        text
        class="mb-0 text-subtitle-2 py-2 rounded-b-0"
      >
        <span class="text-caption"
          >Telegram BOT belum terhubung ke Oriens CRM. Ikuti instruksi di bawah untuk
          menghubungkan.</span
        >
      </v-alert>
      <div
        v-else-if="loadingDetail"
        class="d-flex justify-center"
      >
        <v-progress-circular
          indeterminate
          color="info"
          class="my-4"
        />
      </div>
      <div v-else>
        <telegram-bot-connection-card
          v-for="token in tokenDetail"
          :key="token.id"
          :connection-data="token"
          @onDeleteSuccess="getTokenDetail(1)"
        />
      </div>
      <v-divider />
      <div class="pa-3 d-flex flex-column">
        <h5>Apa itu Telegram?</h5>
        <span class="text-subtitle-2"
          >Telegram adalah sebuah aplikasi layanan pengirim pesan instan multiplatform berbasis
          cloud yang bersifat gratis dan nirlaba.</span
        >
      </div>
      <v-divider />
      <div class="pa-3 d-flex flex-column">
        <h5>Cara Integrasi BOT Telegram</h5>
        <span class="text-subtitle-2 font-weight-bold">Jika belum memiliki Bot Telegram</span>
        <span class="text-subtitle-2"
          >1. Buat bot terlebih dahulu,
          <a
            class="font-weight-bold"
            href="https://t.me/BotFather"
            target="_blank"
            >klik disini</a
          >
          atau cari
          <a
            href="https://t.me/BotFather"
            target="_blank"
            class="primary--text font-weight-bold"
            >@BotFather</a
          >
          pada kolom pencarian Telegram untuk membuat bot</span
        >
        <span class="text-subtitle-2"
          >2. Klik tombol <span class="warning--text font-weight-boldt">start.</span>, dan klik
          perintah <span class="warning--text font-weight-boldt">/newbot.</span></span
        >
        <span class="text-subtitle-2">3. Ikuti petunjuk dari Telegram.</span>
        <span class="text-subtitle-2"
          >4. Jika sudah, salin token yang anda dapatkan dari
          <a
            href="https://t.me/BotFather"
            target="_blank"
            class="primary--text font-weight-bold"
            >@BotFather</a
          >.</span
        >
        <span class="text-subtitle-2"
          >5. Kembali ke Oriens, klik tombol Tambah Integrasi Telegram.</span
        >
        <span class="text-subtitle-2">6. Tempel token yang sudah disalin ke dalam form.</span>
        <span class="text-subtitle-2">7. Klik simpan.</span>
        <span class="text-subtitle-2">8. Tunggu sampai bot menampilkan status terhubung.</span>
        <span class="text-subtitle-2"
          >9. Selamat! Bot Telegram anda sudah terhubung dengan Oriens.</span
        >
        <span class="text-subtitle-2 font-weight-bold">Jika sudah memiliki Bot Telegram</span>
        <span class="text-subtitle-2"
          >1. Salin token yang anda dapatkan dari
          <a
            href="https://t.me/BotFather"
            target="_blank"
            class="primary--text font-weight-bold"
            >@BotFather</a
          >.</span
        >
        <span class="text-subtitle-2"
          >2. Kembali ke Oriens, klik tombol Tambah Integrasi Telegram.</span
        >
        <span class="text-subtitle-2">3. Tempel token yang sudah disalin ke dalam form.</span>
        <span class="text-subtitle-2">4. Klik simpan.</span>
        <span class="text-subtitle-2">5. Tunggu sampai bot menampilkan status terhubung.</span>
        <span class="text-subtitle-2"
          >6. Selamat! Bot Telegram anda sudah terhubung dengan Oriens.</span
        >
      </div>
    </v-card>

    <telegram-bot-form
      ref="telegramForm"
      @success="getTokenDetail(1)"
    />
  </div>
</template>

<script>
import { onMounted } from 'vue'
import TelegramBotForm from './TelegramBotForm.vue'
import useIntegration from '@/composables/useIntegration'
import TelegramBotConnectionCard from '@/views/integration/telegram-bot/component/TelegramBotConnectionCard.vue'

export default {
  components: {
    TelegramBotForm,
    TelegramBotConnectionCard,
  },
  setup() {
    const { getTokenDetail, loadingDetail, tokenDetail } = useIntegration()
    onMounted(() => {
      getTokenDetail(1)
    })

    return {
      loadingDetail,
      tokenDetail,
      getTokenDetail,
    }
  },
}
</script>

<style></style>
